import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PASSWORD_RESET_REQUIRED_EXCEPTION } from './constants';
import LoginForm from './components/login-form';

import * as actions from "./actions";

const mapStateToProps = state => ({
  ...state.session,
});

class Login extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.error && nextProps.error.code === PASSWORD_RESET_REQUIRED_EXCEPTION) {
      this.props.history.push('/change-password');
    }
  }

  render() {
    const { error } = this.props;

    return (
      <div className="container mt-4">

        { error && error.message &&              
          <div className="d-flex justify-content-center">
            <div className="alert alert-danger w-50" role="alert">
              {error.message}
            </div>
          </div>
        }
        <LoginForm {...this.props} />
      </div>
    )
  }
}

export default withRouter(connect(
  mapStateToProps,
  { ...actions },
)(Login));