export const SET_PLANNING_TAB                     = '/planning/SET_PLANNING_TAB';

export const PLANNING_SOURCE                      = '/planning/SOURCE';

export const PLANNING_TAB_HOMES                   = 'homes';
export const PLANNING_TAB_WINERIES                = 'wineries';
export const PLANNING_TAB_BREWERIES               = 'breweries';
export const PLANNING_TAB_GOLF                    = 'golf';
export const PLANNING_TAB_RESTAURANTS             = 'restaurants';
export const PLANNING_TAB_COFFEE_SHOPS            = 'coffeeShops';
export const PLANNING_TAB_MORE                    = 'more';

export const PLANNING_SELECT_SOURCE               = '/planning/PLANNING_SELECT_SOURCE';

export const PLANNING_INCREASE_SEARCH_WIDTH       = '/planning/INCREASE_SEARCH_WIDTH';
export const PLANNING_DECREASE_SEARCH_WIDTH       = '/planning/DECREASE_SEARCH_WIDTH';

export const PLANNING_LOCATION_CATEGORIES_SUCCESS = '/planning/LOCATION_CATEGORIES_SUCCESS';
export const PLANNING_LOCATION_CATEGORIES_ERROR   = '/planning/LOCATION_CATEGORIES_ERROR';

export const PLANNING_SET_DESTINATION_ID          = '/planning/DESTINATION_ID';
export const PLANNING_SET_THINGS_TO_DO_ID         = '/planning/THINGS_TO_DO_ID';

export const PLANNING_SET_INFO                    = '/planning/SET_INFO';

export const SET_PLANNING_VIEW                    = '/planning/SET_PLANNING_VIEW';
export const PLANNING_VIEW_MAP                    = '/planning/PLANNING_VIEW_MAP';
export const PLANNING_VIEW_GRID                   = '/planning/PLANNING_VIEW_GRID';

export const PLANNING_TOGGLE_LOCATION_FAVORITE    = '/planning/TOGGLE_LOCATION_FAVORITE';