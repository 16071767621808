import firebase from "firebase/compat/app";
import 'firebase/compat/storage';

import {v4} from 'uuid';

import { saveDocument } from '../actions/actions';

export const saveLocationCategory = (entity, images, sizes) => (dispatch) => {
  console.log('save------', entity);

  var saved = false; 
  
  if (images && images.length > 0) {
    let file = images[0];
    const uuid = v4();

    var matched = file.name.match(/\.(png|jpg|gif|jpeg)$/)
    if (matched) {
      var name = `${uuid}${matched[0]}`;
      saved = true;

      var ref = firebase.storage().ref();
      var child = ref.child(name);
      child.put(file).then((snapshot) => {
        entity.imageFileName = name;
        entity.imageSize = sizes[0];        
        dispatch(saveDocument('locationCategories', entity, '/location-categories'))
      });
    } else {
      console.log("image not matched");
    }   
  }

  if (!saved) {
    dispatch(saveDocument('locationCategories', entity, '/location-categories'))
  }
  
  // dispatch(saveDocument('locationCategories', entity, '/location-categories'))
};
