import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import LocationCard from './location-card';

function CustomHits ({ hits, refresh, pageDoneChanging, pageChange, uid, user }) {
    
   return (
    <div>
        <ul className="list-group">
            {hits.map( hit => (
            <li className="list-inline-item">
                <LocationCard hit={hit} refresh={refresh} pageDoneChanging={pageDoneChanging} pageChange={pageChange} uid={uid} user={user}/>
            </li>
            ))}
        </ul>
    </div>
);
    
}

export default connectHits(CustomHits);