import React from 'react';

import { Highlight } from 'react-instantsearch-dom';
import PhoneNumber from '../../components/phone-number';
import FirebaseImage from '../../firebase/firebase-image';
import LocationCardInfoFooter from './location-card-info-footer';

import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getImageAspect } from '../../helpers';
import LocationLink from './location-link';
import get from 'lodash/get';
import { getUID, getUser } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';
import { addActivity } from '../../firebase'


export default ({ hit, setEditing, updatedItem }) => {
  if (updatedItem) {
    console.log("hit----", hit);
  }
  
  const signedIn = useSelector(state => Boolean(state.session.user));
  const uid = useSelector(state => getUID(state));
  const user = useSelector(state => getUser(state));
  const imageAspect = getImageAspect(hit.imageSize);

  const registerViewLocationActivity = async () => {
    let activity = {
      email: user.email,
      locationName: hit.name,
      locationID: hit.objectID,
      activity: 'viewedLocation',
    }

    await addActivity(activity);
  }

  return (
    <div className={classNames("card mt-3 text-left", { 'border-secondary': updatedItem == true})} id={hit.objectID}>     
      <div className={classNames("card-header", { 'bg-secondary': updatedItem == true})}>
        <h5 className="card-title mb-0">
          {
            signedIn && IDCheck(uid) &&
            <span>
              <LocationLink id={hit.objectID} registerViewLocationActivity={registerViewLocationActivity}>
                <i className="fa fa-pencil-square" />                
              </LocationLink>
              <button className="btn btn-link" onClick={() => {
                setEditing(true);
                registerViewLocationActivity();
                }}>
                <i className="fa fa-pencil-square-o" />
              </button>
            </span>
          }

          <Highlight hit={hit} attribute="name" />
          <span style={{ fontSize: '10px', color: 'lightgray', marginLeft: '4px' }}>{hit.objectID}</span>
        </h5>   
      </div>       
      
      <div className="card-body">
      { hit.description && <p className="card-text">{hit.description}</p> }

      <div className="card-text">
        { hit.street && <span><Highlight hit={hit} attribute="street" />,&nbsp;</span> }
        <Highlight hit={hit} attribute="city" />, <Highlight hit={hit} attribute="region" /> <Highlight hit={hit} attribute="postalCode" />            

        <div>
          <PhoneNumber number={hit.phoneNumber} />
        </div>
      </div>
      { hit.url && <a href={hit.url} className="card-link" target="_blank" rel="noopener noreferrer">{hit.url}</a> }   
        <div>                     
          <FirebaseImage imageFileName={hit.imageFileName} style={{ width: "25%" } } />
        </div>
    </div>
      
      { (hit.categories || hit.file) &&
        <LocationCardInfoFooter hit={hit} />
      }
    </div>
  );
}