import places from 'places.js';

export const PLACES_CONFIG = {
  appId: 'plVO35LQZXJB',
  apiKey: '65814cefc7696d8ee0a3d40beba169a2',
  countries: ['us'],  
};

// container: '#my-input-DOM-selector',
export const placesConfig = (settings) => {
  const config = { ...PLACES_CONFIG, ...settings };
  console.log("places", config);

  return places(config);
}; 
