import React from 'react';
import { Route, Switch } from 'react-router';

import PlacesCustomSearch from './containers/places-custom-search';
import PlaceCustom from './containers/place-custom';

export default () =>
  <Switch>
    <Route exact path="/places-custom">
        <PlacesCustomSearch/>
    </Route>

    <Route exact path="/place-custom/:id">
        <PlaceCustom/>
    </Route>
  </Switch>;