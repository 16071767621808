import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router";

import PlaceCustomForm from '../components/places-custom-form';

import { deletePlaceCustom, addPlaceCustom } from '../../firebase';

function PlaceCustom( props ) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [place, setPlace] = useState({new: true});
    const [canDelete, setCanDelete] = useState(null);
    const [submitComplete, setSubmitComplete] = useState(false);
    const [stateOptions, setStateOptions] = useState([
        "AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL",
        "GA", "HI","IA","ID","IL","IN","KS","KY","LA","MA",
        "MD","ME","MI","MN","MO","MS","MT","NC","ND","NE",
        "NH","NJ","NM","NV","NY","OH","OK","OR","PA","RI",
        "SC","SD","TN","TX","UT","VA","VT","WA","WI","WV","WY"
    ])
    const [states, setStates] = useState(null);

    const { id } = useParams();
    const { state } = useLocation();
    let history = useHistory();

    useEffect(() => {
        if ( id === 'new') {
          console.log("new prop location");
          setCanDelete(false);
        }else {
          console.log(state.hit);
          setPlace(state.hit);
          console.log(place);
          setCanDelete(true);
        }
    });

    useEffect(() => {
        if ( id !== 'new') {
          if(place.state!==undefined){
            if(place.state.length > 2){
                let stateArray = place.state.split(' & ');
                setStates(stateArray);
            }else{
                setStates(place.state);
            }
          }
          console.log(states);
        }
    }, [place]);

    useEffect(() => {
      if (submitComplete) {
        console.log("done submitting");
      history.push("/places-custom");
      }
    }, [submitComplete]);

      function savePlace(values) {
        console.log('saving place', values);
        let placeData = {};

        let _geoloc = {
          lat: Number(values._geoloc.lat),
          lng: Number(values._geoloc.lng)
          
        }

        let stateString = values.state.join(" & ");

        let radius = '';

        if(values.radius) {
          radius = values.radius;
        }

        if (id === 'new') {
          console.log("saveing new place");

            placeData = {
              _geoloc: _geoloc,
              name: values.name,
              state: stateString,
              lat: _geoloc.lat,
              lng: _geoloc.lng,
              radius: radius
            }
            
            console.log(placeData);
            let complete = addPlaceCustom(placeData);
            complete.then(function(results) {
              console.log(results);
              setSubmitComplete(results);
            });

        } else {
          
            console.log("saving updated place data");

            placeData = {
              _geoloc: _geoloc,
              name: values.name,
              state: stateString,
              lat: _geoloc.lat,
              lng: _geoloc.lng,
              radius: radius,
              objectID: place.objectID
            }

            console.log(placeData);
            let complete = addPlaceCustom(placeData);
            complete.then(function(results) {
              console.log(results);
              setSubmitComplete(results);
            });
        }

        setIsSubmitting(true);
      }

      function deletePlaceData() {
        console.log("deleting place");
        console.log("place");
        setIsSubmitting(true);
        let complete = deletePlaceCustom(place);
        complete.then(function(results) {
          console.log(results);
          setSubmitComplete(results);
        });
      }

    return (
        <div className="container mt-4">        
        <h2 className="text-left">Place Custom</h2>
        <PlaceCustomForm
        isSubmitting={isSubmitting}
        savePlace={savePlace}
        deletePlace={deletePlaceData}
        place={place}
        canDelete={canDelete}
        stateOptions={stateOptions}
        states={states}
        />
      </div>

    );
}

export default PlaceCustom;