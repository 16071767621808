import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

import CategoryGridItem from './category-grid-item';

const CategoryGrid = ({ locationCategories, selectedCategory }) => {

    if (isEmpty(locationCategories)) {
        return ( <span></span> )
    } 

    return (        
        <div className="row">
        {
            orderBy(filter(locationCategories, 'locationCategoryTypeId'), 'name', 'asc').map((category, index) => {
                return (
                
                category.locationCategoryTypeId && category.imageFileName &&
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>              
                    <CategoryGridItem key={category.objectID} category={category} selectedCategory={selectedCategory} />              
                </div>
                
            )})
        }
        </div>
    )
  
}

export default CategoryGrid;