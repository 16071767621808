import React from 'react';
// import TripGalaxyMapContainer from '../map/trip-galaxy-map-container';
import Card from './card';
import Image, { SITE } from '../components/image';

const TempOne = () =>
  <div className="container mt-4">
    <div className="roa">
      <div className="point-of-interest carousel slide w-100" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Image className="d-block w-100 img-fluid" imageType={SITE} imageFileName="iStock-855272852.jpg" /> 
            <div className="carousel-caption text-left" style={{ position: "absolute", top: 5, left: 15 }}>
              <h1 style={{ fontWeight: 900 }}>CALIFORNIA WINERIES</h1>
              <p style={{ fontWeight: 'bold' }} className="d-none d-md-block">
                California accounts for nearly 90 percent of American wine production, it is geologically diverse
                and is full of varying terroirs (The complete natural environment including soil, topography and
                climate). The various growing regions and abundance of climates provide a uniquely diverse selection
                of wines, with different flavors and characteristics; there is a wine for every palate.
              </p>  
            </div>
          </div>
        </div>
      </div>    
    </div>    
    <Card title="The North Coast" lat={39.3661929} lng={-123.3616896} zoom={6}>
      The North Coast is the grape region that put California wine on the map, it includes Napa, Sonoma, Mendocino, Lake, Solano and Marin Counties. It is a designated AVA, and is home to more than half of the state's wineries.
    </Card>
    <Card title="Napa County" img="/DSC09078.jpeg">
    The Napa Valley contains several climate regions, providing an ideal terroir for growing a variety of wine grapes due to the variation in temperature and precipitation, as well as variations in soil composition and topography. Although nearly 90 percent of all United States wine is produced in California, and roughly a third of California wineries are based in Napa County, only about 4 percent of California wine is produced in Napa. It is home to 16 AVAs, over 600 wineries, and arguably some of the best restaurants and finest wines in the country. Napa is best known for Cabernet Sauvignon, but produces fine Chardonnay, Chenin Blanc, Merlot, Pinot Noir, Sauvignon Blanc, Riesling, and Zinfandel as well.
    </Card>
    <Card title="Sonoma County" img="iStock-172625585.jpg">
    Sonoma County is one of California's largest producers of wine grapes, far out producing the Napa Valley AVAs, it is home to 13 AVAs and more than 500 wineries. Sonoma grows more Pinot Noir grapes than any other county in the state, they also grow Chardonnay, Cabernet Sauvignon, Merlot, Sauvignon Blanc and Zinfandel.
    </Card>
    <Card title="Los Carneros" img="iStock-946697270.jpg">
    Los Carneros, often called Carneros, is an AVA that straddles the county lines of Napa and Sonoma. Adjacent to the San Pablo Bay it is an ideal growing region for Chardonnay and Pinot Noir and produces world-class sparkling wines from these varietals.
    </Card>
    <Card title="Lake County" img="iStock-516813100.jpg">
    With 5 AVAs, Lake County sits just east of Mendocino and is named for the largest inland body of water in the state of California, Clear Lake. Known for its fine, affordable Cabernet Sauvignon and Sauvignon Blanc, as well as Merlot, Chardonnay and some other less common varietals.
    </Card>
    <Card title="Mendocino County" img="iStock-1064005412.jpg">
    Mendocino County is the northernmost growing area in the region.  Home to 10 AVAs, with cool coastal fog, and redwood forest cover makes it perfect for growing Chardonnay, Cabernet Sauvignon and Pinot Noir. Because of the county's diverse geography and climate, dozens of other varietals grow here ranging from more familiar varietals such Sauvignon Blanc and Syrah, to lesser known varietals, such as Colombard and Charbono.
    </Card>
    <Card title="The Central Coast" img="iStock-507795392.jpg">
    The expansive area spanning the coastal region between Point Mugu and Monterey Bay. Located northwest of Los Angeles County and south of San Francisco and San Mateo counties; it includes six counties, Ventura, Santa Barbara, San Luis Obispo, Monterey, San Benito, and Santa Cruz. The Central Coast can be divided into two distinct growing regions, north and south.
    </Card>
    { false && 
    <Card title="The North Central Coast" lat={35.9257316} lng={-121.5345384} />
    }

    <Card title="Livermore Valley" img="iStock-967517026.jpg">
    East of the San Francisco Bay, the vineyards in the Livermore Valley date back to the 1760s, when Spanish missionaries planted the first wine grapes in the valley. In 1983 Carl H. Wente and James Concannon founded the first wineries there, both wineries are still in operation and still family run. While this region grows several different varietals, nearly 80% of California's Chardonnay vines trace their genetic roots to a Livermore Valley clone.
    </Card>
    <Card title="Monterey County" img="iStock-1045613060.jpg">
    Home to 6 AVAs, including the Monterey AVA that extends nearly 100 miles, from its northern point, north of Monterey Bay to its southern point, north of Paso Robles. Over 50% of the grapes grown in the Monterey AVA are Chardonnay. This region also produces world class Riesling, Pinot Noir, and Bordeaux varietals.
    </Card>

    <Card title="Santa Cruz Mountains" img="iStock-1009917164.jpg">
    One of California's first AVAs to be defined by its mountain topography, following the fog line along the coast to encompass their highest vineyards along wooded peaks and small vineyards tucked into quiet hillsides. The region is known primarily for Pinot Noir, Chardonnay and Cabernet Sauvignon, but many varietals are grown here. The tasting experience in this area is unique, winemakers are in many of he tasting rooms, allowing for a more personal wine tasting experience.
    </Card>

    <Card title="San Luis Obispo County" img="iStock-153496604.jpg">
    This region is populated by many artisan vintners and family-owned wineries. Home to a variety of terrains and micro-climates including cool coastal valleys and inland hills tempered by maritime breezes, known for Rhone blends, heritage Zinfandels, Pinot Noirs and Chardonnays.
    </Card>

    <Card title="Paso Robles" img="iStock-471059346.jpg">
    Among California's largest and oldest AVAs, with viticulture here dating back to the late 18th Century, when the first wine grapes were planted by Spanish missionaries. With optimal growing conditions, more than 60 wine grape varieties are grown here, ranging from Cabernet Sauvignon and Merlot to Syrah, Viognier and Roussanne, to Zinfandel, the area's heritage wine varietal. Famous for its Rhône varietals and blends, hosting the annual Hospice du Rhône, the world's liveliest gathering of international Rhône wine producers. 

    </Card>

    <Card title="Santa Barbara County" img="iStock-825712008.jpg">
    One of the world's most diverse wine growing regions, with its transverse mountain ranges, proximity to the Pacific Ocean, long growing season, and complex soils. This beautiful wine destination was immortalized in 2004 by the film Sideways, which celebrated the area's signature Pinot Noir. Santa Barbara County also produces superior Chardonnay, Syrah, Riesling, Cabernet Sauvignon and Zinfandel varietals.

    </Card>

    <Card title="The Central Valley" img="iStock-998881710.jpg">
    Although the coastal areas receive most of the recognition for producing some of the country's finest wines, the Central Valley is the largest wine grape growing region in California. This region stretches 300 miles, starting east of the San Francisco Bay in the central California valley and extending south through the Sacramento and San Joaquin Valleys, all the way to Bakersfield. Responsible for supplying more than 50% of the grapes to the state's winemakers, including Chardonnay, Cabernet Sauvignon and Zinfandel; this area also produces fine port-style wines.
    </Card>

    <Card title="The Sierra Foothills" img="/IMG_0464.jpg">
    The center of the California Gold Rush, winemaking came to this historic area in 1848, the same year gold was discovered. The Sierra Foothills AVA is one of the largest in the state of California, including portions of eight counties and boasting well over 100 wineries. Many of the wineries are small, boutique wineries, often family-owned. This area produces fine old-vine Zinfandel, Cabernet Sauvignon, Syrah, Barbera, Sangiovese, Viognier and Chardonnay. 

    </Card>

    <Card title="Southern California" img="iStock-836660804.jpg">
    Home to the oldest wine grapes in the state, planted in 1769 at Mission San Diego de Alcala, Southern California is expanding its presence in the wine market. Known for quiet vineyards tucked into rolling valleys and foothills, as well as intimate high-altitude vineyards. This area's temperate Mediterranean climates and warm regions tempered by coastal fog, produce Merlot, Chardonnay, Italian and Rhône varietals, old-vine Zinfandel and port-style wines.
    </Card>
  </div>;


export default TempOne;