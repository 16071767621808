import React from 'react';

const URL_BASE = 'https://firebasestorage.googleapis.com/v0/b/';

export const UPLOADED = "trip-galaxy.appspot.com/o/";
export const SITE = "trip-galaxy-site/o/";
export const PLACES = "trip-galaxy-places/o/";

export default ({ imageFileName, className, imageType=UPLOADED, alt, style={} }) => {
  if (imageFileName && imageFileName.length > 0) {
  return <img style={{ objectFit: 'contain', ...style }} draggable="false" src={`${URL_BASE}${imageType}${imageFileName}?alt=media`} className={className} alt={alt}   />;
  }

  return ( <span></span> )
}