import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

export default ({ item, locationCategoryTypes }) => {

  return (
    <li className="list-group-item">

      <div className="row">
        <div className="col-md-5 text-left">      
          <i className={`fa ${item.icon} mr-2`} />
          {
            item.name && item.children &&
            <Link 
            to={{
              pathname: `/location-category/${item.objectID}`,
              state: {subCategories: item.children}
            }}>{item.name}</Link>
          }
          {
            item.name && !item.children &&
            <Link 
            to={{
              pathname: `/location-category/${item.objectID}`,
              state: {subCategories: false}
            }}>{item.name}</Link>
          }
          {
            !item.name && item.children &&
            <Link 
            to={{
              pathname: `/location-category/${item.objectID}`,
              state: {subCategories: item.children}
            }}>Unknown Category</Link>
          }
          {
            !item.name && !item.children &&
            <Link 
            to={{
              pathname: `/location-category/${item.objectID}`,
              state: {subCategories: false}
            }}>Unknown Category</Link>
          }
          
        </div>

        <div className="col-md-2">
          { get(locationCategoryTypes[item.locationCategoryTypeId], 'name') }
        </div>

        <div className="col-md-5">
          {
            item.children &&
            <span>
              
                { item.children.map((child, index) =>
                  <span style={{ fontSize: '0.8rem' }}>
                    {child.name}
                    {index === (item.children.length - 1) ? '' : ', '}
                  </span>
                )}
            </span>
          }
          <div>
            <Link style={{ marginLeft: 10, fontSize: '0.8em' }} to={`/location-sub-categories/${item.objectID}`}>Manage Sub-Categories</Link>      
          </div>        
        </div>

      </div> 
    </li>
  );
};

// LocationCategory.defaultTypes = { 
//   locationCategoryTypes: {}
// }

// export default connect(
//   mapStateToProps,
// )(LocationCategory);