import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import some from 'lodash/some';
import PlanFinalizationItem from './plan-finalization-item';
import { getSavedLocationResults } from '../../search/location-search';
import { set } from 'lodash';
import PlanGridItemDetails from '../components/plan-grid-item-details';
import DiscussionMessaging from './discussion-messaging';
import PlanPeople from './plan-people';
import remove from 'lodash/remove';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { setInfo } from '../actions';
import { useDispatch } from 'react-redux';

import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const getListStyle = isDraggingOver => ({
	margin: 'auto',
	minHeight: '30px',
  background: isDraggingOver ? "lightblue" : "lightgrey",
	overflowX: 'auto',
	flexWrap: 'nowrap'
});

const getItemStyle = (isDragging, draggableStyle) => ({
  
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});


const PlanFinalization = ({ planId, selectedLocations, selectedScheduledStops, selectedUnscheduledStops, removeFromSelectedLocations, place, isNew, backFromFinalize, imageNumber,
	localities, savePlanForm, deletePlanForm, planData, planName, setPlanName, setLocalityName, uid, toSharePlan, isPlanOwner, planUsers,
	isPlanInvitee, invites, isPlanUser, email, fromSelectingLocationMessage, messageLocation, selectLocationFromDiscussion,
	removeLocationFromDiscussion, setDetailedLocations, detailedLocations, removeLocality, setLocalityDateRange, setLocationTime, stopsOnDay, localitiesDateRange, localitiesOnDragEnd }) => {

	const [selected, setSelected] = useState(false);
	const [planDetails, setPlanDetails] = useState([]);
	const [loadDetails, setLoadDetails] = useState(true);
	const [showDetails, setShowDetails] = useState(false);
	const [detailedLocation, setDetailedLocation] = useState({});

	const [finalizationDetails, setFinalizationDetails] = useState(true);
	const [finalizationDiscussion, setFinalizationDiscuss] = useState(false);

	const [getawayHatchDetailedLocation, setGetawayHatchDetailedLocation] = useState(false);
	const [googleDetailedLocation, setGoogleDetailedLocation] = useState(true);
	const [yelpDetailedLocation, setYelpDetailedLocation] = useState(false);
	const [canDelete, setCanDelete] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [localiotiesDates, setLocaliotiesDates] = useState([]);
	

	const [datePickerIndex, setDatePickerIndex] = useState(false);

	const [focusedInput, setFocusedInput] = useState(null);

	const { register, handleSubmit, errors } = useForm();

	const dispatch = useDispatch();

  //useEffect(() => {
    
  //  setSelected(true);
	//}, [selectedLocations]);

  useEffect(() => { //--------------------------------make plan details an array of arrays, an array for each locality

		if (loadDetails) {
			getPlanDetails();
		}

	}, [localities]);

	useEffect(() => {



	}, [selectedLocations])
	
	useEffect(() => {

		if (planData && planData.objectID) {
			setCanDelete(true);
		}
		
	}, [planData]);

	useEffect(() => {

		if (fromSelectingLocationMessage) {
			showFinalizationDiscussion();
		}

	}, [fromSelectingLocationMessage]);

  	const getPlanDetails = async () => {//---------------------------maybe only check for certain locations where we need the info, like if the location details is needed
		//let details = []; //----------------------------make object with locality id as keys and array of details as contents, then search that array based on location id 
//-------Add a details section to each stop, go through each stop before getting details and only search for locations with no details, when saving seperate out the details

//------maybe move to plan page so it is easier to override the localities
		//let detailedLocalities = localities;

		//for(let i = 0; i < detailedLocalities.length; i++) {
		let details = detailedLocations;
		let location;

		for(let i = 0; i < localities.length; i++) {

			for(let sStop = 0; sStop < localities[i].scheduledStops.length; sStop++) {
				
				if (!details[localities[i].scheduledStops[sStop].id]) {
					location = await getSavedLocationResults([localities[i].scheduledStops[sStop].location]);
					details[localities[i].scheduledStops[sStop].id] = location[0];
				}
			}

			
			for(let uStop = 0; uStop < localities[i].unscheduledStops.length; uStop++) {
				if (!details[localities[i].unscheduledStops[uStop].id]) {
					location = await getSavedLocationResults([localities[i].unscheduledStops[uStop].location]);
					details[localities[i].unscheduledStops[uStop].id] = location[0];
				}
			}

			/*for(let sStop = 0; sStop < detailedLocalities[i].scheduledStops.length; sStop++) {
				if (!detailedLocalities[i].scheduledStops[sStop].details) {
					detailedLocalities[i].scheduledStops[sStop].details = await getSavedLocationResults([detailedLocalities[i].scheduledStops[sStop].location]);
				}
			}

			for(let uStop = 0; uStop < detailedLocalities[i].unscheduledStops.length; uStop++) {
				if (!detailedLocalities[i].unscheduledStops[uStop].details) {
					detailedLocalities[i].unscheduledStops[uStop].details = await getSavedLocationResults([detailedLocalities[i].unscheduledStops[uStop].location]);
				}
			}*/

			

			/*let locations = localities[i].scheduledStops.concat(localities[i].unscheduledStops);
			locations = locations.map(location => location.location);
			let locationDetails = await getSavedLocationResults(locations);
			
			let locality = {
				//name: localities[i].name,
				id: localities[i].id,
				locations: locationDetails
			}
			details.push(locality);
			*/
		}

		

		//console.log(details);
		console.log(details);
		setDetailedLocations(details);
		
		//setPlanDetails(details);
		setLoadDetails(false);

		/*let locations = Object.keys(localities);

		for(let i = 0; i < locations.length; i++) {
			let locationDetails = await getSavedLocationResults(localities[locations[i]].locations);
			
			let locality = {
				place: {
					name: localities[locations[i]].place.name,
					id: localities[locations[i]].place.objectID
				},
				locations: locationDetails
			}
			details.push(locality);
		}
		
		setPlanDetails(details);
		setLoadDetails(false);*/
	}

	const handleNameTextChange = (event) => {
		setPlanName(event.target.value);
	}

	const handleBackButton = (event) => {
		event.preventDefault();
		dispatch(setInfo(false));
		setPlanDetails([]);
		backFromFinalize();
	}

	const handleAddToButton = (event, place, localityID) => {
		event.preventDefault();

		console.log(event, place, localityID);
		dispatch(setInfo(place));
		setPlanDetails([]);
		backFromFinalize(localityID, place);
	}

	const handleAddToLocalityDate = (event, localityID, place, date) => {
		event.preventDefault();

		console.log("adding location with date: ", date);
		dispatch(setInfo(place));
		setPlanDetails([]);

		backFromFinalize(localityID, place, date);
	}

	const handleInviteButton = (event) => {
		event.preventDefault();
		setPlanDetails([]);
		toSharePlan();
	}

	const removePlanLocations = (locationID, place, localityID) => {
		removeFromSelectedLocations(locationID, place, localityID);

		let details = planDetails;
		for (let i = 0; i < details.length; i++) {

			if (place.id.localeCompare(details[i].place.id) === 0) {

				/*for (let j = 0; j < details[i].locations.length; j++) {
					
					if (locationID.localeCompare(details[i].locations[j].objectID) === 0) {
						console.log("removeing details, removing location");
						details[i].locations.splice(j, 1);

						if (details[i].locations.length === 0) {
							details.splice(i, 1);
						}
						break;
					}
				}*/
				
				remove(details[i].locations, function(location) {
					return (locationID.localeCompare(location.objectID) === 0);
				});

				if (details[i].locations.length === 0) {

					details.splice(i, 1);
				}

				break;
			}
		}
		
		setPlanDetails(details);
	}

	const removePlanLocality = (event, localityID) => {
		event.preventDefault();
		console.log(event, localityID)
		
		removeLocality(localityID);
		
	}

	const showDetailsCard = async (location) => {

		if (location.service && (location.service.localeCompare("getaway-hatch") === 0)) {
			setGetawayHatchDetailedLocation(true);
			setGoogleDetailedLocation(false);
			setYelpDetailedLocation(false);

		} else if (location.service && (location.service.localeCompare("google-places") === 0)) {
			setGetawayHatchDetailedLocation(false);
			setGoogleDetailedLocation(true);
			setYelpDetailedLocation(false);

		} else if (location.service && (location.service.localeCompare("yelp-fusion") === 0)) {
			setGetawayHatchDetailedLocation(false);
			setGoogleDetailedLocation(false);
			setYelpDetailedLocation(true);
		}

		setDetailedLocation(location);

    setShowDetails(true);
  }

  const hideDetailsCard = () => {

    setShowDetails(false);
	}

	const handleSubmitButton = () => {
		setIsSubmitting(true);
		savePlanForm();
	}

	const handleDeleteButton = () => {
		setIsSubmitting(true);
		deletePlanForm(planData.objectID);
	}

	const showFinalizationDetails = () => {
		setFinalizationDetails(true);
		setFinalizationDiscuss(false);
		console.log("details");
	}

	const showFinalizationDiscussion = () => {
		setFinalizationDetails(false);
		setFinalizationDiscuss(true);

		//if no messages, load messages and create listener
		console.log("discussion");
	}

	const handleEditLocalityDate = (event, localityIndex) => {
		event.preventDefault();
		setDatePickerIndex(localityIndex);
	}

	const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

		console.log(source, destination, draggableId);
		localitiesOnDragEnd(source, destination, draggableId)

    // dropped outside the list
    

    /*if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState.filter(group => group.length));
    }*/
  }

  return (
		<div>
			{
				showDetails && 
				<div className="container mt-4">        
					<PlanGridItemDetails getawayHatchSearch={getawayHatchDetailedLocation} 
					googleSearch={googleDetailedLocation} location={detailedLocation}
					hideDetailsCard={hideDetailsCard} yelpSearch={yelpDetailedLocation}/>
				</div>
      }
			
			{
				!showDetails && 
				<div className="container-fluid">
					{ isPlanOwner &&
					<div className="row">
						<div className="col">
							<button className="btn btn-primary pull-left left-button pl-4" onClick={handleBackButton}>
								<div className="fa fa-arrow-left"/>
								Back to locations
								</button>
						</div>
					</div>
					}
					
					<div className="row justify-content-md-center">
						<div className="col w-100 align-self-center">
							<form onSubmit={handleSubmit(handleSubmitButton)}>
								<div className="card border-dark text-center">
									
									<div className="card-header border-dark">
										<div className="row">
											<div className="col-md-auto">
												<p>Plan Name: </p>
											</div>
											{ isPlanOwner &&
											<div className="col">
												<input type="text" className="form-control" name="name" id="locationSearch" value={planName}
													placeholder="What will you name your plan?" onChange={handleNameTextChange} {...register( 'name', { 
														required: {
																value: true,
																message: "name is required"
														}})}></input>
														{ errors.name &&
															<span className="badge badge-warning">
																	{errors.name.message}
															</span>
                            }
											</div>
											}

											{ !isPlanOwner &&
												<p className="h3">{planName}</p>
											}
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="card">
												{
													isNew &&
													<img className="card-img-top"
													src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy-places/o/generic-${imageNumber}.jpg?alt=media`} 
													style={{ maxHeight: '400px', width: 'auto', height: 'auto', objectFit: 'cover' } } alt="" 
													/>
												}
												{
													!isNew && 
													<img className="card-img-top"
													src={planData.featuredImg} 
													style={{ maxHeight: '400px', width: 'auto', height: 'auto', objectFit: 'cover' } } alt="" 
												/>
												}

												<div className="card-header border-dark pt-4">
													{
														isPlanUser && !isNew &&
														<div className="btn-group btn-group-toggle btn-block w-75" data-toggle="buttons">
															<label className={classNames("btn btn-outline-primary", { "active": finalizationDetails})}>
																<input type="radio" name="finalization-options" id="Details" 
																autoComplete="off" onClick={ showFinalizationDetails }/>Details
															</label>

															<label className={classNames("btn btn-outline-primary", { "active": finalizationDiscussion})}>
																<input type="radio" name="finalization-options" id="Discussion"  
																autoComplete="off" onClick={ showFinalizationDiscussion }/>Discussion
															</label>
														</div>
													}

													{ finalizationDetails && !finalizationDiscussion &&
														<div className="card text-center mx-auto w-75">
															<h1 className="text-dark text-center">Locations to Visit</h1>
															{
																loadDetails &&
																<div className="row" >
																	<div className="col">
																		<p className="text-center">Loading...</p>
																		<div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
																	</div>
																</div>
															}
															{/*
																!loadDetails && planDetails.map((planPlace, index) =>
																<div key={planPlace.place.name} className="card border-dark text-center">
																	<div className="card-header border-dark pt-4">
																		<p>{planPlace.place.name}</p>
																	</div>
																	<div className="row">
																		{
																			planPlace.locations.map((details) => 
																				<div key={details.objectID} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>
																					<PlanFinalizationItem source={details} removePlanLocations={removePlanLocations} 
																					planPlace={planPlace.place} showDetailsCard={showDetailsCard} uid={uid} isPlanOwner={isPlanOwner}/>
																				</div>
																			)
																		}
																	</div>
																</div>
																)
																	*/}
															{
																!loadDetails && localities.map((locality, index) =>
																<div key={locality.id} className="card border-dark text-center">
																	<div className="card-header border-dark pt-4">
																	{
																		isPlanOwner &&
																		<button className="btn btn-sm btn-link pull-right right-button" onClick={(e) => removePlanLocality(e, locality.id)}>
																			<i className="fa fa-times-circle" />
																		</button>
																	}
																		<p className="h3" {...console.log(datePickerIndex !== index)}>{locality.name}</p>
																		{
																			isPlanOwner && datePickerIndex !== false && datePickerIndex === index && !isSubmitting &&
																			<DateRangePicker
																				startDate={locality.start} // momentPropTypes.momentObj or null,
																				startDateId={locality.start + "start"} // PropTypes.string.isRequired,
																				endDate={locality.end} // momentPropTypes.momentObj or null,
																				endDateId={locality.end + "end"} // PropTypes.string.isRequired,
																				onDatesChange={({ startDate, endDate }) => setLocalityDateRange(startDate, endDate, index, locality.id)} // PropTypes.func.isRequired,
																				focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
																				onFocusChange={focused => setFocusedInput(focused)} // PropTypes.func.isRequired,
																			/>
																		}
																		{
																			datePickerIndex !== index && !isSubmitting && 
																			<div>
																				{ 
																					(locality.start !== null) && 
																					<p className="my-0 py-0">{moment(locality.start).toDate().toDateString()}</p>
																				}
																				{
																					(locality.start === null) && 
																					<p className="my-0 py-0">-</p>
																				}
																				{
																					((locality.start !== null) || (locality.end !== null)) && 
																					<p className="my-0 py-0">{" to "}</p>
																				}
																				{
																					(locality.end !== null) && 
																					<p className="my-0 py-0">{moment(locality.end).toDate().toDateString()}</p>
																				}
																				{ isPlanOwner &&
																				<button className="btn btn-primary btn-sm" onClick={(e) => handleEditLocalityDate(e, index)}>
																					Edit dates
																					<i className="fa fa-calendar" />
																				</button>
																				}
																			</div>
																		}
																	</div>
																	<DragDropContext onDragEnd={onDragEnd}>
																		<div className="card-body">
																					{ localitiesDateRange && localitiesDateRange[index] &&
																					localitiesDateRange[index].map((date, dateIndex) =>
																					
																					<div key={date} className="row text-center">
																						{ false &&
																						<button className="btn btn-sm btn-link pull-right right-button" onClick={(e) => handleAddToLocalityDate(e, locality.id, locality.place, date)}>
																							<i className="fa fa-plus-square" />
																						</button>
																						}
																						<div className="col text-center">
																							<p className="text-center"><u>{date.toDateString()}</u></p>
																						</div>
																						
																						<Droppable key={date.toDateString()} direction="horizontal" droppableId={`${index},${date.toDateString()}`}>
																							{(provided, snapshot) => (
																								<div
																									className="row w-100"
																									ref={provided.innerRef}
																									style={getListStyle(snapshot.isDraggingOver)}
																									{...provided.droppableProps}
																								>
																								
																									{stopsOnDay(date, locality.id, index).map((stop, stopIndex) => 
																										<div key={stop.id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px', maxHeight: '500px', maxWidth: '250px' }}>
																											{ detailedLocations[stop.id] &&
																											
																												<>
																													<PlanFinalizationItem source={detailedLocations[stop.id]} removePlanLocations={removePlanLocations} 
																													planPlace={locality.place} showDetailsCard={showDetailsCard} uid={stop.location.uid} isPlanOwner={isPlanOwner} localityID={locality.id}
																													timeOfDay={stop.timeOfDay} setLocationTime={setLocationTime} scheduled={true} localityID={locality.id} locationID={stop.id}
																													localityIndex={index} locationIndex={stopIndex} isSubmitting={isSubmitting} stop={stop} stopIndex={stopIndex}/>

																													{provided.placeholder}
																												</>
																											
																											}
																										</div>
																									)}
																							</div>
																							)}
																						</Droppable>
																					</div>
																					)
																					
																					}
																			
																			<div className="row" {...console.log(localitiesDateRange, index)}>
																				
																				{/*
																					locality.scheduledStops.map((stop, stopIndex) =>
																					<div key={stop.id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>
																						{ detailedLocations[stop.id] &&

																							<PlanFinalizationItem source={detailedLocations[stop.id]} removePlanLocations={removePlanLocations} 
																							planPlace={locality.place} showDetailsCard={showDetailsCard} uid={stop.location.uid} isPlanOwner={isPlanOwner} localityID={locality.id}
																							timeOfDay={stop.timeOfDay} setLocationTime={setLocationTime} scheduled={true} locationID={stop.id}
																							localityIndex={index} locationIndex={stopIndex} isSubmitting={isSubmitting}/>
																						
																						}
																						</div>
																					)
																					*/}
																				
																			</div>
																		</div>
																		<div className="card-body">
																			<div className="row text-center">
																				<div className="col text-center">
																					<p className="text-center"><u>Unscheduled Stops</u></p>
																				</div>
																			</div>
																				<Droppable key={'unscheduled'} direction="horizontal" droppableId={`${index},unscheduled`}>
																				{(provided, snapshot) => (
																					<div
																						className="row w-100"
																						ref={provided.innerRef}
																						style={getListStyle(snapshot.isDraggingOver)}
																						{...provided.droppableProps}
																					>
																						{
																							locality.unscheduledStops.map((stop, stopIndex) =>
																							<div key={stop.id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px', maxHeight: '500px', maxWidth: '250px' }}>
																								{ detailedLocations[stop.id] &&
																								<>
																									<PlanFinalizationItem source={detailedLocations[stop.id]} removePlanLocations={removePlanLocations} 
																									planPlace={locality.place} showDetailsCard={showDetailsCard} uid={stop.location.uid} isPlanOwner={isPlanOwner} localityID={locality.id}
																									timeOfDay={stop.timeOfDay} setLocationTime={setLocationTime} scheduled={false} localityID={locality.id} locationID={stop.id}
																									localityIndex={index} locationIndex={stopIndex} isSubmitting={isSubmitting} stop={stop} stopIndex={stopIndex}/>

																									{provided.placeholder}
																								</>
																								}
																								</div>
																							)
																						}
																					</div>
              														)}
																				</Droppable>
																		</div>
																	</DragDropContext>

																	<div className="row">
																		<button className="btn btn-info btn-sm btn-block" onClick={(e) => handleAddToButton(e, locality.place, locality.id)}>Add More Locations to {locality.name} <i className="fa fa-plus" /></button> {/*Call handleAddToButton call which passes locality.place*/}
																	</div>
																	
																	
																</div>
																)
															}

															{ isPlanOwner &&
															<div className="row">
																<div className="col">
																	<button className="btn btn-primary pl-4" onClick={handleBackButton}>
																		Add more destinations
																	</button>
																</div>
															</div>
															}
															
															{
																<div className="card-footer border-dark">
																	<h1 className="text-dark text-center">People in the Plan</h1>
																	{
																		planUsers && Array.isArray(planUsers) && planUsers.map((user) => 
																		<div key={user.uid} className="row d-flex justify-content-center">
																			<div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
																				<PlanPeople user={user} uid={uid} planOwner={planData.owner} isUser={true}/>           
																			</div>    
																		</div>
																		)
																	}
																	{ 
																		invites && Array.isArray(invites) && invites.map((invite) =>
																		<div  className="row d-flex justify-content-center" {...console.log("invite: ",invite)}>
																			{ invite.inviteeUid &&
																				<div key={invite.inviteeUid} className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
																					<PlanPeople user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
																					uid={uid} planOwner={''} isUser={false}/>           
																				</div> 
																			}
																			{ !invite.inviteeUid && invite.inviteeEmail &&
																				<div key={invite.inviteeEmail} className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
																					<PlanPeople user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
																					uid={uid} planOwner={''} isUser={false}/>           
																				</div> 
																			}  
																		</div>
																		)
																	}
																	{ 
																		planData && planData.invites.map((invite) => 
																		<div key={invite} className="row d-flex justify-content-center">
																			{ invite.inviteeUid && 
																				<div key={invite.inviteeUid} className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
																					<PlanPeople user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
																					uid={uid} planOwner={''} isUser={false}/>           
																				</div> 
																			}
																			{ !invite.inviteeUid && invite.inviteeEmail &&
																				<div key={invite.inviteeEmail} className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
																					<PlanPeople user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
																					uid={uid} planOwner={''} isUser={false}/>           
																				</div> 
																			}
																				
																		</div>
																		)
																	}
																</div>
															}
															<div className="row">
																{ !isPlanInvitee && isPlanUser &&
																	<div className="col">
																	<button className="btn btn-primary pl-4" onClick={handleInviteButton}>
																		Add more friends
																	</button>
																</div>
																}
																
															</div>
														</div>
	 												}
													 
													{ finalizationDiscussion && !finalizationDetails && !isNew && isPlanUser &&
														<DiscussionMessaging planID={planId} uid={uid} email={email} messageLocation={messageLocation} 
														selectLocationFromDiscussion={selectLocationFromDiscussion} removeLocationFromDiscussion={removeLocationFromDiscussion}
														showDetailsCard={showDetailsCard} isPlanOwner={isPlanOwner}/>
													}

													{  finalizationDiscussion && !finalizationDetails && (isNew || !isPlanUser) &&
													<div className="card text-center mx-auto w-75">
														<h1 className="text-dark text-center">Discussion</h1>
														<div className="card text-center" style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '25em', flexGrow: '1', padding: '20px'}}>
														
														</div>
														<div className="card text-center">
															<div className="row">
																<div className="col mr-0 pr-0">
																	<input type="text" className="form-control" name="textMessage" id="textMessage" disabled={true}
																	placeholder="Write a Message...">
																	</input>
																</div>
																<div className="col-auto align-self-end ml-0 pl-0">
																	<button type="button" className="btn btn-primary pull-right" disabled={true}>
																		<i className="fa fa-paper-plane" />
																	</button>
																</div>
															</div>
														</div>
													</div>
													}
												</div>
											</div>
										</div>
									</div>
								</div> 
								<div className="form-row">
									<div className="col mb-2 p-4">
											{
													canDelete && !isSubmitting && isPlanOwner &&
													<button type="button" className="btn btn-danger pull-left" onClick={() => { handleDeleteButton() }}>
															<i className="fa fa-trash" />
													</button>
											}
											{ uid && isPlanOwner && false &&
												<button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
													{ !isSubmitting && <span>Save</span>}
													{ isSubmitting && <i className="fa fa-spinner fa-spin" /> }
												</button>
											}
											{ !uid &&
												<button className="btn btn-primary pull-right" disabled={true}>
													{<span>Account required to save plan</span>}
												</button>
											}
											
											{ !isSubmitting && <Link to="/" className="btn btn-light pull-right mr-2">Cancel to HomePage</Link> }

									</div>
            		</div>
							</form>
						</div>
					</div>
				</div>
			}
		</div>
  );
}

export default PlanFinalization;