import React, {useState} from 'react';
import './plan-filter-dropdown.css';

import { tokenizeCategory} from '../filter-info';
import { Link } from 'react-router-dom';

export const DropdownItem = ({ item, setPlanningTab }) =>  {
    if (('children' in item) && (item.children !== null)) {
        return [ //set this to be a dropdown menu and map dropdownsubitems to subcategories
        <>
        <div className="submenu-item dropright">
            <a className="dropdown-item dropdown-toggle disabled"> {item.name} </a>
            <div className="dropdown-menu">
            <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => setPlanningTab({name: item.name})}>{"All " + item.name}</a>
            { item.children.map((subCategory =>
                <DropdownSubItem /*key={item.objectID}*/ item={subCategory.name} setPlanningTab={setPlanningTab}/>
            ))}
            </div>
        </div>
        </>
        ]
    }
  
    return <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => setPlanningTab(item)}>{item.name}</a>
  }
  
  const DropdownSubItem = ({ item, setPlanningTab}) => 
    <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={() => setPlanningTab({name: item})}>{item}</a>



export const NavBarDropdownItem = ({ item }) => {

    if (('children' in item) && (item.children !== null)) { 
        return [
            <>
        <div className="submenu-item dropright">
            <a className="dropdown-item dropdown-toggle disabled" key={item.objectID}> {item.name} </a>
            <div className="dropdown-menu">
                <Link /*key={category.objectID}*/ className="dropdown-item" to={`/planning/category/${tokenizeCategory(item.name)}`}>
                    {"All " + item.name}
                </Link>
                { item.children.map((subCategory =>
                    <NavBarDropdownSubItem item={subCategory.name}/>
                ))}
            </div>
        </div>
        </>
        ]
    }
    
    return[
    <Link key={item.objectID} className="dropdown-item" to={`/planning/category/${tokenizeCategory(item.name)}`}>
    {item.name}
    </Link>
    ]
}

const NavBarDropdownSubItem = ({ item }) =>
    <Link /*key={category.objectID}*/ className="dropdown-item" to={`/planning/category/${tokenizeCategory(item)}`}>
        {item}
    </Link>
