import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//ownProps.match.params.id
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps);
  return {
  };
};

const SignUp = () =>
  <div className="container mt-4">
    <div className="row">
      <div className="col-xs-12 offset-xs-0 col-md-6 offset-md-3">
      <div className="card">
        <h5 className="card-header text-left">Have your property make money for you!</h5>
          <div className="card-body">
            <input className="form-control mb-1" type="text" placeholder="First Name" />
            <input className="form-control mb-1" type="text" placeholder="Last Name" />
            <input className="form-control mb-1" type="text" placeholder="Phone Number" />
            <input className="form-control mb-1" type="text" placeholder="Email" />
            <input className="form-control mb-1" type="text" placeholder="Password" />
          </div>
        </div>
        <div className="pull-right mt-2">
          <Link className="btn btn-primary mr-2" to="/listing/address">Sign Up</Link>
          or
          <Link className="btn btn-outline-primary ml-2" to="/listing/address">Login</Link>
        </div>
      </div>
    </div>
  </div>;


export default withRouter(connect(
  mapStateToProps,
)(SignUp));