import React, { useState, useEffect } from 'react';
import { forwardGeocode } from '../shared/mapping';
import {useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

/*
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
*/

import get from 'lodash/get';

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import PlaceSearchItem from './place-search-item';
import { getAlgoliaPlacesResults } from '../search/location-search';

import 'react-bootstrap-typeahead/css/Typeahead.css';
//import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { setInfo } from '../planning/actions';

const PlaceSearchForm = ({ placeSelected, infoName, loading }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [places, setPlaces] = useState([]);
    const [selected, setselected] = useState("Where to?");
    // const [search, setSearch] = useState();

    useEffect(() => {
      if (infoName) {
        console.log('infoname selected: ' + infoName);
        setselected(infoName);
      }
    }, [infoName]);

    const onSearch = async (searchTerm) => {
      setIsLoading(true);
     
      // var address = await geocodeByAddress(searchTerm);
      //console.log("address --------  ", address);
      let info = await getAlgoliaPlacesResults(searchTerm);
      setPlaces(info || []);
      setIsLoading(false);
    }

    const placeChanged = async (selected) => {    
      console.log('selected', selected);
      setselected(selected);
      dispatch(setInfo(selected[0]));
      dispatch(push('/planning'));
    }

    return (
      <div className="form-row">
        <div className="col-lg-12">
        
          <AsyncTypeahead
            id='place-search-typeahead'
            disabled={loading}
            autoFocus={true}
            allowNew={false}
            multiple={false}
            isLoading={isLoading}
            options={places}
            labelKey={option => `${option.name}, ${option.state}`}
            minLength={4}
            delay={200}
            onSearch={onSearch}
            onChange={placeSelected || placeChanged}
            placeholder={selected}
            //{...console.log("placeSelected: " + placeSelected)}
            renderMenuItemChildren={(place, props) => (
              <PlaceSearchItem key={place.objectID} place={place} />
            )}
          />          
        </div>    
      </div>
    );
};

export default PlaceSearchForm;