import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router";

import PointsOfInterestForm from '../components/points-of-interest-form';

//import { deletePlaceCustom, addPlaceCustom } from '../../firebase';

function FeaturedPointsOfInterest( props ) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [pointsOfInterest, setPointsOfInterest] = useState([]);
    const [pointsOfInterestData, setPointsOfInterestData] = useState({id: "new"});
    const [canDelete, setCanDelete] = useState(null);
    const [submitComplete, setSubmitComplete] = useState(false);
    

    const { id } = useParams();
    const { state } = useLocation();
    let history = useHistory();

    useEffect(() => {
        if ( id === 'new') {
          console.log("new points of interst");
          setCanDelete(false);
        }else { //---------------------------------------load data from database and set to state variables------------------------------------------
          console.log(state.hit);
          setPointsOfInterest(state.hit);
          console.log(pointsOfInterest);
          setCanDelete(true);
        }
    });

    // useEffect(() => {
    //     if ( id !== 'new') {
    //       if(place.state!==undefined){
    //         if(place.state.length > 2){
    //             let stateArray = place.state.split(' & ');
    //             setStates(stateArray);
    //         }else{
    //             setStates(place.state);
    //         }
    //       }
    //       console.log(states);
    //     }
    // }, [place]);

    useEffect(() => {
      if (submitComplete) {
        console.log("done submitting");
      history.push("/featured-points-of-interest");
      }
    }, [submitComplete]);

      function savePointsOfInterest(values) {
        console.log('saving place', values);
        let pointsOfInterestData = {};

        if (id === 'new') {
          console.log("saveing new points of interest");

            pointsOfInterestData = {
              featured: values.featured,
              pointsOfInterest: values.pointsOfInterest
            }
            
            console.log(pointsOfInterestData);
            // let complete = addPlaceCustom(placeData);
            // complete.then(function(results) {
            //   console.log(results);
            //   setSubmitComplete(results);
            // });

        } else {
          
            console.log("saving updated points of interest data");

            pointsOfInterestData = {
              featured: values.featured,
              pointsOfInterest: values.pointsOfInterest,
              objectID: pointsOfInterest.objectID
            }

            console.log(pointsOfInterestData);
            // let complete = addPlaceCustom(placeData);
            // complete.then(function(results) {
            //   console.log(results);
            //   setSubmitComplete(results);
            // });
        }

        setIsSubmitting(true);
      }

      function deletePointsOfInterestData() {
        console.log("deleting points of interest");
        console.log("points of interest");
        setIsSubmitting(true);
        // let complete = deletePlaceCustom(place);
        // complete.then(function(results) {
        //   console.log(results);
        //   setSubmitComplete(results);
        // });
      }

    return (
        <div className="container mt-4">        
        <h2 className="text-left">Points Of Interest</h2>
        <PointsOfInterestForm
        isSubmitting={isSubmitting}
        savePointsOfInterest={savePointsOfInterest}
        deletePointsOfInterest={deletePointsOfInterestData}
        pointsOfInterest={pointsOfInterest}
        pointsOfInterestData={pointsOfInterestData}
        canDelete={canDelete}
        />
      </div>

    );
}

export default FeaturedPointsOfInterest;