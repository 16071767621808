import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams} from "react-router";
import { getCollection, getDocument, collection, getReadsFilters } from '../../firebase';

// const getLocationSubCategories = (id) => 
//   getCollection('locationSubCategories', 'locationCategoryID', '==', id);


const ReadsCities = () => {
  const { id } = useParams();
  const [readsRegion, setReadsRegion] = useState(null);
  const [readsRegionCities, setReadsRegionCities] = useState([]);

  useEffect(() => {        
    getDocument('readsLocations', id).then(doc => {
      console.log("got reads region");
      console.log(doc);
      setReadsRegion(doc);
    });    
  }, [id]);

  useEffect(() => {
    if (!readsRegion) { return; }

    console.log('reads region changed', readsRegion);
    setReadsRegionCities([]);

    // getLocationSubCategories(id).then(collection => {
    // collection('locationCategories', { field: 'parentID', op: '==', val: id, orderBy: 'name'}).then((results) => {
    //   setLocationSubCategories(results);
    // });

    let complete = getReadsFilters({regionID: id});

    complete.then((results) => {
      console.log(results);
      //setReadsRegionCities(results.data);
      setReadsRegionCities(results);
    });
  }, [id, readsRegion]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">   
            
              { !readsRegion && <h5 className="mb-0 pull-left">Loading...</h5>}
              { 
                readsRegion &&
                <h5 className="mb-0 pull-left">
                  { readsRegion.name }

                  <Link to={`/reads-city/${id}/new`} className="btn btn-sm btn-outline-primary ml-2">
                    <i className="fa fa-plus mr-1" />
                    New Sub-Category
                  </Link>
                </h5>
              }  
            </div>
            
            <ul className="list-group list-group-flush">
              {
                readsRegionCities && readsRegionCities.map(item => {
                  return (
                    <li className="list-group-item text-left" key={item.objectID}>
                      <div className="row">

                        <div className='col'>
                          <Link to={`/reads-city/${id}/${item.objectID}`}>{item.name}</Link>
                        </div>

                        <div className='col'>
                          <div>{item.amount}</div>
                        </div>
                      </div>
                      
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadsCities;