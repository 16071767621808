import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import ReportCards from '../components/report-cards';

import searchClient from '../../search';

import LocationRefinementList from '../../locations/components/location-refinement-list';

import {
    InstantSearch,
    Hits,
    SearchBox,  
    Pagination,
    Stats,
    Configure
  } from 'react-instantsearch-dom';


class Reports extends Component {
    constructor(props) {
        super(props);

    }

    state = {
        refresh: false,
        isDeleting: false,
    }

    refresh = () => {
        this.setState({refresh: true}, () => {
            this.setState({refresh: false});
        });
    
        console.log("Refresh!");
    };

    setIsDeleting = (deleting) => {
        
        this.setState({isDeleting: deleting});
    }

    render () {
        return (
            <div className="container mt-4">
                <InstantSearch searchClient={searchClient} indexName="location_issues" refresh={this.state.refresh}>
                    <div className="card mt-4">
                        <div className="card-header">
                            <h3 className="pull-left" style={{ marginBottom: 0 }}>
                                Location Error Reports
                                <Stats />

                            </h3>
                        </div>
                        <div className="card-body">              
                            <div className="container">
                                <div className="row form-group">
                                <div className="col d-flex justify-content-center">
                                    <Pagination />
                                </div>
                                </div>
                                
                                <div className="row">
                                    {/* <div className="col-md-3 mt-3">                    
                                    <div className="card mb-2">
                                        <div className="card-header">Emails</div>                      
                                        <LocationRefinementList attribute="email" limit={50} />                            
                                    </div>
                                    </div> */}

                                    <div className="col-md-9">
                                    <ReportCards refresh={this.refresh} isDeleting={this.state.isDeleting} setIsDeleting={this.setIsDeleting}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InstantSearch>  
            </div>  
        );
    }
}

export default Reports;