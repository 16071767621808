import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// import { WidthProvider, Responsive } from "react-grid-layout";
// import { getImageAspect, LANDSCAPE_IMAGE, PORTRAIT_IMAGE } from '../../helpers';
import PlanGridItem from './plan-grid-item';
import { object } from 'prop-types';
// import 'react-grid-layout/css/styles.css'; // import 'react-resizable/css/styles.css';
// import './plan-grid.css';

// const ResponsiveGridLayout = WidthProvider(Responsive);

// const COLS_LIMIT = 6;

/*
const buildDataGrid = (source, index) => {
  const aspect = getImageAspect(source.imageSize);
  var h = 7;

  if (source.imageFileName) {
    if (aspect === LANDSCAPE_IMAGE) {
      h += 3;
    } else if (aspect === PORTRAIT_IMAGE) {
      h += 7;
    } else {
      h += 5; // SQUARE_IMAGE, UNKNOWN_ASPECT
    }
  }

  if (source.name && source.name.length > 16) {
    h += 1;
  } else if (source.name && source.name.length > 24) {
    h += 2;
  }

  if (source.street && source.street.length > 20) {
    h += 1;
  }

  if (!isEmpty(source.phoneNumber)) {
    h += 1;
  }
  
  const results = {
    x: (index % COLS_LIMIT),
    y: Math.floor(index / COLS_LIMIT),
    w: 1,
    h,
  };

  // if (source.objectID === 'wEIiRDyj3Ewg5UDWfFYg') {
  //   console.log("found square image test", results);
  // }

  return results;
};
*/
const mapStateToProps = (state, ownProps) => {  
  const favorites = get(ownProps, 'plan.favorites') || [];

  return {
    favorites,
  }
};

class PlanGrid extends Component {

  render() {
    const { locations, planId, toggleLocationFavorite, favorites, getawayHatchSearch, googleSearch, showDetailsCard,
      googleNextPage, loadMoreResults, yelpSearch, yelpNextPage, selectedLocations, addToSelectedLocations, 
      removeFromSelectedLocations, place, isPlanOwner, selectingForMessage, addToMessageLocation, localityID } = this.props;
  
    if (isEmpty(locations)) {
      return ( <span>Sorry, no results...</span> )
    } 

    return (        
      <div>
        <div className="row">
        {
          locations.map((source, index) => 
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>              
                <PlanGridItem key={source.objectID} favorite={favorites.includes(source.objectID)} source={source}  planId={planId} 
                toggleLocationFavorite={toggleLocationFavorite} getawayHatchSearch={getawayHatchSearch} googleSearch={googleSearch} 
                showDetailsCard={showDetailsCard} yelpSearch={yelpSearch} selectedLocations={selectedLocations.map(location => location.location)}
                addToSelectedLocations={addToSelectedLocations} removeFromSelectedLocations={removeFromSelectedLocations} place={place}
                isPlanOwner={isPlanOwner} selectingForMessage={selectingForMessage} addToMessageLocation={addToMessageLocation} localityID={localityID}/>            
            </div>
          )
        }
        </div>
        {
          ((googleSearch && googleNextPage) || (yelpSearch && yelpNextPage)) &&
          <div className="row">
            <div className="col align-self-center">
              <button className="btn btn-primary" onClick={ () => loadMoreResults() }>More results</button>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
)(PlanGrid);