import React, { Component, setState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Filter from 'lodash/filter';
import Includes from 'lodash/includes';
import classNames from 'classnames';

import HeaderCarousel from '../../components/header-carousel';
import RootPlanTabContent from '../components/root-plan-tab-content';
import PlanFilters from '../components/plan-filters';
// import Search from '../components/search';

import { getUID } from '../../session/selectors';
import { selectCollection, selectDocument } from '../../actions/selectors';
import { selectThingsToDo, selectServices, selectPlanLocations } from '../selectors';
import { getIDFromParams, getLastTokenFromPath } from '../../actions/selectors';
import PlanTagline from '../components/plan-tagline';

import * as Constants from '../constants';
import * as Actions from '../actions';
import * as CoreActions from '../../actions/actions';

import pick from 'lodash/pick';
import track from '../../actions/track';
import PlaceSearchForm from '../../place-search/place-search-form';

import { getAlgoliaLocationResults, getGoogleExtraResults, getSavedLocationResults } from '../../search/location-search';
import { searchYelpLocations, analytics } from '../../firebase/index'
import { FullNameToAbbreviation, AbbreviationToFullName } from '../../shared/state-conversion';

import PlanGridItemDetails from '../components/plan-grid-item-details';
import { rest, result } from 'lodash';

const mapStateToProps = (state, ownProps) => {
  //const destinationLocations = selectPlanLocations(state);
  const tab = state.planning.tab || "All";
  //const planId = getIDFromParams(state);
  //const plan = selectDocument(state, 'deprecatedPlans', planId);

  //const category = planId ? null : getLastTokenFromPath(state);
  return {
    tab,
    info: false /*state.session.info*/,
    //planId,
    //category,
    isNew: !Boolean(ownProps.planId),
    //uid: getUID(state),
    ...state.planning,
    //plan,
    planningView: state.planning.planningView,
    //thingsToDoLocations: selectThingsToDo(state),
    //serviceLocations: selectServices(state),
    //destinationLocations,
    //locationCategoryTypes: selectCollection(state, 'locationCategoryTypes') || {},
    //locationCategories: selectCollection(state, 'locationCategories') || {},
  }
};

const google = window.google;

let service;

class PlanLocationSelect extends Component {
  constructor(props) {
    super(props);
    this.onSelected               = this.onSelected.bind(this);
    this.placeSelected            = this.placeSelected.bind(this);
    this.onViewportChange         = this.onViewportChange.bind(this);
    this.viewClassName            = this.viewClassName.bind(this);
    this.getLocations             = this.getLocations.bind(this);
    this.handleSearchSourceChange = this.handleSearchSourceChange.bind(this);
    this.showDetailsCard          = this.showDetailsCard.bind(this);
    this.hideDetailsCard          = this.hideDetailsCard.bind(this);
    this.loadMoreResults          = this.loadMoreResults.bind(this);
    this.getGoogleLocationResults = this.getGoogleLocationResults.bind(this);
    this.getYelpResults           = this.getYelpResults.bind(this);

    this.timer = null;

    this.categories = [
      'Amusement & Theme Parks',
      'Artisan Shops & Markets',
      'Arts & Entertainment',
      'Bakeries',
      'Bars & Lounges',
      'Breweries',
      'Catering',
      'Coffee & Tea',
      'Desserts & Confections',
      'Distilleries',
      'Downtown Districts',
      'Driving Service',
      'Festivals & Events',
      'Gardens',
      'Golf Courses',
      'Groceries',
      'Grocery Delivery',
      'Historic Sites Landmarks & Monuments',
      'Indoor Sports & Games',
      'Libraries & Bookstores',
      'Limo Service',
      'Museums',
      'Nightlife',
      'Outdoor Adventures',
      'Parks & Recreation',
      'Personal Chef',
      'Private Charters',
      'Resorts',
      'Restaurants',
      'Spas',
      'Sports',
      'Taprooms & Wine Bars',
      'Tasting Rooms',
      'Tourist Attractions',
      'Tours',
      'Wildlife & Nature Reserves',
      'Wineries',
      'Zoos & Aquariums'
    ]

    this.state = {
      viewport: {
        zoom: 12,
        minZoom: 10,
      },

      showCategories: true,
      locationSearch: '',
      locationSearchGetaway: '',
      locationSearchGoogle: '',
      locationSearchYelp: '',
      searchResults: [],
      googleResults: [],
      yelpResults: [],
      getawayHatchResults: [],
      savedResults: [],
      categorySelected: false,
      category: "All",
      //infoFromLocation: null,
      place: false,
      getawayHatchSearch: true,
      googleSearch: false,
      yelpSearch: false,
      savedSearch: false,
      showDetails: false,
      selectedLocation: false,
      loading: false,
      googleNextPage: false,
      yelpOffset: 0,
      yelpNextPage: false
    };
  }

  async showDetailsCard(location) {

    if (this.state.googleSearch) {

      let details = await getGoogleExtraResults(location.objectID);
      
      details.name = location.name;
      details.imageURL = location.imageURL;
      details.imageAttributions = location.imageAttributions;
      details.attributions = location.attributions;
      
      this.setState({
        selectedLocation: details 

      }, () => {
        // analytics.logEvent('location_viewed', {
        //   userID: this.props.uid,
        //   locationName: details.name,
        //   locationID: location.objectID
        // });

        this.setState({showDetails: true});

      });

    } else if (this.state.getawayHatchSearch || this.state.yelpSearch) {

      this.setState({
        selectedLocation: location 

      }, () => {
        // analytics.logEvent('location_viewed', {
        //   userID: this.props.uid,
        //   locationName: location.name,
        //   locationID: location.objectID
        // });

        this.setState({showDetails: true});

      });
    }
  }

  hideDetailsCard() {

    this.setState({showDetails: false});
  }

  showCategoriesGrid = () => {

    this.setState({ 
      showCategories: true,
      categorySelected: false,
      category: "All",
      getawayHatchSearch: true,
      googleSearch: false,
      yelpSearch: false,
      getawayHatchResults: [],
      googleResults: [],
      yelpResults: []
    });

    //this.props.setPlanningTab({name: "All" });
  }

  dontShowCategoriesGrid = async () => {

    let results = await this.getLocations();

    this.setState({
      locationSearch: '',
      searchResults: results

     }, () => {
       this.setState({
        showCategories: false

       });
     });
  }

  selectedCategory = ( categoryName ) => {

    this.setState({
      category: categoryName,
      locationSearch: categoryName

     }, async () => {
      let results = await this.getLocations();

      this.setState({
        searchResults: results
        
      }, () => {
        
      this.setState({ 
        showCategories: false,
        categorySelected: true,
      });
     });
    });
  }

  async componentDidMount() {
    track("Adventure Plan");
    this.props.getCollection('locationCategories');

    const { category, setPlanningTab, setPlanInfoFromLocation, info } = this.props;
    /*
    if (category && !info) {
      setPlanInfoFromLocation();
      //setPlanningTab({ name: category });
      this.setState({ infoFromLocation: true });                          
    }

    if (info && info.lng && info.lat) {
      this.setState({ infoFromLocation: false });
      this.onSelected(
        {
          width: 0,
          height: 0,
          zoom: 12,
          minZoom: 10,
          latitude: info.lat,
          longitude: info.lng,
        },
        info
      );
    }
    */
    //console.log('calling forward geocode');
    //var info = await forwardGeocode('san fran');
  }
  
  componentWillReceiveProps(nextProps) {
    //if (nextProps.info.longitude !== this.props.info.longitude ||    
    //  nextProps.info.latitude !== this.props.info.latitude) {

      //nextProps.setPlanInfoFromLocation();
      //this.setState({ infoFromLocation: true });
    //}

    if (nextProps.planId && nextProps.planId !== this.props.planId) {
      this.props.getDocument('deprecatedPlans', nextProps.planId);
    }

    if (nextProps.category && nextProps.category !== this.props.category) {
      //nextProps.setPlanningTab({ name: nextProps.category });
    }
    
    const nextPlanInfoId = get(nextProps, 'plan.info.id');
    if (nextPlanInfoId && nextPlanInfoId !== get(this.props, 'plan.info.id')) {
      this.onSelected(
        {
          width: 0,
          height: 0,
          zoom: 12,
          minZoom: 10,
          latitude: nextProps.plan.info.lat,
          longitude: nextProps.plan.info.lng,
        },
        nextProps.plan.info
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (this.state.showCategories && this.state.categorySelected) {
      this.setState({ showCategories: false });

    } else if (this.state.showCategories && !(this.state.locationSearch === '')) {
      this.setState({ showCategories: false });
    }

    if (prevProps.info !== this.props.info) {

      this.setState({ searchResults: this.getResults() });
    }

    if (prevState.category !== this.state.category) {
      this.setState({ searchResults: this.getResults() });
    }

    if (!this.state.place && this.props.place) {
      this.setState({place: this.props.place});
    }
  }

  onSelected(viewport, item) {
    console.log('on selected', viewport, item);
    this.setState({ ...this.state, viewport,  });
    this.props.fullTextSearch('locations', 'plan_locations', [item.lng, item.lat]);
    this.props.setInfo(item);
    //this.setState({ infoFromLocation: false });
  }

  placeSelected(places) {                         
    if (places.length === 0 || !(places[0].lng && places[0].lat)) {
      return
    }
    var info = places[0];
    this.onSelected(
      {
        width: 0,
        height: 0,
        zoom: 12,
        minZoom: 10,
        latitude: info.lat,
        longitude: info.lng,
      },
      info
    );

    this.setState({ 
      //infoFromLocation: false,
      locationSearch: '',
      searchResults: [],
      googleResults: [],
      getawayHatchResults: [],
      yelpResults: [],
      getawayHatchSearch: true,
      googleSearch: false,
      yelpResults:false,
      yelpOffset: 0, 
      place: info
     }, () => {
       this.showCategoriesGrid();
     });

     this.props.addPlaceDataToLocalities();
  }

  viewClassName(viewName) {
    viewName = (this.props.planningView === viewName ? 'btn-secondary' : 'btn-outline-secondary');
    return `btn ${viewName}`;        
  }

  search(values) {
    // console.log("plan search", values);
    // const search = { 
    //   postal_code: { operator: "ilike", value: `${values.whereTo}%` },
    // };
  }

  onViewportChange = (newViewport) => {
    this.setState({ viewport: newViewport });
  }  
  
  handleSearchTextChange = async (event) => {

    let searchText = event.target.value;
    
    let ms = 1000;

    if (this.state.getawayHatchSearch) {
      ms = 1000;
    } else if (this.state.googleSearch || this.state.yelpSearch) {
      ms = 2000;
    }
    
    if (this.timer) {
      clearTimeout(this.timer);
    }
    

    this.setState({
      locationSearch: searchText,
      yelpOffset: 0

    }, async () => {
  
      this.timer = setTimeout( async () => {

        if (searchText === '') {
          this.showCategoriesGrid();

        } else {
          let results = await this.getLocations();

          this.setState({ searchResults: results }/*, () => {
            analytics.logEvent('location_search', {
              userID: this.props.uid,
              search: results
            });
          }*/);
        }
      }, ms);
    });
  }

  getResults = async () => {
    let results = await this.getLocations();

    return results;
  }

  getLocations = async () => {

    let info = this.state.place;
    
    let place = {
      city: null,
      region: null,
      lng: null,
      lat: null
    };

    let category = this.state.category;
    let region;

    /*if (this.state.infoFromLocation) {

      if (info.context) {
        region = FullNameToAbbreviation(info.context[0].text);

        place.city = info.text;
        place.lat = info.center[1];
        place.lng = info.center[0];

      } else {
        region = FullNameToAbbreviation(info.region_name);

        if (!region && info.context) {
          region = info.context[0].text;
        }

        place.lat = info.latitude;
        place.lng = info.longitude;
        place.city = info.city;
      }
      
      place.region = region;

    } else {*/
      
      region = FullNameToAbbreviation(info.state);

      if (!region) {
        region = info.state;
      }

      place.city = info.name;
      place.region = region;
      place.lat = info.lat;
      place.lng = info.lng;
    //}

    let results;
    let search = this.state.locationSearch;
    let getawayHatch = this.state.getawayHatchSearch;
    let googleSearch = this.state.googleSearch;
    let yelpSearch = this.state.yelpSearch;
    let savedSearch = this.state.savedSearch;

    this.setState({loading: true});

    if (getawayHatch) {
      
      results = await getAlgoliaLocationResults(search, place, category);

      this.setState({
        getawayHatchResults: results,
        locationSearchGetaway: search,
      });

    } else if (googleSearch) {
      
      results = await this.getGoogleLocationResults(search, place, category);

      this.setState({
        googleResults: results,
        locationSearchGoogle: search
      });

    } else if (yelpSearch) {

      if (this.state.yelpNextPage) {
        let previousResults = this.state.yelpResults;

        results = await this.getYelpResults(search, place, category);

        let allResults = [...previousResults, ...results]

        this.setState({
          yelpResults: allResults,
          locationSearchYelp: search,
          searchResults: allResults
        });

      } else {
        results = await this.getYelpResults(search, place, category);

        this.setState({
          yelpResults: results,
          locationSearchYelp: search
        });
      }
    } else if (savedSearch) {
      results = [] //await getSavedLocationResults(this.props.selectedLocations, search, place);

      this.setState({
        getawayHatchResults: results,
        locationSearchGetaway: search,
      });
    }
    
    this.setState({loading: false});
    
    return results;
  }

  handleSearchSourceChange(event) {
    
    if(event){
      if(event.target.id === "GetawayHatch"){
        
        this.setState({ 
          getawayHatchSearch: true,
          googleSearch: false,
          yelpSearch: false

        }, async () => {
          let getawayHatchResults = this.state.getawayHatchResults;

          if (!getawayHatchResults) {
            getawayHatchResults = [];
          }

          if (getawayHatchResults.length == 0) {
            let results = await this.getLocations();
            this.setState({ searchResults: results });

          } else {

            if (this.state.locationSearch !== this.state.locationSearchGetaway) {
              let results = await this.getLocations();
              this.setState({ searchResults: results });

            } else {
              this.setState({searchResults: getawayHatchResults});
            }
            
          }
         });

      } else if (event.target.id === "Google") {
        
        this.setState({
          getawayHatchSearch: false,
          googleSearch: true,
          yelpSearch: false

        }, async () => {
          let googleResults = this.state.googleResults;

          if (!googleResults) {
            googleResults = [];
          }

          if (googleResults.length == 0) {
            let results = await this.getLocations();
            this.setState({ searchResults: results });
            
          } else {

            if (this.state.locationSearch !== this.state.locationSearchGoogle) {
              let results = await this.getLocations();
              this.setState({ searchResults: results });
            } else {
              this.setState({searchResults: googleResults});
            }
          }
        });

      } else if (event.target.id === "Yelp") {
        this.setState({
          getawayHatchSearch: false,
          googleSearch: false,
          yelpSearch: true

        }, async () => {
          let yelpResults = this.state.yelpResults;

          if (!yelpResults) {
            yelpResults = [];
          }

          if (yelpResults.length == 0) {
            let results = await this.getLocations();
            this.setState({ searchResults: results });
            
          } else {

            if (this.state.locationSearch !== this.state.locationSearchYelp) {
              let results = await this.getLocations();
              this.setState({ searchResults: results });

            } else {
              this.setState({searchResults: yelpResults});
            }
          }
        });
      }
    }
  }

  
  getGoogleLocationResults(query, place, category) {

    let location = new google.maps.LatLng(place.lat, place.lng);

    let request = {
      name: query,
      location: location,
      radius: 8000,
      type: 'establishment'
    }

    let places = [];

    if (!service) {
      service = new google.maps.places.PlacesService(document.createElement('div'));
    }

    return new Promise( async (resolve, reject) => {
      let locations = [];

      service.nearbySearch(request, async (results, status, pagination) => {
        if (status === google.maps.places.PlacesServiceStatus.OK || status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          
          for (let i = 0; i < results.length; i++) {

            let location = {
              objectID: results[i].place_id,
              name: results[i].name,
              imageURL: false,
              imageAttributions: '',
              attributions: results[i].html_attributions
            }

            if (results[i].photos) {
              let photos = results[i].photos;
              location.imageURL = photos[0].getUrl({maxWidth: 600});
              location.imageAttributions = photos[0].html_attributions;
            }
            
            locations.push(location);
          }

          if (pagination.hasNextPage) {
            //await moreResults(pagination);
            let getNextPage = pagination.hasNextPage && function() {
              pagination.nextPage();
            }

            this.setState({ 
              googleResults: locations,
              searchResults: locations

            }, () => {
              this.setState({
                googleNextPage: getNextPage 
              })
            })
          } else {
            this.setState({ 
              googleResults: locations,
              searchResults: locations

            } , () => {
              this.setState({
                googleNextPage: false
              })
            })
          }

          resolve(locations);
          
        } else {

          reject(new Error('google maps status for nearbySearch for ' + request + ' not ok ' + status));
        }
      });
    })
  }

  async getYelpResults(query, place, category) {
    
    let yelpData = {
      query: query,
      place: {
          lat: place.lat,
          lng: place.lng
      },
      offset: this.state.yelpOffset
      };

    let yelp = await searchYelpLocations(yelpData);

    let yelpResults = [];

    if (yelp.data.businesses.length) {
      for (let i = 0; i < yelp.data.businesses.length; i++) {

        let location = {
          objectID: yelp.data.businesses[i].id,
          name: yelp.data.businesses[i].name,
          imageURL: yelp.data.businesses[i].image_url,
          street: yelp.data.businesses[i].location.address1,
          city: yelp.data.businesses[i].location.city,
          region: yelp.data.businesses[i].location.state,
          postalCode: yelp.data.businesses[i].location.zip_code,
          url: yelp.data.businesses[i].url,
          phoneNumber: yelp.data.businesses[i].display_phone
        }
  
        yelpResults.push(location);
      }
    }
    

    if ((this.state.yelpOffset + 20) > yelp.data.total) {
      this.setState({yelpNextPage: false});

    } else {
      this.setState({
        yelpNextPage: true,
        yelpOffset: this.state.yelpOffset + 20
      })
    }

    return yelpResults
  }

  async loadMoreResults() {
    console.log("more results");

    if (this.state.googleSearch) {
      await this.state.googleNextPage();
      
    } else if (this.state.yelpSearch) {
      let results = this.getLocations();
    }
  }

  render() {
    // const { tab, listings, destinationLocations, read, setPlanningView, isNew, info, uid } = this.props;
    const { tab, setPlanningView, info, locationCategories, selectedLocations, 
      addToSelectedLocations, removeFromSelectedLocations, savePlan, isPlanOwner, selectingForMessage, addToMessageLocation, localityID } = this.props;

    return (
      <div {...console.log(info, this.state.place)}>
        {
          this.state.showDetails && 
          <div className="container mt-4">        
            <PlanGridItemDetails {...this.props} getawayHatchSearch={this.state.getawayHatchSearch} 
            googleSearch={this.state.googleSearch} location={this.state.selectedLocation}
            hideDetailsCard={this.hideDetailsCard} yelpSearch={this.state.yelpSearch}/>
          </div>
        }

        {
        !this.state.showDetails &&
        <>
        <HeaderCarousel imageFileName="food-and-wine-brings-people-together-picture-id639001742.jpg">     
        
        {
          info && this.props.isNew &&
          <div {...console.log("has info and is new")}>
            <div className="col-md-auto w-100">
              <p className="h6 text-white" style={{textShadow:'5px 5px 10px black'}}>
              You can add to the plan by choosing a city, region, or destination to discover your perfect getaway.
              </p>
            </div>
            <PlaceSearchForm placeSelected={this.placeSelected} infoName={info.name} loading={this.state.loading}/>
            {
            !info && 
            <div className="card text-white bg-secondary my-0 py-0">
              <div className="card-header my-0 py-0">
                <h5 className="card-title text-center my-0 py-0">Please search a location to begin.</h5>
              </div>
            </div>
            }
            {
            info && 
            <div className="card text-white bg-secondary my-0 py-0">
              <div className="card-header my-0 py-0">
                <h5 className="card-title text-center text-warning my-0 py-0">Searching a new location will create a new destination in your plan. 
                To add to an already created destination, press the "Review Plan and Save" button.</h5>
              </div>
            </div>
            }
          </div>
          
        }
        {
          !info && this.props.isNew &&
          <div {...console.log("no info and is new")}>
            <div className="col-md-auto w-100">
              <p className="h6 text-white" style={{textShadow:'5px 5px 10px black'}}>
                  You can add to the plan by choosing a city, region, or destination to discover your perfect getaway.
              </p>
            </div>
            <PlaceSearchForm placeSelected={this.placeSelected} infoName={''} loading={this.state.loading}/>
            {
            !info && 
            <div className="card text-white bg-secondary my-0 py-0">
              <div className="card-header my-0 py-0">
                <h5 className="card-title text-center my-0 py-0">Please search a location to begin.</h5>
              </div>
            </div>
            }
          </div>
          
        }
        {
          this.props.planId && this.props.plan &&
          <div {...console.log(info, this.state.place)} {...console.log("has plan id and has plan")}>
            <div className="col-md-auto w-100">
              <p className="h6 text-white" style={{textShadow:'5px 5px 10px black'}}>
              You can add to the plan by choosing a city, region, or destination to discover your perfect getaway.
              </p>
            </div>
            <PlaceSearchForm placeSelected={this.placeSelected} infoName={this.state.place.name} loading={this.state.loading}/>
            {
            !info && !this.state.place &&
            <div className="card text-white bg-secondary my-0 py-0">
              <div className="card-header my-0 py-0">
                <h5 className="card-title text-center my-0 py-0">Please search a location to begin.</h5>
              </div>
            </div>
            }
            {
            (info || this.state.place) &&
            <div className="card text-white bg-secondary my-0 py-0">
              <div className="card-header my-0 py-0">
                <h5 className="card-title text-center text-warning my-0 py-0">Searching a new location will create a new destination in your plan. 
                To add to an already created destination, press the "Review Plan and Save" button.</h5>
              </div>
            </div>
            }
          </div>
        }
          { /*}
          <div className="form-row mt-1 mb-1">
            <div className="col-md-12">
              <Search search={this.search} {...rest} onSelected={this.onSelected} viewport={this.state.viewport} />
              
            </div>
          </div>
          */ }
        </HeaderCarousel>

        <div className="container mt-4">        
          <div className="form-row">
            <div className="col-md-12">
              <h4 className="mb-0">Adventure Awaits</h4>
              <PlanTagline tab={tab} />
            </div>
          </div>

          { (info || this.state.place) &&
          <div className="row mt-1">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-header text-left pb-0">
                    <div className="row">
                      <div className="col-10">
                        {/*<PlanFilters setPlanningTab={this.props.setPlanningTab} />*/}
                        <div className="row">
                          <div className="col-md-auto">

                            { (info || this.state.place) && !selectingForMessage &&
                              <button className="btn btn-primary ml-3" onClick={ () => savePlan() }>Review Plan and Save</button>
                            }

                            { selectingForMessage &&
                              <button className="btn btn-primary ml-3" onClick={ () => savePlan() }>Back To Plan</button>
                            }

                          </div>

                          <div className="col pr-0 mr-0">
                            <input type="text" className="form-control" id="locationSearch" value={this.state.locationSearch} 
                            placeholder={`Where are you going? ${this.categories[Math.floor(Math.random() * (this.categories.length - 1))]}?`} onChange={this.handleSearchTextChange}></input>
                          </div>

                          {
                            !(this.state.locationSearch === '') &&
                            <div className="col-md-auto pl-0 ml-0">
                              <button className="btn btn-outline-primary" onClick={() => {
                                this.setState({
                                  locationSearch: '',
                                  showCategories: true
                                }, () => this.showCategoriesGrid());
                              }}>
                                <i className="fa fa-times"></i>
                                Clear Search
                                </button>
                            </div>
                          }
                          
                        </div>
                      </div>
                      <div className="col-md-auto">
                        <div className="pull-right">
                          <div className="btn-group" role="group" aria-label="Basic example">
                            <button
                              type="button"
                              className={ this.viewClassName(Constants.PLANNING_VIEW_GRID) }              
                              onClick={ () => setPlanningView(Constants.PLANNING_VIEW_GRID) }
                            >
                              <i className="fa fa-columns" />
                            </button>
                            <button
                              type="button"
                              className={ this.viewClassName(Constants.PLANNING_VIEW_MAP) }              
                              onClick={ () => setPlanningView(Constants.PLANNING_VIEW_MAP) }
                            >
                              <i className="fa fa-map" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3 px-0">
                      <div className="col">
                        <div className="card border-light">
                          <div className="card-body">
                            {this.state.showCategories &&
                              <p className="text-center">What places are you interested in?</p>
                            }

                            {!this.state.showCategories &&
                              <>
                              <div className="row">

                                {
                                  this.state.loading &&
                                  <div className="btn-group btn-group-toggle btn-block" data-toggle="buttons">
                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.getawayHatchSearch})}>
                                      <input type="radio" name="search-options" id="GetawayHatch" 
                                      autoComplete="off" disabled/>Getaway Hatch
                                    </label>

                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.googleSearch})}>
                                      <input type="radio" name="search-options" id="Google"  
                                      autoComplete="off" disabled/>Google
                                    </label>

                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.yelpSearch})}>
                                      <input type="radio" name="search-options" id="Yelp"  
                                      autoComplete="off" disabled/>Yelp
                                    </label>
                                  </div>
                                }

                                {
                                  !this.state.loading &&
                                  <div className="btn-group btn-group-toggle btn-block" data-toggle="buttons">
                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.getawayHatchSearch})}>
                                      <input type="radio" name="search-options" id="GetawayHatch" 
                                      autoComplete="off" onClick={ this.handleSearchSourceChange }/>Getaway Hatch
                                    </label>

                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.googleSearch})}>
                                      <input type="radio" name="search-options" id="Google"  
                                      autoComplete="off" onClick={ this.handleSearchSourceChange }/>Google
                                    </label>

                                    <label className={classNames("btn btn-outline-secondary", { "active": this.state.yelpSearch})}>
                                      <input type="radio" name="search-options" id="Yelp"  
                                      autoComplete="off" onClick={ this.handleSearchSourceChange }/>Yelp
                                    </label>
                                  </div>
                                }
                                
                              </div>

                              <div className="row">
                                {
                                  this.state.getawayHatchSearch &&
                                  <div className="col pt-2">
                                    <p className="text-center">Here are some ideas from Getaway Hatch...</p>
                                  </div>
                                }

                                {
                                  this.state.googleSearch &&
                                  <div className="col pt-2">
                                    <p className="text-center">Here are some ideas from Google...</p>
                                  </div>
                                }

                                {
                                  this.state.yelpSearch &&
                                  <div className="col pt-2">
                                    <p className="text-center">Here are some ideas from Yelp...</p>
                                  </div>
                                }
                              </div>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div className="card-body">
                {
                  this.state.loading &&
                  <div className="row" >
                    <div className="col" >
                      <p className="text-center">Loading...</p>
                      <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                  </div>
                }

                {/*
                  !this.state.loading && this.props.isNew &&
                  <RootPlanTabContent {...this.props} onViewportChange={this.onViewportChange} viewport={this.state.viewport} 
                  showCategories={this.state.showCategories} getawayHatchSearch={this.state.getawayHatchSearch} 
                  googleSearch={this.state.googleSearch} selectedCategory={this.selectedCategory} searchResults={this.state.searchResults}
                  showDetailsCard={this.showDetailsCard} googleNextPage={this.state.googleNextPage} loadMoreResults={this.loadMoreResults}
                  yelpSearch={this.state.yelpSearch} yelpNextPage={this.state.yelpNextPage} selectedLocations={selectedLocations} 
                  addToSelectedLocations={addToSelectedLocations} removeFromSelectedLocations={removeFromSelectedLocations} 
                  place={{name: info.name, id: info.id}}/>
                */}
                {
                  !this.state.loading &&
                  <RootPlanTabContent {...this.props} onViewportChange={this.onViewportChange} viewport={this.state.viewport} 
                  showCategories={this.state.showCategories} getawayHatchSearch={this.state.getawayHatchSearch} 
                  googleSearch={this.state.googleSearch} selectedCategory={this.selectedCategory} searchResults={this.state.searchResults}
                  showDetailsCard={this.showDetailsCard} googleNextPage={this.state.googleNextPage} loadMoreResults={this.loadMoreResults}
                  yelpSearch={this.state.yelpSearch} yelpNextPage={this.state.yelpNextPage} selectedLocations={selectedLocations} 
                  addToSelectedLocations={addToSelectedLocations} removeFromSelectedLocations={removeFromSelectedLocations} 
                  place={{name: this.state.place.name, id: this.state.place.id}} isPlanOwner={isPlanOwner}
                  selectingForMessage={selectingForMessage} addToMessageLocation={addToMessageLocation} localityID={localityID}/>
                }
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        </>
        }
      </div>
    );
  }
}
export default withRouter(connect(
  mapStateToProps,
  { ...Actions, ...CoreActions },
)(PlanLocationSelect));