import React, { Component } from 'react'; 
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { selectCollection } from '../actions/selectors';
import isEmpty from 'lodash/isEmpty';

import { getUID } from '../session/selectors';

import * as SessionActions from '../session/actions';
import * as ListingActions from '../listings/actions';
import * as Actions from '../actions/actions';


import { tokenizeCategory} from '../planning/filter-info';
import { selectEatsAndDrinks, selectThingsToDo, selectServices } from '../location-categories/selectors';

import moment from 'moment';


import filter from 'lodash/filter';
import { compareByName } from '../helpers';
import { invoke } from '../firebase';
import { NavBarDropdownItem } from '../planning/components/plan-filter-dropdown';
import IDCheck from '../shared/IDCheck';

const mapPlans = (plans) => {
  if (!plans) { return [] };

  return Object.values(plans).sort((a, b) => (a.lastSearch < b.lastSearch ? 1 : -1));
}

const mapStateToProps = state => {
  return {
    session: state.session,
    signedIn: Boolean(state.session.user),
    uid: getUID(state),
    plans: mapPlans(selectCollection(state, 'deprecatedPlans')),
    eatsAndDrinks: selectEatsAndDrinks(state),
    thingsToDo: selectThingsToDo(state),
    services: selectServices(state),
  };
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationCategories: [],
      eatsAndDrinksCategories: [],
      thingsToDoCategories: [],
      servicesCategories: []
    }
  }

  componentDidMount() {
    if (this.props.uid) {
      this.props.getCollection('deprecatedPlans', [{ attr: 'uid', op: '==', val: this.props.uid }]);
    }  

    if (!this.props.eatsAndDrinks || this.props.eatsAndDrinks.length === 0) {
      this.props.getCollection('locationCategories');
    }

    invoke('locationCategories').then((results) => {
      let locations = results.data.sort(compareByName);
      let eatsAndDrinks = filter(locations, { locationCategoryTypeId: 'Jx4CRXOWUlh0YxDLe7Cy' });
      let thingsToDo = filter(locations, { locationCategoryTypeId: 'X06UDvoQRrUPlcWReC00' });
      let services = filter(locations, { locationCategoryTypeId: 'ECWcWPh8igZHxjdGcR1F' });

      this.setState({locationCategories: locations});
      this.setState({eatsAndDrinksCategories: eatsAndDrinks});
      this.setState({thingsToDoCategories: thingsToDo});
      this.setState({servicesCategories: services});
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uid && nextProps.uid !== this.props.uid) {
      this.props.getCollection('deprecatedPlans', [{ attr: 'uid', op: '==', val: nextProps.uid }]);
    }
  }

  render() {
    const { signedIn, plans, eatsAndDrinks, thingsToDo, services, uid } = this.props;

    return (
      <nav id="root-navbar" className="navbar sticky-top navbar-expand-lg navbar-dark"
        style={{ backgroundColor: 'black', paddingTop: 0, paddingBottom: 0 }}
        >
        <Link to="/" className="navbar-brand" style={{ paddingTop: 0, paddingBottom: 0 }}>              
          <img src="/logo.png" width="60" height="60" className="d-inline-block align-top" alt="" />
          <span style={{ verticalAlign: 'middle', lineHeight: '60px', fontWeight: '900', fontFamily: 'Playfair Display'}}>Getaway Hatch</span>
          <span className="ml-2" style={{ verticalAlign: 'middle', lineHeight: '40px', fontWeight: '100', fontSize: '1rem', fontStyle: 'italic' }}>Adventure Awaits!</span>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto"> 
            <li>
              <Link className="nav-link" to="/home">
                HomePage
              </Link>
            </li>   
            
            { /*
              !signedIn &&
              <li className="nav-item">
                <Link className="nav-link" to="/locations">Restaurants</Link>
              </li>
            */ }

            { signedIn && IDCheck(uid) &&
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Locations
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/locations">Locations</Link>
                  <Link className="dropdown-item" to="/location-categories">Categories</Link>     
                  <Link className="dropdown-item" to="/reads-filters">Reads Filters</Link>   
                </div>
              </li>
            }

            { signedIn && IDCheck(uid) &&

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Places
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/places">Places Metadata</Link>
                  <Link className="dropdown-item" to="/places-custom">Places Custom</Link>     
                </div>
              </li>
            }

            { signedIn && (uid.localeCompare("7tnnSo6p33VkMBpEAJySdHVMIV13") == 0 || uid.localeCompare("cPTQr9I9eyb5T4HXAG1ABUO1qXp2") == 0 || uid.localeCompare("dfFwzassLNfSAUPVj1PU8MEtDYv1") == 0 || 
            uid.localeCompare("g49ujCixL1XgMj9NL9zIGrRUL2q2") == 0 || uid.localeCompare("r4sIwq1Il8bfFdtIWkV5OyRQ6Pz1") == 0) &&
              <li>
              <Link className="nav-link" to="/activities">
                Activities
              </Link>
            </li> 
            }

            { signedIn && (uid.localeCompare("7tnnSo6p33VkMBpEAJySdHVMIV13") == 0 || uid.localeCompare("cPTQr9I9eyb5T4HXAG1ABUO1qXp2") == 0 || uid.localeCompare("dfFwzassLNfSAUPVj1PU8MEtDYv1") == 0 || 
            uid.localeCompare("g49ujCixL1XgMj9NL9zIGrRUL2q2") == 0 || uid.localeCompare("r4sIwq1Il8bfFdtIWkV5OyRQ6Pz1") == 0) &&
              <li>
              <Link className="nav-link" to="/location-reports">
                Location Reports
              </Link>
            </li> 
            }

            {/* signedIn && IDCheck(uid) &&

            <li className="nav-item dropdown">
              <Link className="nav-link" to="/featured-points-of-interest">
                Points Of Interest
              </Link>
            </li> */
            }
          </ul>

          <ul className="navbar-nav ml-auto">
          {/* signedIn &&
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  My Favorites
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item">No favorites yet!</a>
                </div>
              </li>
            */}
            {/*
              !isEmpty(plans) && 
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarPlans" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  My Plans
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarPlans">
                  { plans.map((plan) =>
                    <Link
                      key={plan.objectID}
                      to={`/planning/${plan.objectID}`}
                      className="dropdown-item"
                    >
                      {plan.info.text}          
                      <div style={{ marginTop: 0, fontSize: '0.8rem', color: 'darkGray' }}>
                        {moment(plan.lastSearch).fromNow()}
                      </div>
                    </Link>
                  )}
                </div>
              </li>
            */}
            { /* 
            <li className="nav-item">
              <a className="nav-link" style={{ cursor: 'pointer' }} onClick={() => { saveUserListing() }}>List your property</a>
            </li>
            */ }

            {
              !signedIn && 
              <li className="nav-item">
                <Link className="nav-link" to="/login">Login</Link>          
              </li>
            }
            {
              signedIn && 
              
              <li className="nav-item">     
                <Link className="nav-link" to="/profile">
                  <i className="fa fa-user-circle" />
                </Link>   
                { /*     
                <a className="nav-link" onClick={() => signOut() } style={{ cursor: 'pointer' }}>Logout</a>
                */ }
              </li>
            }            
          </ul>
        </div>
      </nav>
    );
  }
}

// old navbar code to select categoryies
/*
<li className="nav-item dropdown">
              
              <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Eats & Drinks
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                { this.state.eatsAndDrinksCategories.map((category =>
                  <NavBarDropdownItem item={category}/>
                ))}
              </div>
            </li>
            

            <li className="nav-item dropdown">
              
                <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Things To Do
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                { this.state.thingsToDoCategories.map((category =>
                    <NavBarDropdownItem item={category}/>
                  ))}
                </div>
              </li>

            
            <li className="nav-item dropdown">
              
                <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                { this.state.servicesCategories.map((category =>
                    <NavBarDropdownItem item={category}/>
                  ))}
                </div>
            </li>
*/

export default connect(
    mapStateToProps,
    { ...SessionActions, ...ListingActions, ...Actions },
)(Navbar);
