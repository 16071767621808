import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Actions from '../actions';

import './location-loader.css';

const mapStateToProps = state => ({
  ...state.session.info,
});

class LocationLoader extends Component {
  componentDidMount() {
    this.props.locationLookup();
  }

  render() {
    // const { ip, city, continent_name, country_name, region_code, region_name, latitude, longitude } = this.props;
    // country_flag: "http://assets.ipstack.com/flags/us.svg"
    // console.log("location loader", this.props);

    if (this.props.ip) {
      return (
        <div id="location-info">
          <span>
            {this.props.city},&nbsp;
            {this.props.region_name},&nbsp;
            {this.props.country_name},&nbsp;
            {this.props.continent_name}&nbsp;
            {this.props.location.country_flag_emoji}
          </span>
          <span>
            {this.props.latitude}, {this.props.longitude}
          </span>

          <span className="viewport-size d-inline d-none d-sm-none">XS</span>
          <span className="viewport-size d-sm-inline d-none d-md-none">SM</span>
          <span className="viewport-size d-md-inline d-none d-lg-none">MD</span>
          <span className="viewport-size d-lg-inline d-none d-xl-none">LG</span>
          <span className="viewport-size d-xl-inline d-none">XL</span>
          
        </div>
      );  
    }
    

    return <div>no location info</div>;
  }
};

export default connect(
  mapStateToProps,
  Actions
)(LocationLoader);