import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { DivOverlay } from 'leaflet';

export default ({ item, }) => {

  return (
    <li className="list-group-item">

      <div className="row">
        <div className="col-md-4 text-left">      
          <i className={`fa ${item.icon} mr-2`} />
          {
            item.name && 
            <Link 
            to={{
              pathname: `/reads-filter/${item.objectID}`,
            }}>{item.name}</Link>
          }
          {
            !item.name &&
            <Link 
            to={{
              pathname: `/reads-filter/${item.objectID}`,
            }}>Unknown Filter Region</Link>
          }
        </div>
        <div className="col-md-1 text-left">
          {
            item.amount &&
            <div>{item.amount}</div>
          }
        </div>
        <div className="col-md-5">
          <div>
            <Link style={{ marginLeft: 10, fontSize: '0.8em' }} to={`/reads-cities/${item.objectID}`}>Manage Cities</Link>      
          </div>        
        </div>

      </div> 
    </li>
  );
};

// LocationCategory.defaultTypes = { 
//   locationCategoryTypes: {}
// }

// export default connect(
//   mapStateToProps,
// )(LocationCategory);