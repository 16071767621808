import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import ListingProgressBar from '../components/listing-progress-bar';
import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';
//import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import * as Actions from '../../actions/actions';
import { getStepInfo } from '../selectors';
import track from '../../actions/track';

import './location.css';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const mapStateToProps = (state, ownProps) => {
  const listing = getDocumentFromParams(state);
  const coordinates = listing && [listing._geoloc.lat, listing._geoloc.lng];

  console.log("coordinates--------", coordinates);

  return {
    ...getStepInfo(state, ownProps),
    listing,
    coordinates,
    id: getIDFromParams(state),
  }
};

// const position = [38.6884, -121.139];
const viewport =  {
  width: "100%",
  height: "400px",
  latitude: 37.7577,
  longitude: -122.4376,
  zoom: 8
};

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = { viewport };    
  }

  componentDidMount() {
    track('Cofirm Listing Location');
    if (!this.props.listing) {
      this.props.getDocument(`listings/${this.props.id}`);
    }
  }
  
  render() {
    const { current, id, listing, coordinates } = this.props;

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-3">
            <ListingProgressBar step={current} />
          </div>

          <div className="col-md-9">
            <div className="card">
              <h5 className="card-header text-left">Verify the location of your rental</h5>        
              <div className="card-body">
                <p className="card-text text-left">Your listing will include a map, but not your address. Your address is only shared
                with guests who have booked your property.</p>

                <p className="card-text text-left">If needed, drag the map to adjust it's location.</p>

                <div className="w-100 mt-3 mb-3">
                {
                  // coordinates &&                
                  // <MapContainer center={coordinates} zoom={17}>
                  //   <TileLayer
                  //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  //     attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  //   />
                  //   <Marker position={coordinates}>
                  //     <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                  //   </Marker>
                  // </MapContainer>
                }
                </div>
              </div>          
            </div>


            {
              listing &&               
              <div className="pull-right mt-2">
                <Link className="btn btn-primary" to={`/listing/details/${id}`}>Next</Link>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Location));


/*
<!--
                <ReactMapGL
                        {...viewport}
                        mapboxApiAccessToken={token}
                        onViewportChange={(viewport) => this.setState({viewport})}
                      />
                      -->
*/