import * as Constants from './constants';

export default (state={}, action) => {
  switch (action.type) {
    case Constants.SET_SEARCH_REFINEMENT:
      return { ...state, [action.searchIndex]: action.searchRefinement, page: 1 };
    case Constants.SET_SEARCH_PAGE:
      return { ...state, page: action.page };
    case Constants.SET_SEARCH_RESULTS:
      return { ...state, [action.key]: action.results };
    default:
      return state;
  }
}