import React from 'react';

import Image, { SITE } from './image';

import './header-carousel.css';

const HeaderCarousel = ({ imageFileName, children }) =>
<div id="video-container" className="container mt-0">
<div id="vid" className="carousel" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      { 
        imageFileName &&
        <Image imageType={SITE} imageFileName={imageFileName} className="img-fluid w-100" alt="home" />		
      }
      { 
        !imageFileName &&
        <video playsInline  muted loop id="myVideo">
          <source src="https://firebasestorage.googleapis.com/v0/b/trip-galaxy-site/o/tripgalaxy-5.mp4?alt=media&token=6410352a-1004-449a-8e8b-78347f10cdbd" type="video/mp4" />
        </video>
      }
      <div
        className="carousel-caption d-none d-block mb-4"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: 6, padding: 8 }}
      >
        { children }              
      </div>
    </div>
  </div>
</div>
</div>;

export default HeaderCarousel