import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Checkbox from '../components/checkbox';
import ListingProgressBar from '../components/listing-progress-bar'
import { getStepInfo } from '../selectors';
// import NextStep from '../components/next-step';
import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';

import { reduxForm } from 'redux-form';
import * as Actions from '../../actions/actions';
import track from '../../actions/track';

const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
});

const mapValueLabel = (value) => {
  switch (value) {
    case "television": return "Television";
    case "dvdPlayer": return "DVD Player";
    case "internet": return "Internet/WiFi";
    case "ac": return "AC/Central Air";
    case "ceilingFans": return "Ceiling Fans";
    case "heat": return "Heat";
    case "fireplace": return "Fireplace";
    case "pool": return "Pool";
    case "heatedPool": return "Heated/Indoor Pool";
    case "sharedPool": return "Community/Shared Pool";
    case "hotTub": return "Hot Tub";
    case "bbq": return "Outdoor Grill/BBQ";
    case "backyard": return "Backyard or Garden";
    case "deck": return "Patio, Deck or Courtyard";
    case "porch": return "Porch, Balcony or Lanai";
    case "parking": return "Designated Parking";
    case "oven": return "Oven/Stove";
    case "microwave": return "Microwave";
    case "highChair": return "Kid's high chair";
    case "crib": return "Crib";
    case "dishwasher": return "Dishwasher";
    case "washer": return "Washer/Dryer";
    case "iron": return "Iron/Ironing board";
    case "gym": return "Gym/Fitness Equipment";
    case "hairDryer": return "Hair Dryer";
    case "bedLinens": return "Bed Linens Provided";
    case "bathTowels": return "Bath Towels Provided";
    case "beachTowels": return "Beach Towels Provided";
    case "soap": return "Soap Provided";
    case "shampoo": return "Shampoo & Conditioner Provided";
    case "paperTowels": return "Paper Products/Towels/Bath Tissue Provided";
    case "housekeeping": return "Housekeeping";
    default: return null;
  }
}

class Amenities extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Amenities");
  }

  submit(values) {
    const amenities = Object.keys(values).map(key => mapValueLabel(key));
    console.log('submit', values, amenities);

    const { id } = this.props;
    const next = `/listing/highlights/${id}`;
    
    console.log("submit", values, next);
    this.props.updateDocument(`listings/${id}`, { amenities }, next);
  }

  render() {
    const { current, handleSubmit, submitting } = this.props;

    return (
      <div className="container">
        <form onSubmit={handleSubmit(this.submit)} noValidate autoComplete="off">
          <div className="row">
            <div className="col-md-3 mt-4">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9 mt-4">
              <div className="card">
                <h5 className="card-header text-left">Amenities</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Television" name="television" /></div>
                    <div className="col-md-3"><Checkbox label="DVD Player" name="dvdPlayer" /></div>
                    <div className="col-md-3"><Checkbox label="Internet/WiFi" name="internet" /></div>
                    <div className="col-md-3"><Checkbox label="AC/Central Air" name="ac" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Ceiling Fans" name="ceilingFans" /></div>
                    <div className="col-md-3"><Checkbox label="Heat" name="heat" /></div>
                    <div className="col-md-3"><Checkbox label="Fireplace" name="fireplace" /></div>
                    <div className="col-md-3"><Checkbox label="Pool"  name="pool" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Heated/Indoor Pool" name="heatedPool" /></div>
                    <div className="col-md-3"><Checkbox label="Community/Shared Pool" name="sharedPool" /></div>
                    <div className="col-md-3"><Checkbox label="Hot Tub" name="hotTub" /></div>
                    <div className="col-md-3"><Checkbox label="Outdoor Grill/BBQ" name="bbq" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Backyard or Garden" name="backyard" /></div>
                    <div className="col-md-3"><Checkbox label="Patio, Deck or Courtyard " name="deck" /></div>
                    <div className="col-md-3"><Checkbox label="Porch, Balcony or Lanai" name="porch" /></div>
                    <div className="col-md-3"><Checkbox label="Designated Parking" name="parking" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Oven/Stove" name="oven" /></div>
                    <div className="col-md-3"><Checkbox label="Microwave" name="microwave" /></div>
                    <div className="col-md-3"><Checkbox label="Kid's high chair" name="highChair" /></div>
                    <div className="col-md-3"><Checkbox label="Crib" name="crib" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Dishwasher" name="dishwasher" /></div>
                    <div className="col-md-3"><Checkbox label="Washer/Dryer" name="washer" /></div>
                    <div className="col-md-3"><Checkbox label="Iron/Ironing board" name="iron" /></div>
                    <div className="col-md-3"><Checkbox label="Gym/Fitness Equipment" name="gym" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Hair Dryer" name="hairDryer" /></div>
                    <div className="col-md-3"><Checkbox label="Bed Linens Provided" name="bedLinens" /></div>
                    <div className="col-md-3"><Checkbox label="Bath Towels Provided" name="bathTowels" /></div>
                    <div className="col-md-3"><Checkbox label="Beach Towels Provided" name="beachTowels" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Soap Provided" name="soap" /></div>
                    <div className="col-md-6"><Checkbox label="Shampoo & Conditioner Provided" name="shampoo" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-6"><Checkbox label="Paper Products/Towels/Bath Tissue Provided" name="paperTowels" /></div>
                  </div>
                </div>
              </div>

              <div className="card w-100 mt-3">
                <h5 className="card-header text-left">Services</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Housekeeping" name="housekeeping" /></div>
                  </div>
                </div>
              </div>

              <div className="pull-right mt-2"> 
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: 'listingAmenities',
})(Amenities);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));