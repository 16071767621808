export const SET_USER_LISTING_ADDRESS = '/listings/SET_USER_LISTING_ADDRESS';
export const GET_USER_LISTINGS = '/listings/GET_USER_LISTINGS';
export const GET_USER_LISTINGS_SUCCESS = '/listings/GET_USER_LISTINGS_SUCCESS';
export const GET_USER_LISTINGS_ERROR = '/listings/GET_USER_LISTINGS_ERROR';

export const SAVE_USER_LISTING = '/listings/SAVE_USER_LISTING';
export const SAVE_USER_LISTINGS_SUCCESS = '/listings/SAVE_USER_LISTING_SUCCESS';
export const SAVE_USER_LISTINGS_ERROR = '/listings/SAVE_USER_LISTING_ERROR';

export const SET_SESSION_DOCUMENT = '/session/SET_SESSION_DOCUMENT';

export const GET_DOCUMENT = '/session/GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = '/session/GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_ERROR = '/session/GET_DOCUMENT_ERROR';

export const INVOKE_CLOUD_FUNCTION = '/session/INVOKE_CLOUD_FUNCTION';
export const INVOKE_CLOUD_FUNCTION_ERROR = '/session/INVOKE_CLOUD_FUNCTION_ERROR';

export const SAVE_DOCUMENT = '/session/UPDATE_DOCUMENT';
export const SAVE_DOCUMENT_ERROR = '/session/UPDATE_DOCUMENT_ERROR';

export const GET_COLLECTION = '/session/GET_COLLECTION';
export const GET_COLLECTION_SUCCESS = '/session/GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_ERROR = '/session/GET_COLLECTION_ERROR';

export const FULL_TEXT_SEARCH = '/full-text/SEARCH';
export const FULL_TEXT_SEARCH_SUCCESS = '/full-text/SEARCH_SUCCESS';

export const SET_PLAN_INFO_FROM_LOCATION = 'session/SET_PLAN_INFO_FROM_LOCATION';