import React, { useState, useEffect } from 'react';
import './homepage.css';
import HeaderCarousel from '../../components/header-carousel';
import PointOfInterest from '../point-of-interest';

import PlaceSearchForm from '../../place-search/place-search-form';
import PlansList from '../../planning/containers/plansList';
import { SITE, PLACES } from '../../components/image';
import { deleteUnusedImages, checkForLocationCoordinates, checkURL } from '../../firebase'; //import {addAllUsersToEmailTaskQueue, testLocationCategoryTrigger, checkForUnusedImages} from '../../firebase';

const solidPeachDivStyle = {
    color: '#777', 
    backgroundColor:'#76797e', 
    textAlign: 'center', 
    padding:'50px 80px', 
    textAlign: 'justify'
}

const solidWhiteDivStyle = {
    color: '#777', 
    backgroundColor:'#FFFFFF', 
    textAlign: 'center', 
    padding:'125px 125px', 
    textAlign: 'justify'
}

const navbarBrand = {
    color: '#FFFFFF',
    fontSize: '150%',
    textShadow:'5px 5px 10px black'
}

function HomePage () {

    // function handleClickUpdateTags() {
    //     console.log("test");

    //     updateLocationTags();
    // };

    // function handleAddAllUsersToEmailTaskQueue() {
    //     console.log("test");

    //     addAllUsersToEmailTaskQueue();
    // };

    // function runCategoryTest() {
    //     console.log("test");
    //     testLocationCategoryTrigger();
    // }
    // function handleCheckForUnusedImages() {
    //     console.log("test");

    //     checkForUnusedImages();
    // }

    // function handleDeleteUnusedImages() {
    //     console.log("test");

    //     deleteUnusedImages();
    // }

    // function handleClickCheckCoords() {
    //     console.log("test");

    //     checkForLocationCoordinates();
    // };

    // function handleClickCheckURL() {
    //     //console.log("test");
    //     // let url1 = 'http://bronxzoo.com/';
    //     // let url2 = 'https://www.chelseamarket.com/';

    //     // console.log(url1.charAt(4) !== 's');
    //     // console.log(url2.charAt(4) !== 's');

    //     checkURL();
    // };

    return (
        <div style={{backgroundColor: "#000"}}>
            <div data-spy="scroll" data-target="#navbar-homepage" data-offset="0">
                <div className="bgimg-1">

                    <nav id="navbar-homepage" className="navbar navbar-expand navbar-transparent" style={{marginLeft: '10%'}}>
                        <a className="navbar-brand" href="#">
                            <i className="fa fa-map-marker" style={navbarBrand} aria-hidden="true">
                                Getaway Hatch
                            </i>
                        </a>

                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#" style={{textShadow:'5px 5px 10px black'}}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#Features" style={{textShadow:'5px 5px 10px black'}}>Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="#GetawayHatch" style={{textShadow:'5px 5px 10px black'}}>Getaway Hatch</a>
                            </li>
                        </ul>
                    </nav>

                    <hr style={{ width: "90%", backgroundColor: "#FFFFFF", height: "1px", border: "none"}}/>

                    <div className="row pt-6">
                        <div className="col-8 offset-md-1">
                            <h1 className="display-4 text-left font-weight-bold text-white" style={{textShadow:'5px 5px 10px black'}}>
                                Adventure Awaits!
                            </h1>
                        </div>

                        <div className="col-sm-5 offset-md-1 text-white">
                            <p className="h3 text-left" style={{textShadow:'5px 5px 10px black'}}>
                                Hatch your next getaway plan. 
                                Explore things to do, create a plan, share it with friends, receive feedback, and finalize your itinerary.</p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-md-auto w-100 pt-5">
                            {/*<p className="h6 text-white" style={{textShadow:'5px 5px 10px black'}}>
                                Begin by choosing the city, region, or destination to discover your perfect getaway.
                            </p>*/}
                            <p className="h6 text-white" style={{textShadow:'5px 5px 10px black'}}>
                                Plans temporarily disabled to prevent issues.
                            </p>
                        </div>
                        {/*<div className="col-md-auto w-50 pt-0 pb-2">
                            <PlaceSearchForm infoName={"Where to?"}/>
                        </div>*/}
                        {/*<div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={handleClickUpdateTags}>update tags</button>
                    </div>*/}
                    {/* <div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={runCategoryTest}>category test</button>
                    </div> */}
                    {/* {<div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={handleAddAllUsersToEmailTaskQueue}>add users to task queue</button>
                    </div>} */}
                    {/* <div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={handleCheckForUnusedImages}>check images</button>
                    </div> */}
                    {/* <div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={handleDeleteUnusedImages}>delete images</button>
                    </div> */}

                    {/* <div className="col-md-auto w-50 pt-0 pb-2">
                            <button onClick={handleClickCheckURL}>check url</button>
                    </div> */}
                    </div>
										
                </div>

                <div>
                    <div className="row justify-content-md-center align-items-end">
                        <div className="col-md-auto w-100">
                            <div>
                                {/*<PlansList></PlansList>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="Features" className="bgimg-2">
                    <div className="row align-items-center">
                        <div className="col-md-2 offset-md-4">
                            
                        </div>

                        <div className="col-md-2 offset-md-3 pt-6">
                            <ul className="list" style={{backgroundColor: 'rgba(52, 52, 52, 0.5)'}}>
                                <li className="pt-2">
                                    <p className="h5 text-left text-white font-weight-bold" 
                                    style={{opacity: "1.0", textShadow:'5px 5px 10px black'}}>Hatch a plan.</p>
                                </li>
                                <li className="pt-2">
                                    <p className="h5 text-left text-white font-weight-bold" 
                                    style={{opacity: "1.0", textShadow:'5px 5px 10px black'}}>Explore the options.</p>
                                </li>
                                <li className="pt-2">
                                    <p className="h5 text-left text-white font-weight-bold" 
                                    style={{opacity: "1.0", textShadow:'5px 5px 10px black'}}>Share with friends and family.</p>
                                </li>
                                <li className="pt-2">
                                    <p className="h5 text-left text-white font-weight-bold" 
                                    style={{opacity: "1.0", textShadow:'5px 5px 10px black'}}>Share feedback.</p>
                                </li>
                                <li className="pt-2">
                                    <p className="h5 text-left text-white font-weight-bold" 
                                    style={{opacity: "1.0", textShadow:'5px 5px 10px black'}}>Finalize your itinerary.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center" style={solidPeachDivStyle}>
                    <div className="col">
                        <img src="/image_4.jpg" className="rounded mx-auto d-block" style={{ maxWidth: "500px", width: 'auto', height: 'auto', objectFit: 'cover' }}/>
                    </div>

                    <div className="col">
                        <h3 className="h1 text-left text-white font-weight-bold" style={{textAlign: 'center', textShadow:'5px 5px 10px black'}}>
                            Plan Your Getaway
                        </h3>

                        <p className="h4 text-left text-white" style={{textShadow:'5px 5px 10px black'}}>
                        Getaway Hatch is an experience planning phone application and website that will assist you with planning your perfect getaway 
                        down to the smallest detail. We provide a comprehensive list of wineries, breweries, distilleries, public golf courses, and 
                        restaurants, as well as spas, coffee shops, things to do, grocery stores and services. You can access our database for recommendations, 
                        as well as Google, and Yelp, to assist with your decisions.
                        </p>

                        <p className="h4 text-left text-white" style={{textShadow:'5px 5px 10px black'}}>
                        Our planning tool allows you to create a plan and share it with others. You can invite friends to vote on what they want to do, 
                        and take part in a live chat to finalize your itinerary. 
                        </p>

                        <p className="h4 text-left text-white" style={{textShadow:'5px 5px 10px black'}}>
                        Getaway Hatch is the best solution, for people traveling together for a weekend getaway, a long vacation, or a corporate retreat, 
                        to assist with your perfect stay. 
                        </p>
                    </div>
                </div>

                <div id="GetawayHatch">
                    <div id="carouselIndicators" className="carousel slide" data-interval="5000" data-ride="carousel" style={solidWhiteDivStyle}>
                        <ol className="carousel-indicators">
                            <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselIndicators" data-slide-to="2"></li> 
                        </ol>

                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-auto align-self-center d-block w-100">
                                        <PointOfInterest image="san-diego-test.jpg" title="San Diego" 
                                        to="/points-of-interest/san-diego" path={PLACES}/>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-auto align-self-center d-block w-100">
                                        <PointOfInterest image="iStock-92622503.jpg" title="Napa Valley" 
                                        to="/points-of-interest/napa-valley" path={SITE}/>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="row justify-content-md-center">
                                    <div className="col-md-auto align-self-center d-block w-100">
                                        <PointOfInterest image="generic-12.jpg" title="California Wine Regions" 
                                        to="/points-of-interest/california-wineries" path={PLACES}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                            <i className="fa fa-chevron-left" aria-hidden="true" style={{color: '#000000'}}></i>
                            <span className="sr-only">Previous</span>
                        </a>

                        <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                            <i className="fa fa-chevron-right" aria-hidden="true" style={{color: '#000000'}}></i>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>

                <div className="bgimg-3">
                    <div className="row justify-content-md-center">
                        <div className="col-md-5 align-self-center">
                            <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                            Getaway Hatch offers the information needed to plan the perfect getaway, 
							including things to do In the destination you choose:  
                            </h6>

                            <ul>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Restaurants
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Coffee shops
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold"style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Wineries
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Breweries
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Golf Courses
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Spas
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Tours
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Museums, Aquariums and Zoos
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Historical Sites and Landmarks
                                    </h6>
                                </li>
                                <li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Parks, Gardens and Recreation
                                    </h6>
                                </li>
								<li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Arts and Entertainment
                                    </h6>
                                </li>
								<li>
                                    <h6 className="font-weight-bold" style={{textAlign: 'left', textShadow:'0px 0px 10px white'}}>
                                    Recommended Online Articles, and more
                                    </h6>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-md-center" style={{color: '#777', backgroundColor:'#FFFFFF'}}>
                    
                </div>

				<div className="row justify-content-md-center" style={{color: '#777', backgroundColor:'#FFFFFF'}}>
                    
                </div>
                {/*
                <div className="row justify-content-center pb-0 mb-0" style={{color: '#777', backgroundColor:'#DCDCDC'}}>
                    <div className="col-3">
                        <ul className="list-unstyled">
                            <li className="py-0 my-0">
                                <p className="py-0 my-0">Contact Us At:</p>
                            </li>
                            <li className="py-0 my-0">
                                <p className="py-0 my-0">Email: Corp@getawayhatch.com</p>
                            </li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <ul className="list-unstyled">
                            <li className="pt-0 mt-0">
                                <a className="text-muted" href="/Getaway_Hatch_Terms_and_Conditions.pdf">Terms and Conditions</a>
                            </li>
                            <li className="pt-0 mt-0">
                                <a className="text-muted" href="/Getaway_Hatch_Privacy_Policy.pdf">Privacy Policy</a> 
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="row justify-content-md-center py-0 my-0" style={{color: '#777', backgroundColor:'#DCDCDC'}}>
                    <hr className="py-0 my-0" style={{ width: "90%", backgroundColor: "#FFFFFF", height: "1px", border: "none"}}/>
                    <ul className="list-unstyled py-0 my-0">
                        <li className="font-weight-light py-0 my-0">
                            <p> © 2020 Getaway Hatch LLC All Rights Reserved</p>
                        </li>
                    </ul>
                    
                </div>
                */}
            </div>
        </div>
    )
}

export default HomePage;