import sfb from './sfb';

import { push } from 'connected-react-router';
import * as Constants from '../constants';
import { takeEvery, call, put } from 'redux-saga/effects'

import isFunction from 'lodash/isFunction';

function* saveDocument(action) {
  // try {
    var results;
    
    if (action.item.objectID) {
      console.log("updating document", action);
      yield call(sfb.firestore.updateDocument, `${action.collection}/${action.item.objectID}`, action.item);
      results = action.item;
    } else {
      console.log("adding document", action);
      results = yield call(sfb.firestore.addDocument, action.collection, action.item);
    }

    console.log('done', results)
    if (action.next) {
      if (isFunction(action.next)) {
        action.next(results);
      } else {
        yield put(push(action.next));
      }
    }
  // } catch

}

export default function* () {
  yield takeEvery(Constants.SAVE_DOCUMENT, saveDocument);
}