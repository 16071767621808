import sfb from './sfb';
// import { push } from 'connected-react-router';

// import { getUID } from '../../session/selectors';
import { getLocationElements } from '../selectors';
import last from 'lodash/last';
import get from 'lodash/get';
import * as Constants from '../constants';
import { call, takeEvery, select, put } from 'redux-saga/effects'

function* setUserListingAddress(action) {
   const suggestion = get(action, 'address.suggestion');
   const locationElements = yield select(getLocationElements)
   const id = last(locationElements);

   console.log('location elements', id);
   if (suggestion) {
      const values = {
         search: suggestion.value,
         street: suggestion.name, 
         city: suggestion.city,
         region: suggestion.administrative,
         postalCode: suggestion.postcode,
         _geoloc: {
            lat: suggestion.latlng.lat,
            lng: suggestion.latlng.lng,
          },
      };

   try {
      const documentRef = yield call(sfb.firestore.updateDocument, `listings/${id}`, values);
      console.log('documentref', documentRef);
      const ref = yield call(sfb.firestore.getDocument, `listings/${id}`);
      yield put({ type: Constants.SET_SESSION_DOCUMENT, [ref.id]: ref.data() });
   } catch (error) {
      console.log('error', error);
   }
   // if (action.path) {
   //    yield put(push(action.path));
   // }
      // yield put ({ type: Constants.SAVE_USER_LISTING, listing });
   }
}

export default function* () {
  yield takeEvery(Constants.SET_USER_LISTING_ADDRESS, setUserListingAddress);
}