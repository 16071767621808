import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch('0XTKDK7IUC', 'b3b6db66d4cf5bafa139ed4c160d28ed');

const index = searchClient.initIndex('places');

export const getAlgoliaPlacesResults = async (query) => {
    try {
        return index.search(query).then(({ hits }) => { return hits });
    } catch (error) {
        return error;
    }
}

export default searchClient;