import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';
import ListingProgressBar from '../components/listing-progress-bar'
import { reduxForm, Field } from 'redux-form'
import { getStepInfo } from '../selectors';
import * as Actions from '../../actions/actions';
import get from 'lodash/get';
import track from '../../actions/track';

const mapStateToProps = (state, ownProps) => {
  const listing = getDocumentFromParams(state);
  
  return {
    ...getStepInfo(state, ownProps),
    listing,
    id: getIDFromParams(state),
    hasPropertyType: Boolean(get(listing, 'propertyType')),
    initialValues: {
      propertyType: "House",
    },
  };
};

class Details extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Description");
    if (!this.props.listing) {
      this.props.getDocument(`listings/${this.props.id}`);
    }
  }

  submit(values) {
    const { id } = this.props;
    const next = `/listing/accomodations/${id}`;
    
    console.log("submit", values, next);
    this.props.updateDocument(`listings/${id}`, values, next);
  }
  
  render() {
    const { current, handleSubmit, submitting } = this.props;

    return (
      <div className="container">
        <form onSubmit={handleSubmit(this.submit)} noValidate autoComplete="off">
          <div className="row">
            <div className="col-md-3 mt-4">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9 mt-4">          
              <div className="card">
                <h5 className="card-header text-left">Describe your property</h5>        
                <div className="card-body">
                  <Field className="form-control mt-4" name="propertyType" component="select">
                    <option>House</option>
                    <option>Bungalow</option>
                    <option>Cottage</option>
                    <option>Cabin</option>
                    <option>Secondary Unit</option>
                    <option>Condominium / Apartment</option>
                    <option>Townhouse</option>
                  </Field>
                </div>
              </div>

              <div className="pull-right mt-2">
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: 'locationDetails',
})(Details);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));