import React from "react";

// import { withScriptjs, withGoogleMap, GoogleMap } from "@react-google-maps/api";

// const TripGalaxyMap = ({ children, lat, lng, defaultZoom }) =>
//   <GoogleMap
//     googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCG0eLmU9Da0dsGMfryLs8lLjDb-JE5LD0&v=3.exp&libraries=geometry,drawing,places`}
//     defaultZoom={defaultZoom}
//     center={{ lat, lng }}
//   >
//     {children}
//   </GoogleMap>;

// export default withScriptjs(withGoogleMap(TripGalaxyMap));

const TripGalaxyMap = ({ children, lat, lng, defaultZoom }) => <p></p>;

export default TripGalaxyMap;