import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import browserImageSize from 'browser-image-size';

import { saveLocationCategory } from '../actions';
import LocationCategoryForm from '../components/location-category-form';
import * as Actions from '../../actions/actions';
import { selectDocument, selectCollection } from '../../actions/selectors';
//import { deleteCategory } from '../../../functions/location-categories';
import { deleteCategory, collection, deleteDocument } from '../../firebase';
import { getUID } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';

const mapStateToProps = (state, ownProps) => {
  return {
    item: selectDocument(state, 'locationCategories', ownProps.match.params.id) || {
      locationCategoryTypeId: 'ECWcWPh8igZHxjdGcR1F',
      parentID: '',
    },
    id: ownProps.match.params.id,
    locationCategoryTypes: selectCollection(state, 'locationCategoryTypes') || {},
    uid: getUID(state)
    
  };
};

class LocationCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      sizes: [],
      isSubmitting: false,
      locationSubCategories: [],
      checkedForSubCategories: false,
      redirect: false,
    };

    this.setImages = this.setImages.bind(this);
    this.saveLocationCategoryAndUploadImages = this.saveLocationCategoryAndUploadImages.bind(this);
    this.callDeleteCategory = this.callDeleteCategory.bind(this);
    // this.copy = this.copy.bind(this);
  }


  async componentDidMount() {
    const { getCollection, getDocument, id, location } = this.props;

    if (id && id !== 'new') {
      getDocument('locationCategories', id);

      if (location && location.state && location.state.subCategories) {
        this.setState({locationSubCategories: location.state.subCategories}, () => this.setState({checkedForSubCategories: true}));

      } else {
        collection('locationCategories', { field: 'parentID', op: '==', val: id, orderBy: 'name'}).then((results) => {
          this.setState({locationSubCategories: results}, () => this.setState({checkedForSubCategories: true}));
        });
      }
      
    }

    if (this.props.locationCategoryTypes.length === 0) {
      getCollection('locationCategoryTypes');
    }

  }
  
  setImages(images) {
    console.log("set images", images);
    var sizes = [];
    if (images.length > 0) {
      browserImageSize(images[0]).then((size) => {
        console.log("size", size);
        sizes.push(size);
        this.setState({ ...this.state, images, sizes });
      })
    } else {
      this.setState({ ...this.state, images, sizes });
    }    
  }

  saveLocationCategoryAndUploadImages(values) {
    console.log('saving location', values);
    
    this.setState({ ...this.state, isSubmitting: true });
    if (IDCheck(this.props.uid)) {
      this.props.saveLocationCategory(values, this.state.images, this.state.sizes);
    }
    
  }

  callDeleteCategory = async (item) => {
    let id = item.objectID;
    console.log("called delete category: " + id);
    if (IDCheck(this.props.uid)) {
      this.setState({isSubmitting: true});

      deleteDocument('locationCategories', id).then((item) => {
        this.setState({redirect: true});
      });

      

      /*this.setState({ ...this.state, isSubmitting: true });
    
      if (this.state.locationSubCategories.length > 0) {
        console.log("deleting subcategories");
        let subCategories = this.state.locationSubCategories;
        for (let i = 0; i < subCategories.length; i++) {
          let subCategoryData = {
            id: subCategories[i].objectID,
            imageFileName: null
          }
          let complete = deleteCategory(subCategoryData);

          complete.then(function(results) {
            console.log(results);
          });
        }
      }

      let categoryData = {
        id: id,
        imageFileName: null
      }

      if (item.imageFileName) {
        categoryData.imageFileName = item.imageFileName;
      }

      let complete = deleteCategory(categoryData);

      complete.then(function(results) {
        console.log(results);
        this.props.history.push('/location-categories');
      }); */
    }
    
  }

  render() {
    const { item, ...rest } = this.props;

    if (this.state.redirect) {
      return <Redirect push to="/location-categories" />;
    }

    return (    
      
      <LocationCategoryForm
        item={item}        
        saveLocationCategoryAndUploadImages={this.saveLocationCategoryAndUploadImages}
        callDeleteCategory={this.callDeleteCategory} images={this.state.images} setImages={this.setImages}
        subCategoriesLength={this.state.locationSubCategories.length} checkedForSubCategories={this.state.checkedForSubCategories}
        {...rest}
      />
    );
  } 
}

export default withRouter(connect(
  mapStateToProps,
  { saveLocationCategory, ...Actions },
)(LocationCategory));