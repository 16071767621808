import React from 'react';

import { connectSortBy } from 'react-instantsearch-dom';

const LocationSortBy = ({ items, refine, createURL }) => (

  <div className="dropdown">
    <button className="btn btn-outline-primary dropdown-toggle w-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Sort Order
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    {items.map(item => (
      
        <a key={item.value}
        className="dropdown-item"
          href={createURL(item.value)}
          style={{ fontWeight: item.isRefined ? 'bold' : '' }}
          onClick={event => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          {item.label}
        </a>
      
    ))}

    </div>
  </div>

);

export default connectSortBy(LocationSortBy);