import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';

export default ({ locationCategory, locationSubCategory, saveLocationSubCategory, deleteLocationSubCategory }) => {  
  const { register, handleSubmit, formState: { errors } } = useForm();

  var name = get(locationSubCategory, 'name'); 
  var hereCategories = get(locationSubCategory, 'hereCategories', false); //return the value or return false if undefined
  var hereFoodTypes = get(locationSubCategory, 'hereFoodTypes', false);

  return (    
      <form onSubmit={handleSubmit(saveLocationSubCategory)}>  
        <div className="row">
          <div className="col-md-12">
            <input className="form-control w-100" name="name" defaultValue={name} {...register( 'name', { required: true })} placeholder="Name" />
            {errors.name && <span style={{ fontSize: '0.6em', color: 'red' }}>Required</span>}
          </div>
        </div>
        <   div className="row">
      <div className="col-md-12 mb-2">
        <ul className="list-group">
          {
            hereCategories && hereCategories.map((hereCategory, index) => 
            <li key={hereCategory} className="list-group-item">
              <p style={{color: 'black'}}>Here.com Category: {hereCategory}</p>
            </li>
            )
          }
        </ul>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 mb-2">
        <ul className="list-group">
          {
            hereFoodTypes && hereFoodTypes.map((hereFoodType, index) => 
            <li key={hereFoodType} className="list-group-item">
              <p style={{color: 'black'}}>Here.com Food Type: {hereFoodType}</p>
            </li>
            )
          }
        </ul>
      </div>
    </div>
        <div className="row mt-2">
          <div className="col-md-12">     
            <button type="button" className="btn btn-danger pull-left" onClick={() => deleteLocationSubCategory()}>
              <i className="fa fa-trash" />
            </button>       

            <button className="btn btn-primary pull-right" type="submit">
              Save
            </button>

            <Link to={`/location-sub-categories/${locationCategory.objectID}`} className="btn btn-outline-primary pull-right mr-1">Cancel</Link>
          </div>
        </div>
      </form>
  );
}  