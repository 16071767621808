import React, { Component } from 'react';

// import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux';

//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import StyledFirebaseAuth from './styled-firebase-auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'

import { withRouter } from 'react-router-dom';

import {analytics} from '../../firebase/index';

import {checkForUser} from '../../firebase'

const mapStateToProps = state => ({
  signedIn: Boolean(state.session.user),
});

class LoginForm extends Component {
  uiConfig = {    
    signInFlow: 'popup',    
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };

  checkUser() {
    console.log(this.props.user);
    
    let user = {
      uid: this.props.user.uid
    }

    if (this.props.user.email) {
      user.email = this.props.user.email;
    }
    if (this.props.user.phoneNumber) {
      user.phoneNumber = this.props.user.phoneNumber;
    }
    if (this.props.user.displayName) {
      let nameArray = this.props.user.displayName.split(' ');
      user.firstName = nameArray[0];
      user.lastName = nameArray[(nameArray.length - 1)];
    }
    console.log("checking for user: ", user);

    checkForUser(user);
  }

  render() {
    if (this.props.signedIn) {
      console.log(this.props.signedIn, this.props.user.uid);
      analytics.logEvent('website_login', {
        userID: this.props.user.uid
      });

      //console.log(firebase.auth().currentUser);
      console.log(this.props.user);

      this.checkUser();

      console.log("signed in");
      this.props.history.push('/');
    }

    return (
      <div>
        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
)(LoginForm));