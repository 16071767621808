import React from 'react';
import { Route, Switch } from 'react-router';

import Address from './containers/address';
import SignUp from './containers/sign-up';
import Location from './containers/location';
import Details from './containers/details';
import Options from './containers/options';
import Security from './containers/security';
import Photos from './containers/photos';
import Calendar from './containers/calendar';
import Payment from './containers/payment';
import Pricing from './containers/pricing';
import Accomodations from './containers/accomodations';
import Amenities from './containers/amenities';
import Highlights from './containers/highlights';
import Info from './containers/info';

export default () =>
  <Switch>
    <Route exact path="/listing/address/:id">
      <Address />
    </Route>

    <Route exact path="/listing/sign-up/:id">
      <SignUp />
    </Route>

    <Route exact path="/listing/location/:id">
      <Location />
    </Route>

    <Route exact path="/listing/details/:id">
      <Details />
    </Route>

    <Route exact path="/listing/options/:id">
      <Options />
    </Route>

    <Route exact path="/listing/photos/:id">
      <Photos />
    </Route>

    <Route exact path="/listing/security/:id">
      <Security />
    </Route>

    <Route exact path="/listing/calendar/:id">
      <Calendar />
    </Route>

    <Route exact path="/listing/payment/:id">
      <Payment />
    </Route>

    <Route exact path="/listing/pricing/:id">
      <Pricing />
    </Route>

    <Route exact path="/listing/accomodations/:id">
      <Accomodations />
    </Route>

    <Route exact path="/listing/amenities/:id">
      <Amenities />
    </Route>

    <Route exact path="/listing/highlights/:id">
      <Highlights />
    </Route>
    
    <Route exact path="/listing/info/:id">
      <Info />
    </Route>
  </Switch>;