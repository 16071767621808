import React from 'react';

export default () =>
  <div className="row mt-3 text-left" style={{ fontSize: ".9rem" }}>  
  <div className="col-md-12">
    <p>This spectacular five-bedroom villa sits on the idyllic shoreline of an area often referred to as the American Riviera. Spend long, blissful days gazing across golden sand to the Pacific Ocean or simply enjoying the home's many five-star amenities. The spacious villa combines the classic charm of a New England beach house with modern design that is pure California.</p>

    <p>Santa Barbara's comfortable, year-round temperatures mean that no matter the season, you can start each day with a walk on the beach. As the sun heats up, choose from several wood decks around the property to lay out on a chaise longue. Head for the roof terrace and cool off in the long plunge pool or under the outdoor shower. Host a cocktail party with the rustic firepit as your centerpiece. Appetizers can be prepared on the grill and served around an al fresco dining table. Ease into the barrel hot tub and watch the stars come alive.</p>

    <p>Discover two distinct worlds inside. On the ground floor, light streams in through French doors and bay windows, illuminating the elegantly-appointed rooms. Dark wood floors, beamed ceilings and custom wood furnishings adorn the living rooms, dining room and kitchen. The fully-equipped kitchen houses high-end appliances and smooth surfaces, making cooking a pleasure. Below, the underground entertainment level features a Cannery Row theme. The décor will remind you of life during the Steinbeck years, but the amenities are strictly 21st century, including a screening room, wine cellar, wet bar and games room.</p>

    <p>You and your guests will sleep comfortably, with the sounds of the sea soothing you to sleep. Each room is individually decorated with contemporary beach house touches, plus bright windows that allow the saltwater breezes to flow freely. The Master bedroom is on the ground floor and opens directly onto the oceanfront terrace, while three of the upper bedrooms have balconies. All rooms feature televisions and an en-suite bathrooms.</p>

    <p>You may never want to leave the property, but if you do, Santa Barbara is home to the best of everything. Organize tours of your diverse natural surroundings or take endless hikes. State Street is 8 miles away, where you'll find upscale shops, unique restaurants, galleries and a host of other venues that have solidified the town's cultural prominence.</p>
  </div>
</div>;