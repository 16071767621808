import React, { Component } from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import Description from '../components/description';
import Amenities from '../components/amenities';
import Image, { SITE } from '../../components/image';

import DateRangePickerWrapper from '../../helpers/date-range-picker-wrapper';
import moment from 'moment';

import track from '../../actions/track';

import './vacation-rental.css'

class VacationRental extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tab: 'details'
    };

    this.setTab=this.setTab.bind(this);
  }

  componentDidMount() {
    track("Vacation Rental");
  }

  setTab(tab) {
    this.setState({ ...this.state, tab });
    console.log("settab", this.state);
  }

  render() {
    const { tab } = this.state;

    console.log("render");
    return (
      <div>
        <Image imageType={SITE} imageFileName="iStock-925052286.jpg" className="img-fluid w-100" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-md-7 mt-3">
              <h2>Room to Roam in Santa Barbara</h2>
              <h3 style={{ color: 'darkgray', fontWeight: 400 }}>$2,500/night</h3>
              <div className="fluid-container mt-4">
                <div className="row">
                  <div className="col-md-3">
                    <div><i className="fa fa-user" /></div>
                    <div>Sleeps 12</div>                
                  </div>
                  <div className="col-md-3">
                    <div><i className="fa fa-bed" /></div>
                    <div>6 Bedrooms</div>
                  </div>
                  <div className="col-md-3">
                    <div><i className="fa fa-bath" /></div>
                    <div>5.5 Bathrooms</div> 
                  </div>
                  <div className="col-md-3">                  
                    <div>
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </div>
                    <div>13 Reviews</div>
                  </div>            
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        { /* eslint-disable-next-line */ }
                        <a className={classNames("nav-link", {'active': tab ==='details' })} onClick={() => { this.setTab('details'); } }>Details</a>
                      </li>
                      <li className="nav-item">
                        { /* eslint-disable-next-line */ }
                        <a className={classNames("nav-link", {'active': tab ==='amenities' })} onClick={() => { this.setTab('amenities'); } }>Amenities</a>
                      </li>
                    </ul>
                  </div>
                </div>

                { tab === 'details' && <Description /> }
                { tab === 'amenities' && <Amenities /> }
              </div>
            </div>

            <div className="col-md-5 mt-3">
              
              <form>
                <div className="card">
                  <div className="card-body">
                  <h5 className="card-title">Plan your stay</h5>
                    <div className="row">
                      <div className="col-md-12 mt-3">
                      <DateRangePickerWrapper
                        startDate={moment()}
                        startDateId="your_unique_start_date_id"
                        endDate={moment()}
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                      />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 mt-3">
                        <button type="button" className="btn btn-outline-primary w-100">Inquire</button>
                      </div>

                      <div className="col-md-6 mt-3">
                        <Link to="/booking/dates" className="btn btn-primary w-100">Book</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VacationRental;