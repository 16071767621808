import { createSelector } from 'reselect';
import { compareByName } from '../helpers';

import filter from 'lodash/filter';

export const getLocationCategories = (state) => state.collections.locationCategories || [];
export const getLocationCategoryArrayByName = (state) => Object.values(getLocationCategories(state)).sort(compareByName)

export const getEatsAndDrink = (state) =>
  filter(getLocationCategoryArrayByName(state), { locationCategoryTypeId: 'Jx4CRXOWUlh0YxDLe7Cy' });

export const getThingsToDo = (state) =>
  filter(getLocationCategoryArrayByName(state), { locationCategoryTypeId: 'X06UDvoQRrUPlcWReC00' });
 
export const getServices = (state) =>
  filter(getLocationCategoryArrayByName(state), { locationCategoryTypeId: 'ECWcWPh8igZHxjdGcR1F' })

export const selectLocationCategoriesByName = createSelector(
  [ getLocationCategoryArrayByName ], (locationCategories) => {
    return locationCategories;      
  }
);

export const selectLocationCategoriesAndSubcategoriesByName = createSelector(
  [ getLocationCategoryArrayByName ], (locationCategories) => {
    
    var items = [];
    for (var i = 0; i < locationCategories.length; i++) {
      var locationCategory = locationCategories[i];
      items.push(locationCategory);

      if (locationCategory.subCategories) {
        var subCategories = locationCategory.subCategories.split(', ');
        for (var x = 0; x < subCategories.length; x++) {
          var subCategory = subCategories[x];
          
          items.push({
            objectID: `${locationCategory.objectID}-${subCategory}`,
            subCategory,
            name: `${locationCategory.name} > ${subCategory}`,
          });
        }
      }
    }

    return items;  
  }
);

export const selectEatsAndDrinks = createSelector(
  [ getEatsAndDrink ], (locationCategories) => {
    return locationCategories;
  }
);

export const selectThingsToDo = createSelector(
  [ getThingsToDo ], (locationCategories) => {
    return locationCategories;
  }
);

export const selectServices = createSelector(
  [ getServices ], (locationCategories) => {
    return locationCategories;
  }
);