import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ActivityCards from '../components/activity-cards';

import searchClient from '../../search';

import LocationRefinementList from '../../locations/components/location-refinement-list';

import {
    InstantSearch,
    Hits,
    SearchBox,  
    Pagination,
    Stats,
    Configure
  } from 'react-instantsearch-dom';


function Activities( props ) {
    
    return (
        <div className="container mt-4">
            <InstantSearch searchClient={searchClient} indexName="activities">
                <div className="card mt-4">
                    <div className="card-header">
                        <h3 className="pull-left" style={{ marginBottom: 0 }}>
                            Activities (Temporarily Disabled For Testing)
                            <Stats />

                        </h3>
                    </div>
                    <div className="card-body">              
                        <div className="container">
                            <div className="row form-group">
                            <div className="col d-flex justify-content-center">
                                <Pagination />
                            </div>
                            </div>
                            
                            <div className="row">
                              <div className="col-md-3 mt-3">                    
                                <div className="card mb-2">
                                  <div className="card-header">Emails</div>                      
                                    <LocationRefinementList attribute="email" limit={50} />                            
                                </div>
                              </div>

                              <div className="col-md-9">
                                <ActivityCards/>
                                {/*<CustomHits refresh={this.refresh} pageDoneChanging={this.pageDoneChanging} pageChange={this.state.pageChange} uid={this.props.uid} user={this.props.user} />*/}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InstantSearch>  
        </div>  
    );
}

export default Activities;