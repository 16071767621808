import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

// import { saveLocationCategory } from '../actions';
// import LocationCategoryForm from '../components/location-category-form';
import * as Actions from '../../actions/actions';
import { selectDocument } from '../../actions/selectors';

import LocationSubCategoryRenameForm from '../components/location-subcategory-rename-form';
// import { database } from 'firebase';

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id; 
  const item = selectDocument(state, 'locationCategories', id);
  var subCategories = [];

  if (item && item.subCategories) {
    subCategories = item.subCategories.split(', ');
  }

  return {
    id,
    item,
    subCategories,
  };
};

class LocationCategoryRename extends Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
  }
  componentDidMount() {
    const { getDocument, id } = this.props;

    getDocument('locationCategories', id);
    
  }

  save(data) {
    console.log("save", data);

    var message = {
      id: data.objectID,
      new: data.new,
      old: data.old,
    };

    this.props.invokeCloudFunction('subCategoryRename', message, '/location-categories');

  }

  render() {
    const { item } = this.props;

    if (!item) {
      return (
        <div>Loading</div>
      )
    }

    return (
      <LocationSubCategoryRenameForm save={this.save} {...this.props} />
    );
  }
}

export default withRouter(connect(
    mapStateToProps,
    Actions,
)(LocationCategoryRename));