import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Checkbox from '../components/checkbox';

import ListingProgressBar from '../components/listing-progress-bar';

import { getStepInfo } from '../selectors';
// import NextStep from '../components/next-step';

import { reduxForm, Field } from 'redux-form';

import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';
import * as Actions from '../../actions/actions';
import track from '../../actions/track';

const mapValueLabel = (value) => {
  switch (value) {
    case "rules.children": return "Children Welcome";
    case "rules.pets": return "Pets Allowed";
    case "rules.events": return "Events Allowed";
    case "rules.smoking": return "Smoking Allowed";
    case "safety.wheelchair": return "Wheelchair Accessible";
    case "safety.elevator": return "Elevator";
    case "safety.lowAllergen": return "Low-Allergen Environment";
    case "safety.smokeDetector": return "Smoke Detector";
    case "safety.carbonMonoxideDetector": return "Carbon Monoxide Detector";
    default: return null;
  }
}
const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
});

class Info extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Headline Info");
  }
  submit(values) {
    

    if (values.rules) {
      values.rules = Object.keys(values.rules).map(item => mapValueLabel(`rules.${item}`))
    }

    if (values.safety) {
      values.safety = Object.keys(values.safety).map(item => mapValueLabel(`safety.${item}`))
    }


    console.log("submit", values);

    const { id } = this.props;
    const next = `/listing/options/${id}`;
    
    console.log("submit", values, next);
    this.props.updateDocument(`listings/${id}`, values, next);
  }

  render() {
    const { current, handleSubmit, submitting } = this.props;

    return (
      <div className="container mt-4">
        <form onSubmit={handleSubmit(this.submit)} noValidate autoCorrect="off">
          <div className="row">
            <div className="col-md-3">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9">
              <div className="card">
                <h5 className="card-header text-left">Listing</h5>        
                <div className="card-body">
                  <p className="card-text text-left">You'll need a headline for your property.</p>
                  <Field component="input" name="headline" type="text" className="form-control" placeholder="Headline" />

                  <Field component="textarea" name="description" className="form-control mt-4" placeholder="Property description" />

                  </div>          
              </div>

              <div className="card w-100 mt-3">      
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3">
                      <Field component="input" type="text" className="form-control" name="checkInTime" placeholder="Check-in Time" />
                    </div>
                    <div className="col-md-3">
                    <Field component="input" type="text" className="form-control" name="checkOutTime" placeholder="Check-out Time" />
                    </div>              
                  </div>
                </div>
              </div>

              <div className="card w-100 mt-3">
                <h5 className="card-header text-left">House Rules</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Children Welcome" name="rules.children" /></div>
                    <div className="col-md-3"><Checkbox label="Pets Allowed" name="rules.pets" /></div>
                    <div className="col-md-3"><Checkbox label="Events Allowed" name="rules.events" /></div>
                    <div className="col-md-3"><Checkbox label="Smoking Allowed" name="rules.smoking" /></div>
                  </div>
                </div>
              </div>

              <div className="card w-100 mt-3">
                <h5 className="card-header text-left">Accessibility & Safety</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Wheelchair Accessible" name="safety.wheelchair" /></div>
                    <div className="col-md-3"><Checkbox label="Elevator" name="safety.elevator" /></div>
                    <div className="col-md-6"><Checkbox label="Low-Allergen Environment " name="safety.lowAllergen" /></div>
                    
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Smoke Detector" name="safety.smokeDetector" /></div>
                    <div className="col-md-6"><Checkbox label="Carbon Monoxide Detector" name="safety.carbonMonoxideDetector" /></div>
                  </div>
                </div>
              </div>

              <div className="pull-right mt-2">
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
          </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: 'listingInfo'
})(Info);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));
