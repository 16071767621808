import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import get from 'lodash/get';
import { app } from '../../firebase';
// import 'firebase/firestore';

const Profile = () => {
  const [profile, setProfile] = useState();
  const uid = useSelector(state => get(state, 'session.user.uid'));
  // const count = useSelector(state => state.counter.count);

  useEffect(() => {
    if (uid) {
      app.firestore().collection("users").doc(uid).get()
      .then((ref) => {
        console.log('success', ref.data());
        setProfile({});
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        setProfile();
      });
    }
  }, [uid]);

  return (
    <div>
    { profile && <p>got profile</p>}
    { !profile && <p>no profile</p>}
    </div>
  );
}

export default Profile;