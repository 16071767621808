import React from 'react';

import { connect } from 'react-redux';
import classNames from 'classnames';
import  { Marker } from 'react-map-gl';

const mapStateToProps = (state, ownProps) => {
  var icon = "fa-map-marker";

  if (ownProps.location.categories && ownProps.location.categories.length > 0) {
    const id = ownProps.location.categories[0].id;
  
    if (id) {
      const locationCategories = ownProps.locationCategories;
      const locationCategory = locationCategories[id];

      if (locationCategory && locationCategory.icon) {
        icon = locationCategory.icon;
      }
    }
  }
  return {
    icon,
  };
}
const PlanMarker = ({ location, icon, setSelectedLocation, ...rest }) => {

  return (
    <Marker
      key={location.objectID}
      latitude={location._geoloc.lat}
      longitude={location._geoloc.lng}      
    >
      <i className={ classNames("fa", icon) } onClick={() => { console.log("click"); setSelectedLocation(location); }} />
    </Marker>
  )
}

export default connect(
  mapStateToProps,
)(PlanMarker);