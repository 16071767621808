import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams} from "react-router";
import { getCollection, getDocument, collection } from '../../firebase';

const getLocationSubCategories = (id) => 
  getCollection('locationSubCategories', 'locationCategoryID', '==', id);


const LocationSubCategories = () => {
  const { id } = useParams();
  const [locationCategory, setLocationCategory] = useState(null);
  const [locationSubCategories, setLocationSubCategories] = useState([]);

  useEffect(() => {        
    getDocument('locationCategories', id).then(doc => {
      setLocationCategory(doc);
    });    
  }, [id]);

  useEffect(() => {
    if (!locationCategory) { return; }

    console.log('location category changed', locationCategory);
    setLocationSubCategories([]);

    // getLocationSubCategories(id).then(collection => {
    collection('locationCategories', { field: 'parentID', op: '==', val: id, orderBy: 'name'}).then((results) => {
      setLocationSubCategories(results);
    });
  }, [id, locationCategory]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">   
            
              { !locationCategory && <h5 className="mb-0 pull-left">Loading...</h5>}
              { 
                locationCategory &&
                <h5 className="mb-0 pull-left">
                  { locationCategory.name }

                  <Link to={`/location-sub-category/${id}/new`} className="btn btn-sm btn-outline-primary ml-2">
                    <i className="fa fa-plus mr-1" />
                    New Sub-Category
                  </Link>
                </h5>
              }  
            </div>
            
            <ul className="list-group list-group-flush">
              {
                locationSubCategories && locationSubCategories.map(item => {
                  return (
                    <li className="list-group-item text-left" key={item.objectID}>
                      <Link to={`/location-sub-category/${id}/${item.objectID}`}>{item.name}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSubCategories;