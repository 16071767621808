import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Footer from './footer';

import track from '../actions/track';
import PointOfInterest from './point-of-interest';

import LocationsCarousel from './locations-carousel';
import HeaderCarousel from '../components/header-carousel';

import PlaceSearchForm from '../place-search/place-search-form';
import PlansList from '../planning/containers/plansList';
import HomePage from './containers/homepage';

class Home extends Component {
  componentDidMount() {
    track("Home Page");
  }

  render() {
    // const { history } = this.props;

    return (
      
      <div>
        <HomePage/>
        {/*
        <HeaderCarousel imageFileNameOld="cfbf1178-a20e-43b1-8577-7d7113a0bdbf.c10.jpg">
          <PlaceSearchForm infoName={"Where to?"}/>
        </HeaderCarousel>

        <div>
          <PlansList></PlansList>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ marginTop: '1.5rem', textAlign: 'center' }}>
              Getaway Hatch is ready to exceed your expectations, and help you sip and swing your way
              through your next vacation.  Browse our amazing destinations, and discover your perfect
              stay with help from our adventure planning tool.
            </h5>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mt-2">
              <PointOfInterest image="iStock-917834102.jpg" title="San Diego" to="/points-of-interest/san-diego" />
            </div>
            <div className="col-md-6 mt-2">
              <PointOfInterest image="iStock-1041495568.jpg" title="Napa Valley" to="/points-of-interest/napa-valley" />
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12 mt-4">          
              <PointOfInterest image="iStock-96402243.jpg" title="California Wineries" to="/points-of-interest/california-wineries" />
            </div>
          </div>          

          <div className="row mt-3">
            <div className="col-md-12">
              <h5 style={{ marginBottom: '1.5rem', textAlign: 'center', display: 'none' }}>
                We are a destination, vacation home rental company, providing comfortable homes that are conveniently
                located near wineries, breweries, distilleries and golf courses throughout California and the Lake Tahoe
                region. We have done the research to come up with the best places to sip, shop, eat, and explore nearby.
              </h5>
            </div>
          </div>
        </div>
        
        <div style={{ display: 'none' }}>
          <LocationsCarousel />
        </div>

        <Footer />
        */}
      </div>
      
    );  
  }
}
export default withRouter(Home);