import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ListingProgressBar from '../components/listing-progress-bar';
import { getStepInfo } from '../selectors';
// import NextStep from '../components/next-step';
import { Link } from 'react-router-dom';
import track from '../../actions/track';

import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';

const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
});

class Photos extends Component {

  componentDidMount() {
    track('Listing Photos');
  }

  render() {
    const { current, id } = this.props;
    return (

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-3">
            <ListingProgressBar step={current} />
          </div>

          <div className="col-md-9">
            <div className="card">
              <h5 className="card-header text-left">Add up to 50 photos of your property</h5>        
              <div className="card-body">
                <p className="card-text text-left">Showcase your property</p>


                <div className="alert alert-primary" role="alert">
                  <h6 className="mt-4 mb-4" style={{ margin: 0, color: "white" }}>Drop photos here</h6>
                </div>
              </div>          
            </div>

            <div className="pull-right mt-2">
            <Link className="btn btn-primary" to={`/listing/info/${id}`}>Next</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
)(Photos));