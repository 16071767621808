import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapFilterInfo } from '../filter-info';
import { collection, invoke } from '../../firebase';
import { DropdownItem } from './plan-filter-dropdown';
import filter from 'lodash/filter';
import { compareByName } from '../../helpers';

import { selectEatsAndDrinks, selectThingsToDo, selectServices } from '../../location-categories/selectors';

// const mapPlanInfo = (key, setPlanningTab) => filterInfo[key].map((name) => {
//   return { name, id: filterInfo[name] };
// });

const mapStateToProps = (state) => {
  return {
    destinationsFilters: mapFilterInfo("destinations"),
    thingsToDoFilters: mapFilterInfo("thingsToDo"),
    servicesFilters: mapFilterInfo("services"),

    eatsAndDrinks: selectEatsAndDrinks(state),
    thingsToDo: selectThingsToDo(state),
    services: selectServices(state),
  };
};

const PlanFilters = ({ destinationsFilters, thingsToDoFilters, servicesFilters, setPlanningTab }) => {
  let [locationCategories, setLocationCategories] = useState([]);
  let [eatsAndDrinksCategories, setEatsAndDrinksCategories] = useState([]);
  let [thingsToDoCategories, setThingsToDoCategories] = useState([]);
  let [servicesCategories, setServicesCategories] = useState([]);

  useEffect(() => {
    // collection('locationCategories', { field: 'parentID', op: '==', val: '', orderBy: 'name' }).then(results => {
    invoke('locationCategories').then((results) => {
      let locations = results.data.sort(compareByName);
      setLocationCategories(locations);
      setEatsAndDrinksCategories(filter(locations, { locationCategoryTypeId: 'Jx4CRXOWUlh0YxDLe7Cy' }));
      setThingsToDoCategories(filter(locations, { locationCategoryTypeId: 'X06UDvoQRrUPlcWReC00' }));
      setServicesCategories(filter(locations, { locationCategoryTypeId: 'ECWcWPh8igZHxjdGcR1F' }));
    });
  }, []);

  return (
    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
    <div className="btn-group" role="group">
      <button id="allFilter" type="button" className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        All
      </button>
      <div className="dropdown-menu" aria-labelledby="allFilter">
        { /* eslint-disable-next-line */ }
        <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={ () => setPlanningTab({name: "All" }) }>All</a>
        { /* eslint-disable-next-line */ }
        <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={ () => setPlanningTab({name: "Favorites" }) }>Favorites</a>
      </div>
    </div>
    { /*
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={ () => setPlanningTab({name: "Listings" }) }
    >
        Listings
    </button>
    */ }

    <div className="btn-group" role="group">
      <button id="destinationsFilter" /*open={open}*/ type="button" className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Eats & Drinks
      </button>
      <div className="dropdown-menu" aria-labelledby="destinationsFilter">
        <DropdownItem item={{ name: "All Eats & Drinks"}} setPlanningTab={setPlanningTab} />
        
        { eatsAndDrinksCategories && eatsAndDrinksCategories.map((category =>
          <DropdownItem key={category.objectID} item={category} setPlanningTab={setPlanningTab} /*{...console.log(",Category: " + JSON.stringify(category.children))}*/ />
        ))}
      </div>
    </div>

    <div className="btn-group" role="group">
      <button id="thingsToDoFilter" type="button" className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Things to Do
      </button>
      <div className="dropdown-menu" aria-labelledby="thingsToDoFilter">
        <DropdownItem item={{ name: "All Things To Do"}} setPlanningTab={setPlanningTab} />
        { thingsToDoCategories && thingsToDoCategories.map((category =>
          <DropdownItem key={category.objectID} item={category} setPlanningTab={setPlanningTab} />
        ))}
      </div>
    </div>

    <div className="btn-group" role="group">
      <button id="servicesFilter" type="button" className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Services
      </button>
      <div className="dropdown-menu" aria-labelledby="servicesFilter">
        <DropdownItem item={{ name: "All Services"}} setPlanningTab={setPlanningTab} />
        { servicesCategories && servicesCategories.map((category =>
          <DropdownItem key={category.objectID} item={category} setPlanningTab={setPlanningTab} />
        ))}
      </div>
    </div>
  </div>
  );
}
  

export default connect(
  mapStateToProps,
)(PlanFilters);