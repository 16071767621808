import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, invoke } from '../../firebase';
import LocationCategory from '../components/location-category';

import { getUID } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';
import { useSelector } from 'react-redux';

export default () => {
  var [locationCategories, setLocationCategories] = useState([]);
  var [locationCategoryTypes, setLocationCategoryTypes] = useState([]);

  const signedIn = useSelector(state => Boolean(state.session.user));
  const uid = useSelector(state => getUID(state));

  useEffect(() => {
    // collection('locationCategories', { field: 'parentID', op: '==', val: '', orderBy: 'name' }).then(results => {
    invoke('locationCategories').then((results) => {
      console.log(results);
      setLocationCategories(results.data);
    });
  }, []);

  useEffect(() => {
    collection('locationCategoryTypes').then(results => {
      setLocationCategoryTypes(results);
    });
  }, []);

  return (      
    <div className="container mt-4">
      <div className="card">
        <div className="card-header text-left">
          Categories
          { signedIn && IDCheck(uid) &&
          <Link className="btn btn-sm btn-outline-secondary ml-2 pull-right" to="/location-category/new">
            <i className="fa fa-plus"/>
            <span className="d-none d-md-inline"  style={{ marginLeft: 4 }}>New Category</span>
          </Link>
          }
        </div>      
        <ul className="list-group list-group-flush">
          {
            locationCategories && locationCategories.map((item) => 
              <LocationCategory item={item} key={item.objectID} 
                locationCategoryTypes={locationCategoryTypes} />
            )
          }
        </ul>
      </div>
    </div>
  );
}