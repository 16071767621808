import React from 'react';

import TGField from '../../forms/tg-field';
import TGSelect from '../../forms/tg-select';
// import TGMultipleSelect from '../../forms/tg-multiple-select';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropZone from 'react-dropzone';
import get from 'lodash/get';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { mapCategories } from '../../helpers/location-helpers';

import { selectLocationCategoriesAndSubcategoriesByName } from '../../location-categories';
import validate from './location-form-validate';
import { getUID } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';

import LocationTags from './location-tags';

const mapStateToProps = (state, ownProps) => {
  var initialValues = { ...ownProps.item, categories: mapCategories(ownProps.item.categories) };
  var isNew = ownProps.id === 'new';

  // console.log("map state to propes", state, ownProps);

  return {
    form: `location-form`,
    isNew,
    initialValues,
    cancelPath: `/locations/${ isNew ? '' : ownProps.id }`,
    canDelete: !isNew,
    // locationCategories: selectLocationCategoriesAndSubcategoriesByName(state),
    imageSizeData: ownProps.item.imageSize ? `${ownProps.item.imageSize.width} x ${ownProps.item.imageSize.height}` : 'No image size data',

    lat: get(ownProps, 'item._geoloc.lat'),
    lng: get(ownProps, 'item._geoloc.lng'),
    uid: getUID(state)
  };
};

// state_city, type, uuid
const LocationForm = ({ lat, lng, geoloc, imageSizeData, canDelete, handleSubmit,
  saveLocationAndUploadImages, archiveLocation, pristine, isSubmitting, cancelPath, id, 
  locationCategories, setImages, images, //readsRegions, readsCities, readsRegionsLoading, readsCitiesLoading,
  //getReads, getCities, readsSelectedRegion, setRegion, readsSelectedCity, readsGroups, setCity,
  isNew,
  copy,
  deleteLocation,
  setEditing,
  item, uid, ...rest
  }) =>
  <form onSubmit={handleSubmit(saveLocationAndUploadImages)} noValidate autoComplete="off">
    <div className="form-row">
      <div className="col-md-12 mb-2">
        <Field component={TGField} className="form-control" name="name" placeholder="Name" />
      </div>
    </div>
    <div className="form-row">
      <div className="col-md-12 mb-2">
        <Field name="categories" component={TGSelect} className="form-control" multiple>
          {
            locationCategories && locationCategories.map((locationCategory, index) =>
            <option key={locationCategory.objectID} value={locationCategory.objectID}>
              {locationCategory.name}
            </option>
            )
          }
        </Field>
      </div>
    </div>

    <div className="form-row">      
      <div className="col-md-12 mb-2">
        <Field component="textarea" className="form-control" name="description" placeholder="Description" />
      </div>
    </div>
    
    <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={LocationTags} className="form-control" name="tags" placeholder="Tags" />
        </div>
      </div> 

    <div className="form-row"> 
      <div className="col-md-12">

      { images.map(image => 
          <div className="pull-left mr-2 mb-4 mt-4" key={`${image.lastModified}${image.size}`}>
            <img  src={URL.createObjectURL(image)} style={{ height: '8rem' } } alt="" />  
          </div>
        )}
        {
          images.length === 0 && item.imageFileName &&
          <div className="pull-left mr-2 mb-4 mt-4">
            <img src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy.appspot.com/o/${item.imageFileName}?alt=media` } style={{ maxHeight: '8rem', maxWidth: '16rem' } } alt="" />
          </div>
        }

        <div className="pull-left mb-4 mt-4">
          <DropZone onDrop={(accepted, rejected) => { setImages(accepted); }}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                  <input {...getInputProps()} />
                  <p>Drop Image Here</p>
                </div>
              </section>
            )}
          </DropZone>
        </div>
      </div>
    </div>

    <div className="form-row">
      <div className="col-md-8 mb-2">
        <Field component="input" type="text" className="form-control" name="street" placeholder="Street" />
      </div>
      <div className="col-md-2 mb-2">
        <Field component={TGField} type="text" className="form-control" name="_geoloc.lng" placeholder="Longitude"
        />
        
      </div>

      <div className="col-md-2 mb-2">
        <Field component={TGField} type="text" className="form-control" name="_geoloc.lat" placeholder="Latitude"
        />
      </div>
    </div>

    <div className="form-row">
      <div className="col-md-7 mb-2">
        <Field component={TGField} type="text" className="form-control" name="city" placeholder="City" />
      </div>

      <div className="col-md-2 mb-2">
        <Field component="select" type="text" className="form-control" name="region">
          <option value="AK">AK</option>
          <option value="AL">AL</option>
          <option value="AR">AR</option>
          <option value="AZ">AZ</option>
          <option value="CA">CA</option>
          <option value="CO">CO</option>
          <option value="CT">CT</option>
          <option value="DC">DC</option>
          <option value="DE">DE</option>
          <option value="FL">FL</option>
          <option value="GA">GA</option>
          <option value="HI">HI</option>
          <option value="IA">IA</option>
          <option value="ID">ID</option>
          <option value="IL">IL</option>
          <option value="IN">IN</option>
          <option value="KS">KS</option>
          <option value="KY">KY</option>
          <option value="LA">LA</option>
          <option value="MA">MA</option>
          <option value="MD">MD</option>
          <option value="ME">ME</option>
          <option value="MI">MI</option>
          <option value="MN">MN</option>
          <option value="MO">MO</option>
          <option value="MS">MS</option>
          <option value="MT">MT</option>
          <option value="NC">NC</option>
          <option value="ND">ND</option>
          <option value="NE">NE</option>
          <option value="NH">NH</option>
          <option value="NJ">NJ</option>
          <option value="NM">NM</option>
          <option value="NV">NV</option>
          <option value="NY">NY</option>
          <option value="OH">OH</option>
          <option value="OK">OK</option>
          <option value="OR">OR</option>
          <option value="PA">PA</option>
          <option value="RI">RI</option>
          <option value="SC">SC</option>
          <option value="SD">SD</option>
          <option value="TN">TN</option>
          <option value="TX">TX</option>
          <option value="UT">UT</option>
          <option value="VA">VA</option>
          <option value="VT">VT</option>
          <option value="WA">WA</option>
          <option value="WI">WI</option>
          <option value="WV">WV</option>
          <option value="WY">WY</option>
        </Field>
      </div>

      <div className="col-md-3 mb-2">
        <Field component={TGField} type="text" className="form-control" name="postalCode" placeholder="Postal Code" />
      </div>
    </div>

    <div className="form-row">
      <div className="col-md-2 mb-2">
        <Field component={TGField} type="text" className="form-control" name="phoneNumber" placeholder="Phone Number" />
      </div>

      { false &&
      <div className="col-md-5 mb-2">
        <div className="input-group">
          <Field component="input" type="text" className="form-control" name="latitude" placeholder="Latitude" />
          <Field component="input" type="text" className="form-control" name="longitude" placeholder="Longitude" />
          <Field name="locationLookup" component="select" className="custom-select">
            <option value="Google Maps Lookup">Google Maps</option>
            <option value="Manual Override">Manual Override</option>
          </Field>
        </div>
      </div>      
      }

      <div className="col-md-5 mb-2">
        <Field component={TGField} type="text" className="form-control" name="url" placeholder="URL" />
      </div>
    </div>

    <div className="form-row">
      <div className="col mb-2">
        <Field component="input" type="text" className="form-control" name="file" placeholder="File"  readOnly/>
      </div>
    </div>

    <div className="form-row">
      <div className="col-12 mb-2">        
        <input type="text" className="form-control" disabled readOnly value={imageSizeData} />               
      </div>
    </div>

{/*     <div className="form-row">
      <div className="col mb-2">
        
      {
        !setEditing && !isSubmitting && readsRegions.length == 0 &&
        <button type="button" className="btn btn-light mr-2" disabled={readsRegionsLoading} onClick={ () => getReads() }>
          {!readsRegionsLoading && <span>Load Recommended Reads Regions</span>}
          {readsRegionsLoading && <i className="fa fa-spinner fa-spin" />}
          
        </button>
      }

{
        !setEditing && !isSubmitting && readsRegions.length > 0 && readsCities.length == 0 && readsSelectedRegion != '' &&
        <button type="button" className="btn btn-light mr-2" disabled={readsCitiesLoading} onClick={ () => getCities() }>
          {!readsCitiesLoading && <span>Load Recommended Reads Cities</span>}
          {readsCitiesLoading && <i className="fa fa-spinner fa-spin" />}
          
        </button>
      }

      </div>
    </div>

    <div className="form-row">
      {
      readsRegions.length != 0 &&
      <div className="col-md-4 mb-4">
        <Field component="select" type="text" className="form-control" name="readsRegion" onChange={(choice) => setRegion(choice.target.value)} value={readsSelectedRegion}
        // value={options.filter(function(option) { //-------------------work on this
        //   return option.value === readsSelectedRegion;
        // })}
        >
        {readsRegions.map( (region) => 
          <option key={region.name} value={region.region}>{region.name}</option> )}
        </Field>
      </div>
      }
      {
      readsRegions.length > 0 && readsCities.length != 0 && readsSelectedRegion != '' &&
      <div className="col-md-4 mb-4">
        <Field component="select" type="text" className="form-control" name="readsCity" 
        //onChange={(choice) => setCity(choice.target.value)} value={readsSelectedCity}
        >
        {readsCities.map( (city) => 
          <option key={city.name} value={city.name}>{city.name}</option> )}
        </Field>
      </div>
      }
{
      //readsRegions.length > 0 && readsCities.length != 0 && readsSelectedRegion != '' &&
      <div className="col-md-4 mb-4">
        <Field component={TGField} type="text" className="form-control" name="group" placeholder="Reads Group" />
      </div>
      }
    </div>
 */}
    <div className="form-row">
      <div className="col mb-2">
        {
          canDelete && IDCheck(uid) &&
          <button type="button" className="btn btn-danger pull-left" onClick={() => { deleteLocation(id) /*archiveLocation(id)*/ }}>
            <i className="fa fa-trash" />
          </button>
        }

        <button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
          { !isSubmitting && IDCheck(uid) && <span>Save</span>}
          { isSubmitting && <i className="fa fa-spinner fa-spin" /> }
        </button>

        { !setEditing && !isSubmitting && !isNew && pristine &&
          <button type="button" className="btn btn-light pull-right mr-2" onClick={() => { copy() }}>
          <i className="fa fa-copy" />
        </button>
        } 
      {
        !setEditing && !isSubmitting &&
        <Link to={cancelPath} className="btn btn-light pull-right mr-2">Cancel</Link>
      }

      {
        setEditing && !isSubmitting &&
        <button className="btn btn-light pull-right mr-2" onClick={ () => setEditing(false) }>
          Cancel
        </button>
      }

      </div>
    </div>
  </form>;


const Wrapped = reduxForm({
  enableReinitialize: true,
  validate,
})(LocationForm);

export default connect(
  mapStateToProps,
)(Wrapped)



