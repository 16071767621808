import React from 'react';

export default () =>
  <div className="mt-2">
    <ul className="list-group text-left">
      <li className="list-group-item">Wheelchair Accessible</li>
      <li className="list-group-item">Satellite television(s)	DVD player(s)</li>
      <li className="list-group-item">Barbecue(s)</li>
      <li className="list-group-item">Air-Conditioning</li>
      <li className="list-group-item">Baby crib</li>
    </ul>
  </div>;
