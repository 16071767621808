import React from 'react';
import { TGField } from '../../forms';
import TGSelect from '../../forms/tg-select';

import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropZone from 'react-dropzone';

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.item,
  };
};

const ReadsFiltersForm = ({ uuid, pristine, item, handleSubmit, saveRegion, callDeleteRegion, submitting, 
  ...rest }) =>
  <form onSubmit={handleSubmit(saveRegion)} noValidate autoComplete="off" style={{ color: 'white' }} {...console.log(item)}>
    <div className="container mt-4">
  
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={TGField} className="form-control" name="name" placeholder="Name" />
        </div>
      </div> 

      {/* <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={TGField} className="form-control" name="amount" placeholder="Amount" />
        </div>
      </div>   */}  

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={TGField} className="form-control" name="region" placeholder="Region" />
        </div>
      </div> 
      
      <div className="form-row"> 
      <div className="col-md-12">

      </div>
    </div>
      <div className="form-row">
        <div className="col mt-2">
          {
            /*uuid &&
            <button type="button" className="btn btn-danger pull-left" onClick={() => { archiveLocation(locationType, uuid) }}>
              <i className="fa fa-trash" />
            </button>
            */
          }

          {
            item && item.objectID && item.amount === 0 &&
            <button type="button" className="btn btn-danger pull-left" onClick={() => { callDeleteRegion(item) }}>
              <i className="fa fa-trash" />
            </button>
          }
          
          <button type="submit" className="btn btn-primary pull-right" disabled={submitting}>
            Save
          </button>

          <Link to="/reads-filters" className="btn btn-light pull-right mr-2">Cancel</Link>
        </div>
      </div>


    </div>
  </form>;
const Wrapped = reduxForm({
  form: "reads-filters-form",
  enableReinitialize: true, 
})(ReadsFiltersForm);

// InitializeFromStateForm = connect(
//   state => ({
//     initialValues: state.item
//   })
// )(InitializeFromStateForm);

export default connect(
  state => ({
    initialValues: state.item
  }),
  mapStateToProps,
)(Wrapped);