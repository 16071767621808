import React from 'react';
import { Route, Switch } from 'react-router';

import PlacesSearch from './containers/places-search';
import Place from './containers/place';

export default () =>
  <Switch>
    <Route exact path="/places">
        <PlacesSearch/>
    </Route>

    <Route exact path="/place/:id">
        <Place/>
    </Route>
  </Switch>;