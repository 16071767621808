import sfb from './sfb';

import { getUID } from '../../session/selectors';

import * as Constants from '../constants';
import { select, put, takeEvery, call } from 'redux-saga/effects'

import { push } from 'connected-react-router';

function* saveUserListing(action) {
   console.log('save user listing', action);
   
   try {      
      let uid = yield select(getUID)
      const documentRef = yield call(sfb.firestore.addDocument, 'listings', { uid });
      const ref = yield call(sfb.firestore.getDocument, documentRef);

      yield put({ type: Constants.SET_SESSION_DOCUMENT, [ref.id]: ref.data() });
      
      yield put(push(`/listing/address/${ref.id}`));

   } catch (e) {
      // yield put({type: "USER_FETCH_FAILED", message: e.message}); GET_USER_LISTINGS_ERROR
      console.log('error', e);
   }
}

export default function* () {
  yield takeEvery(Constants.SAVE_USER_LISTING, saveUserListing);
}