import React from 'react';
import isString from 'lodash/isString';

import { connect } from 'react-redux';

import { tokenizeCategory } from '../filter-info';

const mapStateToProps = (state, { tab }) => {
  var item = isString(tab) ? tab : tab.name;
  var tagline = tokenizeCategory(item);
  switch (tagline) {    
    case 'favorites':        
      tagline = 'Click on the heart next to your favorites to add them to your adventure plan.';
      break;
    case 'listings':
      tagline = 'Choose the perfect home.  Mark your favorites, compare side-by-side, add the best to your plan, share with others, allow the people you share with to favorite and / or add to the plan.';
      break;
    case 'all-eats-drinks':
    case 'restaurants':
    case 'coffee-shops':
    case 'wineries':
    case 'breweries':
    case 'distilleries':
    case 'bars':    
      tagline = 'Explore destinations. Build your adventure around a destination, or find destinations nearby.  Favorite your top picks to save them to your plan.';
      break;
    case 'all-things-to-do':
    case 'arts-entertainment':
    case 'gardens':
    case 'historic-sites-landmarks':
    case 'indoor-sports-games':
    case 'kids-family':
    case 'museums':
    case 'parks-recreation':
    case 'resorts':
    case 'shopping':    
    case 'tourist-attractions':
    case 'tours':
    case 'zoos-wildlife-preserves':
    case 'golf-courses':
    case 'spas':
    case 'other-things-to-do':
      tagline = 'Explore places to eat, the best coffee shops, spas and things to do nearby.  Favorite your top picks to save them to your plan.';
      break;
    case 'all-services':
    case 'limo-driving-services':
    case 'groceries':        
    case 'limo-services':
    case 'catering':
    case 'personal-chefs':
    case 'private-charters':
      tagline = 'The details to complete your perfect trip. Find the best transportation option for you, catering, personal chefs, groceries nearby, and more. Favorite your choices to add them to your plan.';
      break;
    case 'all':
    default:
      tagline = 'This is the place to explore, compare, plan and share your adventure. You can plan everything down to the last detail.';
      break;

    // case 'Favorites':
    //   tagline = "Favs"
  }

  return {
    tagline,
  };
};

const PlanTagline = ({ tagline }) => {
  if (!tagline) {
    return (<span></span>);
  }

  return <p className="text-muted font-weight-lighter font-size-smaller">{tagline}</p>;
}

export default connect(
  mapStateToProps,
)(PlanTagline);