import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import some from 'lodash/some';
import { rest, set } from 'lodash';
import remove from 'lodash/remove';
import { useForm } from 'react-hook-form';
import PlanPeople from './plan-people';
import PlanGridItemDetails from './plan-grid-item-details';


const PlanInvite = ({ isNew, planData, uid, owner, savePlan, backFromFinalize, isPlanOwner, planUsers, 
	addEmailToInvite, isPlanInvitee, invites }) => {

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [currentEmail, setCurrentEmail] = useState('');

	const { register, handleSubmit, errors, reset } = useForm();

  const handleBackButton = (event) => {
		event.preventDefault();
		backFromFinalize();
	}

	const handleSubmitButton = (data) => {
		//event.preventDefault();
		console.log(data.email);
		
		setIsSubmitting(true);

		addEmailToInvite(data.email);
		

		setIsSubmitting(false);
		reset();
	}
  
  return (
		<div>
			<div className="container-fluid">
					{ isPlanOwner &&
					<div className="row">
						<div className="col">
							<button className="btn btn-primary pull-left left-button pl-4" onClick={handleBackButton}>
								<div className="fa fa-arrow-left"/>
								Back to locations
								</button>
						</div>
					</div>
					}
					
					
					<div className="row justify-content-md-center">
						<div className="col w-100 align-self-center">
							<form onSubmit={handleSubmit(handleSubmitButton)}>
								<div className="card border-dark text-center">
									
									<div className="row">
										<div className="col">
											<div className="card">
												<div className="card-header border-dark pt-4">
													<div className="card text-center mx-auto w-75">
														<h1 className="text-dark text-center">Share Plan</h1>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="card-header border-dark">
										<div className="row">
											<div className="col-md-auto">
												<p>Add an email to invite: </p>
											</div>
											<div className="col">
												<input type="text" className="form-control" name="email" id="locationSearch" 
													placeholder="Add Person" {...register( 'email', { 
														required: {
																value: true,
																message: "email is required to send an invitation"
														},
														pattern: {
															value: /^\S+@\S+$/i,
															message: "must be a valid email"
														}
														})}></input>
														{ errors.email &&
															<span className="badge badge-warning">
																	{errors.email.message}
															</span>
														}
											</div>
											<div className="form-row">
												<div className="col">
														{ uid && !isPlanInvitee &&
															<button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
																{ !isSubmitting && <span>Send Invite</span>}
																{ isSubmitting && <i className="fa fa-spinner fa-spin" /> }
															</button>
														}
														{ !uid &&
															<button className="btn btn-primary pull-right" disabled={true}>
																{<span>Account required to invite others to plan</span>}
															</button>
														}

												</div>
											</div>
										</div>
									</div>
									{
										planUsers && planData && Array.isArray(planUsers) && planUsers.map((user) => 
										<div key={user.uid} className="row d-flex justify-content-center" {...console.log("user: ",user)}>
											<div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
												<PlanPeople user={user} uid={uid} planOwner={planData.owner} isUser={true}/>           
											</div>    
										</div>
										)
									}

									{ 
										invites && Array.isArray(invites) && invites.map((invite) => 
										<div key={invite.inviteeUid} className="row d-flex justify-content-center" {...console.log("invite: ",invite)}>
											<div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
												<PlanPeople user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
												uid={uid} planOwner={''} isUser={false}/>           
											</div>    
										</div>
										)
									}

									{ 
										planData && planData.invites.map((invite) => 
										<div key={invite} className="row d-flex justify-content-center" {...console.log("invite: ",invite)}>
											<div key={invite} className="col-lg-5 col-md-6 col-sm-8 col-xs-12">              
												<PlanPeople key={invite.inviteeUid} user={{email: invite.inviteeEmail, uid: invite.inviteeUid}} 
												uid={uid} planOwner={''} isUser={false}/>           
											</div>    
										</div>
										)
									}
									{/*
									<div className="row">
										<div className="col">
											<div className="card border-dark text-center mx-auto w-75">
												<div className="row">
													<div className="col">
														<i className="fa fa-user-circle align-middle"></i>
													</div>
													<div className="col">
														<p className="align-middle text-left px-2">test@test.com</p>
													</div>
													<div className="col">
														<p className="align-middle px-2">Invited</p>
													</div>
												</div>
												
											</div>
										</div>
									</div>
									*/}

								</div>
								 
							</form>
						</div>
					</div>
					<div className="row">
						<div className="col pt-4">
							{ isPlanOwner && !isSubmitting &&
								<button className="btn btn-primary ml-3" onClick={ () => savePlan() }>Review Plan and Save</button>
							}
							{ !isPlanOwner && !isSubmitting &&
								<button className="btn btn-primary ml-3" onClick={ () => savePlan() }>Back to Plan</button>
							}
							
						</div>
					</div>
			</div>
		</div>
  );
}

export default PlanInvite;