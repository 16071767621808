import { createSelector } from 'reselect';
import get from 'lodash/get';
import last from 'lodash/last';
// import { selectLocationCategoriesByName } from '../location-categories/selectors
import { tokenizeCategory } from './filter-info';
import filter from 'lodash/filter';

const getTab = (state) => state.planning.tab;
const getPlanLocations = (state) => get(state, 'search.plan_locations.hits') || [];
const getLocations = (state) => Object.values(state.planning.locations);
const getPlanDestinationId = (state) => state.planning.destinationId;
const getPlanThingsToDoId = (state) => state.planning.thingsToDoId;
const getServiceCategories = (state) => state.planning.services;
const getLocationCategories = (state) => (state.collections.locationCategories || {})


const SERVICES          = 'ECWcWPh8igZHxjdGcR1F';
const EATS_AND_DRINKS   = 'Jx4CRXOWUlh0YxDLe7Cy';
const THINGS_TO_DO      = 'X06UDvoQRrUPlcWReC00';

const getFilterIdsNew = (name, locationCategories) => {
  // const name = getTab(state);
  name = tokenizeCategory(name);

  const values = Object.values(locationCategories);
  
  var filtered = [];

  if (name === "all-eats-drinks") {    
    filtered = filter(locationCategories, { locationCategoryTypeId: EATS_AND_DRINKS });
  } else if (name === "all-things-to-do") {
    filtered = filter(locationCategories, { locationCategoryTypeId: THINGS_TO_DO });
  } else if (name === "all-services") {
    filtered = filter(locationCategories, { locationCategoryTypeId: SERVICES });
  } else {
    for (var i = 0; i < values.length; i++) {
      if (name === tokenizeCategory(values[i].name)) {
        filtered.push(values[i]);
      }
    }
  }

  return filtered.map(locationCategory => locationCategory.objectID);
}

const getPlan = (state) => {
  const location =  state.router.location;
  var tokens = location.pathname.split('/');
  var token = last(tokens);

  if (tokens.length === 2) {
    return null;
  }

  if (token.match(/^[a-z0-9]{20}([a-z0-9]{8})?$/i) && state.collections.plans) {
    return state.collections.plans[token];
  }

  return null;
}

export const selectPlanLocations = createSelector(
  [getTab, getPlanLocations, getPlan, getLocationCategories],
    (tab, planLocations, plan, locationCategories) => {

    console.log("select plan locations", tab);
    if (!tab || tab.name.length === 0 || tab.name === "All" || tab.name === 'planning') {
      return planLocations.sort((a, b) => {
        if (!a.name) { return a };
        if (!b.name) { return b };
        return a.name.localeCompare(b.name);
      });
    }
    
    var filtered = [];
  
    if (tab.name === "Favorites" && plan && plan.favorites) {
      for (var i = 0; i < planLocations.length; i++) {
        if (plan.favorites.includes(planLocations[i].objectID)) {
          filtered.push(planLocations[i]);
        }
      }
    } else {
      const ids = getFilterIdsNew(tab.name, locationCategories);
      for (var i = 0; i < planLocations.length; i++) { // eslint-disable-line no-redeclare
        var planLocation = planLocations[i];

        if (planLocation.name) {

          // if location has no categories at all, include.
          if ((!planLocation.categories || planLocation.categories.length === 0)) {
            //filtered.push(planLocation);
            continue;
          }
          
          for (var x = 0; x < planLocation.categories.length; x++) {
            if (ids.includes(planLocation.categories[x].id)) {
              filtered.push(planLocation);
              break;
            }
          }
        }
      }
    }

    return filtered.sort((a, b) => {
      if (!a.name) { return a };
      if (!b.name) { return b };
      return a.name.localeCompare(b.name);
    });
  }
);

const filterLocations = (id, locations) => {
  if (!id || !locations) { return [] }
  var filtered = locations.filter((location) => { return location.location_category_id === id; } )

  filtered.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return filtered;
};

export const selectDestinations = createSelector(
  [getPlanDestinationId, getLocations], filterLocations    
);

export const selectThingsToDo = createSelector(
  [getPlanThingsToDoId, getLocations], filterLocations
);

export const selectServices = createSelector(
  [getServiceCategories, getLocations], (categories, locations) => {
    if (!categories || categories.length === 0 || !locations || locations.length === 0) { return [] }

    const ids = categories.map((category) => category.id);

    var filtered = locations.filter((location) => { return ids.includes(location.location_category_id); } )

    filtered.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return filtered;    
  }
);