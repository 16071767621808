import React from 'react';

import { Link } from 'react-router-dom';

import ListingProgressBar from '../components/listing-progress-bar';

export default () =>
  <div className="container mt-4">
    <div className="row">
      <div className="col-md-3">
        <ListingProgressBar step={4} />
      </div>

      <div className="col-md-9">
        <div className="card">
          <h5 className="card-header text-left">Protect your account</h5>        
          <div className="card-body">
            <p className="card-text text-left">Send a verification code to your phone to confirm your identity.</p>

            <h5 className="text-left">
              <i className="fa fa-phone-square mr-2" />
              + 1 916 508-3564
            </h5>

            <h5 className="text-left">
              
              <Link to="/listing/security">
                <i className="fa fa-phone-square mr-2" />
                Add a new phone number
              </Link>
            </h5>
          </div>          
        </div>

        <div className="pull-right mt-2">
          <Link className="btn btn-primary" to="/listing/calendar">Next</Link>
        </div>

      </div>
    </div>
  </div>