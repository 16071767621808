import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DropZone from 'react-dropzone';
import { isNumber } from 'lodash';
import { func } from 'prop-types';

function PointsOfInterestForm({ isSubmitting, savePointsOfInterest, deletePointsOfInterest, pointsOfInterest, pointsOfInterestData, canDelete, setImages }) {
    let emptyPoint = {pointDescription: "", pointImage: "", pointName: ""};
    let emptyPointOfInterest = {category: "", description: "", imageName: "", interestName: "", points: [emptyPoint]};

    const [numbersSet, setNumbersSet] = useState(false);
    const [numberOfPointsOfInterest, setNumberOfPointsOfInterest] = useState(1);
    const [typeOfPointsOfInterest, setTypeOfPointsOfInterest] = useState("category");
    const [pointsOfInterestArray, setPointsOfInterestArray] = useState([]);
    const [pointsArray, setPointsArray] = useState([[emptyPoint]]); //-------------------should change to numbers
    const [numberArray, setNumberArray] = useState([emptyPointOfInterest]);

    const { register, watch, handleSubmit, control, errors } = useForm();
    const onSubmit = (data) => {
        console.log("Submitted");
        console.log(data);
        //savePointsOfInterest(data);
    };

    const isNumber = (value) => {
        return !isNaN(value);
    }

    useEffect(() => {
        if (pointsOfInterestData.id === "new") {
            //set state variable with data

            //setPointsOfInterestArray(pointsOfInterestData.pointsOfInterests);

        }
        
        
      }, [pointsOfInterestData]);

      useEffect(() => {
        
        let array = []; 
        array.length = numberOfPointsOfInterest;
        console.log("before array fill: " + array.length);
        console.log(array);
        array.fill(emptyPointOfInterest);
        console.log("after array fill: " + array.length);
        console.log(array);
        setNumberArray(array);

        if (numberOfPointsOfInterest == 1) {
            console.log("set to category");
            setTypeOfPointsOfInterest("category")
        } else if (numberOfPointsOfInterest == 3) {
            console.log("set to inside");
            setTypeOfPointsOfInterest("inside")
        } else if (numberOfPointsOfInterest == 6) {
            console.log("set to rows");
            setTypeOfPointsOfInterest("rows")
        }
        
      }, [numberOfPointsOfInterest]);

      useEffect(() => {
          console.log("numberArray");
        console.log(numberArray);
      }, [numberArray]);

      useEffect(() => {
        console.log("typeOfPointsOfInterest");
      console.log(typeOfPointsOfInterest);
    }, [typeOfPointsOfInterest]);

      function numberOfPointsOfInterestChange(event) {
        console.log("on change: " + event.target.value);
        setNumberOfPointsOfInterest(event.target.value);
        let array = pointsArray;
        let before = array.length;
        //console.log(array);
        array.length = event.target.value;
        //console.log(array);
        if (before < event.target.value) {
            for (let i = (before - 1); i < event.target.value; i++) {
                array[i] = [emptyPoint];
            }
        }
        //array.fill([]);
        console.log("after num POI changed");
        console.log(array);
        setPointsArray(array);
      }

      function typeOfPointsOfInterestChange(event) {
        setTypeOfPointsOfInterest(event.target.value);
      }

      function numberOfPointsChange(event, pointOfInterestNumber) {
        // let array = [];
        // array.length = event.target.value;
        let temp = pointsArray;
        let point = temp[pointOfInterestNumber];
        let before = temp[pointOfInterestNumber].length;
        point.length = event.target.value;
        if (before < event.target.value) { 
            for (let i = (before - 1); i < event.target.value; i++) {
                point[i] = [emptyPoint];
            }
        }
        console.log(point);
        temp[pointOfInterestNumber] = point;
        console.log("after number of points changes");
        console.log(temp);
        setPointsArray(temp);
      }

      function setImages(images, interest, point) {
        console.log("set images", images);

        if (point == -1) { //the image is for the point of interest and not the point

        }
      }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            { !numbersSet &&
            <div className="row">
                <div className=" col p-0">
                    <div className="card">
                        <div className="card-body">
                        <div className="col mb-2">
                            <p className="text-danger">WARNING! -Changing the number of Points Of Interest or Points will delete Data- WARNING!</p>
                        </div>
                        <div className="row">
                            <div className="col-6 p-0">       
                                <div className="card">   
                                    <div className="card-body">
                                        <p className="text-center">Number of Points Of Interest</p>
                                        <select type="text" className="form-control" name="number" onChange={numberOfPointsOfInterestChange} defaultValue={1}
                                        ref={register({ 
                                            required: {
                                                value: true,
                                                message: "Number of Points of Interest required"
                                            }
                                        })}>
                                            <option value={1} >{1}</option>
                                            <option value={3} >{3}</option>  
                                            <option value={6} >{6}</option> 
                                        </select>

                                        { errors.number &&
                                        <span className="badge badge-warning">
                                            {errors.number.message}
                                        </span>
                                        }
                                    </div> 
                                </div> 
                            </div> 

                            <div className="col-6 p-0">               
                                <div className="card">   
                                    <div className="card-body">
                                        <p className="text-center">Type of Points Of Interest</p>
                                        <select type="text" className="form-control" name="type" defaultValue={"category"} onChange={typeOfPointsOfInterestChange}
                                        ref={register({ 
                                            required: {
                                                value: true,
                                                message: "Number of Points of Interest required"
                                            }
                                        })}>
                                            { numberOfPointsOfInterest == 1 &&
                                                <>
                                                <option value={"category"} >{"Top Locations From Category"}</option>
                                                <option value={"links"} >{"External Links"}</option> 
                                                </>
                                            }
                                            { numberOfPointsOfInterest == 3 &&
                                                <>
                                                <option value={"inside"} >{"Borderless With Text Inside Image"}</option>
                                                <option value={"outside"} >{"Image With Text Below Image"}</option>
                                                </>
                                            }
                                            { numberOfPointsOfInterest == 6 &&
                                                <>
                                                <option value={"rows"} >{"Two Rows of Three With Text Below"}</option>
                                                </>
                                            }
                                            
                                        </select>

                                        { errors.type &&
                                        <span className="badge badge-warning">
                                            {errors.type.message}
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>   
                        </div>
                                <div className="row py-2">
                                {
                                    numberArray.map( (e, i) => {
                                        return <div className="col-6" key={"interest" + e + i}>   
                                                <div className="card">   
                                                    <div className="col mb-2">
                                                        <p className="text-center">{"Number of Points for Point Of Interest #" + (i + 1)}</p>
                                                        <select type="text" className="form-control" name={"numberPoints"+i} defaultValue={1} onChange={(e) => numberOfPointsChange(e, i)}
                                                        ref={register({ 
                                                            required: {
                                                                value: true,
                                                                message: "Number of Points required"
                                                            }
                                                        })}>
                                                            <option value={1} >{1}</option>
                                                            <option value={2} >{2}</option>  
                                                            <option value={3} >{3}</option> 
                                                            <option value={4} >{4}</option>
                                                            <option value={5} >{5}</option>  
                                                            <option value={6} >{6}</option> 
                                                            <option value={7} >{7}</option>
                                                            <option value={8} >{8}</option>  
                                                            <option value={9} >{9}</option> 
                                                            <option value={10} >{10}</option>
                                                        </select>

                                                        {/* { errors.number &&
                                                        <span className="badge badge-warning">
                                                            {errors.number.message}
                                                        </span>
                                                        } */}
                                                    </div >
                                                </div>
                                            </div>
                                    })
                                }
                                </div> 
                        </div>
                    </div>
                </div>
            </div> 
            }

            { numbersSet &&
            <div className="row">
                <div className=" col p-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-12 mb-2">
                                    <label><input type="checkbox" value={false} name="featured" ref={register} />{"Featured"}</label>
                            </div>
                            <div className="row">
                                <div className=" col p-0">
                                    <div className="card">
                                        <div className="card-body">
                                        
                                        {
                                            numberArray.map( (e, i) => {
                                                return <div key={"interest" + e + i} className="row py-2">
                                                    <div className="col-12">   
                                                        <div className="card">   
                                                            <div className="col mb-2">
                                                                <div className="card-title">
                                                                    <div className="col mb-2 p-0">
                                                                        <input 
                                                                        type="text" className="form-control" defaultValue={pointsOfInterest.name} name={"name"+i} placeholder="Point Of Interest Name" 
                                                                        ref={register({ 
                                                                        required: {
                                                                            value: true,
                                                                            message: "name is required"
                                                                        }})}/>
                                                                    
                                                                        { errors.name &&
                                                                        <span className="badge badge-warning">
                                                                            {errors.name.message}
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            <div className="col mb-2">
                                                                <div className="card-title">
                                                                    <div className="col mb-2 p-0">
                                                                        <input 
                                                                        type="text" className="form-control" defaultValue={pointsOfInterest.description} name={"description"+i} placeholder="Point Of Interest Description" 
                                                                        ref={register({ 
                                                                        required: {
                                                                            value: true,
                                                                            message: "description is required"
                                                                        }})}/>
                                                                    
                                                                        { errors.name &&
                                                                        <span className="badge badge-warning">
                                                                            {errors.name.message}
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            {(typeOfPointsOfInterest.localeCompare("category") === 0) &&
                                                            <div className="col mb-2">
                                                                <div className="card-title">
                                                                    <div className="col mb-2 p-0">
                                                                        <input 
                                                                        type="text" className="form-control" defaultValue={pointsOfInterest.category} name={"category"+i} placeholder="Points Of Interest Category" 
                                                                        ref={register({ 
                                                                        required: {
                                                                            value: true,
                                                                            message: "category is required"
                                                                        }})}/>
                                                                    
                                                                        { errors.name &&
                                                                        <span className="badge badge-warning">
                                                                            {errors.name.message}
                                                                        </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                            {!(typeOfPointsOfInterest.localeCompare("links") === 0) &&
                                                            <div className="col mb-2">
                                                                <div className="card-title">
                                                                    <div className="col mb-2 p-0">
                                                                        <input 
                                                                        type="text" className="form-control" defaultValue={pointsOfInterest.lng} name={"lng"+i} placeholder={"Point Of Interest Latitude" + i} 
                                                                        ref={register()}/>
                                                                    
                                                                        {/* { errors.name &&
                                                                        <span className="badge badge-warning">
                                                                            {errors.name.message}
                                                                        </span>
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            }
                                                            {!(typeOfPointsOfInterest.localeCompare("links") === 0) &&
                                                            <div className="col mb-2">
                                                                <div className="card-title">
                                                                    <div className="col mb-2 p-0">
                                                                        <input 
                                                                        type="text" className="form-control" defaultValue={pointsOfInterest.lat} name={"lat"+i} placeholder={"Point Of Interest Longitude" + i} 
                                                                        ref={register()}/>
                                                                    
                                                                        {/* { errors.name &&
                                                                        <span className="badge badge-warning">
                                                                            {errors.name.message}
                                                                        </span>
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            }
                                                            <div className="row justify-content-md-center">
                                                                <div className="col-12 col-centered">
                                                                    <p>Point Of Interest Image</p>
                                                                </div>
                                                            </div> 
                                                            <div className="row justify-content-md-center">
                                                                <div className="col-12 col-centered">
                                                                    <DropZone onDrop={(accepted, rejected) => { setImages(accepted, i, -1); }}>
                                                                        {({getRootProps, getInputProps}) => (
                                                                        <section>
                                                                            <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                                                                            <input {...getInputProps()} />
                                                                            <p>Drop Image Here</p>
                                                                            </div>
                                                                        </section>
                                                                        )}
                                                                    </DropZone>
                                                                </div>
                                                            </div>
                                                            {pointsArray[i] &&
                                                                pointsArray[i].map( (point, j) => {
                                                                    return <div key={"points" + point + j} className="row py-2 justify-content-center" {...console.log(j)}>
                                                                        <div className="col-8" {...console.log(i + " " + pointsArray[i] + " length: " + pointsArray[i].length)}>   
                                                                            <div className="card">  
                                                                                <div className="row justify-content-md-center">
                                                                                    <div className="col-12 col-centered">
                                                                                        <p>Point Image</p>
                                                                                    </div>
                                                                                </div> 
                                                                                <div className="row justify-content-md-center">
                                                                                    <div className="col-12 col-centered">
                                                                                        <DropZone onDrop={(accepted, rejected) => { setImages(accepted, i, j); }}>
                                                                                            {({getRootProps, getInputProps}) => (
                                                                                            <section>
                                                                                                <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                                                                                                <input {...getInputProps()} />
                                                                                                <p>Drop Image Here</p>
                                                                                                </div>
                                                                                            </section>
                                                                                            )}
                                                                                        </DropZone>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col mb-2">
                                                                                    <div className="card-title">
                                                                                        <div className="col mb-2 p-0">
                                                                                            <input 
                                                                                            type="text" className="form-control" defaultValue={pointsOfInterest.name} name={"namePoint"+j} placeholder={"Point Of Interest Name " + j} 
                                                                                            ref={register({ 
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "name is required"
                                                                                            }})}/>
                                                                                        
                                                                                            { errors.name &&
                                                                                            <span className="badge badge-warning">
                                                                                                {errors.name.message}
                                                                                            </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                {!(typeOfPointsOfInterest.localeCompare("links") === 0) &&
                                                                                <div className="col mb-2">
                                                                                    <div className="card-title">
                                                                                        <div className="col mb-2 p-0">
                                                                                            <input 
                                                                                            type="text" className="form-control" defaultValue={pointsOfInterest.description} name={"descriptionPoint"+j} placeholder={"Point Of Interest Description" + j} 
                                                                                            ref={register({ 
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "description is required"
                                                                                            }})}/>
                                                                                        
                                                                                            { errors.name &&
                                                                                            <span className="badge badge-warning">
                                                                                                {errors.name.message}
                                                                                            </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                }
                                                                                {!(typeOfPointsOfInterest.localeCompare("links") === 0) &&
                                                                                <div className="col mb-2">
                                                                                    <div className="card-title">
                                                                                        <div className="col mb-2 p-0">
                                                                                            <input 
                                                                                            type="text" className="form-control" defaultValue={pointsOfInterest.description} name={"location"+j} placeholder={"Point Of Interest LocationID" + j} 
                                                                                            ref={register({ 
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "description is required"
                                                                                            }})}/>
                                                                                        
                                                                                            { errors.name &&
                                                                                            <span className="badge badge-warning">
                                                                                                {errors.name.message}
                                                                                            </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                }
                                                                                {(typeOfPointsOfInterest.localeCompare("links") === 0) &&
                                                                                <div className="col mb-2">
                                                                                    <div className="card-title">
                                                                                        <div className="col mb-2 p-0">
                                                                                            <input 
                                                                                            type="text" className="form-control" defaultValue={pointsOfInterest.url} name={"link"+j} placeholder={"Point Of Interest Link" + j} 
                                                                                            ref={register({ 
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "link is required"
                                                                                            }})}/>
                                                                                        
                                                                                            { errors.name &&
                                                                                            <span className="badge badge-warning">
                                                                                                {errors.name.message}
                                                                                            </span>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>  
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>  
                                            })
                                        }
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>  
            }
            <div className="form-row">
                <div className="col mb-2 p-4">
                    {
                        numbersSet && canDelete && !isSubmitting &&
                        <button type="button" className="btn btn-danger pull-left" onClick={() => { deletePointsOfInterest() }}>
                            <i className="fa fa-trash" />
                        </button>
                    }
                    {numbersSet && 
                        <button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
                        { !isSubmitting && <span>Save</span>}
                        { isSubmitting && <i className="fa fa-spinner fa-spin" /> }
                        </button>
                    }
                    {!numbersSet && 
                        <button className="btn btn-primary pull-right" onClick={() =>setNumbersSet(true)}>
                            <span>Set Number of Points Of Interest and Points</span>
                        </button>
                    }
                    { !isSubmitting && <Link to="/featured-points-of-interest" className="btn btn-light pull-right mr-2">Cancel</Link> }

                </div>
            </div>
        </form>

    );
}

export default PointsOfInterestForm;

{/* <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className=" col p-0">
                    <div className="card">
                        <div className="card-body">
                        <div className="col mb-2">
                            <p className="text-danger">WARNING! -Changing the number of Points Of Interest or Points will delete Data- WARNING!</p>
                        </div>
                        <div className="col mb-2">
                                <label><input type="checkbox" value={false} name="featured" ref={register} />{"Featured"}</label>
                        </div>
                        <div className="row">
                            <div className="col-6 p-0">       
                                <div className="card">   
                                    <div className="card-body">
                                        <p className="text-center">Number of Points Of Interest</p>
                                        <select type="text" className="form-control" name="number" onChange={numberOfPointsOfInterestChange} defaultValue={1}
                                        ref={register({ 
                                            required: {
                                                value: true,
                                                message: "Number of Points of Interest required"
                                            }
                                        })}>
                                            <option value={1} >{1}</option>
                                            <option value={3} >{3}</option>  
                                            <option value={6} >{6}</option> 
                                        </select>

                                        { errors.number &&
                                        <span className="badge badge-warning">
                                            {errors.number.message}
                                        </span>
                                        }
                                    </div> 
                                </div> 
                            </div> 

                            <div className="col-6 p-0">               
                                <div className="card">   
                                    <div className="card-body">
                                        <p className="text-center">Type of Points Of Interest</p>
                                        <select type="text" className="form-control" name="type" defaultValue={"category"} onChange={typeOfPointsOfInterestChange}
                                        ref={register({ 
                                            required: {
                                                value: true,
                                                message: "Number of Points of Interest required"
                                            }
                                        })}>
                                            { numberOfPointsOfInterest == 1 &&
                                                <>
                                                <option value={"category"} >{"Top Locations From Category"}</option>
                                                <option value={"links"} >{"External Links"}</option> 
                                                </>
                                            }
                                            { numberOfPointsOfInterest == 3 &&
                                                <>
                                                <option value={"inside"} >{"Borderless With Text Inside Image"}</option>
                                                <option value={"outside"} >{"Image With Text Below Image"}</option>
                                                </>
                                            }
                                            { numberOfPointsOfInterest == 6 &&
                                                <>
                                                <option value={"rows"} >{"Two Rows of Three With Text Below"}</option>
                                                </>
                                            }
                                            
                                        </select>

                                        { errors.type &&
                                        <span className="badge badge-warning">
                                            {errors.type.message}
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>   
                        </div>
                            {/* <div className="col mb-2">
                                <p className="text-center">Number of Points Of Interest</p>
                                    <select type="text" className="form-control" name="number" multiple 
                                    ref={register({ 
                                        required: {
                                            value: true,
                                            message: "Number of Points of Interest required"
                                        }
                                    })}>
                                        <option value={1} selected>{1}</option>
                                        <option value={3} >{3}</option>  
                                        <option value={6} >{6}</option> 
                                    </select>

                                    { errors.number &&
                                    <span className="badge badge-warning">
                                        {errors.number.message}
                                    </span>
                                    }
                            </div> */}

                                // {
                                //     numberArray.map( (e, i) => {
                                //         return <div key={"interest" + e + i} className="row py-2">
                                //             <div className="col-12">   
                                //                 <div className="card">   
                                //                     <div className="col mb-2">
                                //                         <div className="card-title">
                                //                             <div className="col mb-2 p-0">
                                //                                 <input 
                                //                                 type="text" className="form-control" defaultValue={pointsOfInterest.name} name={"name"+i} placeholder="Point Of Interest Name" 
                                //                                 ref={register({ 
                                //                                 required: {
                                //                                     value: true,
                                //                                     message: "name is required"
                                //                                 }})}/>
                                                            
                                //                                 { errors.name &&
                                //                                 <span className="badge badge-warning">
                                //                                     {errors.name.message}
                                //                                 </span>
                                //                                 }
                                //                             </div>
                                //                         </div>
                                                    
                                //                     </div>
                                //                     <div className="col mb-2">
                                //                         <div className="card-title">
                                //                             <div className="col mb-2 p-0">
                                //                                 <input 
                                //                                 type="text" className="form-control" defaultValue={pointsOfInterest.description} name={"description"+i} placeholder="Point Of Interest Description" 
                                //                                 ref={register({ 
                                //                                 required: {
                                //                                     value: true,
                                //                                     message: "name is required"
                                //                                 }})}/>
                                                            
                                //                                 { errors.name &&
                                //                                 <span className="badge badge-warning">
                                //                                     {errors.name.message}
                                //                                 </span>
                                //                                 }
                                //                             </div>
                                //                         </div>
                                                    
                                //                     </div>
                                //                     {(typeOfPointsOfInterest.localeCompare("category") === 0) &&
                                //                     <div className="col mb-2">
                                //                         <div className="card-title">
                                //                             <div className="col mb-2 p-0">
                                //                                 <input 
                                //                                 type="text" className="form-control" defaultValue={pointsOfInterest.name} name={"category"+i} placeholder="Points Of Interest Category" 
                                //                                 ref={register({ 
                                //                                 required: {
                                //                                     value: true,
                                //                                     message: "name is required"
                                //                                 }})}/>
                                                            
                                //                                 { errors.name &&
                                //                                 <span className="badge badge-warning">
                                //                                     {errors.name.message}
                                //                                 </span>
                                //                                 }
                                //                             </div>
                                //                         </div>
                                //                     </div>
                                //                     }
                                //                     <div className="col mb-2">
                                //                         <p className="text-center"></p>
                                //                         <select type="text" className="form-control" name={"numberPoints"+i} defaultValue={1} onChange={(e) => numberOfPointsChange(e, i)}
                                //                         ref={register({ 
                                //                             required: {
                                //                                 value: true,
                                //                                 message: "Number of Points required"
                                //                             }
                                //                         })}>
                                //                             <option value={1} >{1}</option>
                                //                             <option value={2} >{2}</option>  
                                //                             <option value={3} >{3}</option> 
                                //                             <option value={4} >{4}</option>
                                //                             <option value={5} >{5}</option>  
                                //                             <option value={6} >{6}</option> 
                                //                             <option value={7} >{7}</option>
                                //                             <option value={8} >{8}</option>  
                                //                             <option value={9} >{9}</option> 
                                //                             <option value={10} >{10}</option>
                                //                         </select>

                                //                         {/* { errors.number &&
                                //                         <span className="badge badge-warning">
                                //                             {errors.number.message}
                                //                         </span>
                                //                         } */}
                                //                     </div >
                                //                     {pointsArray[i] &&
                                //                         pointsArray[i].map( (point, j) => {
                                //                             return <div key={"points" + point + j} className="row py-2 justify-content-center" {...console.log(j)}>
                                //                                 <div className="col-8" {...console.log(i + " " + pointsArray[i] + " length: " + pointsArray[i].length)}>   
                                //                                     <div className="card">   
                                //                                         <div className="col mb-2">
                                //                                             <div className="card-title">
                                //                                                 <div className="col mb-2 p-0">
                                //                                                     <input 
                                //                                                     type="text" className="form-control" defaultValue={pointsOfInterest.name} name={"name"+j} placeholder={"Point Of Interest Name " + j} 
                                //                                                     ref={register({ 
                                //                                                     required: {
                                //                                                         value: true,
                                //                                                         message: "name is required"
                                //                                                     }})}/>
                                                                                
                                //                                                     { errors.name &&
                                //                                                     <span className="badge badge-warning">
                                //                                                         {errors.name.message}
                                //                                                     </span>
                                //                                                     }
                                //                                                 </div>
                                //                                             </div>
                                                                        
                                //                                         </div>
                                //                                         <div className="col mb-2">
                                //                                             <div className="card-title">
                                //                                                 <div className="col mb-2 p-0">
                                //                                                     <input 
                                //                                                     type="text" className="form-control" defaultValue={pointsOfInterest.description} name={"description"+j} placeholder={"Point Of Interest Description" + j} 
                                //                                                     ref={register({ 
                                //                                                     required: {
                                //                                                         value: true,
                                //                                                         message: "description is required"
                                //                                                     }})}/>
                                                                                
                                //                                                     { errors.name &&
                                //                                                     <span className="badge badge-warning">
                                //                                                         {errors.name.message}
                                //                                                     </span>
                                //                                                     }
                                //                                                 </div>
                                //                                             </div>
                                                                        
                                //                                         </div>
                                //                                     </div>
                                //                                 </div>
                                //                             </div>  
                                //                         })
                                //                     }
                                //                 </div>
                                //             </div>
                                //         </div>  
                                //     })
                                // }

                            {/* <div className="col mb-2">
                                <div className="card-title">
                                    <div className="col mb-2 p-0">
                                        <input 
                                        type="text" className="form-control" defaultValue={pointsOfInterest.name} name="name" placeholder="Points Of Interest Name" 
                                        ref={register({ 
                                        required: {
                                            value: true,
                                            message: "name is required"
                                        }})}/>
                                    
                                        { errors.name &&
                                        <span className="badge badge-warning">
                                            {errors.name.message}
                                        </span>
                                        }
                                    </div>
                                    
                                    <div className="col mb-2 p-0">
                                        <input 
                                        type="text" className="form-control" defaultValue={pointsOfInterest.radius} name="radius" placeholder="Points Of Interest Radius" 
                                        ref={register({ 
                                            validate: isNumber
                                        })}/>

                                        { errors.radius && errors.radius.type === "validate" &&
                                        <span className="badge badge-warning">
                                            Radius needs to be a number
                                        </span>
                                        }
                                    </div>
                                </div>
                                
                            </div> */}

                            {/* <div className="col mb-2">
                                <select type="text" className="form-control" name="state" multiple 
                                ref={register({ 
                                    required: {
                                        value: true,
                                        message: "state is required"
                                    }
                                })}>
                                    {stateOptions.map( state => {
                                        if(states && states.includes(state)){
                                            return <option value={state} selected>{state}</option>
                                        }else{
                                            return <option value={state}>{state}</option>
                                        }
                                    })}
                                </select>

                                { errors.state &&
                                <span className="badge badge-warning">
                                    {errors.state.message}
                                </span>
                                }
                            </div> */}
            //             </div>
            //         </div>
            //     </div>
            // </div>  
            {/* <div className="row">
                <div className="col-6 p-0">       
                    <div className="card">   
                        <div className="card-body">
                            <p className="text-center">Latitude</p>

                            <input 
                            type="text" className="form-control" value={lngLat.lat} name="_geoloc.lat" placeholder="Latitude" disabled
                            ref={register({ 
                                required: {
                                    value: true,
                                    message: "latitude is required"
                                }, 
                                pattern: {
                                    value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                    message: " invalid Latitude " 
                                }
                                    })}/>

                            { errors._geoloc && errors._geoloc.lat &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lat.message}
                            </span>
                            }
                        </div> 
                    </div> 
                </div> 

                <div className="col-6 p-0">               
                    <div className="card">   
                        <div className="card-body">
                            <p className="text-center">Longitude</p>

                            <input 
                            type="text" className="form-control" value={lngLat.lng} name="_geoloc.lng" placeholder="Longitude" disabled
                            ref={register({ 
                            required: {
                                value: true,
                                message: "longitude is required"
                            }, 
                            pattern: {
                                value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                message: " invalid Longitude " 
                            }
                                })}/>

                            { errors._geoloc && errors._geoloc.lng &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lng.message}
                            </span>
                            }
                        </div>
                    </div>
                </div>   
            </div>    */}
             

        //     <div className="form-row">
        //         <div className="col mb-2 p-4">
        //             {
        //                 canDelete && !isSubmitting &&
        //                 <button type="button" className="btn btn-danger pull-left" onClick={() => { deletePointsOfInterest() }}>
        //                     <i className="fa fa-trash" />
        //                 </button>
        //             }
        //             <button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
        //                 { !isSubmitting && <span>Save</span>}
        //                 { isSubmitting && <i className="fa fa-spinner fa-spin" /> }
        //             </button>
        //             { !isSubmitting && <Link to="/places-custom" className="btn btn-light pull-right mr-2">Cancel</Link> }

        //         </div>
        //     </div>
        // </form> */}