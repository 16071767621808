import React from 'react';
import { Route, Switch } from 'react-router';

import FeaturedPointsOfInterestSearch from './containers/featured-points-of-interest-search';
import FeaturedPointsOfInterest from './containers/featured-points-of-interest';

export default () =>
  <Switch>
    <Route exact path="/featured-points-of-interest">
        <FeaturedPointsOfInterestSearch/>
    </Route>

    <Route exact path="/featured-points-of-interest/:id">
        <FeaturedPointsOfInterest/>
    </Route>
  </Switch>;