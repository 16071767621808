import isNumber from 'lodash/isNumber';
export const validateRequired = value => value && value.length > 0;

export const validatePostalCode = value => {
  if (!value) { return true; }

  return value.match(/[0-9]{5}/);
};

export const validatePhoneNumber = value => {
  if (!value) { return true; }

  return value.match(/[0-9]{10}/);
};

export const validateURL = url => {
  if (!url) { return true; }

  let regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

  return url.match(regex);
}

export const validateLatLng = value => {
  if (!value || isNumber(value)) { return true; }

  console.log("validate", value);

  let regex = /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/; // eslint-disable-line no-useless-escape

  return value.match(regex);
}