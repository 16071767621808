import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import BookingValue from '../component/booking-value';

class BookingConfirmation extends Component {
  render() {
    return (
<div className="container mt-2">
        <form>
          <div className="row">          
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 class="card-title text-left">Trip Summary</h5>
                    

                    <div className="row">
                      <div className="col-md-4">
                        <BookingValue label="Reservation ID" value="HY-33434" />
                      </div>
                      <div className="col-md-4">
                        <BookingValue label="Arrival" value="June 19, 2019" />
                      </div>
                      <div className="col-md-4">
                        <BookingValue label="Departure" value="June 22, 2019" />
                      </div>
                    </div>

                    <div className="row mt-3">
                    <div className="col-md-4">
                        <BookingValue label="Property ID" value="12345" />
                      </div>
                      <div className="col-md-4">
                        <BookingValue label="Check-in Time" value="4:00PM" />
                      </div>
                      <div className="col-md-4">
                        <BookingValue label="Check-out Time" value="11:00AM" />
                      </div>
                    </div>

                    <div className="row mt-3">
                    <div className="col-md-4">
                        <BookingValue label="Guests" value="4 Adults" />
                      </div>
                      <div className="col-md-8">
                        <BookingValue label="Owner" value="Joey Vivenzio, Licensed Real Estate Salesperson" />
                      </div>
                    </div>
                  </div>
                </div>
               
                <Link className="btn btn-primary pull-right mt-3" to="/">Done</Link>                
              </div>              
          </div>              
        </form>
      </div>
    );
  }
}

export default BookingConfirmation;