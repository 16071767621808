export const GET_LISTINGS = 'GET_LISTINGS';
export const LISTINGS_SUCCESS = 'LISTINGS_SUCCESS';

export const SAVE_LISTING = 'SAVE_LISTING';
export const LISTING_SAVED = 'LISTING_SAVED';

export const LISTING_IMAGE_STORED = 'LISTING_IMAGE_STORED';

export const GET_USER_LISTINGS = '/listings/GET_USER_LISTINGS';
export const GET_USER_LISTINGS_SUCCESS = '/listings/GET_USER_LISTINGS_SUCCESS';
export const GET_USER_LISTINGS_ERROR = '/listings/GET_USER_LISTINGS_ERROR';

export const SAVE_USER_LISTING = '/listings/SAVE_USER_LISTING';
export const SAVE_USER_LISTINGS_SUCCES = '/listings/SAVE_USER_LISTING_SUCCESS';
export const SAVE_USER_LISTINGS_ERRO = '/listings/SAVE_USER_LISTING_ERROR';
