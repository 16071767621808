import React from 'react';

const TGSelect = ({ input, label, type, meta: { touched, error, warning }, children, name, value, ...rest}) =>
  <div>
    <select name={name} className="form-control" {... input} {...rest} >
      { children }
    </select>

    {
      touched && error &&
        <small style={{ width: "100%", textAlign: 'left' }} className="form-text text-muted">{error}</small>
    }
  </div>;


export default TGSelect;