import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PointOfInterest } from './featured-points-of-interest';
import PointsOfInterestCard from '../components/points-of-interest-card';

import {getPageOfPointsOfInterest} from '../../firebase'
import { setPlanInfoFromLocation } from '../../planning/actions';

function FeaturedPointsOfInterestSearch( props ) {

    //have state up here that loads the data and passes it to the search
    //then add the pagination
    const maxItemsPerPage = 3;

    const [pointsOfInterest, setPointsOfInterest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [lastVisible, setLastVisible] = useState(false);
    const [firstVisible, setFirstVisible] = useState(false);

    // useEffect(() => {
    //     if ( pointsOfInterest == false && !loading) {
    //         let data = {
    //             first: true
    //         }
    //         getResults(data); // get return data
    //     }
    // });

    function getResults(data) {
        setLoading(true);
        let results =  getPageOfPointsOfInterest(data);

        results.then(function(results) {
            console.log(results);

            let data = results.data;
            setLastVisible(data.lastVisible);
            setFirstVisible(data.firstVisible);
            setPointsOfInterest(data.pointsOfInterest);
            
            setLoading(false);
          });
    }

    function nextPage(nextPage) {
        let data = {}

        if ( nextPage == true && lastVisible != false ) {
            data.lastVisible = lastVisible;

            getResults(data);

        } else if ( firstVisible != false ) {
            data.firstVisible = firstVisible;

            getResults(data);

        }
    }

    function pagination() {
        return <div>
         {currentPage > 1 ?
          <button onClick={() => this.nextPage(false)}>{"Back"}</button>
         : null}
         {pointsOfInterest.length -1 > currentPage * maxItemsPerPage ?
          <button onClick={() => this.nextPage(true)}>{"Next"}</button>
         : null}
        </div>;
       }
    
    return (
        <div className="container mt-4">
                <div className="card mt-4">
                    <div className="card-header">
                        <h3 className="pull-left" style={{ marginBottom: 0 }}>
                            Points Of Interest
                        </h3>
                        {
                            <Link className="btn btn-sm btn-outline-secondary pull-right" to='/featured-points-of-interest/new'>
                              <i className="fa fa-plus"/>
                              <span className="d-none d-md-inline"  style={{ marginLeft: 4 }}>Points Of Interest</span>
                            </Link>
                        }
                    </div>
                    <div className="card-body">              
                        <div className="container">
                            <div className="row form-group">
                            <div className="col d-flex justify-content-center">
                                {/* <Pagination /> */}
                                <div>
                                    {currentPage > 1 ?
                                    <button onClick={() => this.nextPage(false)}>{"Back"}</button>
                                    : null}
                                    {pointsOfInterest.length -1 > currentPage * maxItemsPerPage ?
                                    <button onClick={() => this.nextPage(true)}>{"Next"}</button>
                                    : null}
                                </div>
       

                            </div>
                            </div>
                            <div className="row form-group">                  
                                {/* <div className="col d-flex justify-content-center">
                                    <PlaceSearchBox />
                                </div> */}
                            </div>
                            <div className="row form-group">
                                <div className="col d-flex justify-content-center">
                                    { loading &&
                                        <i className="fa fa-spinner fa-spin" />
                                    }

                                    { !loading && pointsOfInterest != false &&
                                        <PointsOfInterestCard hits={pointsOfInterest}/>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>  
    );
}

export default FeaturedPointsOfInterestSearch;