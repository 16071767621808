import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ListingProgressBar from '../components/listing-progress-bar';

import { getStepInfo } from '../selectors';
// import NextStep from '../components/next-step';

import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';

import { reduxForm, Field } from 'redux-form';

import * as Actions from '../../actions/actions';
import track from '../../actions/track';


const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
  initialValues: {
    textOptIn: false,
    bookingMethod: 'instant',
  }
});

class Options extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Booking Options");
  }

  submit(values) {
    console.log('submit', values);

    const { id } = this.props;
    const next = `/listing/calendar/${id}`;
    
    console.log("submit", values, next);
    this.props.updateDocument(`listings/${id}`, values, next);
  }

  render() {
    const { current, handleSubmit, submitting } = this.props;
    return(      
      <div className="container mt-4">
        <form onSubmit={handleSubmit(this.submit)} autoComplete="off" noValidate>
          <div className="row">
            <div className="col-md-3">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9">
              <div className="card">
                <h5 className="card-header text-left">Booking options</h5>        
                <div className="card-body">
                  <p className="card-text text-left">Select a booking method</p>

                  <div className="clearfix">
                    <div className="form-check pull-left">
                      <Field component="input" className="form-check-input" type="radio" name="bookingMethod" value="instant" />
                      <label className="form-check-label">
                        Instant Booking
                      </label>                
                    </div>
                  </div>

                  <p className="text-left mt-1">
                    Automatically accept booking requests from all travelers for dates you have available and
                    add the bookings to your calendar.
                  </p>
                  
                  <div className="clearfix mt-4">
                    <div className="form-check pull-left">
                      <Field component="input" className="form-check-input" type="radio" name="bookingMethod"  value="review" />
                      <label className="form-check-label">
                        24-hour Review
                      </label>                
                    </div>
                  </div>

                  <p className="text-left mt-1">
                    Allow 24 hours to communicate with guests and accept booking requests.
                  </p>


                  <div className="pull-left">
                    <div className="form-check mt-4">
                      <Field component="input" type="checkbox" className="form-check-input" name="textOptIn" />
                      <label className="form-check-label">
                        Recieve text messages for all inquiries and booking requests. 
                      </label>
                    </div>
                  </div>
                </div>          
              </div>
              <div className="pull-right mt-2">
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: 'listingOptions'
})(Options);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));

