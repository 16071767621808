import React from 'react';
import { TGField } from '../../forms';
import TGSelect from '../../forms/tg-select';

import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropZone from 'react-dropzone';

import Icons from './icons';

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.item,
  };
};

const hereCategories = [
  {
    value: "100-1000-0000",
    name: "Restaurant",
  },
  {
    value: "100-1000-0001",
    name: "Casual Dining",
  },
  {
    value: "100-1000-0002",
    name: "Fine Dining",
  },
  {
    value: "100-1000-0003",
    name: "Take Out and Delivery Only",
  },
  {
    value: "100-1000-0004",
    name: "Food Market-Stall",
  },
  {
    value: "100-1000-0005",
    name: "Taqueria",
  },
  {
    value: "100-1000-0006",
    name: "Deli",
  },
  {
    value: "100-1000-0007",
    name: "Cafeteria",
  },
  {
    value: "100-1000-0008",
    name: "Bistro",
  },
  {
    value: "100-1000-0009",
    name: "Fast Food",
  },
  {
    value: "100-1100-0000",
    name: "Coffee-Tea",
  },
  {
    value: "100-1100-0010",
    name: "Coffee Shop",
  },
  {
    value: "100-1100-0331",
    name: "Tea House",
  },
  {
    value: "200-2000-0000",
    name: "Nightlife-Entertainment",
  },
  {
    value: "200-2000-0011",
    name: "Bar or Pub",
  },
  {
    value: "200-2000-0012",
    name: "Night Club",
  },
  {
    value: "200-2000-0013",
    name: "Dancing",
  },
  {
    value: "200-2000-0014",
    name: "Karaoke",
  },
  {
    value: "200-2000-0015",
    name: "Live Entertainment-Music",
  },
  {
    value: "200-2000-0016",
    name: "Billiards-Pool Hall",
  },
  {
    value: "200-2000-0017",
    name: "Video Arcade-Game Room",
  },
  {
    value: "200-2000-0018",
    name: "Jazz Club",
  },
  {
    value: "200-2000-0019",
    name: "Beer Garden",
  },
  {
    value: "200-2000-0306",
    name: "Adult Entertainment",
  },
  {
    value: "200-2000-0368",
    name: "Cocktail Lounge",
  },
  {
    value: "200-2100-0019",
    name: "Cinema",
  },
  {
    value: "200-2200-0000",
    name: "Theatre, Music and Culture",
  },
  {
    value: "200-2200-0020",
    name: "Performing Arts",
  },
  {
    value: "200-2300-0000",
    name: "Gambling-Lottery-Betting",
  },
  {
    value: "200-2300-0021",
    name: "Casino",
  },
  {
    value: "200-2300-0022",
    name: "Lottery Booth",
  },
  {
    value: "300-3000-0000",
    name: "Landmark-Attraction",
  },
  {
    value: "300-3000-0023",
    name: "Tourist Attraction",
  },
  {
    value: "300-3000-0024",
    name: "Gallery",
  },
  {
    value: "300-3000-0025",
    name: "Historical Monument",
  },
  {
    value: "300-3000-0065",
    name: "Winery",
  },
  {
    value: "300-3000-0232",
    name: "Named Intersection-Chowk",
  },
  {
    value: "300-3000-0350",
    name: "Brewery",
  },
  {
    value: "300-3000-0351",
    name: "Distillery",
  },
  {
    value: "300-3100-0000",
    name: "Museum",
  },
  {
    value: "300-3100-0027",
    name: "Children's Museum",
  },
  {
    value: "300-3100-0028",
    name: "History Museum",
  },
  {
    value: "300-3100-0029",
    name: "Art Museum",
  },
  {
    value: "300-3200-0000",
    name: "Religious Place",
  },
  {
    value: "300-3200-0030",
    name: "Church",
  },
  {
    value: "300-3200-0031",
    name: "Temple",
  },
  {
    value: "300-3200-0032",
    name: "Synagogue",
  },
  {
    value: "300-3200-0033",
    name: "Ashram",
  },
  {
    value: "300-3200-0034",
    name: "Mosque",
  },
  {
    value: "300-3200-0309",
    name: "Other Place of Worship",
  },
  {
    value: "300-3200-0375",
    name: "Gurdwara",
  },
  {
    value: "350-3500-0233",
    name: "Body of Water",
  },
  {
    value: "350-3500-0234",
    name: "Reservoir",
  },
  {
    value: "350-3500-0235",
    name: "Waterfall",
  },
  {
    value: "350-3500-0300",
    name: "Bay-Harbor",
  },
  {
    value: "350-3500-0302",
    name: "River",
  },
  {
    value: "350-3500-0303",
    name: "Canal",
  },
  {
    value: "350-3500-0304",
    name: "Lake",
  },
  {
    value: "350-3510-0236",
    name: "Mountain or Hill",
  },
  {
    value: "350-3510-0237",
    name: "Mountain Passes",
  },
  {
    value: "350-3510-0238",
    name: "Mountain Peaks",
  },
  {
    value: "350-3520-0224",
    name: "Undersea Feature",
  },
  {
    value: "350-3522-0239",
    name: "Forest, Heath or Other Vegetation",
  },
  {
    value: "350-3550-0336",
    name: "Natural and Geographical",
  },
  {
    value: "400-4000-4580",
    name: "Public Sports Airport",
  },
  {
    value: "400-4000-4581",
    name: "Airport",
  },
  {
    value: "400-4000-4582",
    name: "Airport Terminal",
  },
  {
    value: "400-4100-0035",
    name: "Train Station",
  },
  {
    value: "400-4100-0036",
    name: "Bus Station",
  },
  {
    value: "400-4100-0037",
    name: "Underground Train-Subway",
  },
  {
    value: "400-4100-0038",
    name: "Commuter Rail Station",
  },
  {
    value: "400-4100-0039",
    name: "Commuter Train",
  },
  {
    value: "400-4100-0040",
    name: "Public Transit Access",
  },
  {
    value: "400-4100-0041",
    name: "Transportation Service",
  },
  {
    value: "400-4100-0042",
    name: "Bus Stop",
  },
  {
    value: "400-4100-0043",
    name: "Local Transit",
  },
  {
    value: "400-4100-0044",
    name: "Ferry Terminal",
  },
  {
    value: "400-4100-0045",
    name: "Boat Ferry",
  },
  {
    value: "400-4100-0046",
    name: "Rail Ferry",
  },
  {
    value: "400-4100-0047",
    name: "Taxi Stand",
  },
  {
    value: "400-4100-0226",
    name: "Highway Exit",
  },
  {
    value: "400-4100-0326",
    name: "Tollbooth",
  },
  {
    value: "400-4100-0337",
    name: "Lightrail",
  },
  {
    value: "400-4100-0338",
    name: "Water Transit",
  },
  {
    value: "400-4100-0339",
    name: "Monorail",
  },
  {
    value: "400-4100-0340",
    name: "Aerial Tramway",
  },
  {
    value: "400-4100-0341",
    name: "Bus Rapid Transit",
  },
  {
    value: "400-4100-0342",
    name: "Inclined Rail",
  },
  {
    value: "400-4100-0347",
    name: "Bicycle Sharing Location",
  },
  {
    value: "400-4100-0348",
    name: "Bicycle Parking",
  },
  {
    value: "400-4200-0048",
    name: "Weigh Station",
  },
  {
    value: "400-4200-0049",
    name: "Cargo Center",
  },
  {
    value: "400-4200-0050",
    name: "Rail Yard",
  },
  {
    value: "400-4200-0051",
    name: "Seaport-Harbour",
  },
  {
    value: "400-4200-0052",
    name: "Airport Cargo",
  },
  {
    value: "400-4200-0240",
    name: "Couriers",
  },
  {
    value: "400-4200-0241",
    name: "Cargo Transportation",
  },
  {
    value: "400-4200-0311",
    name: "Delivery Entrance",
  },
  {
    value: "400-4200-0312",
    name: "Loading Dock",
  },
  {
    value: "400-4200-0313",
    name: "Loading Zone",
  },
  {
    value: "400-4300-0000",
    name: "Rest Area",
  },
  {
    value: "400-4300-0199",
    name: "Complete Rest Area",
  },
  {
    value: "400-4300-0200",
    name: "Parking and Restroom Only Rest Area",
  },
  {
    value: "400-4300-0201",
    name: "Parking Only Rest Area",
  },
  {
    value: "400-4300-0202",
    name: "Motorway Service Rest Area",
  },
  {
    value: "400-4300-0308",
    name: "Scenic Overlook Rest Area",
  },
  {
    value: "500-5000-0000",
    name: "Hotel or Motel",
  },
  {
    value: "500-5000-0053",
    name: "Hotel",
  },
  {
    value: "500-5000-0054",
    name: "Motel",
  },
  {
    value: "500-5100-0000",
    name: "Lodging",
  },
  {
    value: "500-5100-0055",
    name: "Hostel",
  },
  {
    value: "500-5100-0056",
    name: "Campground",
  },
  {
    value: "500-5100-0057",
    name: "Guest House",
  },
  {
    value: "500-5100-0058",
    name: "Bed and Breakfast",
  },
  {
    value: "500-5100-0059",
    name: "Holiday Park",
  },
  {
    value: "500-5100-0060",
    name: "Short-Time Motel",
  },
  {
    value: "550-5510-0000",
    name: "Outdoor-Recreation",
  },
  {
    value: "550-5510-0202",
    name: "Park-Recreation Area",
  },
  {
    value: "550-5510-0203",
    name: "Sports Field",
  },
  {
    value: "550-5510-0204",
    name: "Garden",
  },
  {
    value: "550-5510-0205",
    name: "Beach",
  },
  {
    value: "550-5510-0206",
    name: "Recreation Center",
  },
  {
    value: "550-5510-0227",
    name: "Ski Lift",
  },
  {
    value: "550-5510-0242",
    name: "Scenic Point",
  },
  {
    value: "550-5510-0358",
    name: "Off Road Trailhead",
  },
  {
    value: "550-5510-0359",
    name: "Trailhead",
  },
  {
    value: "550-5510-0374",
    name: "Off-Road Vehicle Area",
  },
  {
    value: "550-5510-0378",
    name: "Campsite",
  },
  {
    value: "550-5510-0379",
    name: "Outdoor Service",
  },
  {
    value: "550-5510-0380",
    name: "Ranger Station",
  },
  {
    value: "550-5510-0387",
    name: "Bicycle Service",
  },
  {
    value: "550-5520-0000",
    name: "Leisure",
  },
  {
    value: "550-5520-0207",
    name: "Amusement Park",
  },
  {
    value: "550-5520-0208",
    name: "Zoo",
  },
  {
    value: "550-5520-0209",
    name: "Wild Animal Park",
  },
  {
    value: "550-5520-0210",
    name: "Wildlife Refuge",
  },
  {
    value: "550-5520-0211",
    name: "Aquarium",
  },
  {
    value: "550-5520-0212",
    name: "Ski Resort",
  },
  {
    value: "550-5520-0228",
    name: "Animal Park",
  },
  {
    value: "550-5520-0357",
    name: "Water Park",
  },
  {
    value: "600-6000-0061",
    name: "Convenience Store",
  },
  {
    value: "600-6100-0062",
    name: "Shopping Mall",
  },
  {
    value: "600-6200-0063",
    name: "Department Store",
  },
  {
    value: "600-6300-0064",
    name: "Food-Beverage Specialty Store",
  },
  {
    value: "600-6300-0066",
    name: "Grocery",
  },
  {
    value: "600-6300-0067",
    name: "Specialty Food Store",
  },
  {
    value: "600-6300-0068",
    name: "Wine and Liquor",
  },
  {
    value: "600-6300-0244",
    name: "Bakery and Baked Goods Store",
  },
  {
    value: "600-6300-0245",
    name: "Sweet Shop",
  },
  {
    value: "600-6300-0363",
    name: "Butcher",
  },
  {
    value: "600-6300-0364",
    name: "Dairy Goods",
  },
  {
    value: "600-6400-0000",
    name: "Drugstore or Pharmacy",
  },
  {
    value: "600-6400-0069",
    name: "Drugstore",
  },
  {
    value: "600-6400-0070",
    name: "Pharmacy",
  },
  {
    value: "600-6500-0072",
    name: "Consumer Electronics Store",
  },
  {
    value: "600-6500-0073",
    name: "Mobile Retailer",
  },
  {
    value: "600-6500-0074",
    name: "Mobile Service Center",
  },
  {
    value: "600-6500-0075",
    name: "Computer and Software",
  },
  {
    value: "600-6500-0076",
    name: "Entertainment Electronics",
  },
  {
    value: "600-6600-0000",
    name: "Hardware, House and Garden",
  },
  {
    value: "600-6600-0077",
    name: "Home Improvement-Hardware Store",
  },
  {
    value: "600-6600-0078",
    name: "Home Specialty Store",
  },
  {
    value: "600-6600-0079",
    name: "Floor and Carpet",
  },
  {
    value: "600-6600-0080",
    name: "Furniture Store",
  },
  {
    value: "600-6600-0082",
    name: "Garden Center",
  },
  {
    value: "600-6600-0083",
    name: "Glass and Window",
  },
  {
    value: "600-6600-0084",
    name: "Lumber",
  },
  {
    value: "600-6600-0085",
    name: "Major Appliance",
  },
  {
    value: "600-6600-0310",
    name: "Power Equipment Dealer",
  },
  {
    value: "600-6600-0319",
    name: "Paint Store",
  },
  {
    value: "600-6700-0000",
    name: "Other Bookshop",
  },
  {
    value: "600-6700-0087",
    name: "Bookstore",
  },
  {
    value: "600-6800-0000",
    name: "Clothing and Accessories",
  },
  {
    value: "600-6800-0089",
    name: "Men's Apparel",
  },
  {
    value: "600-6800-0090",
    name: "Women's Apparel",
  },
  {
    value: "600-6800-0091",
    name: "Children's Apparel",
  },
  {
    value: "600-6800-0092",
    name: "Shoes-Footwear",
  },
  {
    value: "600-6800-0093",
    name: "Specialty Clothing Store",
  },
  {
    value: "600-6900-0000",
    name: "Consumer Goods",
  },
  {
    value: "600-6900-0094",
    name: "Sporting Goods Store",
  },
  {
    value: "600-6900-0095",
    name: "Office Supply and Services Store",
  },
  {
    value: "600-6900-0096",
    name: "Specialty Store",
  },
  {
    value: "600-6900-0097",
    name: "Pet Supply",
  },
  {
    value: "600-6900-0098",
    name: "Warehouse-Wholesale Store",
  },
  {
    value: "600-6900-0099",
    name: "General Merchandise",
  },
  {
    value: "600-6900-0100",
    name: "Discount Store",
  },
  {
    value: "600-6900-0101",
    name: "Flowers and Jewelry",
  },
  {
    value: "600-6900-0102",
    name: "Variety Store",
  },
  {
    value: "600-6900-0103",
    name: "Gift, Antique and Art",
  },
  {
    value: "600-6900-0105",
    name: "Record, CD and Video",
  },
  {
    value: "600-6900-0106",
    name: "Video and Game Rental",
  },
  {
    value: "600-6900-0246",
    name: "Bicycle and Bicycle Accessories Shop",
  },
  {
    value: "600-6900-0247",
    name: "Market",
  },
  {
    value: "600-6900-0248",
    name: "Motorcycle Accessories",
  },
  {
    value: "600-6900-0249",
    name: "Non-Store Retailers",
  },
  {
    value: "600-6900-0250",
    name: "Pawnshop",
  },
  {
    value: "600-6900-0251",
    name: "Used-Second Hand Merchandise Stores",
  },
  {
    value: "600-6900-0305",
    name: "Adult Shop",
  },
  {
    value: "600-6900-0307",
    name: "Arts and Crafts Supplies",
  },
  {
    value: "600-6900-0355",
    name: "Florist",
  },
  {
    value: "600-6900-0356",
    name: "Jeweler",
  },
  {
    value: "600-6900-0358",
    name: "Toy Store",
  },
  {
    value: "600-6900-0388",
    name: "Hunting-Fishing Shop",
  },
  {
    value: "600-6900-0389",
    name: "Running-Walking Shop",
  },
  {
    value: "600-6900-0390",
    name: "Skate Shop",
  },
  {
    value: "600-6900-0391",
    name: "Ski Shop",
  },
  {
    value: "600-6900-0392",
    name: "Snowboard Shop",
  },
  {
    value: "600-6900-0393",
    name: "Surf Shop",
  },
  {
    value: "600-6900-0394",
    name: "BMX Shop",
  },
  {
    value: "600-6900-0395",
    name: "Camping-Hiking Shop",
  },
  {
    value: "600-6900-0396",
    name: "Canoe-Kayak Shop",
  },
  {
    value: "600-6900-0397",
    name: "Cross Country Ski Shop",
  },
  {
    value: "600-6900-0398",
    name: "Tack Shop",
  },
  {
    value: "600-6950-0000",
    name: "Hair and Beauty",
  },
  {
    value: "600-6950-0399",
    name: "Barber",
  },
  {
    value: "600-6950-0400",
    name: "Nail Salon",
  },
  {
    value: "600-6950-0401",
    name: "Hair Salon",
  },
  {
    value: "700-7000-0107",
    name: "Bank",
  },
  {
    value: "700-7010-0108",
    name: "ATM",
  },
  {
    value: "700-7050-0109",
    name: "Money Transferring Service",
  },
  {
    value: "700-7050-0110",
    name: "Check Cashing Service-Currency Exchange",
  },
  {
    value: "700-7100-0000",
    name: "Communication-Media",
  },
  {
    value: "700-7100-0134",
    name: "Telephone Service",
  },
  {
    value: "700-7200-0000",
    name: "Commercial Services",
  },
  {
    value: "700-7200-0252",
    name: "Advertising-Marketing, PR and Market Research",
  },
  {
    value: "700-7200-0253",
    name: "Catering and Other Food Services",
  },
  {
    value: "700-7200-0254",
    name: "Construction",
  },
  {
    value: "700-7200-0255",
    name: "Customer Care-Service Center",
  },
  {
    value: "700-7200-0256",
    name: "Engineering and Scientific Services",
  },
  {
    value: "700-7200-0257",
    name: "Farming",
  },
  {
    value: "700-7200-0258",
    name: "Food Production",
  },
  {
    value: "700-7200-0259",
    name: "Human Resources and Recruiting Services",
  },
  {
    value: "700-7200-0260",
    name: "Investigation Services",
  },
  {
    value: "700-7200-0261",
    name: "IT and Office Equipment Services",
  },
  {
    value: "700-7200-0262",
    name: "Landscaping Services",
  },
  {
    value: "700-7200-0263",
    name: "Locksmiths and Security Systems Services",
  },
  {
    value: "700-7200-0264",
    name: "Management and Consulting Services",
  },
  {
    value: "700-7200-0265",
    name: "Manufacturing",
  },
  {
    value: "700-7200-0266",
    name: "Mining",
  },
  {
    value: "700-7200-0267",
    name: "Modeling Agencies",
  },
  {
    value: "700-7200-0268",
    name: "Motorcycle Service and Maintenance",
  },
  {
    value: "700-7200-0269",
    name: "Organizations and Societies",
  },
  {
    value: "700-7200-0270",
    name: "Entertainment and Recreation",
  },
  {
    value: "700-7200-0271",
    name: "Finance and Insurance",
  },
  {
    value: "700-7200-0272",
    name: "Healthcare and Healthcare Support Services",
  },
  {
    value: "700-7200-0274",
    name: "Rental and Leasing",
  },
  {
    value: "700-7200-0275",
    name: "Repair and Maintenance Services",
  },
  {
    value: "700-7200-0276",
    name: "Printing and Publishing",
  },
  {
    value: "700-7200-0277",
    name: "Specialty Trade Contractors",
  },
  {
    value: "700-7200-0278",
    name: "Towing Service",
  },
  {
    value: "700-7200-0279",
    name: "Translation and Interpretation Services",
  },
  {
    value: "700-7200-0324",
    name: "Apartment Rental-Flat Rental",
  },
  {
    value: "700-7200-0328",
    name: "B2B Sales and Services",
  },
  {
    value: "700-7200-0329",
    name: "B2B Restaurant Services",
  },
  {
    value: "700-7200-0330",
    name: "Aviation",
  },
  {
    value: "700-7200-0342",
    name: "Interior and Exterior Design",
  },
  {
    value: "700-7200-0344",
    name: "Property Management",
  },
  {
    value: "700-7200-0345",
    name: "Financial Investment Firm",
  },
  {
    value: "700-7250-0136",
    name: "Business Facility",
  },
  {
    value: "700-7300-0111",
    name: "Police Station",
  },
  {
    value: "700-7300-0112",
    name: "Police Services-Security",
  },
  {
    value: "700-7300-0113",
    name: "Fire Department",
  },
  {
    value: "700-7300-0280",
    name: "Ambulance Services",
  },
  {
    value: "700-7400-0000",
    name: "Consumer Services",
  },
  {
    value: "700-7400-0133",
    name: "Travel Agent-Ticketing",
  },
  {
    value: "700-7400-0137",
    name: "Dry Cleaning and Laundry",
  },
  {
    value: "700-7400-0138",
    name: "Attorney",
  },
  {
    value: "700-7400-0140",
    name: "Boating",
  },
  {
    value: "700-7400-0141",
    name: "Business Service",
  },
  {
    value: "700-7400-0142",
    name: "Funeral Director",
  },
  {
    value: "700-7400-0143",
    name: "Mover",
  },
  {
    value: "700-7400-0144",
    name: "Photography",
  },
  {
    value: "700-7400-0145",
    name: "Real Estate Services",
  },
  {
    value: "700-7400-0146",
    name: "Repair Service",
  },
  {
    value: "700-7400-0147",
    name: "Social Service",
  },
  {
    value: "700-7400-0148",
    name: "Storage",
  },
  {
    value: "700-7400-0149",
    name: "Tailor and Alteration",
  },
  {
    value: "700-7400-0150",
    name: "Tax Service",
  },
  {
    value: "700-7400-0151",
    name: "Utilities",
  },
  {
    value: "700-7400-0152",
    name: "Waste and Sanitary",
  },
  {
    value: "700-7400-0281",
    name: "Bicycle Service and Maintenance",
  },
  {
    value: "700-7400-0282",
    name: "Bill Payment Service",
  },
  {
    value: "700-7400-0283",
    name: "Body Piercing and Tattoos",
  },
  {
    value: "700-7400-0284",
    name: "Wedding Services and Bridal Studio",
  },
  {
    value: "700-7400-0285",
    name: "Internet Cafe",
  },
  {
    value: "700-7400-0286",
    name: "Kindergarten and Childcare",
  },
  {
    value: "700-7400-0287",
    name: "Maid Services",
  },
  {
    value: "700-7400-0288",
    name: "Marriage and Match Making Services",
  },
  {
    value: "700-7400-0289",
    name: "Public Administration",
  },
  {
    value: "700-7400-0292",
    name: "Wellness Center and Services",
  },
  {
    value: "700-7400-0293",
    name: "Pet Care",
  },
  {
    value: "700-7400-0327",
    name: "Legal Services",
  },
  {
    value: "700-7400-0343",
    name: "Tanning Salon",
  },
  {
    value: "700-7400-0352",
    name: "Recycling Center",
  },
  {
    value: "700-7400-0365",
    name: "Electrical",
  },
  {
    value: "700-7400-0366",
    name: "Plumbing",
  },
  {
    value: "700-7450-0114",
    name: "Post Office",
  },
  {
    value: "700-7460-0115",
    name: "Tourist Information",
  },
  {
    value: "700-7600-0000",
    name: "Fueling Station",
  },
  {
    value: "700-7600-0116",
    name: "Petrol-Gasoline Station",
  },
  {
    value: "700-7600-0322",
    name: "EV Charging Station",
  },
  {
    value: "700-7800-0118",
    name: "Automobile Dealership-New Cars",
  },
  {
    value: "700-7800-0119",
    name: "Automobile Dealership-Used Cars",
  },
  {
    value: "700-7800-0120",
    name: "Motorcycle Dealership",
  },
  {
    value: "700-7850-0000",
    name: "Car Repair-Service",
  },
  {
    value: "700-7850-0121",
    name: "Car Wash-Detailing",
  },
  {
    value: "700-7850-0122",
    name: "Car Repair",
  },
  {
    value: "700-7850-0123",
    name: "Auto Parts",
  },
  {
    value: "700-7850-0124",
    name: "Emission Testing",
  },
  {
    value: "700-7850-0125",
    name: "Tire Repair",
  },
  {
    value: "700-7850-0126",
    name: "Truck Repair",
  },
  {
    value: "700-7850-0127",
    name: "Van Repair",
  },
  {
    value: "700-7850-0128",
    name: "Road Assistance",
  },
  {
    value: "700-7850-0129",
    name: "Automobile Club",
  },
  {
    value: "700-7851-0117",
    name: "Rental Car Agency",
  },
  {
    value: "700-7900-0000",
    name: "Truck-Semi Dealer-Services",
  },
  {
    value: "700-7900-0130",
    name: "Truck Dealership",
  },
  {
    value: "700-7900-0131",
    name: "Truck Parking",
  },
  {
    value: "700-7900-0132",
    name: "Truck Stop-Plaza",
  },
  {
    value: "700-7900-0323",
    name: "Truck Wash",
  },
  {
    value: "800-8000-0000",
    name: "Hospital or Health Care Facility",
  },
  {
    value: "800-8000-0154",
    name: "Dentist-Dental Office",
  },
  {
    value: "800-8000-0155",
    name: "Family-General Practice Physicians",
  },
  {
    value: "800-8000-0156",
    name: "Psychiatric Institute",
  },
  {
    value: "800-8000-0157",
    name: "Nursing Home",
  },
  {
    value: "800-8000-0158",
    name: "Medical Services-Clinics",
  },
  {
    value: "800-8000-0159",
    name: "Hospital",
  },
  {
    value: "800-8000-0161",
    name: "Optical",
  },
  {
    value: "800-8000-0162",
    name: "Veterinarian",
  },
  {
    value: "800-8000-0325",
    name: "Hospital Emergency Room",
  },
  {
    value: "800-8000-0340",
    name: "Therapist",
  },
  {
    value: "800-8000-0341",
    name: "Chiropractor",
  },
  {
    value: "800-8000-0367",
    name: "Blood Bank",
  },
  {
    value: "800-8100-0000",
    name: "Government or Community Facility",
  },
  {
    value: "800-8100-0163",
    name: "City Hall",
  },
  {
    value: "800-8100-0164",
    name: "Embassy",
  },
  {
    value: "800-8100-0165",
    name: "Military Base",
  },
  {
    value: "800-8100-0168",
    name: "County Council",
  },
  {
    value: "800-8100-0169",
    name: "Civic-Community Center",
  },
  {
    value: "800-8100-0170",
    name: "Court House",
  },
  {
    value: "800-8100-0171",
    name: "Government Office",
  },
  {
    value: "800-8100-0172",
    name: "Border Crossing",
  },
  {
    value: "800-8200-0000",
    name: "Education Facility",
  },
  {
    value: "800-8200-0173",
    name: "Higher Education",
  },
  {
    value: "800-8200-0174",
    name: "School",
  },
  {
    value: "800-8200-0295",
    name: "Training and Development",
  },
  {
    value: "800-8200-0360",
    name: "Coaching Institute",
  },
  {
    value: "800-8200-0361",
    name: "Fine Arts",
  },
  {
    value: "800-8200-0362",
    name: "Language Studies",
  },
  {
    value: "800-8300-0000",
    name: "Other Library",
  },
  {
    value: "800-8300-0175",
    name: "Library",
  },
  {
    value: "800-8400-0000",
    name: "Event Spaces",
  },
  {
    value: "800-8400-0139",
    name: "Banquet Hall",
  },
  {
    value: "800-8400-0176",
    name: "Convention-Exhibition Center",
  },
  {
    value: "800-8500-0000",
    name: "Parking",
  },
  {
    value: "800-8500-0177",
    name: "Parking Garage-Parking House",
  },
  {
    value: "800-8500-0178",
    name: "Parking Lot",
  },
  {
    value: "800-8500-0179",
    name: "Park and Ride",
  },
  {
    value: "800-8500-0315",
    name: "Cellphone Parking Lot",
  },
  {
    value: "800-8600-0000",
    name: "Sports Facility-Venue",
  },
  {
    value: "800-8600-0180",
    name: "Sports Complex-Stadium",
  },
  {
    value: "800-8600-0181",
    name: "Ice Skating Rink",
  },
  {
    value: "800-8600-0182",
    name: "Swimming Pool",
  },
  {
    value: "800-8600-0183",
    name: "Tennis Court",
  },
  {
    value: "800-8600-0184",
    name: "Bowling Center",
  },
  {
    value: "800-8600-0185",
    name: "Indoor Ski",
  },
  {
    value: "800-8600-0186",
    name: "Hockey",
  },
  {
    value: "800-8600-0187",
    name: "Racquetball Court",
  },
  {
    value: "800-8600-0188",
    name: "Shooting Range",
  },
  {
    value: "800-8600-0189",
    name: "Soccer Club",
  },
  {
    value: "800-8600-0190",
    name: "Squash Court",
  },
  {
    value: "800-8600-0191",
    name: "Fitness-Health Club",
  },
  {
    value: "800-8600-0192",
    name: "Indoor Sports",
  },
  {
    value: "800-8600-0193",
    name: "Golf Course",
  },
  {
    value: "800-8600-0194",
    name: "Golf Practice Range",
  },
  {
    value: "800-8600-0195",
    name: "Race Track",
  },
  {
    value: "800-8600-0196",
    name: "Sporting Instruction and Camps",
  },
  {
    value: "800-8600-0197",
    name: "Sports Activities",
  },
  {
    value: "800-8600-0199",
    name: "Basketball",
  },
  {
    value: "800-8600-0200",
    name: "Badminton",
  },
  {
    value: "800-8600-0314",
    name: "Rugby",
  },
  {
    value: "800-8600-0316",
    name: "Diving Center",
  },
  {
    value: "800-8600-0376",
    name: "Bike Park",
  },
  {
    value: "800-8600-0377",
    name: "BMX Track",
  },
  {
    value: "800-8600-0381",
    name: "Running Track",
  },
  {
    value: "800-8700-0000",
    name: "Facilities",
  },
  {
    value: "800-8700-0166",
    name: "Cemetery",
  },
  {
    value: "800-8700-0167",
    name: "Crematorium",
  },
  {
    value: "800-8700-0198",
    name: "Public Restroom-Toilets",
  },
  {
    value: "800-8700-0296",
    name: "Clubhouse",
  },
  {
    value: "800-8700-0298",
    name: "Registration Office",
  },
  {
    value: "900-9100-0000",
    name: "City, Town or Village",
  },
  {
    value: "900-9100-0214",
    name: "Hamlet",
  },
  {
    value: "900-9100-0215",
    name: "Named Place",
  },
  {
    value: "900-9100-0216",
    name: "Neighborhood",
  },
  {
    value: "900-9200-0000",
    name: "Outdoor Area-Complex",
  },
  {
    value: "900-9200-0218",
    name: "Industrial Zone",
  },
  {
    value: "900-9200-0219",
    name: "Marina",
  },
  {
    value: "900-9200-0220",
    name: "RV Parks",
  },
  {
    value: "900-9200-0299",
    name: "Collective Community",
  },
  {
    value: "900-9200-0301",
    name: "Island",
  },
  {
    value: "900-9200-0386",
    name: "Meeting Point",
  },
  {
    value: "900-9300-0000",
    name: "Building",
  },
  {
    value: "900-9300-0221",
    name: "Residential Area-Building",
  },
  {
    value: "900-9400-0000",
    name: "Administrative Region-Streets",
  },
  {
    value: "900-9400-0399",
    name: "Administrative Region",
  },
  {
    value: "900-9400-0400",
    name: "Postal Area",
  },
  {
    value: "900-9400-0401",
    name: "Street or Square",
  },
  {
    value: "900-9400-0402",
    name: "Intersection",
  },
];

const hereFoodTypes = [
  {
    value: "101-000",
    name: "American",
  },
  {
    value: "101-001",
    name: "American-Californian",
  },
  {
    value: "101-002",
    name: "American-Southwestern",
  },
  {
    value: "101-003",
    name: "American-Barbecue/Southern",
  },
  {
    value: "101-004",
    name: "American-Creole",
  },
  {
    value: "101-039",
    name: "American-Native American",
  },
  {
    value: "101-040",
    name: "American-Soul Food",
  },
  {
    value: "101-070",
    name: "American-Cajun",
  },
  {
    value: "102-000",
    name: "Mexican",
  },
  {
    value: "102-005",
    name: "Mexican-Yucateca",
  },
  {
    value: "102-006",
    name: "Mexican-Oaxaquena",
  },
  {
    value: "102-007",
    name: "Mexican-Veracruzana",
  },
  {
    value: "102-008",
    name: "Mexican-Poblana",
  },
  {
    value: "103-000",
    name: "Canadian",
  },
  {
    value: "150-000",
    name: "Australian",
  },
  {
    value: "151-000",
    name: "Hawaiian/Polynesian",
  },
  {
    value: "152-000",
    name: "Caribbean",
  },
  {
    value: "153-000",
    name: "Cuban",
  },
  {
    value: "200-000",
    name: "Asian",
  },
  {
    value: "201-000",
    name: "Chinese",
  },
  {
    value: "201-009",
    name: "Chinese-Szechuan",
  },
  {
    value: "201-010",
    name: "Chinese-Cantonese",
  },
  {
    value: "201-041",
    name: "Chinese-Shanghai",
  },
  {
    value: "201-042",
    name: "Chinese-Beijing",
  },
  {
    value: "201-043",
    name: "Chinese-Hunan/Hubei",
  },
  {
    value: "201-044",
    name: "Chinese-Jiangsu/Zhejiang",
  },
  {
    value: "201-045",
    name: "Chinese-Shandong",
  },
  {
    value: "201-046",
    name: "Chinese-Northeastern",
  },
  {
    value: "201-047",
    name: "Chinese-Inner Mongolian",
  },
  {
    value: "201-048",
    name: "Chinese-Yunnan/Guizhou",
  },
  {
    value: "201-049",
    name: "Chinese-Taiwanese",
  },
  {
    value: "201-050",
    name: "Chinese-Guangxi",
  },
  {
    value: "201-051",
    name: "Chinese-Jiangxi",
  },
  {
    value: "201-052",
    name: "Chinese-Northwestern",
  },
  {
    value: "201-053",
    name: "Chinese-Porridge",
  },
  {
    value: "201-054",
    name: "Chinese-Islamic",
  },
  {
    value: "201-055",
    name: "Chinese-Hot Pot",
  },
  {
    value: "202-000",
    name: "Indian",
  },
  {
    value: "202-011",
    name: "Indian-Tandoori",
  },
  {
    value: "202-012",
    name: "Indian-Punjabi",
  },
  {
    value: "202-013",
    name: "Indian-Rajasthani",
  },
  {
    value: "202-014",
    name: "Indian-Mughlai",
  },
  {
    value: "202-015",
    name: "Indian-Bengali",
  },
  {
    value: "202-016",
    name: "Indian-Goan",
  },
  {
    value: "202-017",
    name: "Indian-Jain",
  },
  {
    value: "202-018",
    name: "Indian-Konkani",
  },
  {
    value: "202-019",
    name: "Indian-Gujarati",
  },
  {
    value: "202-020",
    name: "Indian-Parsi",
  },
  {
    value: "202-021",
    name: "Indian-South Indian",
  },
  {
    value: "202-022",
    name: "Indian-Maharashtrian",
  },
  {
    value: "202-023",
    name: "Indian-North Indian",
  },
  {
    value: "202-024",
    name: "Indian-Malvani",
  },
  {
    value: "202-025",
    name: "Indian-Hyderabadi",
  },
  {
    value: "203-000",
    name: "Japanese",
  },
  {
    value: "203-026",
    name: "Japanese-Sushi",
  },
  {
    value: "204-000",
    name: "Southeast Asian",
  },
  {
    value: "205-000",
    name: "Thai",
  },
  {
    value: "206-000",
    name: "Vietnamese",
  },
  {
    value: "207-000",
    name: "Korean",
  },
  {
    value: "208-000",
    name: "Pakistani",
  },
  {
    value: "209-000",
    name: "Malaysian",
  },
  {
    value: "210-000",
    name: "Bruneian",
  },
  {
    value: "211-000",
    name: "Indonesian",
  },
  {
    value: "212-000",
    name: "Filipino",
  },
  {
    value: "250-000",
    name: "Middle Eastern",
  },
  {
    value: "251-000",
    name: "Azerbaijani",
  },
  {
    value: "252-000",
    name: "Turkish",
  },
  {
    value: "253-000",
    name: "Lebanese",
  },
  {
    value: "254-000",
    name: "Yemeni",
  },
  {
    value: "255-000",
    name: "Burmese",
  },
  {
    value: "256-000",
    name: "Cambodian",
  },
  {
    value: "257-000",
    name: "Singaporean",
  },
  {
    value: "258-000",
    name: "Sri Lankan",
  },
  {
    value: "259-000",
    name: "Tibetan",
  },
  {
    value: "300-000",
    name: "European",
  },
  {
    value: "301-000",
    name: "French",
  },
  {
    value: "301-027",
    name: "French-Alsatian",
  },
  {
    value: "301-028",
    name: "French-Auvergnate",
  },
  {
    value: "301-029",
    name: "French-Basque",
  },
  {
    value: "301-030",
    name: "French-Corse",
  },
  {
    value: "301-031",
    name: "French-Lyonnaise",
  },
  {
    value: "301-032",
    name: "French-Provencale",
  },
  {
    value: "301-033",
    name: "French-Sud-ouest",
  },
  {
    value: "302-000",
    name: "German",
  },
  {
    value: "303-000",
    name: "Greek",
  },
  {
    value: "304-000",
    name: "Italian",
  },
  {
    value: "305-000",
    name: "Irish",
  },
  {
    value: "306-000",
    name: "Austrian",
  },
  {
    value: "307-000",
    name: "Belgian",
  },
  {
    value: "308-000",
    name: "British Isles",
  },
  {
    value: "309-000",
    name: "Dutch",
  },
  {
    value: "310-000",
    name: "Swiss",
  },
  {
    value: "311-000",
    name: "Spanish",
  },
  {
    value: "311-034",
    name: "Spanish-Tapas",
  },
  {
    value: "313-000",
    name: "Portuguese",
  },
  {
    value: "314-000",
    name: "Maltese",
  },
  {
    value: "315-000",
    name: "Sicilian",
  },
  {
    value: "350-000",
    name: "Scandinavian",
  },
  {
    value: "351-000",
    name: "Finnish",
  },
  {
    value: "352-000",
    name: "Swedish",
  },
  {
    value: "353-000",
    name: "Norwegian",
  },
  {
    value: "354-000",
    name: "Danish",
  },
  {
    value: "370-000",
    name: "East European",
  },
  {
    value: "371-000",
    name: "Hungarian",
  },
  {
    value: "372-000",
    name: "Mediterranean",
  },
  {
    value: "373-000",
    name: "Baltic",
  },
  {
    value: "374-000",
    name: "Belorusian",
  },
  {
    value: "375-000",
    name: "Ukrainian",
  },
  {
    value: "376-000",
    name: "Polish",
  },
  {
    value: "377-000",
    name: "Russian",
  },
  {
    value: "378-000",
    name: "Bohemian",
  },
  {
    value: "379-000",
    name: "Balkan",
  },
  {
    value: "380-000",
    name: "Caucasian",
  },
  {
    value: "381-000",
    name: "Romanian",
  },
  {
    value: "382-000",
    name: "Armenian",
  },
  {
    value: "400-000",
    name: "South American",
  },
  {
    value: "401-000",
    name: "Surinamese",
  },
  {
    value: "402-000",
    name: "Venezuelan",
  },
  {
    value: "403-000",
    name: "Latin American",
  },
  {
    value: "404-000",
    name: "Argentinean",
  },
  {
    value: "405-000",
    name: "Chilean",
  },
  {
    value: "406-000",
    name: "Brazilian",
  },
  {
    value: "406-035",
    name: "Brazilian-Baiana",
  },
  {
    value: "406-036",
    name: "Brazilian-Capixaba",
  },
  {
    value: "406-037",
    name: "Brazilian-Mineira",
  },
  {
    value: "406-038",
    name: "Brazilian-Bakery",
  },
  {
    value: "407-000",
    name: "Peruvian",
  },
  {
    value: "500-000",
    name: "African",
  },
  {
    value: "501-000",
    name: "Moroccan",
  },
  {
    value: "502-000",
    name: "Egyptian",
  },
  {
    value: "503-000",
    name: "Ethiopian",
  },
  {
    value: "504-000",
    name: "Seychellois",
  },
  {
    value: "505-000",
    name: "South African",
  },
  {
    value: "506-000",
    name: "North African",
  },
  {
    value: "600-000",
    name: "Oceanic",
  },
  {
    value: "800-056",
    name: "Steak House",
  },
  {
    value: "800-057",
    name: "Pizza",
  },
  {
    value: "800-058",
    name: "Snacks and Beverages",
  },
  {
    value: "800-059",
    name: "Hot Dogs",
  },
  {
    value: "800-060",
    name: "Sandwich",
  },
  {
    value: "800-061",
    name: "Breakfast",
  },
  {
    value: "800-062",
    name: "Chicken",
  },
  {
    value: "800-063",
    name: "Ice Cream",
  },
  {
    value: "800-064",
    name: "International",
  },
  {
    value: "800-065",
    name: "Continental",
  },
  {
    value: "800-066",
    name: "Fusion",
  },
  {
    value: "800-067",
    name: "Burgers",
  },
  {
    value: "800-068",
    name: "Creperie",
  },
  {
    value: "800-069",
    name: "Pastries",
  },
  {
    value: "800-071",
    name: "Fondue",
  },
  {
    value: "800-072",
    name: "Brunch",
  },
  {
    value: "800-073",
    name: "Bistro",
  },
  {
    value: "800-074",
    name: "BrewPub",
  },
  {
    value: "800-075",
    name: "Seafood",
  },
  {
    value: "800-076",
    name: "Vegan",
  },
  {
    value: "800-077",
    name: "Vegetarian",
  },
  {
    value: "800-078",
    name: "Grill",
  },
  {
    value: "800-079",
    name: "Jewish/Kosher",
  },
  {
    value: "800-080",
    name: "Soup",
  },
  {
    value: "800-081",
    name: "Lunch",
  },
  {
    value: "800-082",
    name: "Dinner",
  },
  {
    value: "800-083",
    name: "Natural/Healthy",
  },
  {
    value: "800-084",
    name: "Organic",
  },
  {
    value: "800-085",
    name: "Noodles",
  },
];

const LocationCategoryForm = ({ uuid, pristine, setImages, item, handleSubmit, saveLocationCategoryAndUploadImages, callDeleteCategory, locationCategoryTypes, submitting, //images,
  subCategoriesLength, checkedForSubCategories, ...rest }) =>
  <form onSubmit={handleSubmit(saveLocationCategoryAndUploadImages)} noValidate autoComplete="off" style={{ color: 'white' }} {...console.log(item)}>
    <div className="container mt-4">
  
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={TGField} className="form-control" name="name" placeholder="Name" />
        </div>
      </div> 

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <Field component={TGField} className="form-control" name="categoryTypeOrder" placeholder="Order" />
        </div>
      </div>    

      <div className="form-row">
        <div className="col-md-10 mb-2">
          <Field name="locationCategoryTypeId" component="select" className="form-control">
            
            { 
              Object.values(locationCategoryTypes).map(type => 
                <option value={type.objectID} key={type.objectID}>{type.name}</option>)
            }
          </Field>
        </div>        

        <div className="col-md-2 mb-2 text-left">        
          
          <Field component="input" type="checkbox" name="more" />          
          <label className="ml-2" style={{ color: 'black' }}>More</label>
        </div>
      </div>
      
      <div className="form-row"> 
      <div className="col-md-12">

      {/* { images.map(image => 
          <div className="pull-left mr-2 mb-4 mt-4" key={`${image.lastModified}${image.size}`}>
            <img  src={URL.createObjectURL(image)} style={{ height: '8rem' } } alt="" />  
          </div>
        )}
        {
          images.length === 0 && item.imageFileName &&
          <div className="pull-left mr-2 mb-4 mt-4">
            <img src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy.appspot.com/o/${item.imageFileName}?alt=media` } style={{ maxHeight: '8rem', maxWidth: '16rem' } } alt="" />
          </div>
        }

        <div className="pull-left mb-4 mt-4">
          <DropZone onDrop={(accepted, rejected) => { setImages(accepted); }}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                  <input {...getInputProps()} />
                  <p>Drop Image Here</p>
                </div>
              </section>
            )}
          </DropZone>
        </div> */}
      </div>
    </div>
    {/* <div className="form-row">
      <div className="col-md-12 mb-2">
        <Field name="hereCategories" component={TGSelect} className="form-control" multiple>
          {
            hereCategories && hereCategories.map((hereCategory, index) =>
            <option key={hereCategory.value} value={hereCategory.value}>
              {hereCategory.name}
            </option>
            )
          }
        </Field>
      </div>
    </div>
    <div className="form-row">
      <div className="col-md-12 mb-2">
        <Field name="hereFoodTypes" component={TGSelect} className="form-control" multiple>
          {
            hereFoodTypes && hereFoodTypes.map((hereFoodType, index) =>
            <option key={hereFoodType.value} value={hereFoodType.value}>
              {hereFoodType.name}
            </option>
            )
          }
        </Field>
      </div>
    </div> */}
<   div className="row">
      <div className="col-md-12 mb-2">
        <ul className="list-group">
          {
            item.hereCategories && item.hereCategories.map((hereCategory, index) => 
            <li key={hereCategory} className="list-group-item">
              <p style={{color: 'black'}}>Here.com Category: {hereCategory}</p>
            </li>
            )
          }
        </ul>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 mb-2">
        <ul className="list-group">
          {
            item.hereFoodTypes && item.hereFoodTypes.map((hereFoodType, index) => 
            <li key={hereFoodType} className="list-group-item">
              <p style={{color: 'black'}}>Here.com Food Type: {hereFoodType}</p>
            </li>
            )
          }
        </ul>
      </div>
    </div>
      <Icons />

      <div className="form-row">
        <div className="col mt-2">
          {
            /*uuid &&
            <button type="button" className="btn btn-danger pull-left" onClick={() => { archiveLocation(locationType, uuid) }}>
              <i className="fa fa-trash" />
            </button>
            */
          }

          {
            item && item.objectID && checkedForSubCategories && subCategoriesLength === 0 &&
            <button type="button" className="btn btn-danger pull-left" onClick={() => { callDeleteCategory(item) }}>
              <i className="fa fa-trash" />
            </button>
          }
          
          <button type="submit" className="btn btn-primary pull-right" disabled={submitting}>
            Save
          </button>

          <Link to="/location-categories" className="btn btn-light pull-right mr-2">Cancel</Link>
        </div>
      </div>


    </div>
  </form>;
const Wrapped = reduxForm({
  form: "location-category-form",
  enableReinitialize: true, 
})(LocationCategoryForm);

export default connect(
  mapStateToProps,
)(Wrapped);