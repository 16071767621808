import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router";
import omit from 'lodash/omit';

import PlaceForm from '../components/places-form';
import browserImageSize from 'browser-image-size';

import { deletePlace, addPlace, addPlaceJustImage, addPlaceJustData } from '../../firebase';

import {v4} from 'uuid';

function Place( props ) {
    const [image, setImage] = useState(null);
    const [size, setSize] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [place, setPlace] = useState({new: true});
    const [canDelete, setCanDelete] = useState(null);
    const [submitComplete, setSubmitComplete] = useState(false);

    const { id } = useParams();
    const { state } = useLocation();
    let history = useHistory();

    useEffect(() => {
        if ( id === 'new') {
          console.log("new prop location");
          setCanDelete(false);
        }else {
          console.log("edited prop location: " + state.hit);
          setPlace(state.hit);
          console.log(place);
          setCanDelete(true);
        }
    });

    useEffect(() => {
      if (submitComplete) {
        console.log("done submitting");
      history.push("/places");
      }
    }, [submitComplete]);

    function setStateImages(images) {
        console.log("set image", images);
        let imageSize = null;
        if (images.length > 0) {
          browserImageSize(images[0]).then((size) => {
            console.log("size", size);
            imageSize = size;
            setImage(images);
            setSize(imageSize);
          })
        } else {
            setImage(images);
            setSize(imageSize);
        }
      }

      function savePlaceAndUploadImages(values) {
        console.log('saving place', values);
        let imageData = {};

        let _geoloc = {
          lat: Number(values._geoloc.lat),
          lng: Number(values._geoloc.lng)
          
        }

        let imageName = null;
        if(((id === 'new') && (image != null)) || ((image != null && image.length > 0) && (place.imageFileName == null))){
          console.log("Creating image name");
          let file = image[0];
            const uuid = v4();
            let matched = file.name.match(/\.(png|PNG|jpg|JPG|gif|GIF|jpeg|JPEG)$/)

            if (matched) {
              imageName = `${uuid}${matched[0]}`;
            } else  {
              console.log("image not matched");
            }
        }

        if (id === 'new') {
          console.log("saveing new place");

          if (image != null && image.length > 0) {
            
            imageData = {
              _geoloc: _geoloc,
              imageFileName: imageName,
              name: values.name,
              lat: _geoloc.lat,
              lng: _geoloc.lng
            }
              
            let imageAndData = {
              image: image[0],
              imageData: imageData
            }
              
            console.log(imageAndData);
            let complete = addPlace(imageAndData);
            console.log("complete: " + complete);
            console.log(complete);
            complete.then(function(results) {
              console.log(results);
              setSubmitComplete(results);
            });

          } else {
            //no image
            imageData = {
              _geoloc: _geoloc,
              imageFileName: null,
              name: values.name,
              lat: _geoloc.lat,
              lng: _geoloc.lng
            }
            
            let imageAndData = {
              image: null,
              imageData: imageData
            }
            
            console.log(imageAndData);
            let complete = addPlace(imageAndData);
            console.log("complete: " + complete);
            console.log(complete);
            complete.then(function(results) {
              console.log(results);
              setSubmitComplete(results);
            });
          }

        } else {
          console.log("saving updated place");

          if (image != null && image.length > 0) {

            if ((place.lat == _geoloc.lat) || (place.lng == _geoloc.lng)) {
              
              if (place.imageFileName == null) {
                imageData = {
                  _geoloc: _geoloc,
                  imageFileName: imageName,
                  name: values.name,
                  lat: _geoloc.lat,
                  lng: _geoloc.lng,
                  objectID: place.objectID
                }
                  
                let imageAndData = {
                  image: image[0],
                  imageData: imageData
                }
                console.log(imageAndData);
                let complete = addPlace(imageAndData);
                complete.then(function(results) {
                  console.log(results);
                  setSubmitComplete(results);
                });

              } else {
                let imageAndData = {
                  image: image[0],
                  imageFileName: place.imageFileName
                }
                console.log(imageAndData);
                let complete = addPlaceJustImage(imageAndData);
                complete.then(function(results) {
                  console.log(results);
                  setSubmitComplete(results);
                });
              }
              

            } else {
              console.log("saving updated place image and data");

              imageData = {
                _geoloc: _geoloc,
                imageFileName: place.imageFileName,
                name: values.name,
                lat: _geoloc.lat,
                lng: _geoloc.lng,
                objectID: place.objectID
              }

              if (place.imageFileName == null) {
                imageData.imageFileName = imageName;
              }
              
              let imageAndData = {
                image: image[0],
                imageData: imageData
              }
              console.log(imageAndData);
              let complete = addPlace(imageAndData);
              complete.then(function(results) {
                console.log(results);
                setSubmitComplete(results);
              });
            }

          } else {
            console.log("saving updated place data");

            imageData = {
              _geoloc: _geoloc,
              imageFileName: place.imageFileName,
              name: values.name,
              lat: _geoloc.lat,
              lng: _geoloc.lng,
              objectID: place.objectID
            }

            console.log(imageData);
            let complete = addPlaceJustData(imageData);
            complete.then(function(results) {
              console.log(results);
              setSubmitComplete(results);
            });
          }
        }

        setIsSubmitting(true);
      }

      function deletePlaceData() {
        console.log("deleting place");
        console.log("place");
        setIsSubmitting(true);
        let complete = deletePlace(place);
        complete.then(function(results) {
          console.log(results);
          setSubmitComplete(results);
        });
      }

    return (
        <div className="container mt-4">        
        <h2 className="text-left">Place</h2>
        <PlaceForm
        setStateImages={setStateImages}
        isSubmitting={isSubmitting}
        savePlaceAndUploadImages={savePlaceAndUploadImages}
        image={image}
        deletePlace={deletePlaceData}
        place={place}
        canDelete={canDelete}
        />
      </div>

    );
}

export default Place;