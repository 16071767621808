import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { render } from 'react-dom';
import LoadScriptOnlyIfNeeded from '../../places/components/map-load-script-only-if-needed';

function PlaceMap({ lngLat, setLngLat }){
    const [map, setMap] = useState(null);
    const containerStyle = {
        width: '400px',
        height: '400px'
    }

    const onClick = (e) => {
        console.log('e: ', e);
        console.log('e.latLng: ', e.latLng);
        let position = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
        setLngLat(position);
    }

    const onLoad = useCallback(function callback(map) {
        setMap(map);
    }, []) 

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, [])
    
    return (
        <LoadScriptOnlyIfNeeded 
        googleMapsApiKey={`AIzaSyCG0eLmU9Da0dsGMfryLs8lLjDb-JE5LD0`} {...console.log(map)}
        >
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={lngLat}
            zoom={10}
            onRightClick={onClick}
            onLoad={onLoad}
            onUnmount={onUnmount}
            >
                <Marker
                position={lngLat}
                />
            </GoogleMap>

        </LoadScriptOnlyIfNeeded>
    )
    
}

export default React.memo(PlaceMap);