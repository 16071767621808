import sfb from './sfb';

import * as Constants from '../constants';
import { put, takeEvery, call } from 'redux-saga/effects'

function* getDocument(action) {   
   try {
      const ref = yield call(sfb.firestore.getDocument, `${action.collection}/${action.id}`);

      // console.log("get document", action, ref.id, ref.data());
      yield put({
         type: Constants.GET_DOCUMENT_SUCCESS,
         id: action.id,
         collection: action.collection,
         item: { ...ref.data(), objectID: ref.id }
      });
   } catch (e) {
      console.log('error', e);
   }
}

export default function* () {
  yield takeEvery(Constants.GET_DOCUMENT, getDocument);
}