import React from 'react';

import { Link } from 'react-router-dom';
import Image, { SITE, PLACES } from '../components/image';

import './point-of-interest.css';

export default ({ image, title, to, path }) => 
      <div>
        <Image className="d-block w-100" imageType={path} imageFileName={image} alt="" style={{ maxHeight: "500px", width: 'auto', height: 'auto', objectFit: 'cover' }}/>
        <div className="carousel-caption text-left" style={{ position: "absolute", top: 5, left: 15 }}>
          <h2>{title}</h2>
          <Link className="btn btn-outline-light link-shadow" to={to}>View</Link>
        </div>
      </div>;


/*<div className="point-of-interest carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <Image className="d-block w-100" imageType={SITE} imageFileName={image} alt="" />
        <div className="carousel-caption text-left" style={{ position: "absolute", top: 5, left: 15 }}>
          <h2>{title}</h2>
          <Link className="btn btn-outline-light link-shadow" to={to}>View</Link>
        </div>
      </div>
    </div>
  </div>*/