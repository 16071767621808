import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import browserImageSize from 'browser-image-size';

//import { saveLocationCategory } from '../actions';
import ReadsFiltersForm from '../components/reads-filters-form';

import { selectDocument, selectCollection } from '../../actions/selectors';
//import { deleteCategory } from '../../../functions/location-categories';
import { deleteCategory, collection, deleteDocument, saveReadsRegion, deleteReadsRegion, getDocument } from '../../firebase';
import { getUID } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';

const mapStateToProps = (state, ownProps) => {
  console.log(state);
  console.log(ownProps);
  return {
    // item: selectDocument(state, 'locationCategories', ownProps.match.params.id) || {
    //   locationCategoryTypeId: 'ECWcWPh8igZHxjdGcR1F',
    //   parentID: '',
    // },
    //item: ownProps.item,
    id: ownProps.match.params.id,
    //locationCategoryTypes: selectCollection(state, 'locationCategoryTypes') || {},
    uid: getUID(state)
    
  };
};

class ReadsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      //locationSubCategories: [],
      //checkedForSubCategories: false,
      readsRegion: {},
      redirect: false,
    };

    this.saveRegion = this.saveRegion.bind(this);
    this.callDeleteRegion = this.callDeleteRegion.bind(this);
    // this.copy = this.copy.bind(this);
  }


  async componentDidMount() {
    const { getCollection, id, location } = this.props;

    if (id && id !== 'new' && !this.state.readsRegion.name) {
      getDocument('readsLocations', id).then(doc => {
        console.log(doc);
        this.setState({ ...this.state, readsRegion: doc});
      });

      // if (location && location.state && location.state.subCategories) {
      //   this.setState({locationSubCategories: location.state.subCategories}, () => this.setState({checkedForSubCategories: true}));

      // } else {
      //   collection('locationCategories', { field: 'parentID', op: '==', val: id, orderBy: 'name'}).then((results) => {
      //     this.setState({locationSubCategories: results}, () => this.setState({checkedForSubCategories: true}));
      //   });
      // }
      
    }

    // if (this.props.locationCategoryTypes.length === 0) {
    //   getCollection('locationCategoryTypes');
    // }

  }
  

  saveRegion(values) {
    console.log('saving location', values);
    
    this.setState({ ...this.state, isSubmitting: true });
    if (IDCheck(this.props.uid)) {
      saveReadsRegion(values).then( (value) => {
        this.setState({redirect: true});
      })
    }
    
  }

  callDeleteRegion = async (item) => {
    let id = item.objectID;
    console.log("called delete category: " + id);
    if (IDCheck(this.props.uid)) {
      this.setState({isSubmitting: true});

      deleteDocument('readsLocations', id).then((item) => {
        this.setState({redirect: true});
      });

      // deleteReadsRegion(id).then((item) => {
      //   this.setState({redirect: true});
      // });

    }
    
  }

  render() {
    const {item, ...rest } = this.props;
//console.log(item);
    if (this.state.redirect) {
      return <Redirect push to="/reads-filters" />;
    }

    return (    
      
      <ReadsFiltersForm
        item={this.state.readsRegion}        
        saveRegion={this.saveRegion}
        callDeleteRegion={this.callDeleteRegion}
        {...rest}
      />
    );
  } 
}

export default withRouter(connect(
  mapStateToProps,
 //{ saveLocationCategory, },
)(ReadsFilter));