import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListingProgressBar from '../components/listing-progress-bar';
import { getStepInfo } from '../selectors';
import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';
import track from '../../actions/track';

// import get from 'lodash/get';
// import { saveUserListing } from '../../listings/actions';
import { SET_USER_LISTING_ADDRESS } from '../../actions/constants';

import { placesConfig } from '../../app/config';
// import { from } from 'zen-observable';

const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
});

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;

    track('Start New Listing');

    this.places = placesConfig({ container: "#listing-address", type: 'address' });

    this.places.on('change', address => {
      console.log("address", address.suggestion);
      dispatch({ type: SET_USER_LISTING_ADDRESS, address });
    });

    this.places.on('clear', () => {
      dispatch({ type: SET_USER_LISTING_ADDRESS });
    });

    this.places.on('error', (error) => {
      dispatch({ type: SET_USER_LISTING_ADDRESS });
    });
  }

  render() {
    const { current, listing, id } = this.props


    return (
      <div className="container mt-4">
        <div className="row">
        <div className="col-md-3">
            <ListingProgressBar step={current} />
          </div>
          <div className="col-md-9">
          <div className="card">
            <h5 className="card-header text-left">Where is your property located?</h5>
              <div className="card-body">
                <input className="form-control mb-1" type="text" placeholder="Property Address" id="listing-address" ref={this.searchRef} />
              </div>
            </div>

            {
              listing && listing.postalCode &&
              <div className="pull-right mt-2">
                <Link className="btn btn-primary" to={`/listing/location/${id}`}>Next</Link>                
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

  export default withRouter(connect(
    mapStateToProps,
    // { saveUserListing },
  )(Address));