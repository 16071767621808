import React from 'react';

import Icon from './icon';

export default () => 
  <div className="card">
  <div className="card-body">
  <div className="form-row">
    <Icon iconName="fa-500px" />
    <Icon iconName="fa-address-book" />
    <Icon iconName="fa-address-book-o" />
    <Icon iconName="fa-address-card" />
    <Icon iconName="fa-address-card-o" />
    <Icon iconName="fa-adjust" />
    <Icon iconName="fa-adn"/>
    <Icon iconName="fa-align-center" />
    <Icon iconName="fa-align-justify" />
    <Icon iconName="fa-align-left" />
    <Icon iconName="fa-align-right" />
    <Icon iconName="fa-amazon" />
    <Icon iconName="fa-ambulance" />
    <Icon iconName="fa-american-sign-language-interpreting" />
    <Icon iconName="fa-anchor" />
    <Icon iconName="fa-android" />
    <Icon iconName="fa-angellist" />
    <Icon iconName="fa-angle-double-down" />
    <Icon iconName="fa-angle-double-left" />
    <Icon iconName="fa-angle-double-right" />
    <Icon iconName="fa-angle-double-up" />
    <Icon iconName="fa-angle-down" />
    <Icon iconName="fa-angle-left" />
    <Icon iconName="fa-angle-right" />
    <Icon iconName="fa-angle-up" />
    <Icon iconName="fa-apple" />
    <Icon iconName="fa-archive" />
    <Icon iconName="fa-area-chart" />
    <Icon iconName="fa-arrow-circle-down" />
    <Icon iconName="fa-arrow-circle-left" />
    <Icon iconName="fa-arrow-circle-o-down" />
    <Icon iconName="fa-arrow-circle-o-left" />
    <Icon iconName="fa-arrow-circle-o-right" />
    <Icon iconName="fa-arrow-circle-o-up" />
    <Icon iconName="fa-arrow-circle-right" />
    <Icon iconName="fa-arrow-circle-up" />
    <Icon iconName="fa-arrow-down" />
    <Icon iconName="fa-arrow-left" />
    <Icon iconName="fa-arrow-right" />
    <Icon iconName="fa-arrow-up" />
    <Icon iconName="fa-arrows" />
    <Icon iconName="fa-arrows-alt" />
    <Icon iconName="fa-arrows-h" />
    <Icon iconName="fa-arrows-v" />
    <Icon iconName="fa-asl-interpreting" />
    <Icon iconName="fa-assistive-listening-systems" />
    <Icon iconName="fa-asterisk" />
    <Icon iconName="fa-at" />
    <Icon iconName="fa-audio-description" />
    <Icon iconName="fa-automobile" />
    <Icon iconName="fa-backward" />
    <Icon iconName="fa-balance-scale" />
    <Icon iconName="fa-ban" />
    <Icon iconName="fa-bandcamp" />
    <Icon iconName="fa-bank" />
    <Icon iconName="fa-bar-chart" />
    <Icon iconName="fa-bar-chart-o" />
    <Icon iconName="fa-barcode" />
    <Icon iconName="fa-bars" />
    <Icon iconName="fa-bath" />
    <Icon iconName="fa-bathtub" />
    <Icon iconName="fa-battery" />
    <Icon iconName="fa-battery-0" />
    <Icon iconName="fa-battery-1" />
    <Icon iconName="fa-battery-2" />
    <Icon iconName="fa-battery-3" />
    <Icon iconName="fa-battery-4" />
    <Icon iconName="fa-battery-empty" />
    <Icon iconName="fa-battery-full" />
    <Icon iconName="fa-battery-half" />
    <Icon iconName="fa-battery-quarter" />
    <Icon iconName="fa-battery-three-quarters" />
    <Icon iconName="fa-bed" />
    <Icon iconName="fa-beer" />
    <Icon iconName="fa-behance" />
    <Icon iconName="fa-behance-square" />
    <Icon iconName="fa-bell" />
    <Icon iconName="fa-bell-o" />
    <Icon iconName="fa-bell-slash" />
    <Icon iconName="fa-bell-slash-o" />
    <Icon iconName="fa-bicycle" />
    <Icon iconName="fa-binoculars" />
    <Icon iconName="fa-birthday-cake" />
    <Icon iconName="fa-bitbucket" />
    <Icon iconName="fa-bitbucket-square" />
    <Icon iconName="fa-bitcoin" />
    <Icon iconName="fa-black-tie" />
    <Icon iconName="fa-blind" />
    <Icon iconName="fa-bluetooth" />
    <Icon iconName="fa-bluetooth-b" />
    <Icon iconName="fa-bold" />
    <Icon iconName="fa-bolt" />
    <Icon iconName="fa-bomb" />
    <Icon iconName="fa-book" />
    <Icon iconName="fa-bookmark" />
    <Icon iconName="fa-bookmark-o" />
    <Icon iconName="fa-braille" />
    <Icon iconName="fa-briefcase" />
    <Icon iconName="fa-btc" />
    <Icon iconName="fa-bug" />
    <Icon iconName="fa-building" />
    <Icon iconName="fa-building-o" />
    <Icon iconName="fa-bullhorn" />
    <Icon iconName="fa-bullseye" />
    <Icon iconName="fa-bus" />
    <Icon iconName="fa-buysellads" />
    <Icon iconName="fa-cab" />
    <Icon iconName="fa-calculator" />
    <Icon iconName="fa-calendar" />
    <Icon iconName="fa-calendar-check-o" />
    <Icon iconName="fa-calendar-minus-o" />
    <Icon iconName="fa-calendar-o" />
    <Icon iconName="fa-calendar-plus-o" />
    <Icon iconName="fa-calendar-times-o" />
    <Icon iconName="fa-camera" />
    <Icon iconName="fa-camera-retro" />
    <Icon iconName="fa-car" />
    <Icon iconName="fa-caret-down" />
    <Icon iconName="fa-caret-left" />
    <Icon iconName="fa-caret-right" />
    <Icon iconName="fa-caret-square-o-down" />
    <Icon iconName="fa-caret-square-o-left" />
    <Icon iconName="fa-caret-square-o-right" />
    <Icon iconName="fa-caret-square-o-up" />
    <Icon iconName="fa-caret-up" />
    <Icon iconName="fa-cart-arrow-down" />
    <Icon iconName="fa-cart-plus" />
    <Icon iconName="fa-cc" />
    <Icon iconName="fa-cc-amex" />
    <Icon iconName="fa-cc-diners-club" />
    <Icon iconName="fa-cc-discover" />
    <Icon iconName="fa-cc-jcb" />
    <Icon iconName="fa-cc-mastercard" />
    <Icon iconName="fa-cc-paypal" />
    <Icon iconName="fa-cc-stripe" />
    <Icon iconName="fa-cc-visa" />
    <Icon iconName="fa-certificate" />
    <Icon iconName="fa-chain" />
    <Icon iconName="fa-chain-broken" />
    <Icon iconName="fa-check" />
    <Icon iconName="fa-check-circle" />
    <Icon iconName="fa-check-circle-o" />
    <Icon iconName="fa-check-square" />
    <Icon iconName="fa-check-square-o" />
    <Icon iconName="fa-chevron-circle-down" />
    <Icon iconName="fa-chevron-circle-left" />
    <Icon iconName="fa-chevron-circle-right" />
    <Icon iconName="fa-chevron-circle-up" />
    <Icon iconName="fa-chevron-down" />
    <Icon iconName="fa-chevron-left" />
    <Icon iconName="fa-chevron-right" />
    <Icon iconName="fa-chevron-up" />
    <Icon iconName="fa-child" />
    <Icon iconName="fa-chrome" />
    <Icon iconName="fa-circle" />
    <Icon iconName="fa-circle-o" />
    <Icon iconName="fa-circle-o-notch" />
    <Icon iconName="fa-circle-thin" />
    <Icon iconName="fa-clipboard" />
    <Icon iconName="fa-clock-o" />
    <Icon iconName="fa-clone" />
    <Icon iconName="fa-close" />
    <Icon iconName="fa-cloud" />
    <Icon iconName="fa-cloud-download" />
    <Icon iconName="fa-cloud-upload" />
    <Icon iconName="fa-cny" />
    <Icon iconName="fa-code" />
    <Icon iconName="fa-code-fork" />
    <Icon iconName="fa-codepen" />
    <Icon iconName="fa-codiepie" />
    <Icon iconName="fa-coffee" />
    <Icon iconName="fa-cog" />
    <Icon iconName="fa-cogs" />
    <Icon iconName="fa-columns" />
    <Icon iconName="fa-comment" />
    <Icon iconName="fa-comment-o" />
    <Icon iconName="fa-commenting" />
    <Icon iconName="fa-commenting-o" />
    <Icon iconName="fa-comments" />
    <Icon iconName="fa-comments-o" />
    <Icon iconName="fa-compass" />
    <Icon iconName="fa-compress" />
    <Icon iconName="fa-connectdevelop" />
    <Icon iconName="fa-contao" />
    <Icon iconName="fa-copy" />
    <Icon iconName="fa-copyright" />
    <Icon iconName="fa-creative-commons" />
    <Icon iconName="fa-credit-card" />
    <Icon iconName="fa-credit-card-alt" />
    <Icon iconName="fa-crop" />
    <Icon iconName="fa-crosshairs" />
    <Icon iconName="fa-css3" />
    <Icon iconName="fa-cube" />
    <Icon iconName="fa-cubes" />
    <Icon iconName="fa-cut" />
    <Icon iconName="fa-cutlery" />
    <Icon iconName="fa-dashboard" />
    <Icon iconName="fa-dashcube" />
    <Icon iconName="fa-database" />
    <Icon iconName="fa-deaf" />
    <Icon iconName="fa-deafness" />
    <Icon iconName="fa-dedent" />
    <Icon iconName="fa-delicious" />
    <Icon iconName="fa-desktop" />
    <Icon iconName="fa-deviantart" />
    <Icon iconName="fa-diamond" />
    <Icon iconName="fa-digg" />
    <Icon iconName="fa-dollar" />
    <Icon iconName="fa-dot-circle-o" />
    <Icon iconName="fa-download" />
    <Icon iconName="fa-dribbble" />
    <Icon iconName="fa-drivers-license" />
    <Icon iconName="fa-drivers-license-o" />
    <Icon iconName="fa-dropbox" />
    <Icon iconName="fa-drupal" />
    <Icon iconName="fa-edge" />
    <Icon iconName="fa-edit" />
    <Icon iconName="fa-eercast" />
    <Icon iconName="fa-eject" />
    <Icon iconName="fa-ellipsis-h" />
    <Icon iconName="fa-ellipsis-v" />
    <Icon iconName="fa-empire" />
    <Icon iconName="fa-envelope" />
    <Icon iconName="fa-envelope-o" />
    <Icon iconName="fa-envelope-open" />
    <Icon iconName="fa-envelope-open-o" />
    <Icon iconName="fa-envelope-square" />
    <Icon iconName="fa-envira" />
    <Icon iconName="fa-eraser" />
    <Icon iconName="fa-etsy" />
    <Icon iconName="fa-eur" />
    <Icon iconName="fa-euro" />
    <Icon iconName="fa-exchange" />
    <Icon iconName="fa-exclamation" />
    <Icon iconName="fa-exclamation-circle" />
    <Icon iconName="fa-exclamation-triangle" />
    <Icon iconName="fa-expand" />
    <Icon iconName="fa-expeditedssl" />
    <Icon iconName="fa-external-link" />
    <Icon iconName="fa-external-link-square" />
    <Icon iconName="fa-eye" />
    <Icon iconName="fa-eye-slash" />
    <Icon iconName="fa-eyedropper" />
    <Icon iconName="fa-fa" />
    <Icon iconName="fa-facebook" />
    <Icon iconName="fa-facebook-f" />
    <Icon iconName="fa-facebook-official" />
    <Icon iconName="fa-facebook-square" />
    <Icon iconName="fa-fast-backward" />
    <Icon iconName="fa-fast-forward" />
    <Icon iconName="fa-fax" />
    <Icon iconName="fa-feed" />
    <Icon iconName="fa-female" />
    <Icon iconName="fa-fighter-jet" />
    <Icon iconName="fa-file" />
    <Icon iconName="fa-file-archive-o" />
    <Icon iconName="fa-file-audio-o" />
    <Icon iconName="fa-file-code-o" />
    <Icon iconName="fa-file-excel-o" />
    <Icon iconName="fa-file-image-o" />
    <Icon iconName="fa-file-movie-o" />
    <Icon iconName="fa-file-o" />
    <Icon iconName="fa-file-pdf-o" />
    <Icon iconName="fa-file-photo-o" />
    <Icon iconName="fa-file-picture-o" />
    <Icon iconName="fa-file-powerpoint-o" />
    <Icon iconName="fa-file-sound-o" />
    <Icon iconName="fa-file-text" />
    <Icon iconName="fa-file-text-o" />
    <Icon iconName="fa-file-video-o" />
    <Icon iconName="fa-file-word-o" />
    <Icon iconName="fa-file-zip-o" />
    <Icon iconName="fa-files-o" />
    <Icon iconName="fa-film" />
    <Icon iconName="fa-filter" />
    <Icon iconName="fa-fire" />
    <Icon iconName="fa-fire-extinguisher" />
    <Icon iconName="fa-firefox" />
    <Icon iconName="fa-first-order" />
    <Icon iconName="fa-flag" />
    <Icon iconName="fa-flag-checkered" />
    <Icon iconName="fa-flag-o" />
    <Icon iconName="fa-flash" />
    <Icon iconName="fa-flask" />
    <Icon iconName="fa-flickr" />
    <Icon iconName="fa-floppy-o" />
    <Icon iconName="fa-folder" />
    <Icon iconName="fa-folder-o" />
    <Icon iconName="fa-folder-open" />
    <Icon iconName="fa-folder-open-o" />
    <Icon iconName="fa-font" />
    <Icon iconName="fa-font-awesome" />
    <Icon iconName="fa-fonticons" />
    <Icon iconName="fa-fort-awesome" />
    <Icon iconName="fa-forumbee" />
    <Icon iconName="fa-forward" />
    <Icon iconName="fa-foursquare" />
    <Icon iconName="fa-free-code-camp" />
    <Icon iconName="fa-frown-o" />
    <Icon iconName="fa-futbol-o" />
    <Icon iconName="fa-gamepad" />
    <Icon iconName="fa-gavel" />
    <Icon iconName="fa-gbp" />
    <Icon iconName="fa-ge" />
    <Icon iconName="fa-gear" />
    <Icon iconName="fa-gears" />
    <Icon iconName="fa-genderless" />
    <Icon iconName="fa-get-pocket" />
    <Icon iconName="fa-gg" />
    <Icon iconName="fa-gg-circle" />
    <Icon iconName="fa-gift" />
    <Icon iconName="fa-git" />
    <Icon iconName="fa-git-square" />
    <Icon iconName="fa-github" />
    <Icon iconName="fa-github-alt" />
    <Icon iconName="fa-github-square" />
    <Icon iconName="fa-gitlab" />
    <Icon iconName="fa-gittip" />
    <Icon iconName="fa-glass" />
    <Icon iconName="fa-glide" />
    <Icon iconName="fa-glide-g" />
    <Icon iconName="fa-globe" />
    <Icon iconName="fa-google" />
    <Icon iconName="fa-google-plus" />
    <Icon iconName="fa-google-plus-circle" />
    <Icon iconName="fa-google-plus-official" />
    <Icon iconName="fa-google-plus-square" />
    <Icon iconName="fa-google-wallet" />
    <Icon iconName="fa-graduation-cap" />
    <Icon iconName="fa-gratipay" />
    <Icon iconName="fa-grav" />
    <Icon iconName="fa-group" />
    <Icon iconName="fa-h-square" />
    <Icon iconName="fa-hacker-news" />
    <Icon iconName="fa-hand-grab-o" />
    <Icon iconName="fa-hand-lizard-o" />
    <Icon iconName="fa-hand-o-down" />
    <Icon iconName="fa-hand-o-left" />
    <Icon iconName="fa-hand-o-right" />
    <Icon iconName="fa-hand-o-up" />
    <Icon iconName="fa-hand-paper-o" />
    <Icon iconName="fa-hand-peace-o" />
    <Icon iconName="fa-hand-pointer-o" />
    <Icon iconName="fa-hand-rock-o" />
    <Icon iconName="fa-hand-scissors-o" />
    <Icon iconName="fa-hand-spock-o" />
    <Icon iconName="fa-hand-stop-o" />
    <Icon iconName="fa-handshake-o" />
    <Icon iconName="fa-hard-of-hearing" />
    <Icon iconName="fa-hashtag" />
    <Icon iconName="fa-hdd-o" />
    <Icon iconName="fa-header" />
    <Icon iconName="fa-headphones" />
    <Icon iconName="fa-heart" />
    <Icon iconName="fa-heart-o" />
    <Icon iconName="fa-heartbeat" />
    <Icon iconName="fa-history" />
    <Icon iconName="fa-home" />
    <Icon iconName="fa-hospital-o" />
    <Icon iconName="fa-hotel" />
    <Icon iconName="fa-hourglass" />
    <Icon iconName="fa-hourglass-1" />
    <Icon iconName="fa-hourglass-2" />
    <Icon iconName="fa-hourglass-3" />
    <Icon iconName="fa-hourglass-end" />
    <Icon iconName="fa-hourglass-half" />
    <Icon iconName="fa-hourglass-o" />
    <Icon iconName="fa-hourglass-start" />
    <Icon iconName="fa-houzz" />
    <Icon iconName="fa-html5" />
    <Icon iconName="fa-i-cursor" />
    <Icon iconName="fa-id-badge" />
    <Icon iconName="fa-id-card" />
    <Icon iconName="fa-id-card-o" />
    <Icon iconName="fa-ils" />
    <Icon iconName="fa-image" />
    <Icon iconName="fa-imdb" />
    <Icon iconName="fa-inbox" />
    <Icon iconName="fa-indent" />
    <Icon iconName="fa-industry" />
    <Icon iconName="fa-info" />
    <Icon iconName="fa-info-circle" />
    <Icon iconName="fa-inr" />
    <Icon iconName="fa-instagram" />
    <Icon iconName="fa-institution" />
    <Icon iconName="fa-internet-explorer" />
    <Icon iconName="fa-intersex" />
    <Icon iconName="fa-ioxhost" />
    <Icon iconName="fa-italic" />
    <Icon iconName="fa-joomla" />
    <Icon iconName="fa-jpy" />
    <Icon iconName="fa-jsfiddle" />
    <Icon iconName="fa-key" />
    <Icon iconName="fa-keyboard-o" />
    <Icon iconName="fa-krw" />
    <Icon iconName="fa-language" />
    <Icon iconName="fa-laptop" />
    <Icon iconName="fa-lastfm" />
    <Icon iconName="fa-lastfm-square" />
    <Icon iconName="fa-leaf" />
    <Icon iconName="fa-leanpub" />
    <Icon iconName="fa-legal" />
    <Icon iconName="fa-lemon-o" />
    <Icon iconName="fa-level-down" />
    <Icon iconName="fa-level-up" />
    <Icon iconName="fa-life-bouy" />
    <Icon iconName="fa-life-buoy" />
    <Icon iconName="fa-life-ring" />
    <Icon iconName="fa-life-saver" />
    <Icon iconName="fa-lightbulb-o" />
    <Icon iconName="fa-line-chart" />
    <Icon iconName="fa-link" />
    <Icon iconName="fa-linkedin" />
    <Icon iconName="fa-linkedin-square" />
    <Icon iconName="fa-linode" />
    <Icon iconName="fa-linux" />
    <Icon iconName="fa-list" />
    <Icon iconName="fa-list-alt" />
    <Icon iconName="fa-list-ol" />
    <Icon iconName="fa-list-ul" />
    <Icon iconName="fa-location-arrow" />
    <Icon iconName="fa-lock" />
    <Icon iconName="fa-long-arrow-down" />
    <Icon iconName="fa-long-arrow-left" />
    <Icon iconName="fa-long-arrow-right" />
    <Icon iconName="fa-long-arrow-up" />
    <Icon iconName="fa-low-vision" />
    <Icon iconName="fa-magic" />
    <Icon iconName="fa-magnet" />
    <Icon iconName="fa-mail-forward" />
    <Icon iconName="fa-mail-reply" />
    <Icon iconName="fa-mail-reply-all" />
    <Icon iconName="fa-male" />
    <Icon iconName="fa-map" />
    <Icon iconName="fa-map-marker" />
    <Icon iconName="fa-map-o" />
    <Icon iconName="fa-map-pin" />
    <Icon iconName="fa-map-signs" />
    <Icon iconName="fa-mars" />
    <Icon iconName="fa-mars-double" />
    <Icon iconName="fa-mars-stroke" />
    <Icon iconName="fa-mars-stroke-h" />
    <Icon iconName="fa-mars-stroke-v" />
    <Icon iconName="fa-maxcdn" />
    <Icon iconName="fa-meanpath" />
    <Icon iconName="fa-medium" />
    <Icon iconName="fa-medkit" />
    <Icon iconName="fa-meetup" />
    <Icon iconName="fa-meh-o" />
    <Icon iconName="fa-mercury" />
    <Icon iconName="fa-microchip" />
    <Icon iconName="fa-microphone" />
    <Icon iconName="fa-microphone-slash" />
    <Icon iconName="fa-minus" />
    <Icon iconName="fa-minus-circle" />
    <Icon iconName="fa-minus-square" />
    <Icon iconName="fa-minus-square-o" />
    <Icon iconName="fa-mixcloud" />
    <Icon iconName="fa-mobile" />
    <Icon iconName="fa-mobile-phone" />
    <Icon iconName="fa-modx" />
    <Icon iconName="fa-money" />
    <Icon iconName="fa-moon-o" />
    <Icon iconName="fa-mortar-board" />
    <Icon iconName="fa-motorcycle" />
    <Icon iconName="fa-mouse-pointer" />
    <Icon iconName="fa-music" />
    <Icon iconName="fa-navicon" />
    <Icon iconName="fa-neuter" />
    <Icon iconName="fa-newspaper-o" />
    <Icon iconName="fa-object-group" />
    <Icon iconName="fa-object-ungroup" />
    <Icon iconName="fa-odnoklassniki" />
    <Icon iconName="fa-odnoklassniki-square" />
    <Icon iconName="fa-opencart" />
    <Icon iconName="fa-openid" />
    <Icon iconName="fa-opera" />
    <Icon iconName="fa-optin-monster" />
    <Icon iconName="fa-outdent" />
    <Icon iconName="fa-pagelines" />
    <Icon iconName="fa-paint-brush" />
    <Icon iconName="fa-paper-plane" />
    <Icon iconName="fa-paper-plane-o" />
    <Icon iconName="fa-paperclip" />
    <Icon iconName="fa-paragraph" />
    <Icon iconName="fa-paste" />
    <Icon iconName="fa-pause" />
    <Icon iconName="fa-pause-circle" />
    <Icon iconName="fa-pause-circle-o" />
    <Icon iconName="fa-paw" />
    <Icon iconName="fa-paypal" />
    <Icon iconName="fa-pencil" />
    <Icon iconName="fa-pencil-square" />
    <Icon iconName="fa-pencil-square-o" />
    <Icon iconName="fa-percent" />
    <Icon iconName="fa-phone" />
    <Icon iconName="fa-phone-square" />
    <Icon iconName="fa-photo" />
    <Icon iconName="fa-picture-o" />
    <Icon iconName="fa-pie-chart" />
    <Icon iconName="fa-pied-piper" />
    <Icon iconName="fa-pied-piper-alt" />
    <Icon iconName="fa-pied-piper-pp" />
    <Icon iconName="fa-pinterest" />
    <Icon iconName="fa-pinterest-p" />
    <Icon iconName="fa-pinterest-square" />
    <Icon iconName="fa-plane" />
    <Icon iconName="fa-play" />
    <Icon iconName="fa-play-circle" />
    <Icon iconName="fa-play-circle-o" />
    <Icon iconName="fa-plug" />
    <Icon iconName="fa-plus" />
    <Icon iconName="fa-plus-circle" />
    <Icon iconName="fa-plus-square" />
    <Icon iconName="fa-plus-square-o" />
    <Icon iconName="fa-podcast" />
    <Icon iconName="fa-power-off" />
    <Icon iconName="fa-print" />
    <Icon iconName="fa-product-hunt" />
    <Icon iconName="fa-puzzle-piece" />
    <Icon iconName="fa-qq" />
    <Icon iconName="fa-qrcode" />
    <Icon iconName="fa-question" />
    <Icon iconName="fa-question-circle" />
    <Icon iconName="fa-question-circle-o" />
    <Icon iconName="fa-quora" />
    <Icon iconName="fa-quote-left" />
    <Icon iconName="fa-quote-right" />
    <Icon iconName="fa-ra" />
    <Icon iconName="fa-random" />
    <Icon iconName="fa-ravelry" />
    <Icon iconName="fa-rebel" />
    <Icon iconName="fa-recycle" />
    <Icon iconName="fa-reddit" />
    <Icon iconName="fa-reddit-alien" />
    <Icon iconName="fa-reddit-square" />
    <Icon iconName="fa-refresh" />
    <Icon iconName="fa-registered" />
    <Icon iconName="fa-remove" />
    <Icon iconName="fa-renren" />
    <Icon iconName="fa-reorder" />
    <Icon iconName="fa-repeat" />
    <Icon iconName="fa-reply" />
    <Icon iconName="fa-reply-all" />
    <Icon iconName="fa-resistance" />
    <Icon iconName="fa-retweet" />
    <Icon iconName="fa-rmb" />
    <Icon iconName="fa-road" />
    <Icon iconName="fa-rocket" />
    <Icon iconName="fa-rotate-left" />
    <Icon iconName="fa-rotate-right" />
    <Icon iconName="fa-rouble" />
    <Icon iconName="fa-rss" />
    <Icon iconName="fa-rss-square" />
    <Icon iconName="fa-rub" />
    <Icon iconName="fa-ruble" />
    <Icon iconName="fa-rupee" />
    <Icon iconName="fa-s15" />
    <Icon iconName="fa-safari" />
    <Icon iconName="fa-save" />
    <Icon iconName="fa-scissors" />
    <Icon iconName="fa-scribd" />
    <Icon iconName="fa-search" />
    <Icon iconName="fa-search-minus" />
    <Icon iconName="fa-search-plus" />
    <Icon iconName="fa-sellsy" />
    <Icon iconName="fa-send" />
    <Icon iconName="fa-send-o" />
    <Icon iconName="fa-server" />
    <Icon iconName="fa-share" />
    <Icon iconName="fa-share-alt" />
    <Icon iconName="fa-share-alt-square" />
    <Icon iconName="fa-share-square" />
    <Icon iconName="fa-share-square-o" />
    <Icon iconName="fa-shekel" />
    <Icon iconName="fa-sheqel" />
    <Icon iconName="fa-shield" />
    <Icon iconName="fa-ship" />
    <Icon iconName="fa-shirtsinbulk" />
    <Icon iconName="fa-shopping-bag" />
    <Icon iconName="fa-shopping-basket" />
    <Icon iconName="fa-shopping-cart" />
    <Icon iconName="fa-shower" />
    <Icon iconName="fa-sign-in" />
    <Icon iconName="fa-sign-language" />
    <Icon iconName="fa-sign-out" />
    <Icon iconName="fa-signal" />
    <Icon iconName="fa-signing" />
    <Icon iconName="fa-simplybuilt" />
    <Icon iconName="fa-sitemap" />
    <Icon iconName="fa-skyatlas" />
    <Icon iconName="fa-skype" />
    <Icon iconName="fa-slack" />
    <Icon iconName="fa-sliders" />
    <Icon iconName="fa-slideshare" />
    <Icon iconName="fa-smile-o" />
    <Icon iconName="fa-snapchat" />
    <Icon iconName="fa-snapchat-ghost" />
    <Icon iconName="fa-snapchat-square" />
    <Icon iconName="fa-snowflake-o" />
    <Icon iconName="fa-soccer-ball-o" />
    <Icon iconName="fa-sort" />
    <Icon iconName="fa-sort-alpha-asc" />
    <Icon iconName="fa-sort-alpha-desc" />
    <Icon iconName="fa-sort-amount-asc" />
    <Icon iconName="fa-sort-amount-desc" />
    <Icon iconName="fa-sort-asc" />
    <Icon iconName="fa-sort-desc" />
    <Icon iconName="fa-sort-down" />
    <Icon iconName="fa-sort-numeric-asc" />
    <Icon iconName="fa-sort-numeric-desc" />
    <Icon iconName="fa-sort-up" />
    <Icon iconName="fa-soundcloud" />
    <Icon iconName="fa-space-shuttle" />
    <Icon iconName="fa-spinner" />
    <Icon iconName="fa-spoon" />
    <Icon iconName="fa-spotify" />
    <Icon iconName="fa-square" />
    <Icon iconName="fa-square-o" />
    <Icon iconName="fa-stack-exchange" />
    <Icon iconName="fa-stack-overflow" />
    <Icon iconName="fa-star" />
    <Icon iconName="fa-star-half" />
    <Icon iconName="fa-star-half-empty" />
    <Icon iconName="fa-star-half-full" />
    <Icon iconName="fa-star-half-o" />
    <Icon iconName="fa-star-o" />
    <Icon iconName="fa-steam" />
    <Icon iconName="fa-steam-square" />
    <Icon iconName="fa-step-backward" />
    <Icon iconName="fa-step-forward" />
    <Icon iconName="fa-stethoscope" />
    <Icon iconName="fa-sticky-note" />
    <Icon iconName="fa-sticky-note-o" />
    <Icon iconName="fa-stop" />
    <Icon iconName="fa-stop-circle" />
    <Icon iconName="fa-stop-circle-o" />
    <Icon iconName="fa-street-view" />
    <Icon iconName="fa-strikethrough" />
    <Icon iconName="fa-stumbleupon" />
    <Icon iconName="fa-stumbleupon-circle" />
    <Icon iconName="fa-subscript" />
    <Icon iconName="fa-subway" />
    <Icon iconName="fa-suitcase" />
    <Icon iconName="fa-sun-o" />
    <Icon iconName="fa-superpowers" />
    <Icon iconName="fa-superscript" />
    <Icon iconName="fa-support" />
    <Icon iconName="fa-table" />
    <Icon iconName="fa-tablet" />
    <Icon iconName="fa-tachometer" />
    <Icon iconName="fa-tag" />
    <Icon iconName="fa-tags" />
    <Icon iconName="fa-tasks" />
    <Icon iconName="fa-taxi" />
    <Icon iconName="fa-telegram" />
    <Icon iconName="fa-television" />
    <Icon iconName="fa-tencent-weibo" />
    <Icon iconName="fa-terminal" />
    <Icon iconName="fa-text-height" />
    <Icon iconName="fa-text-width" />
    <Icon iconName="fa-th" />
    <Icon iconName="fa-th-large" />
    <Icon iconName="fa-th-list" />
    <Icon iconName="fa-themeisle" />
    <Icon iconName="fa-thermometer" />
    <Icon iconName="fa-thermometer-0" />
    <Icon iconName="fa-thermometer-1" />
    <Icon iconName="fa-thermometer-2" />
    <Icon iconName="fa-thermometer-3" />
    <Icon iconName="fa-thermometer-4" />
    <Icon iconName="fa-thermometer-empty" />
    <Icon iconName="fa-thermometer-full" />
    <Icon iconName="fa-thermometer-half" />
    <Icon iconName="fa-thermometer-quarter" />
    <Icon iconName="fa-thermometer-three-quarters" />
    <Icon iconName="fa-thumb-tack" />
    <Icon iconName="fa-thumbs-down" />
    <Icon iconName="fa-thumbs-o-down" />
    <Icon iconName="fa-thumbs-o-up" />
    <Icon iconName="fa-thumbs-up" />
    <Icon iconName="fa-ticket" />
    <Icon iconName="fa-times" />
    <Icon iconName="fa-times-circle" />
    <Icon iconName="fa-times-circle-o" />
    <Icon iconName="fa-times-rectangle" />
    <Icon iconName="fa-times-rectangle-o" />
    <Icon iconName="fa-tint" />
    <Icon iconName="fa-toggle-down" />
    <Icon iconName="fa-toggle-left" />
    <Icon iconName="fa-toggle-off" />
    <Icon iconName="fa-toggle-on" />
    <Icon iconName="fa-toggle-right" />
    <Icon iconName="fa-toggle-up" />
    <Icon iconName="fa-trademark" />
    <Icon iconName="fa-train" />
    <Icon iconName="fa-transgender" />
    <Icon iconName="fa-transgender-alt" />
    <Icon iconName="fa-trash" />
    <Icon iconName="fa-trash-o" />
    <Icon iconName="fa-tree" />
    <Icon iconName="fa-trello" />
    <Icon iconName="fa-tripadvisor" />
    <Icon iconName="fa-trophy" />
    <Icon iconName="fa-truck" />
    <Icon iconName="fa-try" />
    <Icon iconName="fa-tty" />
    <Icon iconName="fa-tumblr" />
    <Icon iconName="fa-tumblr-square" />
    <Icon iconName="fa-turkish-lira" />
    <Icon iconName="fa-tv" />
    <Icon iconName="fa-twitch" />
    <Icon iconName="fa-twitter" />
    <Icon iconName="fa-twitter-square" />
    <Icon iconName="fa-umbrella" />
    <Icon iconName="fa-underline" />
    <Icon iconName="fa-undo" />
    <Icon iconName="fa-universal-access" />
    <Icon iconName="fa-university" />
    <Icon iconName="fa-unlink" />
    <Icon iconName="fa-unlock" />
    <Icon iconName="fa-unlock-alt" />
    <Icon iconName="fa-unsorted" />
    <Icon iconName="fa-upload" />
    <Icon iconName="fa-usb" />
    <Icon iconName="fa-usd" />
    <Icon iconName="fa-user" />
    <Icon iconName="fa-user-circle" />
    <Icon iconName="fa-user-circle-o" />
    <Icon iconName="fa-user-md" />
    <Icon iconName="fa-user-o" />
    <Icon iconName="fa-user-plus" />
    <Icon iconName="fa-user-secret" />
    <Icon iconName="fa-user-times" />
    <Icon iconName="fa-users" />
    <Icon iconName="fa-vcard" />
    <Icon iconName="fa-vcard-o" />
    <Icon iconName="fa-venus" />
    <Icon iconName="fa-venus-double" />
    <Icon iconName="fa-venus-mars" />
    <Icon iconName="fa-viacoin" />
    <Icon iconName="fa-viadeo" />
    <Icon iconName="fa-viadeo-square" />
    <Icon iconName="fa-video-camera" />
    <Icon iconName="fa-vimeo" />
    <Icon iconName="fa-vimeo-square" />
    <Icon iconName="fa-vine" />
    <Icon iconName="fa-vk" />
    <Icon iconName="fa-volume-control-phone" />
    <Icon iconName="fa-volume-down" />
    <Icon iconName="fa-volume-off" />
    <Icon iconName="fa-volume-up" />
    <Icon iconName="fa-warning" />
    <Icon iconName="fa-wechat" />
    <Icon iconName="fa-weibo" />
    <Icon iconName="fa-weixin" />
    <Icon iconName="fa-whatsapp" />
    <Icon iconName="fa-wheelchair" />
    <Icon iconName="fa-wheelchair-alt" />
    <Icon iconName="fa-wifi" />
    <Icon iconName="fa-wikipedia-w" />
    <Icon iconName="fa-window-close" />
    <Icon iconName="fa-window-close-o" />
    <Icon iconName="fa-window-maximize" />
    <Icon iconName="fa-window-minimize" />
    <Icon iconName="fa-window-restore" />
    <Icon iconName="fa-windows" />
    <Icon iconName="fa-won" />
    <Icon iconName="fa-wordpress" />
    <Icon iconName="fa-wpbeginner" />
    <Icon iconName="fa-wpexplorer" />
    <Icon iconName="fa-wpforms" />
    <Icon iconName="fa-wrench" />
    <Icon iconName="fa-xing" />
    <Icon iconName="fa-xing-square" />
    <Icon iconName="fa-y-combinator" />
    <Icon iconName="fa-y-combinator-square" />
    <Icon iconName="fa-yahoo" />
    <Icon iconName="fa-yc" />
    <Icon iconName="fa-yc-square" />
    <Icon iconName="fa-yelp" />
    <Icon iconName="fa-yen" />
    <Icon iconName="fa-yoast" />
    <Icon iconName="fa-youtube" />
    <Icon iconName="fa-youtube-play" />
    <Icon iconName="fa-youtube-square" />
    </div>
    </div>
  </div>;