import * as Constants from './constants';
import {v4} from 'uuid';
// import { API, Storage } from 'aws-amplify';

import { arrayPush } from 'redux-form';

export const listingsSuccess = (results) => ({
  type: Constants.LISTINGS_SUCCESS,
  ...results,
});

// 'listing-form'
export const listingImageStored = (storageKey) => ({
  type: Constants.LISTING_IMAGE_STORED,
  storageKey,
});

export const putListingImage = (file) => (dispatch) => {
  var matched = file.name.match(/\.(png|jpg|gif|jpeg)$/)
  console.log("dropzone", file.name);      

  if (matched) {
    var name = `${v4()}${matched[0]}`;
    console.log("uploading", file.name, name);
    Storage.put(name, file).then (storageKey => {
      console.log("success", storageKey);
      dispatch(arrayPush('listing-form', 'images', storageKey.key));
      dispatch(listingImageStored(storageKey))
    }).catch(err => {
      console.log("error", err);
    });
  }
};

export const saveUserListing = (listing) => ({
  type: Constants.SAVE_USER_LISTING,
  // console.log('save user listing');
  // firebase.firestore().collection('listings').doc().set({ test: 'hello'}).then(result => {
  //   console.log("success", result);
  // }).catch(error => {
  //   console.log("error", error);
  // })
});


// export const SAVE_USER_LISTING = '/listings/SAVE_USER_LISTING';
// export const SAVE_USER_LISTINGS_SUCCES = '/listings/SAVE_USER_LISTING_SUCCESS';
// export const SAVE_USER_LISTINGS_ERRO = '/listings/SAVE_USER_LISTING_ERROR';