import React from 'react';

const PhoneNumber = ({number, className}) => {

  if (number && number.length === 10) {
    return (
      <span className={className}>
        ({number.substring(0, 3)}) {number.substring(3, 6)}-{number.substring(6, 10)}
      </span>
    );
  }

  return <span>{number}</span>

};

export default PhoneNumber;