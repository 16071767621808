import React from 'react';
import { Link } from 'react-router-dom';

export default ({ placeType, children, id, placeData }) => {
  let path = `/place/${id}`;

  return (
    <Link to={{
      pathname: path,
      state: placeData
      }}>
      { children }
    </Link>
  )

};
  