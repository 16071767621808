// import mixpanel from 'mixpanel-browser';

// mixpanel.init('2b7ae5ba7db9968a374cd80be77ee2de');
const env_check = process.env.NODE_ENV === 'production';

export default (event) => {  
  if (env_check) {  
    // mixpanel.track(event);
  } else {
    // console.log('mixpanel', event);
  }
}

