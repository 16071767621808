import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';

export default ({ readsRegion, readsRegionCity, deleteReadsCity, saveReadsCity }) => {  
  const { register, handleSubmit, formState: { errors } } = useForm();

  var name = get(readsRegionCity, 'name'); 
  var region = get(readsRegionCity, 'region');
  var group = get(readsRegionCity, 'group');

  return (    
      <form onSubmit={handleSubmit(saveReadsCity)}>  
        <div className="row">
          <div className="col-md-12">
            <input className="form-control w-100" name="name" defaultValue={name} {...register( 'name', { required: true })} placeholder="Name" />
            {errors.name && <span style={{ fontSize: '0.6em', color: 'red' }}>Required</span>}
          </div>
        </div>
        <   div className="row">
        <div className="col-md-12">
            <input className="form-control w-100" name="region" defaultValue={region} {...register( 'region', { required: true })} placeholder="Region" />
            {errors.name && <span style={{ fontSize: '0.6em', color: 'red' }}>Required</span>}
          </div>
    </div>
    <div className="row">
    <div className="col-md-12">
            <input className="form-control w-100" name="group" defaultValue={group} {...register( 'group', {})} placeholder="Group" />
            {errors.name && <span style={{ fontSize: '0.6em', color: 'red' }}></span>}
          </div>
    </div>
        <div className="row mt-2">
          <div className="col-md-12">     
            <button type="button" className="btn btn-danger pull-left" onClick={() => deleteReadsCity()}>
              <i className="fa fa-trash" />
            </button>       

            <button className="btn btn-primary pull-right" type="submit">
              Save
            </button>

            <Link to={`/reads-cities/${readsRegion.objectID}`} className="btn btn-outline-primary pull-right mr-1">Cancel</Link>
          </div>
        </div>
      </form>
  );
}  