import * as ActionsConstants from '../actions/constants';
import * as PlanningConstants from '../planning/constants';

const toggleLocationFavorite = (state, action) => {
  
  var item = state.plans[action.planId];
  // console.log("toggle location favorites", action.planId, action.locationId, item);

  var index = item.favorites.indexOf(action.locationId);
  if (index >= 0) {
    // console.log("removing location favorite");

    return {
      ...state,
      plans: {
        ...state.plans,
        [action.planId]: {
          ...item,
          favorites: [...item.favorites.slice(0, index), ...item.favorites.slice(index + 1)],
        }
      }
    };
  } else { // add location
    // console.log("adding location favorite");
    return {
      ...state,
      plans: {
        ...state.plans,
        [action.planId]: {
          ...item,
          favorites: [
            ...item.favorites,
            action.locationId,
          ]
        }
      }
    };
  }

  // return state;
}

export default (state={}, action) => {
  switch (action.type) {
    case ActionsConstants.GET_COLLECTION_SUCCESS:
      return { ...state, [action.collection]: action.items };
    case ActionsConstants.GET_DOCUMENT_SUCCESS:
      return { ...state, [action.collection]: { ...state[action.collection], [action.id]: action.item } };
    case PlanningConstants.PLANNING_TOGGLE_LOCATION_FAVORITE:
      return toggleLocationFavorite(state, action);
    default: return state;
  }
}