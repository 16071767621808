import React, {useState} from 'react';
import { connectHits } from 'react-instantsearch-dom';
import {deleteLocationReport} from '../../firebase';
import IDCheck from '../../shared/IDCheck';

function ReportCards ({ hits, refresh, isDeleting, setIsDeleting }) {
    
   return (
    <div>
        <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Location Name</th>
            <th scope="col">Type</th>
            <th scope="col">Location ID</th>
            <th scope="col">Time</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
          <tbody>
            {hits.map( (hit) => (
            <tr key={hits.objectID}>
              <td>{hit.name}</td>
              
              <td>{hit.type.toString()}</td>
              
              <td>{hit.locationID}</td>
              
              <td>{new Date(hit.createdAt).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })}</td>

              <td>{
                  <button type="button" disabled={isDeleting} className="btn btn-danger pull-left" onClick={ async () => { 
                    setIsDeleting(true);
                    await deleteLocationReport({objectID: hit.objectID});
                    setTimeout(function(){
                      setIsDeleting(false);
                      refresh();
                    },10000);

                    
                    
                   }}>
                    <i className="fa fa-trash" />
                  </button>
              }</td>
              
            </tr>
            
            ))}
          </tbody>
        </table>
    </div>
);
    
}

export default connectHits(ReportCards);

{/* <li className="list-inline-item">
                <div className="card border-dark mb-3" style={{width: "22rem", height: "14rem"}}>
                  <p className="text-left my-0 py-0">{(index + 1)}</p>
                    <div className="card-header bg-transparent">
                        <h5 className="card-title">{"email: " + hit.email}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text font-weight-bold h5">{"Activity: " + hit.activity}</p>
                        {
                          hit.searchTerm &&
                          <p className="card-text">{"Search Term: " + hit.searchTerm}</p>
                        }
                        {
                          hit.locationName &&
                          <p className="card-text">{"Location Name: " + hit.locationName}</p>
                        }
                    </div>
                    <div className="card-footer my-0 py-0">
                      <small className="text-muted">{"id: " + hit.objectID + " "}</small>
                      {
                        hit.locationID &&
                        <small className="text-muted">{"LocationID: " + hit.locationID}</small>
                      }
                    </div>
                </div>
            </li> */}