import React from 'react';
import classNames from 'classnames';
import { connectPagination } from 'react-instantsearch-dom';

import './location-pagination.css';

const LocationPagination = ({ currentRefinement, nbPages, createURL, setSearchPage, refine, pageHasChanged }) => {
  if (nbPages <= 1) {
    return <span />
  }
  console.log(nbPages);
  return (
      <ul style={{ paddingLeft: 0 }}>
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;

          return (
            <li key={index} style={{ display: 'inline', padding: '2px', fontSize: '0.8em' }}>
              <button
                className={classNames('btn btn-link', { 'selected-page': currentRefinement === page })}
                style={{ padding: 0 }}
                onClick={event => {
                  event.preventDefault();                  
                  setSearchPage(page);
                  refine(page);
                  pageHasChanged();
                }}
              >
                {page}
              </button>
            </li>
          );
        })}
      </ul>
    );
  }

export default connectPagination(LocationPagination);