import { GET_USER_LISTINGS } from '../constants';
import {  takeEvery } from 'redux-saga/effects'

// const createListing = (values) => firebase.firestore().collection('listings').doc().set(values);

function* getUserListings(action) {
   // try {      
      // const user = yield call(createListing, { test: 'dddddd'});
      //yield put({type: "USER_FETCH_SUCCEEDED", user: user}); GET_USER_LISTINGS_SUCCESS
   // } catch (e) {
      // yield put({type: "USER_FETCH_FAILED", message: e.message}); GET_USER_LISTINGS_ERROR
   // }
}

export default function* () {
  yield takeEvery(GET_USER_LISTINGS, getUserListings);
}