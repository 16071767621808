import React from 'react';

import { TripGalaxyMapContainer } from '../map';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
  return {
    tab: ownProps.match.params.tab,
  };
}; 

const PointOfInterestMap = ({ tab }) =>
  <div className="container">
    <div className="w-100 mt-3 mb-3">
      <TripGalaxyMapContainer width={800} height={400} lat={38.5321881} lng={-121.4863512} />    
    </div>
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <Link className={classNames("nav-link", {"active": tab ==="listings"})} to="/points-of-interest/map/listings" replace>Listings</Link>
      </li>
      <li className="nav-item">
      <Link className={classNames("nav-link", {"active": tab ==="services"})} to="/points-of-interest/map/services" replace>Services</Link>
      </li>
      <li className="nav-item">
        <Link className={classNames("nav-link", {"active": tab ==="attractions"})} to="/points-of-interest/map/attractions" replace>Attractions</Link>
      </li>
    </ul>
  </div>

export default withRouter(connect(
  mapStateToProps,
)(PointOfInterestMap));