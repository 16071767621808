import React from 'react';
import { Route, Switch } from 'react-router';

import VacationRentals from './containers/vacation-rentals';
import VacationRental from './containers/vacation-rental';

export default () =>
  <Switch>
    <Route exact path="/vacation-rentals">
      <VacationRentals />
    </Route>

    <Route exact path="/vacation-rental">
      <VacationRental />
    </Route>
  </Switch>;