import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '../../firebase';
import browserImageSize from 'browser-image-size';
import { invoke, deleteLocation, analytics, addActivity } from '../../firebase';
import { copy, trimLocationValues } from '../shared-functions';
import { getUID } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';
import findIndex from 'lodash/findIndex';

import firebase from "firebase/compat/app";
import 'firebase/storage';

import {v4} from 'uuid';

// import { app } from '../../firebase';
  

import { archiveLocation } from '../actions';

import LocationForm from './location-form';
import { setScratchPadValue } from '../../session/actions';

export default ({ hit, setEditing, itemUpdated, refresh, uid, user }) => {
  //const locationCategories = useSelector(state => state.locationCategories);
  // const images = useSelector(state => state.images);
  const [images, setImages] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [item, setItem] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  //const []
  const [locationCategories, setLocationCategories] = useState([]);

  
  useEffect(() => {
    console.log('--------', hit.objectID);
      
      app.firestore().collection("locations").doc(hit.objectID).get()
      .then((ref) => {
        console.log('success', ref.data());
        setItem(ref.data());
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        setItem();
      });
      
  }, []);

  useEffect(() => {
    // collection('locationCategories', { field: 'parentID', op: '==', val: '', orderBy: 'name' }).then(results => {

    let locationCategoriesAndSubCategories = [];
    refresh();
    invoke('locationCategories').then((results) => {
      console.log(results);
      results.data.forEach(locationCategory => {
        locationCategoriesAndSubCategories.push(locationCategory);

        if (locationCategory.children) {
          locationCategory.children.forEach(child => {
            locationCategoriesAndSubCategories.push({ ...child, name: `${locationCategory.name} > ${child.name}` });
          });
        }
      })

      setLocationCategories(locationCategoriesAndSubCategories);
    });
  }, []);

  
  const setImagesFromForm = (localImages) => {
    console.log("set images", localImages);
    var localSizes = [];
    if (localImages.length > 0) {
      browserImageSize(localImages[0]).then((size) => {
        console.log("size", size);
        localSizes.push(size);
        // this.setState({ ...this.state, images, sizes });
        setImages(localImages);
        setSizes(localSizes);
      })
    } else {
      //this.setState({ ...this.state, images, sizes });
      console.log("Local image length not greater than zero");
      setImages([]);
      setSizes([]);
    }
  }

  const copyLocation = () => {
    copy(this.props);
  }

  const setSetUpdatedAndRefresh = () => {
    itemUpdated(true);
    setEditing(false);
    refresh();
  }

  const saveLocationAndUploadImages = async (localValues) => {
    console.log('saving location', localValues);

    localValues = trimLocationValues(localValues);
    
    //this.setState({ ...this.state, isSubmitting: true });
    setSubmitting(true);

    for (let i = 0; i < localValues.categories.length; i++) {
      let index = findIndex(locationCategories, function(category) { return category.objectID === localValues.categories[i]});
      if (index === -1) {
        console.log(localValues.categories[i], ' category doesnt exist in categories anymore');
        localValues.categories.splice(i, 1);
        i--;
      } else {
        console.log(localValues.categories[i], ' category exists');
      }
    }
    console.log('saving location after category check', localValues);
    var saved = false; 

    if (localValues._geoloc && (typeof localValues._geoloc.lat === 'string' || localValues._geoloc.lat instanceof String)) {
      let lat = parseFloat(localValues._geoloc.lat);
      let lng = parseFloat(localValues._geoloc.lng);
      localValues._geoloc.lat = lat;
      localValues._geoloc.lng = lng;
    }

    /*if (localValues.tags) {
      if (typeof localValues.tags == 'string') {
        localValues.tags = localValues.tags ? localValues.tags.split(', ') : [];
      }

    } else {
      localValues.tags = [];
    }*/
    
  
    var callable = app.functions().httpsCallable('saveLocation');
    let locationID = null;

    if (hit && hit.objectID) {
      locationID = hit.objectID;
    }

    analytics.logEvent('location_edited', {
      userID: uid,
      locationName: localValues.name,
      locationID: locationID
    });

    let activity = {
      email: user.email,
      locationName: localValues.name,
      locationID: locationID,
      activity: 'locationEdit'
    }

    await addActivity(activity);

    if (images && images.length > 0) {
      let file = images[0];
      const uuid = v4();

      var matched = file.name.match(/\.(png|jpg|gif|jpeg)$/)
      if (matched) {
        var name = `${uuid}${matched[0]}`;
        console.log(name);
        saved = true;

        var ref = firebase.storage().ref();
        var child = ref.child(name);
        child.put(file).then(async (snapshot) => {
          localValues.imageFileName = name;
          localValues.imageSize = sizes[0]; 

          callable(localValues).then(() => {
            setSetUpdatedAndRefresh();
          }).catch(() => {
            setEditing(false);
            refresh();
            console.log('location saved');
          });    

          // try {

          //   let saveLocation = await fetch('http://localhost:5001/trip-galaxy/us-central1/saveLocation', {
          //       method: 'POST',
          //       headers: {
          //           "Content-Type": "application/json"
          //       },
          //       body: JSON.stringify({data: localValues})
          //     });
          //     setSetUpdatedAndRefresh();
          //     console.log(saveLocation.json());
          // } catch {
          //   setEditing(false);
          //   refresh();
          //   console.log('location saved');
          // };

          // console.log('---- saved with image');   
          // dispatch(invokeCloudFunction('saveLocation', entity, ['/locations', 'data.objectID']));
          // await save(localValues);
        });
      } else {
        console.log("image not matched");
      }   
    } else {

      callable(localValues).then(() => {
        setSetUpdatedAndRefresh();
      }).catch(() => {
        setEditing(false);
        refresh();
        console.log('location saved');
      });
      
      // try {

      //   let saveLocation = await fetch('http://localhost:5001/trip-galaxy/us-central1/saveLocation', {
      //       method: 'POST',
      //       headers: {
      //           "Content-Type": "application/json"
      //       },
      //       body: JSON.stringify({data: localValues})
      //     });
      //     setSetUpdatedAndRefresh();
      //     console.log(saveLocation.json());
      // } catch {
      //   setEditing(false);
      //   refresh();
      //   console.log('location saved');
      // };

    }

    /*
    if (!saved) {
      console.log('---- saved');
      // dispatch(invokeCloudFunction('saveLocation', entity, ['/locations', 'data.objectID']));
      await save(localValues);
    }
    */
  }

  const  callArchiveLocation = async (id) => {
    console.log("called archive location: " + id);
    console.log(hit);

    setSubmitting(true);

    analytics.logEvent('location_deleted', {
      userID: uid,
      locationName: hit.name,
      locationID: id
    });

    let activity = {
      email: user.email,
      locationName: hit.name,
      locationID: id,
      activity: 'locationDelete'
    }

    await addActivity(activity);

    let complete = deleteLocation({id});

    complete.then(function(results) {
      console.log(results);
      setEditing(false);
      refresh();
    });
  }

  // //const saveLocationAndUploadImages = async (localValues) => {
  // const getReads = async () => {
  //   console.log("called get reads");
  //   this.setState({ ...this.state, readsRegionsLoading: true });
    

  //   let complete = getReadsFilters({});

  //   complete.then((results) => {
  //     console.log(results);
  //     //this.setState({ ...this.state, readsRegionsLoading: false, readsRegions: results.data });
  //     this.setState({ ...this.state, readsRegionsLoading: false, readsRegions: results });
  //   });
  // }

  // const getCities = async () => {
  //   console.log('called get cities');
  //   let index = findIndex(this.state.readsRegions, (region) => { 
      
  //     return region.region == this.state.readsSelectedRegion; 
  //   });

  //   if (index !== -1) {
  //   //use readsSelectedRegion
  //   //console.log(this.state.readsSelectedRegion);
  //   this.setState({ ...this.state, readsCitiesLoading: true });

  //   let complete = getReadsFilters({regionID: this.state.readsRegions[index].objectID});

  //   complete.then((results) => {
  //     console.log(results);
  //     //this.setState({ ...this.state, readsCitiesLoading: false, readsCities: results.data });
  //     this.setState({ ...this.state, readsCitiesLoading: false, readsCities: results });
  //   });
  //   } else {
  //     console.log('couldnt find matching region ID');
  //   }
    
  // }

  // const setRegion = (region) => {
  //   console.log("in set region");
  //   console.log(region);
  //   this.setState({
  //     ...this.state,
  //     readsSelectedRegion: region,
  //   });
  // }

  return (
    <div className="card mt-3 text-left" id={hit.objectID}>     
      
      <div className="card-body">
        {
          !item &&
          <div className="text-center" style={{ fontSize: '2em' }}>
            <i className="fa fa-spinner fa-spin" />
          </div>          
        }

        {
          item &&
          <LocationForm
          item={item}
          name={`location-${hit.objectID}`}
          locationCategories={locationCategories}
          setImages={setImagesFromForm}
          saveLocationAndUploadImages={saveLocationAndUploadImages}
          images={images}
          copy={copyLocation}
          setEditing={setEditing}
          isSubmitting={isSubmitting}
          archiveLocation={callArchiveLocation}
          id={hit.objectID}
          deleteLocation={callArchiveLocation}
        />
        } 
      </div>
    </div>
  );

}


/*


          
          isSubmitting={this.state.isSubmitting}
          
          */