import omit from 'lodash/omit';

export const copy = (props) => {
    var item = omit(props.item, ['objectID']);
    item['_geoloc'] = {
      lat: 0,
      lng: 0,
    };
    console.log("copy", item);
    props.setScratchPadValue('location', item);
    props.history.push('/location/new');
  }

  export const trimLocationValues = (values) => {
    if (values.name) {
      values.name = values.name.trim();
    }

    if (values.description) {
      values.description = values.description.trim();
    }

    if (values.street) {
      values.street = values.street.trim();
    }

    if (values.city) {
      values.city = values.city.trim();
    }

    if (values.postalCode) {
      values.postalCode = values.postalCode.trim();
    }

    if (values.phoneNumber) {
      values.phoneNumber = values.phoneNumber.trim();
    }

    return values;
  }