import { createSelector } from 'reselect';
import get from 'lodash/get';
import last from 'lodash/last';

const getRouterLocation = (state) => state.router.location;
const getDocuments = (state) => state.session.documents;
const getInfo = (state) => state.session.info;
const getCollections = (state) => state.collections;

export const getLocationElements = createSelector(
  [getRouterLocation], (location) => location.pathname.split('/')
);

export const getLastTokenFromPath = createSelector(
  [getRouterLocation], (location) => {
    return last(location.pathname.split('/'));
  }
)

export const getIDFromParams = createSelector(
  [getRouterLocation], (location) => {
    var tokens = location.pathname.split('/');
    var token = last(tokens);

    if (tokens.length === 2) {
      return null;
    }

    if (token.match(/^[a-z0-9]{20}([a-z0-9]{8})?$/i)) {
      return token;
    }

    return null;
  }
);

export const getDocumentFromParams = createSelector(
  [getRouterLocation, getDocuments], (location, documents) => {
    const id = last(location.pathname.split('/'));

    return documents[id];
  }
)

export const getUserLatLong = createSelector(
  [getInfo], (info) => {
    if (info && info.latitude && info.longitude) {
      return [info.latitude, info.longitude];
    }

    return null;
  }
)

export const selectCollection = (state, collection) => {
  return getCollections(state)[collection] || [];
}

export const selectDocument = (state, collection, id) => {
  if (!id) { return {}; }
  return get(getCollections(state), `${collection}.${id}`);
}