import React, { Component } from 'react';
// import { connect, useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectLocationCategoriesByName } from '../../location-categories';
import { setSearchRefinement, setSearchPage } from '../../search/actions';
import { selectLocationsByName } from '../selectors';

import LocationCard from '../components/location-card';
import * as actions from '../actions';
import { getUID, getUser } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';
import { analytics, addActivity } from '../../firebase/index'

import LocationRefinementList from '../components/location-refinement-list';
import LocationSortBy from '../components/location-sort-by';
import LocationSearchBox from '../components/location-search-box';
import LocationPagination from '../components/location-pagination';
import CustomHits from '../components/custom-hits';

import searchClient from '../../search';

import './location-search.css';

import {
  InstantSearch,
  Hits,
  // SearchBox,  
  Pagination,
  Stats,
  Configure,
  RefinementList,
  ScrollTo,
} from 'react-instantsearch-dom';


const mapStateToProps = (state, ownProps) => {

  const props = {
    initialValues: { region: 'CA' },
    locations: selectLocationsByName(state),
    locationCategories: selectLocationCategoriesByName(state),
    signedIn: Boolean(state.session.user),
    refinement: state.search.locations,
    page: state.search.page,
    uid: getUID(state),
    user: getUser(state),
  };

  return props;
};

class LocationSearch extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this); 
    this.getSearchQuery = this.getSearchQuery.bind(this);
    this.sendSearchAnalytics = this.sendSearchAnalytics.bind(this);
  }

  state = {
    refresh: false,
    pageChange: false,
    query: '',
    typing: false,
    typingTimeout: 0,
  }

  onSubmit(event) {
    console.log("onsubmit", event);    
  }

  componentDidMount() {
    searchClient.clearCache();
    this.refresh();
  }

  refresh = () => {
    this.setState({refresh: true}, () => {
        this.setState({refresh: false});
    });

    console.log("Refresh!");
  };

  pageHasChanged = () => {
    this.setState({pageChange: true});
  }

  pageDoneChanging = () => {
    this.setState({pageChange: false});
  }

  sendSearchAnalytics = async () => {
    console.log('query: ', this.state.query);

    console.log(this.props.uid, this.props.user.email);

    if (this.state.query !== "") {

    analytics.logEvent('location_search', {
      userID: this.props.uid,
      searchTerm: this.state.query,
      //userEMail: this.props.user.email,
    });

    let activity = {
      searchTerm: this.state.query,
      email: this.props.user.email,
      activity: 'locationSearch'
    }

    await addActivity(activity);
    }
  }

  getSearchQuery = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
   }

   const self = this;

   this.setState({
      query: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
          self.sendSearchAnalytics();
        }, 2500)
   });
  }

  render() {
    const { signedIn, setSearchRefinement, refinement, page, uid } = this.props;
    
    return (
      <div className="container mt-4">
        <InstantSearch searchClient={searchClient} indexName="locations" refresh={this.state.refresh}>
          <Configure typoTolerance={false} facets={['region', 'postalCode', 'categories.name', 'categories.subcategories', 'tagsArray' ]}
            restrictSearchableAttributes = {['name', 'region', 'street', 'city', 'postalCode', 'categories.name', 'categories.subcategories']}
            hitsPerPage={100}
            />
          <div className="card mt-4" {...console.log("test")}>
            <div className="card-header">
              <h3 className="pull-left" style={{ marginBottom: 0 }}>
                Locations
                <Stats />

              </h3>
              {
                signedIn && IDCheck(uid) &&
                <Link className="btn btn-sm btn-outline-secondary pull-right" to='/location/new'>
                  <i className="fa fa-plus"/>
                  <span className="d-none d-md-inline"  style={{ marginLeft: 4 }}>Location</span>
                </Link>
              }
            </div>
            
            <div className="card-body">              
              <div className="container">
                <ScrollTo scrollOn="page">
                <div className="row form-group">
                  <div className="col d-flex justify-content-center">
                    {/* <LocationPagination defaultRefinement={page} setSearchPage={this.props.setSearchPage} pageHasChanged={this.pageHasChanged}/> */}
                    <Pagination />
                  </div>
                </div>
                </ScrollTo>
                <div className="row">                  
                  <div className="col-md-10 pr-0">
                    <LocationSearchBox
                    getSearchQuery={this.getSearchQuery}
                      defaultRefinement={refinement}
                      setSearchRefinement={setSearchRefinement}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mt-3">
                  <div className="card mb-2">
                    <div className="card-header">HasTags</div>                      
                        <LocationRefinementList attribute="hasTags" limit={100} />                             
                    </div>

                    <div className="card mb-2">
                    <div className="card-header">Has Coordinates</div>                      
                        <LocationRefinementList attribute="hasCoordinates" limit={100} />                             
                    </div>

                    <div className="card mb-2">
                      <div className="card-header">States</div>                      
                        <LocationRefinementList attribute="region" limit={51} />                            
                    </div>
                    <div className="card mb-2">
                    <div className="card-header">Categories</div>                      
                        <LocationRefinementList attribute="categories.name" limit={100} />                                          
                    </div>
                    
                    <div className="card mb-2">
                    <div className="card-header">Tags</div>                      
                        <LocationRefinementList attribute="tagsArray" limit={100} />                             
                    </div>

                    
                  </div>

                  <div className="col-md-9">
                    <CustomHits refresh={this.refresh} pageDoneChanging={this.pageDoneChanging} pageChange={this.state.pageChange} uid={this.props.uid} user={this.props.user} />
                  </div>
                </div>
              </div>
            </div>
          </div>     
          <div className="row form-group">
            <div className="col d-flex justify-content-center">
              {/* <LocationPagination defaultRefinement={page} setSearchPage={this.props.setSearchPage} pageHasChanged={this.pageHasChanged}/> */}
              <Pagination />
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  }
};

export default connect(
  mapStateToProps,
  { ...actions, setSearchRefinement, setSearchPage },
)(LocationSearch);