import * as Constants from '../constants';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import { setInfo } from '../../planning/actions';
import { fullTextSearch } from '../actions';
const mapbox = require('@mapbox/mapbox-sdk/services/geocoding');
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGFubXVua3RnIiwiYSI6ImNqdHo4b3ZjMDMyMWE0M20yN2NkOHFidG4ifQ.RsFGVqqQBEYWKGnXE4HDow';
const geocodingClient = mapbox({ accessToken: MAPBOX_TOKEN }); 


const getSessionInfo = state => state.session.info;

const reverseGeocode = (longitude, latitude) => new Promise((resolve, reject) => {
  geocodingClient.reverseGeocode({
    query: [longitude, latitude], 
    types: ["place"],
  }).send().then(response => {
    // console.log("response", response.body.features[0]);
    resolve(response.body);
  });
});

function* setPlanInfoFromLocation(action) {  
  const info = yield select(getSessionInfo);

  if (info.longitude && info.latitude) {
    const response = yield call(reverseGeocode, info.longitude, info.latitude);
    if (response.features && response.features.length > 0) {
      yield put(fullTextSearch('locations', 'plan_locations', response.features[0].center));
      yield put(setInfo(response.features[0]));
    }
  }
}

export default function* () {
  yield takeLatest(Constants.SET_PLAN_INFO_FROM_LOCATION, setPlanInfoFromLocation);
}