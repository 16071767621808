import * as Constants from './constants';
import { invokeCloudFunction } from '../actions/actions';

import firebase from "firebase/compat/app";
import 'firebase/compat/storage';

import {v4} from 'uuid';

export const saveLocation = (entity, images, sizes) => (dispatch) => {
  var saved = false; 
  
  if (images && images.length > 0) {
    let file = images[0];
    const uuid = v4();

    var matched = file.name.match(/\.(png|jpg|gif|jpeg)$/)
    if (matched) {
      var name = `${uuid}${matched[0]}`;
      saved = true;

      var ref = firebase.storage().ref();
      var child = ref.child(name);
      child.put(file).then((snapshot) => {
        entity.imageFileName = name;
        entity.imageSize = sizes[0];        
        dispatch(invokeCloudFunction('saveLocation', entity, ['/locations', 'data.objectID']));
      });
    } else {
      console.log("image not matched");
    }   
  }

  if (!saved) {
    dispatch(invokeCloudFunction('saveLocation', entity, ['/locations', 'data.objectID']));
  }
};

export const updateSearch = (search) => ({
  type: Constants.UPDATE_SEARCH,
  search,
});

export const archiveLocation = (id) => (dispatch) => {
  dispatch(invokeCloudFunction('deleteLocation', { id }, '/locations'));
};

export const lastIdentity = (lastIdentity=0) => ({
  type: Constants.LAST_IDENTITY,
  lastIdentity: parseInt(lastIdentity, 10),
});