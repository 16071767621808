import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListingProgressBar from '../components/listing-progress-bar';
import { Field, reduxForm } from 'redux-form';
import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';

import * as Actions from '../../actions/actions';
import { getStepInfo } from '../selectors';
import track from '../../actions/track';
    
const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
  initialValues: {
    bedrooms: 1,
    accomodates: 1,
    bathrooms: 1,
  }
});

class Accomodations extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Accomodations");
  }
  
  submit(values) {
    const { id } = this.props;
    const next = `/listing/amenities/${id}`;
    
    console.log("submit", values, next);
    this.props.updateDocument(`listings/${id}`, values, next);
  }

  render() {
    const { current, submitting, handleSubmit } = this.props;
    return (
      <div className="container">
        <form onSubmit={handleSubmit(this.submit)} noValidate autoComplete="off">
          <div className="row">
            <div className="col-md-3 mt-4">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9 mt-4">
              <div className="card">
                <h5 className="card-header text-left">How Many Guests Can Your Property Accommodate?</h5>        
                <div className="card-body">

                <Field className="form-control mt-2" name="bedrooms" component="select">
                  <option value={1}>1 Bedroom</option>
                  <option value={2}>2 Bedrooms</option>
                  <option value={3}>3 Bedrooms</option>
                  <option value={4}>4 Bedrooms</option>
                  <option value={5}>5 Bedrooms</option>
                  <option value={6}>6 Bedrooms</option>
                  <option value={7}>7 Bedrooms</option>
                  <option value={8}>8+ Bedrooms</option>
                </Field>

                <Field className="form-control mt-2" name="accomodates" component="select">
                  <option value={1}>1 Guest</option>
                  <option value={2}>2 Guests</option>
                  <option value={3}>3 Guests</option>
                  <option value={4}>4 Guests</option>
                  <option value={5}>5 Guests</option>
                  <option value={6}>6 Guests</option>
                  <option value={7}>7 Guests</option>
                  <option value={8}>8 Guests</option>
                  <option value={9}>9 Guests</option>
                  <option value={10}>10 Guests</option>
                  <option value={11}>11 Guests</option>
                  <option value={12}>12+ Guests</option>
                </Field>

                <Field className="form-control mt-2" name="bathrooms" component="select">
                  <option value={1}>1 Bathroom</option>
                  <option value={2}>2 Bathrooms</option>
                  <option value={3}>3 Bathrooms</option>
                  <option value={4}>4+ Bathrooms</option>
                </Field>
                </div>
              </div>

              <div className="pull-right mt-2">
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: "listingAccomodations",
})(Accomodations);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));