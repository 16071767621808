
import React from 'react';
import { Link } from 'react-router-dom';

import ListingProgressBar from '../components/listing-progress-bar';

export default () =>
  <div className="container mt-4">
    <div className="row">
      <div className="col-md-3">
        <ListingProgressBar step={7} />
      </div>

      <div className="col-md-9">
        <div className="card">
          <h5 className="card-header text-left">Pricing</h5>        
          <div className="card-body">
            <p className="card-text text-left"></p>

          </div>          
        </div>

        <div className="pull-right mt-2">
          <Link className="btn btn-primary" to="/">Done</Link>
        </div>

      </div>
    </div>
  </div>