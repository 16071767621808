import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Image from '../../components/image';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import './plan-grid-item.css';
import classNames from 'classnames';
import { getImageAspect } from '../../helpers';

import PhoneNumber from '../../components/phone-number';

import PlanGridItemDetails from './plan-grid-item-details';

const mapStateToProps = (state, ownProps) => {
  return {
    imageAspect: getImageAspect(ownProps.source.imageSize),
  };
}

const PlanGridItem = ({ source, planId, toggleLocationFavorite, favorite, getawayHatchSearch, googleSearch, yelpSearch, 
  showDetailsCard, selectedLocations, addToSelectedLocations, removeFromSelectedLocations, place, isPlanOwner, selectingForMessage, 
  addToMessageLocation, localityID }) => {

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    //let justLocations = selectedLocations.map(location => location.location);
    let isSelected = some(selectedLocations, formatLocation(source.objectID, getawayHatchSearch, googleSearch, yelpSearch));

    setSelected(isSelected);
  }, [selectedLocations]);

  function handleClick() {
  
    showDetailsCard(source);
  }

  function handleClickForSelectLocation() {
    console.log("clicked location to save");
    let service;

    if (getawayHatchSearch) {
      service = "getaway-hatch";

    } else if (googleSearch) {
      service = "google-places";

    } else if (yelpSearch) {
      service = "yelp-fusion";

    }

    if (selected) {
      removeFromSelectedLocations(source.objectID, place, localityID);
      setSelected(false);

    } else {
      if (getawayHatchSearch) {
        source.service = "getaway-hatch";
      } else if (googleSearch) {
        source.service = "google-places";
      } else if (yelpSearch) {
        source.service = "yelp-fusion";
      }
      addToSelectedLocations(source.objectID, service, source);
      setSelected(true);

    }
  }

  function handleClickForMessageLocation() {
    console.log("clicked location to save");
    let service;

    if (getawayHatchSearch) {
      service = "getaway-hatch";

    } else if (googleSearch) {
      service = "google-places";

    } else if (yelpSearch) {
      service = "yelp-fusion";

    }

    if (getawayHatchSearch) {
      source.service = "getaway-hatch";
    } else if (googleSearch) {
      source.service = "google-places";
    } else if (yelpSearch) {
      source.service = "yelp-fusion";
    }
    addToMessageLocation(source.objectID, service, source);
    //setSelected(true);

    
  }

  function formatLocation(objectID, getawayHatchSearch, googleSearch, yelpSearch) {

    let location = {
      uid: objectID
    }

    if (getawayHatchSearch) {
      location.service = "getaway-hatch";

    } else if (googleSearch) {
      location.service = "google-places";

    } else if (yelpSearch) {
      location.service = "yelp-fusion";

    }

    return location;

  }

  function checkSelected(selectedLocations, objectID, getawayHatchSearch, googleSearch, yelpSearch) {
    
    let selected = some(selectedLocations, formatLocation(objectID, getawayHatchSearch, googleSearch, yelpSearch));
    
    return selected;
  }

  return (
    <div className={`card plan-grid-item`} style={{ height: '100%', marginBottom: '10px' }}>
      <div className="clearfix card-controls">
        {/*
        <button className="btn btn-sm btn-link pull-left left-button">
          <i className="fa fa-comment-o" />
        </button>
        <button className="btn btn-sm btn-link pull-left left-button"  onClick={() => toggleLocationFavorite(planId, source.objectID) }>
          <i className={classNames("fa", { "fa-thumbs-o-up": !favorite, "fa-thumbs-up": favorite })} />
        </button>      
        */}

        {
          selected && isPlanOwner && !selectingForMessage &&
          <button className="btn btn-sm btn-link pull-left left-button" onClick={() => handleClickForSelectLocation()}>
            <i className="fa fa-plus-square" />
          </button>
        }

        {
          !selected && isPlanOwner && !selectingForMessage &&
          <button className="btn btn-sm btn-link pull-left left-button" onClick={() => handleClickForSelectLocation()}>
            <i className="fa fa-plus-square-o" />
          </button>
        }

        {
          selectingForMessage &&
          <button className="btn btn-sm btn-link pull-left left-button" onClick={() => handleClickForMessageLocation()}>
            <i className="fa fa-comments-o" />
          </button>
        }
        
      </div>

      <a className="btn" onClick={() =>  handleClick()}>
        {
          getawayHatchSearch && source.imageFileName &&
          <Image imageFileName={source.imageFileName} className="card-img-top"/>
        }

        {
          googleSearch && source.imageURL &&
          <div>
            <div className="row overflow-hidden">
              <div className="col w-100">
                <img src={source.imageURL} alt={source.name + " Image"} className="card-img-top center-block" 
                style={{ maxHeight: "150px", width: 'auto', height: 'auto'}} />
              </div>
            </div>
            <div className="row overflow-hidden">
              <div className="col w-100">
                <img className="card-img-top float-left" style={{ maxWidth: "100px" }} src='/powered_by_google.png'></img>
              </div>
            </div>
          </div>
        }

        {
          yelpSearch && source.imageURL && 
          <div>
            <div className="row overflow-hidden">
              <div className="col w-100">
                <img src={source.imageURL} alt={source.name + " Image"} className="card-img-top center-block" 
                style={{ maxHeight: "150px", width: 'auto', height: 'auto'}} />
              </div>
            </div>
            <div className="row overflow-hidden">
              <div className="col w-100">
                <img className="card-img-top float-left" style={{ maxWidth: "40px" }} src='/yelp-title-logo.png'></img>
              </div>
            </div>
          </div>
        }

        {
          !source.imageURL && !source.imageFileName &&
          <div className="py-5"></div>
        }

        <div className="card-body px-1" style={{ paddingTop: '-4px', marginTop: '0' }}>
            <h1 className="card-title text-reset">{source.name}</h1>
            
            <div className="card-footer mt-2 px-0">
              {
                source.tags && (typeof source.tags === 'string' || source.tags instanceof String) && source.tags.split(', ').map(tag => 
                  <span key={tag} className="badge badge-light">{tag}</span>
                )     
              }

              {
                source.tags && Array.isArray(source.tags) && source.tags.map(tag => 
                  <span key={tag} className="badge badge-light">{tag}</span>
                )     
              }
            </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  mapStateToProps,
)(PlanGridItem);