import algoliasearch from 'algoliasearch/lite';
import { result } from 'lodash';

import {searchYelpDetails} from '../firebase/index'

const searchClient = algoliasearch('0XTKDK7IUC', 'b3b6db66d4cf5bafa139ed4c160d28ed');

const indexPlacesCustom = searchClient.initIndex('places_custom');

const indexLocations = searchClient.initIndex('locations');

const indexPlacesMetadata = searchClient.initIndex('places_metadata');

const google = window.google;

let service;

const searchPlaces = async (query) => {
    let data = {
        'query': query,
        'type': 'city',
        'countries': 'us',
    };

    const response = await fetch('https://places-dsn.algolia.net/1/places/query', {
        method: 'post',
        body: JSON.stringify(data),
        headers: new Headers({
            'X-Algolia-Application-Id': 'plVO35LQZXJB',
            'X-Algolia-API-Key': '65814cefc7696d8ee0a3d40beba169a2'
        })
    })

    return response.json();
}

const searchPlacesCustom = async (query) => {
    try {
        return indexPlacesCustom.search(query).then(({ hits }) => { return hits });
    } catch (error) {
        return error;
    }
}

export const getAlgoliaPlacesResults = async (query) => {
    let places = await searchPlaces(query);

    let hits = places.hits;
    let refinedPlaces = [];

    for (let i = 0; i < hits.length; i++) {
        refinedPlaces.push({
          name: hits[i].locale_names.default[0],
          state: hits[i].administrative[0],
          lat: hits[i]._geoloc.lat,
          lng: hits[i]._geoloc.lng,
          id: hits[i].objectID
        });
    }

    let placesCustom = await searchPlacesCustom(query);
    return [...placesCustom, ...refinedPlaces];
    
}

export const getAlgoliaLocationResults = async (query, place, category) => {

    let results;
    let hitsPerPage = 200;

    if ((category === "All")) {

        results = await indexLocations.search(query, {
            filters: `"city:${place.city}" AND region:${place.region}`,
            hitsPerPage: hitsPerPage,

        }).then(({ hits }) => {
            return hits;
        }).catch(error => console.log(error));
        
    }else{
        
        results = await indexLocations.search(query, {
            filters: `city:"${place.city}" AND region:${place.region} AND categories.name:"${category}"`,
            hitsPerPage: hitsPerPage,

        }).then(({ hits }) => {
            return hits;
         }).catch(error => { 
             console.log(error);
            });
    }

    return results;
}

export const getAlgoliaPlacesMetadataResults = async (query) => {

	let results;
	let hitsPerPage = 200;

	results = await indexPlacesMetadata.search(query).then(({ hits }) => {
			return hits;
	}).catch(error => console.log(error));
		
	return results;
}

export const getGoogleExtraResults = async (locationID) => {

    if (!service) {
        service = new google.maps.places.PlacesService(document.createElement('div'));
    }

    return new Promise( async function(resolve, reject) {

        let extraRequest = {
            placeId: locationID,
            fields: ['name', 'formatted_phone_number', 'website', 'address_components', 'photo']
        }

        let locationDetails = service.getDetails(extraRequest, async (place, status) => {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                let location = {
                    objectID: null,
                    streetNumber: null,
                    streetName: null,
                    street: null,
                    city: null,
                    region: null,
                    postalCode: null,
                    url: null,
					phoneNumber: null,
					name: null
                }
    
                location.objectID = locationID;
    
                for ( let j = 0; j < place.address_components.length; j++) {
                    let component = place.address_components[j];
    
                    if (component.types.includes('administrative_area_level_1')) {
                        location.region = component.short_name;
                        continue;
    
                    } else if (component.types.includes('locality')) {
                        location.city = component.short_name;
                        continue;
    
                    } else if (component.types.includes('street_number')) {
                        location.streetNumber = component.short_name;
                        continue;
    
                    } else if (component.types.includes('route')) {
                        location.streetName = component.short_name;
                        continue;
    
                    } else if (component.types.includes('postal_code')) {
                        location.postalCode = component.short_name;
                        continue;
    
                    }
                }
                
                location.street = location.streetNumber + " " + location.streetName;
                location.url = place.website;
                location.phoneNumber = place.formatted_phone_number;
				location.imageURL = place.photos[0].getUrl({maxWidth: 600});
				location.name = place.name;

                resolve(location);
            } else {

                if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    console.log("no resulsts");
                } else {
                    reject(new Error('google maps status for getDetails for ' + extraRequest + ' not ok ' + status));
                }
            }
        })
    })
}

export const getSavedLocationResults = async (selectedLocations, search, place) => {

    let algoliaLocations = [];
    let algoliaResults = [];
    let googleLocations = [];
    let googleResults = [];
    let yelpLocations = [];
    let yelpResults =[];

    for(let i = 0; i < selectedLocations.length; i++) {

        if (selectedLocations[i].service.localeCompare("getaway-hatch") === 0) {
            algoliaLocations.push(selectedLocations[i].uid);

        } else if (selectedLocations[i].service.localeCompare("google-places") === 0) {
            googleLocations.push(selectedLocations[i].uid);

        } else if (selectedLocations[i].service.localeCompare("yelp-fusion") === 0) {
            yelpLocations.push(selectedLocations[i].uid);
        }
    }

    let result;

    for (let i = 0; i < algoliaLocations.length; i++) {
        result = await indexLocations.getObject(algoliaLocations[i]);
        result.service = "getaway-hatch";
        algoliaResults.push(result);
    }
		
    for (let i = 0; i < googleLocations.length; i++) {
        result = await getGoogleExtraResults(googleLocations[i]);
        result.service = "google-places";
        googleResults.push(result);
    }
		
    for (let i = 0; i < yelpLocations.length; i++) {
        let yelp = await searchYelpDetails({id: yelpLocations[i]});
		
        result = {
            objectID: yelp.data.id,
            name: yelp.data.name,
            imageURL: yelp.data.image_url,
            region: yelp.data.location.state,
            postalCode: yelp.data.location.zip_code,
            url: yelp.data.url,
            phoneNumber: yelp.data.display_phone,
            service: "yelp-fusion"
        }

        if (yelp.data.location.city) {
            result.city = yelp.data.location.city;
        }
        
        if (yelp.data.location.address1) {
            result.street = yelp.data.location.address1;
        }
					
		yelpResults.push(result);
        /*let yelpData = {"data": {
            "id": yelpLocations[i]
          }};
        
        let yelpReturnedData = await fetch('http://localhost:5001/trip-galaxy/us-central1/searchYelpDetails', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(yelpData)
        });
				
				let yelp = await yelpReturnedData.json();

        if (yelp.result) {
            result = {
                objectID: yelp.result.id,
                name: yelp.result.name,
                imageURL: yelp.result.image_url,
                street: yelp.result.location.address1,
                city: yelp.result.location.city,
                region: yelp.result.location.state,
                postalCode: yelp.result.location.zip_code,
                url: yelp.result.url,
                service: "yelp-fusion"
            }
    
						yelpResults.push(result);
        }*/
		}

    let allResults = [...algoliaResults, ...googleResults, ...yelpResults];

    return allResults;
}

export default searchClient;