import React, {useState} from 'react';
import { connectHits } from 'react-instantsearch-dom';
import PlaceCustomLink from './place-custom-link';

function PlacesCustomCard ({ hits }) {

    function formatLargeStatesGroup(states) {
        let joinedStates = states.split(" & ").join(", ");
        let statesLength = joinedStates.length;

        return [joinedStates.slice(0, statesLength - 4), " & ", joinedStates.slice(statesLength - 2)].join('');
    }
    
   return (
    <div>
        <ul>
            {hits.map( hit => (
            <li className="list-inline-item" key={hit.objectID}>
                <div className="card border-dark mb-3" style={{width: "14rem", height: "22rem"}}>
                    <h5>
                    {
                            <PlaceCustomLink id={hit.objectID} placeData={{hit}}>
                                <i className="fa fa-pencil mr-2 pull-left" />                
                            </PlaceCustomLink>
                            }
                    </h5> 
                    <div className="card-header bg-transparent">
                        <h5 className="card-title">{hit.name}</h5>
                    </div>
                    <div className="card-body">
                        {(hit.state.length > 7 ) && 
                            <p className="card-text">{"States: " + formatLargeStatesGroup(hit.state)}</p>
                        }
                        {(hit.state.length <= 7) && 
                            <p className="card-text">{"States: " + hit.state}</p>
                        }
                        
                        <p className="card-text">{"Latitude: " + hit.lat + ", Longitude: " + hit.lng}</p>
                        {(hit.radius != null) && <p className="card-text">{"Radius: " + hit.radius}</p>}
                    </div>
                </div>
            </li>
            ))}
        </ul>
    </div>
);
    
}

export default connectHits(PlacesCustomCard);