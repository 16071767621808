import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router";

import ReadsCityForm from '../components/reads-city-form';

import { getDocument, insertDocument, updateDocument, deleteDocument, saveReadsCity, deleteReadsCity } from '../../firebase';

export default () => {
  var { readsRegionId, id } = useParams();
  var history = useHistory();
  const [readsRegion, setReadsRegion] = useState(null);
  const [readsRegionCity, setReadsRegionCity] = useState({name: '', region: '', group: ''});

  useEffect(() => {
console.log(readsRegionId, id);
console.log(history);

    if (!readsRegionId) { return; }

    console.log('getting region and city data');
    getDocument('readsLocations', readsRegionId).then(readsRegion => {      
      if (id !== 'new') {
        getDocument('readsLocations/' + readsRegionId + '/cities', id).then(readsRegionCity => { 
          setReadsRegionCity(readsRegionCity);
          setReadsRegion(readsRegion);
        });
      } else {
        setReadsRegion(readsRegion);
      }
    });
  }, [readsRegionId]);

  const saveReadsCityForm = (data) => { //--------------------------------------------------------------------
    console.log('save reads city');
    console.log(data);
    data.parentID = readsRegionId;

    saveReadsCity(data).then(() => {
      history.push(`/reads-cities/${readsRegionId}`);
    });

    // if (id === 'new') {
    //   data.parentID = readsRegionId;

    //   insertDocument('locationCategories', data).then((item) => {
    //     history.push(`/location-sub-categories/${readsRegionId}`);
    //   });
    // } else {
    //   updateDocument('locationCategories', id, data).then((item) => {
    //     history.push(`/location-sub-categories/${readsRegionId}`);
    //   });
    // }
  
  }

  const deleteReadsCity = () => { //----------------------------------------------------------------------
    console.log('delete');

    deleteDocument('readsLocations/' + readsRegionId + '/cities', id).then((item) => {
      history.push(`/reads-cities/${readsRegionId}`);
    });
    // deleteDocument('locationCategories', id).then((item) => {
    //   history.push(`/location-sub-categories/${readsRegionId}`);
    // });
  }

  console.log(readsRegionId, id);

  if (!readsRegion) {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <h5 className="text-left">Loading...</h5>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          {!readsRegion && <h5 className="mb-0 pull-left">Loading...</h5>}
          { readsRegion && <h5 className="mb-0 pull-left"> {readsRegion.name} </h5> }
        </div>
        <div className="card-body">
          <ReadsCityForm
            readsRegion={readsRegion}
            readsRegionCity={readsRegionCity}
            deleteReadsCity={deleteReadsCity}
            saveReadsCity={saveReadsCityForm} />
        </div>
      </div>
    </div>
  );
};
