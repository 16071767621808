import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, invoke } from '../../firebase';
import ReadsFilter from '../components/reads-filter.js';

import { getUID, getUser } from '../../session/selectors';
import IDCheck from '../../shared/IDCheck';
import { useSelector } from 'react-redux';
import { getReadsFilters } from '../../firebase';

export default () => {
  var [ReadsFilters, setReadsFilters] = useState([]);

  const signedIn = useSelector(state => Boolean(state.session.user));
  const uid = useSelector(state => getUID(state));

  useEffect(() => {
    // invoke('locationCategories').then((results) => {
    //   console.log(results);
    //   setLocationCategories(results.data);
    // });

    let complete = getReadsFilters({});

    complete.then((results) => {
      console.log(results);
      //setReadsFilters(results.data);
      setReadsFilters(results);
    });
  }, []);

  useEffect(() => {
    // collection('locationCategoryTypes').then(results => {
    //   setLocationCategoryTypes(results);
    //});
  }, []);

  return (      
    <div className="container mt-4">
      <div className="card">
        <div className="card-header text-left">
          Reads Filters
          { signedIn && IDCheck(uid) &&
          <Link className="btn btn-sm btn-outline-secondary ml-2 pull-right" to="/reads-filter/new">
            <i className="fa fa-plus"/>
            <span className="d-none d-md-inline"  style={{ marginLeft: 4 }}>New Filter Region</span>
          </Link>
          }
        </div>      
        <ul className="list-group list-group-flush">
          {
            ReadsFilters && ReadsFilters.map((item) => {
              console.log(item);
              return <ReadsFilter item={item} key={item.objectID}/>
            }
              
            )
          }
        </ul>
      </div>
    </div>
  );
}