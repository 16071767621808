import React, {useState, useEffect} from 'react';
import { app, sendMessagePlan } from '../../firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useStore } from 'react-redux';
import DropZone from 'react-dropzone';
import browserImageSize from 'browser-image-size';
import Some from 'lodash/some';
import {v4} from 'uuid';

import PlanFinalizationItem from './plan-finalization-item';
import { getSavedLocationResults } from '../../search/location-search';
import DiscussionReaction from './discussion-reaction';
import PlanGridItemDetails from '../components/plan-grid-item-details';


const DiscussionMessaging = ({ planID, uid, email, messageLocation, selectLocationFromDiscussion, removeLocationFromDiscussion,
  isPlanOwner }) => {
  
  const [maxMessageCount, setMaxMessageCount] = useState(10);
  
  const [messageAttachments, setMessageAttachments] = useState({});
  const [messageLocationDetails, setMessageLocationDetails] = useState({});

  const [messageToSend, setMessageToSend] = useState('');
  const [messagesEnd, setMessagesEnd] = useState(null);
  const [sending, setSending] = useState(false);
  const [messageImage, setMessageImage] = useState(false);
  const [messageLocationSelected, setMessageLocationSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadDetails, setLoadDetails] = useState(false);
  const [locationDetails, setLocationDetails] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [detailsSelectedLocation, setdetailsSelectedLocation] = useState(false);
  const [getawayHatchSearch, setGetawayHatchSearch] = useState(false);
  const [googleSearch, setGoogleSearch] = useState(false);
  const [yelpSearch, setYelpSearch] = useState(false);

  const [image, setImage] = useState(false);
  const [imageTooLarge, setImageTooLarge] = useState(false);
  const [size, setSize] = useState(null);

  const [lastMessageDay, setLastMessageDay] = useState(false);

  const MAX_HEIGHT = 1000;
  const MAX_WIDTH = 1000;

  const [canSend, setCanSend] = useState(false);

  const messagesRef = app.firestore().collection('plans_dev').doc(planID).collection('messages');
  const query = messagesRef.orderBy('timestamp', 'desc').limit(maxMessageCount);

  const [messages] = useCollectionData(query, { idField: 'id' });

  useEffect(() => {
		//componentWillReceiveProps
    
		//call to check for message changes
		scrollToBottom();
  }, [messagesEnd]);

  useEffect(() => {
    console.log("message location changed: ", messageLocation);
    let attachments = messageAttachments;
    let locationResults;
    
    if (messageAttachments) {
      if (messageLocation) {
        attachments.location = messageLocation;
      } else {
        attachments = {};
      }
      
      setMessageAttachments(attachments);
      console.log("message attachments: ", messageAttachments);

      if (messageLocation.location && messageLocation.location.service && messageLocation.location.uid) {
        getLocationDetails(messageLocation.location);
        setCanSend(true);
      }

    }

		//call to check for message changes
		
  }, [messageLocation]);
  
  useEffect(() => {
    console.log(messages);
    if (Array.isArray(messages)) {
      getMessageLocationDetails();
      console.log(messages.length);
    }
		
	}, [messages]);

  const getLocationDetails = async (location) => {
    console.log("getting location details for messages");
    setLoadDetails(true);
    let results = await getSavedLocationResults([location]);
    console.log(results);

    setLocationDetails(results[0])
    setLoadDetails(false);
    scrollToBottom();
  }

  const getMessageLocationDetails = async () => {
    
    let locations = [];
    console.log('details: ', messageLocationDetails);
    console.log('messageattatchments: ', messageAttachments);

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].properties.location && !Some(messageLocationDetails, messages[i].uid )) {
        locations.push(messages[i].properties.location);
        console.log("load details for: ", messages[i]);
      }
    }

    console.log(locations);

    let details = await getSavedLocationResults(locations);

    console.log(details);

    let detailObjects = {};

    for (let i = 0; i < details.length; i++) {
      detailObjects[details[i].objectID] = details[i];
    }

    if (!(Object.keys(detailObjects).length === 0)) {

      setMessageLocationDetails(detailObjects);
      scrollToBottom();
    }
  }

  const scrollToBottom = () => {
		if (messagesEnd) {
			messagesEnd.scrollIntoView({ behavior: "smooth" });
		}
  }
  
  const onTextMessageChange = (e) => {

    if (e.target.value.localeCompare('') === 0) {
      setCanSend(false);
    } else {
      setCanSend(true);
    }
		setMessageToSend(e.target.value);
  }
  
  const handleDiscussionSendTextButton = async () => {
    console.log(messageToSend);
    let message = messageToSend;
    
    setSending(true);
    setMessageToSend('');
    setLocationDetails(false);
    setCanSend(false);

    console.log(message);
    //call function to save message
    let messageData = {
      content: messageToSend,
      properties: {},
      reactions: {},
      senderEmail: email,
      senderUid: uid
    }

    if (messageLocation && messageLocation.location) {
      messageData.properties.location = messageLocation.location;
    }

    let imageName = null;
    if (image && image.length > 0) {
      console.log("Creating image name");
      let file = image[0];
      const uuid = v4();
      let matched = file.name.match(/\.(png|PNG|jpg|JPG|gif|GIF|jpeg|JPEG)$/)

      if (matched) {
        imageName = `${uuid}${matched[0]}`;
        messageData.properties.image = imageName;
      } else  {
        console.log("image not matched");
      }
    }

    setLocationDetails(false);
    removeLocationFromDiscussion();

    let data = {
      planID: planID,
      message: messageData
    }

    if (imageName !== null) {
      data.image = {
        image: image[0],
        imageFileName: imageName
      };
    }

    removeImage();

    console.log(data);

    let returnData = await sendMessagePlan(data);

    if (returnData) {
      setSending(false);
    }
    
  }
  
  const handleDiscussionSearchLocationButton = () => {
    selectLocationFromDiscussion();
  }

  const removeSelectedLocation = (id, place) => {
    console.log("remove message location: ", id, place);

    if (messageToSend.localeCompare('') === 0 && !image) {
      setCanSend(false);
    }

    setLocationDetails(false);
    removeLocationFromDiscussion();

  }

  function setStateImages(images) {
    console.log("set image", images);
    let imageSize = null;
    if (images.length > 0) {
      browserImageSize(images[0]).then((size) => {
        console.log("size", size);

        if (size.width < MAX_WIDTH && size.height < MAX_HEIGHT) {
          imageSize = size;
          setImage(images);
          setSize(imageSize);
          setImageTooLarge(false);
          setCanSend(true);
        } else {
          setImageTooLarge(true);
        }
        
      });
    }/* else {
        setImage(images);
        setSize(imageSize);
    }*/
  }

  function removeImage() {
    setImage(false);
    setSize(null);

    if (messageToSend.localeCompare('') === 0 && !messageLocation) {
      setCanSend(false);
    }
  }

  function increaseMaxMessages() {
    let max = maxMessageCount + 10;
    setMaxMessageCount(max);
  }

  function showDetailsCard(location) {
    if (location.service.localeCompare("getaway-hatch") === 0) {
      setGetawayHatchSearch(true);
      setGoogleSearch(false);
      setYelpSearch(false);

    } else if (location.service.localeCompare("google-places") === 0) {
      setGetawayHatchSearch(false);
      setGoogleSearch(true);
      setYelpSearch(false);

    } else if (location.service.localeCompare("yelp-fusion") === 0) {
      setGetawayHatchSearch(false);
      setGoogleSearch(false);
      setYelpSearch(true);

    }

    setdetailsSelectedLocation(location);

    setShowDetails(true);
  }

  function hideDetailsCard() {
    setShowDetails(false);
  }

  function getDate(timestampDate, lastTimestampDate) {
    console.log(timestampDate);
    if (lastTimestampDate === false || timestampDate.localeCompare(lastTimestampDate) !== 0) {
      
      return timestampDate;
    } else {
      return "";
    }
  }

  function getTime(timestampTime) {
    console.log(timestampTime);
    return timestampTime;
  }

  return (<>
    <div >
      {
        showDetails && 
        <div className="container mt-4">        
          <PlanGridItemDetails getawayHatchSearch={getawayHatchSearch} 
          googleSearch={googleSearch} location={detailsSelectedLocation}
          hideDetailsCard={hideDetailsCard} yelpSearch={yelpSearch}/>
        </div>
      }
      { !showDetails && 
      <div className="card text-center mx-auto w-75" {...console.log(messageAttachments)}>
        <h1 className="text-dark text-center">Discussion</h1>
        <div className="card text-center" style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '25em', flexGrow: '1', padding: '20px'}}>
        {
          Array.isArray(messages) && (messages.length === maxMessageCount) &&
          <div>
            <button type="button" className="btn btn-secondary" onClick={increaseMaxMessages}>Load More Messages</button>
          </div>
        }
        {
        messages && Array.isArray(messages) && messages.slice(0).reverse().map((message, i, arr) => 
          <div key={i} {...console.log(message.timestamp.toDate())}>
            { (message.senderUid.localeCompare(uid) === 0) &&
            <div>
              { message.timestamp &&
              <div className="row mx-0 px-0">
                <div className="col mx-0 px-0">
                  {i > 0 && getDate(message.timestamp.toDate().toDateString(), arr[i - 1].timestamp.toDate().toDateString())}
                  {i === 0 && getDate(message.timestamp.toDate().toDateString(), false)}
                </div>
              </div>
              }
              { message.senderEmail && arr[i - 1].senderEmail && (((i > 0 && arr[i - 1].senderEmail.localeCompare(message.senderEmail) !== 0)) || (i === 0)) &&
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 align-self-start text-right">
                  <p className="mb-0 pb-0"><small>{message.senderEmail}</small></p>
                </div>
                
              </div>
              }
              { message.properties.location && messageLocationDetails[message.properties.location.uid] &&
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 align-self-end text-right">
                  <PlanFinalizationItem source={messageLocationDetails[message.properties.location.uid]} removePlanLocations={function() {console.log("trying to remove location form messages")}} 
                  planPlace={false} showDetailsCard={showDetailsCard} uid={uid} isPlanOwner={false}/>
                </div>
              </div>
              }
              { message.properties.image && 
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 align-self-end text-right">
                  <img 
                  src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy-chat/o/${message.properties.image}?alt=media`} 
                  style={{ maxHeight: '8rem', maxWidth: '16rem' } } alt="" 
                  />
                </div>
              </div>
              }
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 align-self-end text-right">
                  <h4 className="mt-0 pt-0">
                    <span className="badge badge-pill badge-primary text-wrap">{message.content}</span>
                  </h4>
                  { message.timestamp &&
                  <h6 className="mt-0 pt-0">
                    <p ><small>{getTime(message.timestamp.toDate().toLocaleTimeString('en-US'))}</small></p>
                  </h6>
                  }
                </div>
              </div>

              <DiscussionReaction uid={uid} message={message} right={true} planID={planID} isPlanOwner={isPlanOwner} 
              location={message.properties.location}/>

            </div>
            }

            { (message.senderUid.localeCompare(uid) !== 0) &&
            <div>
              { message.timestamp &&
              <div className="row mx-0 px-0">
                <div className="col mx-0 px-0">
                  {i > 0 && getDate(message.timestamp.toDate().toDateString(), arr[i - 1].timestamp.toDate().toDateString())}
                  {i === 0 && getDate(message.timestamp.toDate().toDateString(), false)}
                </div>
              </div>
              }

              { message.senderEmail && (((i > 0 && arr[i - 1].senderEmail.localeCompare(message.senderEmail) !== 0)) || (i === 0)) &&
              <div className="row">
                <div className="col-md-6 align-self-start text-left">
                  <p className="mb-0 pb-0"><small>{message.senderEmail}</small></p>
                </div>
                <div className="col-md-6"></div>
              </div>
              }
              { message.properties.location && messageLocationDetails[message.properties.location.uid] &&
              <div className="row">
                <div className="col-md-6 align-self-start text-left">
                  <PlanFinalizationItem source={messageLocationDetails[message.properties.location.uid]} removePlanLocations={function() {console.log("trying to remove location form messages")}} 
                  planPlace={false} showDetailsCard={showDetailsCard} uid={uid} isPlanOwner={false}/>
                </div>
                <div className="col-md-6"></div>
              </div>
              }
              { message.properties.image && 
              <div className="row">
                <div className="col-md-6 align-self-start text-left">
                  <img 
                  src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy-chat/o/${message.properties.image}?alt=media`} 
                  style={{ maxHeight: '8rem', maxWidth: '16rem' } } alt="" 
                  />
                </div>
                <div className="col-md-6"></div>
              </div>
              }
              <div className="row">
                <div className="col-md-6 align-self-start text-left">
                  <h4 className="mt-0 pt-0">
                    <span className="badge badge-pill badge-secondary text-wrap">{message.content}</span>
                  </h4>
                  { message.timestamp &&
                  <h6 className="mt-0 pt-0">
                    <p ><small>{getTime(message.timestamp.toDate().toLocaleTimeString('en-US'))}</small></p>
                  </h6>
                  }
                </div>
                <div className="col-md-6"></div>
              </div>

              <DiscussionReaction uid={uid} message={message} right={false} planID={planID} isPlanOwner={isPlanOwner}
              location={message.properties.location}/>

            </div>
            }
          </div>
        )
        }
        <span ref={(el) => { setMessagesEnd(el); }}></span>
        
        </div>

        <div className="row" {...console.log(messageAttachments, messageAttachments.location, messageLocation)}>
          {
            (messageAttachments.location !== undefined || image) &&
            <div className="col-md-6"></div>
          }
          {
            messageAttachments.location !== undefined && !loadDetails && locationDetails &&
            
              <div className="col-md-6 align-self-end text-right">
                <PlanFinalizationItem source={locationDetails} removePlanLocations={removeSelectedLocation} 
                planPlace={messageAttachments.place} showDetailsCard={showDetailsCard} uid={uid} isPlanOwner={true}/>
              </div>
          }
          {
            messageAttachments && messageAttachments.location !== undefined && loadDetails &&
            
              <div className="col align-self-end text-right">
                <div className="card">
                  <p className="text-center">Loading...</p>
                  <div className="row justify-content-center">
                    <div className="col align-self-center" align="center">
                      <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                  </div>
                </div>
              </div>
          }
          {
            image &&
            <div className="col align-self-end text-right pull-right" key={`${image.lastModified}${image.size}`}>
              <div className="row align-self-end text-right pull-right">
                <div className="col align-self-end text-right pull-right">
                  <div className="card border-secondary">
                    <div className="row text-right pull-right">
                      <div className="col">
                        <button className="btn btn-sm btn-link" style={{width:"15%"}} type="button" onClick={removeImage}>
                        <i className="fa fa-times-circle" />
                        </button>
                      </div>
                    </div>
                    
                    
                    <div className="card-img-bottom">
                      <img  src={URL.createObjectURL(image[0])} style={{ height: '8rem' } } alt="" />  
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          }
        </div>

        { email &&
        <div className="card text-center">
          <div className="row">
            <div className="col-auto align-self-end mr-0 pr-0">
              <button type="button" className="btn btn-secondary pull-left" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapse">
                <i className="fa fa-plus-circle" />
              </button>
            </div>
            <div className="col mx-0 px-0">
              <input type="text" className="form-control" name="textMessage" id="textMessage" value={messageToSend}
              placeholder="Write a Message..." onChange={onTextMessageChange}>
              </input>
            </div>
            { messageImage &&
              <div className="col-auto align-self-end mx-0 px-0">
              <button type="button" className="btn btn-primary pull-right" disabled={true}>
                <i className="fa fa-image" />
              </button>
            </div>
            }
            { messageLocationSelected &&
              <div className="col-auto align-self-end mx-0 px-0">
              <button type="button" className="btn btn-primary pull-right" disabled={true}>
                <i className="fa fa-map-marker" />
              </button>
            </div>
            }
            { (!loading && !sending) && canSend &&
              <div className="col-auto align-self-end ml-0 pl-0">
              <button type="button" className="btn btn-primary pull-right" onClick={() => { handleDiscussionSendTextButton() }}>
                <i className="fa fa-paper-plane" />
              </button>
            </div>
            }
            { (!loading && !sending) && !canSend &&
              <div className="col-auto align-self-end ml-0 pl-0">
              <button type="button" className="btn btn-primary pull-right" disabled={true}>
                <i className="fa fa-paper-plane" />
              </button>
            </div>
            }
            { (loading || sending) &&
              <div className="col-auto align-self-end ml-0 pl-0">
              <button type="button" className="btn btn-primary pull-right" disabled={true}>
              <i className="fa fa-spinner fa-spin" />
              </button>
            </div>
            }
          </div>
          <div className="collapse" id="collapse">
            <div className="card bg-secondary text-center mx-auto w-100">
              <h1 className="text-light text-center">Add Media</h1>
              <div className="card bg-secondary text-center">
                <div className="row align-items-center">
                  <div className="col align-self-center py-2">
                    <DropZone onDrop={(accepted, rejected) => { setStateImages(accepted); }}>
                      {({getRootProps, getInputProps}) => (
                      <section className="d-flex justify-content-center">
                        <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'white', borderStyle: 'dashed', borderRadius: '5px' }}>
                            <p>Drop Image Here</p>
                        </div>
                      </section>
                      )}
                    </DropZone>
                  </div>
                </div>

                { imageTooLarge &&
                  <span className="badge badge-danger">Image size too large. Max size is {MAX_HEIGHT} x {MAX_WIDTH}.</span>
                }

                <div className="row">
                  <div className="col align-self-center py-2">
                    <button type="button" className="btn btn-primary" onClick={() => { handleDiscussionSearchLocationButton() }}>
                      <i className="fa fa fa-comments-o">Recommend a Location to the plan owner</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        
        { !email &&
        <div className="card text-center">
          <div className="row">
            <div className="col-auto align-self-end mr-0 pr-0">
              <button type="button" className="btn btn-secondary pull-left" disabled={true}>
                <i className="fa fa-plus-circle" />
              </button>
            </div>
            <div className="col mr-0 pr-0">
              <input type="text" className="form-control" name="textMessage" id="textMessage" disabled={true}
              placeholder="Write a Message...">
              </input>
            </div>
            <div className="col-auto align-self-end ml-0 pl-0">
              <button type="button" className="btn btn-primary pull-right" disabled={true}>
                <i className="fa fa-paper-plane" />
              </button>
            </div>
          </div>
        </div>
        }
      </div>
      }
    </div>
  </>)
}

export default DiscussionMessaging;