import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CaliforniaWineries from './california-wineries';
import SanDiego from './san-diego';
import NapaValley from './napa-valley';
import PointOfInterestMap from './point-of-interest-map';

export default () =>
  <Switch>
    <Route path="/points-of-interest/california-wineries">
      <CaliforniaWineries />
    </Route>

    <Route path="/points-of-interest/san-diego">
      <SanDiego />
    </Route>
    
    <Route path="/points-of-interest/napa-valley">
      <NapaValley />
    </Route>

    <Route path="/points-of-interest/map/:tab">
      <PointOfInterestMap />
    </Route>
  </Switch>