import React, { useState, useEffect } from 'react';
import DropZone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import PlaceMap from './place-map';

function PlacesForm({ setStateImages, isSubmitting, savePlaceAndUploadImages, image, deletePlace, place, canDelete }) {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    
    const [lngLat, setLngLat] = useState({ lat: 0, lng: 0});

    const onSubmit = (data) => {
        console.log("Submitted");
        console.log(data);
        savePlaceAndUploadImages(data);
    };

    useEffect(() => {
        if ( place.new ) {
            setLngLat({ lat: 38.578730, lng: -121.495512 });
            console.log("form set lnglat new");
        }else {
          setLngLat({ lat: place.lat, lng: place.lng });
          console.log("form set lnglat edit");
        }
    }, [place]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col p-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="col mb-2">
                                <input 
                                type="text" className="form-control" defaultValue={place.name} name="name" placeholder="Place Name" 
                                {...register( 'name', { 
                                required: "name is required"
                                    })}/>
                            </div>
                            <div className="col mb-2">
                                { errors.name &&
                                <span className="badge badge-warning">
                                    {errors.name?.message}
                                </span>
                                }
                            </div>

                            <div className="col" align="center" {...console.log(place)} {...console.log(place.imageFileName == null)}>
                                {
                                (image !== null) &&
                                    <div className="pull-left mr-2 mb-4 mt-4" key={`${image.lastModified}${image.size}`}>
                                        <img  src={URL.createObjectURL(image[0])} style={{ height: '8rem' } } alt="" />  
                                    </div>
                                }
                                {
                                (image == null) && place.imageFileName && place.imageFileName !== null &&
                                <div className="pull-left mr-2 mb-4 mt-4"> 
                                    <img 
                                    src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy-places/o/${place.imageFileName}?alt=media`} 
                                    style={{ maxHeight: '8rem', maxWidth: '16rem' } } alt="" 
                                    />
                                </div>
                                }
                                {
                                (image == null) && (place.new || place.imageFileName == null) &&
                                <div className="pull-left mb-4 mt-4">
                                    <DropZone onDrop={(accepted, rejected) => { setStateImages(accepted); }}>
                                        {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                                                <input name="image" {...getInputProps()} {...register('image')} />
                                                    <p>Drop Image Here</p>
                                            </div>
                                        </section>
                                        )}
                                    </DropZone>
                                </div>
                                }
                                {
                                (place.imageFileName || image != null) &&
                                <div className="pull-left mb-4 mt-4">
                                    <DropZone onDrop={(accepted, rejected) => { setStateImages(accepted); }}>
                                        {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()} style={{ height: '8rem', width: '8rem', borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed', borderRadius: '5px' }}>
                                                <input name="image" {...getInputProps()} {...register('imageReplace')} />
                                                    <p>Drop Image Here To Replace Current Image</p>
                                            </div>
                                        </section>
                                        )}
                                    </DropZone>
                                </div>  
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col p-0">
                    <div className="card">
                        <div className="card-body" align="center">
                            <div className="p-4">
                                <p>Right Click to place a marker.</p>
                                <PlaceMap 
                                lngLat={lngLat}
                                setLngLat={setLngLat}
                                />
                            </div>
                        </div>
                    </div>  
                </div> 
            </div> 

            <div className="row"> 
                <div className="col-6 p-0"> 
                    <div className='card'>
                        <div className="card-body">
                            <p>Latitude</p>
                            <input 
                            type="text" className="form-control" value={lngLat.lat} name="_geoloc.lat" placeholder="Latitude" disabled
                            {...register( '_geoloc.lat', { 
                                required: "required", 
                                pattern: {
                                    value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                    message: " invalid Latitude " 
                                }
                                    })}/>
                            { errors._geoloc && errors._geoloc.lat &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lat?.message}
                            </span>
                            }
                        </div>
                    </div>
                </div>
                
                <div className="col-6 p-0">
                    <div className="card">
                        <div className="card-body">
                            <p>Longitude</p>
                            <input 
                            type="text" className="form-control" value={lngLat.lng} name="_geoloc.lng" placeholder="Longitude" disabled
                            {...register( '_geoloc.lng', { 
                            required: "required", 
                            pattern: {
                                value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                message: " invalid Longitude " 
                            }
                                })}/>

                            { errors._geoloc && errors._geoloc.lng &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lng?.message}
                            </span>
                            }
                        </div>
                    </div>
                </div>
            </div>    

            <div className="form-row">
                <div className="col mb-2 p-4">
                    {
                        canDelete && !isSubmitting &&
                        <button type="button" className="btn btn-danger pull-left" onClick={() => { deletePlace() }}>
                            <i className="fa fa-trash" />
                        </button>
                    }
                    <button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
                        { !isSubmitting && <span>Save</span>}
                        { isSubmitting && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                    { !isSubmitting && <Link to="/places" className="btn btn-light pull-right mr-2">Cancel</Link> }

                </div>
            </div>
        </form>

    );
}

export default PlacesForm;