import React, { Component, createRef, useState, useCallback, useEffect } from 'react';

import LocationCardInfo from './location-card-info';
import LocationCardForm from './location-card-form';

export default ({ hit, refresh, pageDoneChanging, pageChange, uid, user }) => {
  const [editing, setEditing] = useState(false);
  // const [updated, ]
  const [updatedItem, itemUpdated] = useState(false);

  useEffect(() => {
    if(updatedItem){
      itemUpdated(false);
      pageDoneChanging();
    }
  },[pageChange]);

  useEffect(() => {
    if(!updatedItem){
      pageDoneChanging();
    }
  },[updatedItem]);

  if (editing) {
    return (
      <LocationCardForm hit={hit} setEditing={setEditing} itemUpdated={itemUpdated} refresh={refresh} uid={uid} user={user}/>
    );
  }

  return (
    <LocationCardInfo hit={hit} updatedItem={updatedItem} setEditing={setEditing} />
  );
};