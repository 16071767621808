import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class BookingProtection extends Component {
  render() {
    return (
      <div className="container mt-2">
        <form>
          <div className="row">          
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 class="card-title text-left">Add Cancellation Protection</h5>
                    <p class="card-text text-left">Get reimbursed for your non-refundable trip costs if you have to cancel for a covered reason.</p>
                  </div>

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="pull-left">
                        You or one of your companions gets sick or injured
                      </span>
                    </li>

                    <li className="list-group-item">
                      <span className="pull-left">
                        Flight cancellation or delays due to adverse weather
                      </span>
                    </li>

                    <li className="list-group-item">
                      <span className="pull-left">
                        Theft of you visa or passport
                      </span>
                    </li>

                    <li className="list-group-item">
                      <span className="pull-left">
                        Involuntary loss of employment
                      </span>
                    </li>
                  </ul>
                </div>
               
                <Link className="btn btn-primary pull-right mt-3 ml-1" to="/booking/identity">Yes, protect my trip costs of $1,121 for $60.44.</Link>
                <Link className="btn btn-secondary pull-right mt-3" to="/booking/identity">No Thanks</Link>
              </div>              
          </div>              
        </form>
      </div>
    );
  }
}

export default BookingProtection;