import algoliasearch from 'algoliasearch/lite';
import * as Constants from '../constants';

import { SET_SEARCH_RESULTS } from '../../search/constants'
import { put, takeEvery, call } from 'redux-saga/effects'

const searchAlgolia = (query) => new Promise((resolve, reject) => {
  const client = algoliasearch('0XTKDK7IUC', 'b3b6db66d4cf5bafa139ed4c160d28ed');
  const aroundLatLng =`${query[1]}, ${query[0]}`;
  const aroundRadius = 12000; // 8km 8000
  const hitsPerPage = 300;
  const searchObject = [{ indexName: 'locations', query: '', params: { aroundLatLng, aroundRadius, hitsPerPage } }];  
    client.search(
      searchObject,  
    (error, success) => {
    if (error) {
      reject(error);
    } else {
      resolve(success);
    }
  });
});

function* fullTextSearch(action) {
  try {
    const query = yield call(searchAlgolia, action.query);

    if (query.results && query.results.length > 0) {
      yield put({ type: SET_SEARCH_RESULTS, key: action.key, results: query.results[0] });
    }
  } catch (error) {
    console.log("error", error);
  }
}

export default function* () {
  yield takeEvery(Constants.FULL_TEXT_SEARCH, fullTextSearch);
}