import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Checkbox from '../components/checkbox';
import ListingProgressBar from '../components/listing-progress-bar'
import { getStepInfo } from '../selectors';

import { getDocumentFromParams, getIDFromParams } from '../../actions/selectors';

import { reduxForm } from 'redux-form';
import * as Actions from '../../actions/actions';
import track from '../../actions/track';

const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
  listing: getDocumentFromParams(state),
  id: getIDFromParams(state),
});

const mapValueLabel = (label) => {
  console.log("mapping", label);
  switch (label) {
    case "location.beach": return "Beach" ;
    case "location.ocean": return "Ocean";
    case "location.lake": return "Lake";
    case "location.waterfront": return "Waterfront";
    case "location.oceanfront": return "Oceanfront";
    case "location.beachfront": return "Beachfront";
    case "location.lakefront": return "Lakefront";
    case "location.beachView": return "Beach View";
    case "location.oceanView": return "Ocean View";
    case "location.lakeView": return "Lake View";
    case "location.mountain": return "Mountain";
    case "location.downtown": return "Downtown";
    case "location.city": return "City";
    case "location.rural": return "Rural";
    case "location.ski": return "Ski-In/Ski-Out";
    case "location.golf": return "Golf Course";
    case "location.vineyard": return "Vineyard";
    case "for.families": return "Families";
    case "for.luxuryStay": return "Luxury Stay";
    case "for.shortStay": return "Short Stay";
    case "for.events": return "Events";
    case "for.businessRetreat": return "Business/Company Retreat";
    case "activities.golf": return "Golf" ;
    case "activities.wineries": return "Winery Tours/Tasting";
    case "activities.breweries": return "Brewery Tours/Tasting" ;
    case "activities.distilleries": return "Distillery Tours/Tasting";
    case "activities.waterSports": return "Water Sports";
    case "activities.diving": return "Snorkeling/Scuba Diving";
    case "activities.skiing": return "Skiing/Snowboarding";
    case "activities.fishing": return "Fishing";
    case "activities.hiking": return "Hiking";
    case "activities.horses": return "Horseback Riding";
    case "activities.tennis": return "Tennis";
    case "activities.climbing": return "Rock/Mountain Climbing";
    case "activities.cycling": return "Cycling/Mountain Biking";
    case "activities.museum": return "Museum";
    case "activities.artGallery": return "Art Gallery";
    case "activities.amuseumentPark": return "Amusement/Theme Park";
    default: return null;
  } 
}

class Highlights extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    track("Listing Highlights");
  }

  submit(values) {
    if (values.for) {
      values.for = Object.keys(values.for).map(f => mapValueLabel("for." + f));
    }

    if (values.location) {
      values.location = Object.keys(values.location).map(f => mapValueLabel("location." + f));
    }

    if (values.activities) {
      values.activities = Object.keys(values.activities).map(f => mapValueLabel("activities." + f));
    }

    const { id } = this.props;
    const next = `/listing/photos/${id}`;
    
    this.props.updateDocument(`listings/${id}`, values, next);
  } 

  render() {
    const { current, handleSubmit, submitting } = this.props;

    return (
      <div className="container">
        <form onSubmit={handleSubmit(this.submit)} noValidate autoComplete="off">
          <div className="row">
            <div className="col-md-3 mt-4">
              <ListingProgressBar step={current} />
            </div>

            <div className="col-md-9 mt-4">
              <div className="card w-100">
                <h5 className="card-header text-left">Location</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Beach" name="location.beach" /></div>
                    <div className="col-md-3"><Checkbox label="Ocean" name="location.ocean" /></div>
                    <div className="col-md-3"><Checkbox label="Lake" name="location.lake" /></div>
                    <div className="col-md-3"><Checkbox label="Waterfront" name="location.waterfront" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Oceanfront" name="location.oceanfront" /></div>
                    <div className="col-md-3"><Checkbox label="Beachfront" name="location.beachfront" /></div>
                    <div className="col-md-3"><Checkbox label="Lakefront" name="location.lakefront" /></div>
                    <div className="col-md-3"><Checkbox label="Beach View" name="location.beachView" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Ocean View" name="location.oceanView" /></div>
                    <div className="col-md-3"><Checkbox label="Lake View" name="location.lakeView" /></div>
                    <div className="col-md-3"><Checkbox label="Mountain" name="location.mountain" /></div>
                    <div className="col-md-3"><Checkbox label="Downtown" name="location.downtown" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="City" name="location.city" /></div>
                    <div className="col-md-3"><Checkbox label="Rural" name="location.rural" /></div>
                    <div className="col-md-3"><Checkbox label="Ski-In/Ski-Out" name="location.ski" /></div>
                    <div className="col-md-3"><Checkbox label="Golf Course" name="location.golf" /></div>           
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Vineyard" name="location.vineyard" /></div>
                  </div>
                </div>
              </div>

              <div className="card w-100 mt-3">
                <h5 className="card-header text-left">Property Good For</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Families" name="for.families" /></div>
                    <div className="col-md-3"><Checkbox label="Luxury Stay" name="for.luxuryStay" /></div>
                    <div className="col-md-3"><Checkbox label="Short Stay" name="for.shortStay" /></div>
                    <div className="col-md-3"><Checkbox label="Events" name="for.events" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-5"><Checkbox label="Business/Company Retreat" name="for.businessRetreat" /></div>
                  </div>
                </div>
              </div>

              <div className="card w-100 mt-3">
                <h5 className="card-header text-left">Nearby Activities</h5>        
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-3"><Checkbox label="Golf" name="activities.golf" /></div>
                    <div className="col-md-3"><Checkbox label="Winery Tours/Tasting" name="activities.wineries" /></div>
                    <div className="col-md-3"><Checkbox label="Brewery Tours/Tasting" name="activities.breweries" /></div>
                    <div className="col-md-3"><Checkbox label="Distillery Tours/Tasting" name="activities.distilleries" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Water Sports" name="activities.waterSports" /></div>
                    <div className="col-md-3"><Checkbox label="Snorkeling/Scuba Diving" name="activities.diving" /></div>
                    <div className="col-md-3"><Checkbox label="Skiing/Snowboarding" name="activities.skiing" /></div>
                    <div className="col-md-3"><Checkbox label="Fishing" name="activities.fishing" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Hiking" name="activities.hiking" /></div>
                    <div className="col-md-3"><Checkbox label="Horseback Riding" name="activities.horses" /></div>
                    <div className="col-md-3"><Checkbox label="Tennis" name="activities.tennis" /></div>
                    <div className="col-md-3"><Checkbox label="Rock/Mountain Climbing" name="activities.climbing" /></div>
                  </div>

                  <div className="form-row mt-3">
                    <div className="col-md-3"><Checkbox label="Cycling/Mountain Biking" name="activities.cycling" /></div>
                    <div className="col-md-3"><Checkbox label="Museum" name="activities.museum" /></div>
                    <div className="col-md-3"><Checkbox label="Art Gallery" name="activities.artGallery" /></div>
                    <div className="col-md-3"><Checkbox label="Amusement/Theme Park" name="activities.amuseumentPark" /></div>
                  </div>
                </div>
              </div>

              <div className="pull-right mt-2">
                <button className="btn btn-primary" disabled={submitting}>Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const Wrapped = reduxForm({
  form: 'listingHighlights',
})(Highlights);

export default withRouter(connect(
  mapStateToProps,
  { ...Actions },
)(Wrapped));