import React from 'react';

import { connectSearchBox } from 'react-instantsearch-dom';

const LocationSearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
  <form noValidate action="" role="search">
    <input
      type="search"
      className="form-control"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
      placeholder="Search..."
    />

    {isSearchStalled ? 'Search is stalled' : ''}
  </form>
);

export default connectSearchBox(LocationSearchBox);