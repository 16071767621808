import React from 'react';
import { connect } from 'react-redux';
import Image from '../../components/image';
import isEmpty from 'lodash/isEmpty';

import './plan-grid-item.css';

const CategoryGridItem = ({ category, selectedCategory }) => {

    function handleClick( categoryName ) {
        
        selectedCategory(categoryName);
    }

    return (
        <div className={`card plan-grid-item`} style={{ height: '100%', marginBottom: '10px' }}>
            <a className="btn" onClick={() =>  handleClick(category.name)}>
                <div className="pull-left mr-2 mb-4 mt-4">
                    <img src={`https://firebasestorage.googleapis.com/v0/b/trip-galaxy.appspot.com/o/${category.imageFileName}?alt=media` } style={{ maxHeight: '8rem', maxWidth: '16rem' } } className="card-img-top" alt="" />
                </div>

                <div className="card-body" style={{ paddingTop: '-14px', marginTop: '0' }}>    
                    <h1 className="card-title text-reset">{category.name}</h1>
                </div>
            </a>
        </div>
        )
}

export default CategoryGridItem;