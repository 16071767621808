import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import * as Actions from '../../actions/actions';
import track from '../../actions/track';

import { selectCollection } from '../../actions/selectors';

import '../../listings/containers/location.css';
import 'leaflet/dist/leaflet.css';

import { getUserLatLong } from '../../actions/selectors';

const mapStateToProps = (state) => {
  return {
    coordinates: getUserLatLong(state),
    vacationRentals: selectCollection(state, 'vacationRentals'),
  };
}

class VacationRentals extends Component {

  componentDidMount() {
    track("Vacation Rentals");
    this.props.getCollection('listings', 'vacationRentals');
  }

  render() {
    const { coordinates, vacationRentals } = this.props;

    return (
      <div>
        {
          // coordinates &&                
          // <MapContainer center={coordinates} zoom={17}>
          //   <TileLayer
          //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //     attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          //   />
          //   { vacationRentals.map(vacationRental =>
          //     <Marker key={`${vacationRental._geoloc.lat}-${vacationRental._geoloc.lng}`} position={[vacationRental._geoloc.lat, vacationRental._geoloc.lng]}>
          //       <Popup>{vacationRental.headline || vacationRental.search}</Popup>
          //     </Marker>
          //   )}
            
          // </MapContainer>
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { ...Actions },
)(VacationRentals);
/*
export default () =>
  <div className="container mt-4">
    <div className="row">
      <ListingCard
        img="/iStock-838094342.jpg"
        title="Luxury Loft in San Francisco"
        sleeps="3"
        beds="3"
        baths="2"
        price="1,700"
        rating="4"
      />
      <ListingCard
        img="/iStock-848549286.jpg"
        title="Golf Course Living"
        sleeps="3"
        beds="3"
        baths="2"
        price="800"
        rating="3.5"
      />
      <ListingCard
        img="/iStock-847040544.jpg"
        title="San Diego Vistas"
        sleeps="3"
        beds="3"
        baths="2"
        price="1,100"
        rating="5"
      />
      <ListingCard
        img="/iStock-157193895.jpg"
        title="Hollywood Hills Estate"
        sleeps="3"
        beds="3"
        baths="2"
        price="6,000"
        rating="3.5"
      />
      <ListingCard
        img="/iStock-898121938.jpg"
        title="Calistoga"
        sleeps="3"
        beds="3"
        baths="2"
        price="350"
        rating="5"
      />
    </div>
  </div>
  */