import React from 'react';
import { Field } from 'redux-form';

export default ({ iconName, ...rest }) =>
  <div className="col col-md-1 col-sm-2 col-3">
    <label className="pull-left">
      <Field name="icon" component="input" type="radio" value={iconName} />
      <i className={ `fa ${iconName} ml-2` } style={{ color: 'black' }} />
    </label>
</div>

  