import React from 'react';

const TGField = ({ input, label, type, meta: { touched, error, warning }, ...rest}) =>
  <div>
    <input {...input} placeholder={label} type={type} {...rest} />
    {
      touched && error &&
        <small style={{ width: "100%", textAlign: 'left' }} className="form-text text-muted">{error}</small>
    }
  </div>;


export default TGField;