import React from 'react';

import {
  // InstantSearch,
  // Hits,
  // SearchBox,  
  // Pagination,
  // Stats,
  // SortBy,
  // ClearRefinements,
  // RefinementList,
  // Configure,
  connectRefinementList,
  
} from 'react-instantsearch-dom';


const LocationRefinementList = ({
  items,              // object[]
  currentRefinement,  // string[]
  refine,             // function
  isFromSearch,       // boolean
  searchForItems,     // function
  createURL,          // function
}) => {
  // return the DOM output
  /*
  <ul class="list-group list-group-flush">
    <li class="list-group-item">Cras justo odio</li>
    <li class="list-group-item">Dapibus ac facilisis in</li>
    <li class="list-group-item">Vestibulum at eros</li>
  </ul>
  */

  return (
    <ul className="list-group list-group-flush">
    {items.map(item => (
      <li key={item.label} className="list-group-item" style={{ padding: '2px'}}>
        { /* eslint-disable-next-line */ }
        <a
          style={{ fontWeight: item.isRefined ? 'bold' : '', fontSize: '0.8rem' }}
          onClick={event => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          {item.label} ({item.count})
        </a>
      </li>
    ))}
  </ul>
  );

};



export default connectRefinementList(LocationRefinementList);