import sfb from './sfb';
import isEmpty from 'lodash/isEmpty';

import { app as firebase } from '../../firebase';
import * as Constants from '../constants';
import { put, takeEvery, call } from 'redux-saga/effects'

function* getCollection(action) {   
   try {
      var ref = action.collection;

      if (!isEmpty(action.filters)) {
         ref = firebase.firestore().collection(action.collection);
         for (var i = 0; i < action.filters.length; i++) {
            var filter = action.filters[i];
            ref.where(filter.attr, filter.op, filter.val);
         }
      }

      const snapshot = yield call(sfb.firestore.getCollection, ref);

      var items = {};
      snapshot.forEach(item => {
         items[item.id] = { objectID: item.id, ...item.data() };
      });

      yield put({ type: Constants.GET_COLLECTION_SUCCESS, items, collection: action.collection });
   } catch (e) {
      console.log('error', e);
   }
}

export default function* () {
  yield takeEvery(Constants.GET_COLLECTION, getCollection);
}