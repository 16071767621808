import getUserListingsSaga from './sagas/get-user-listings-saga';
import saveUserListingSaga from './sagas/save-user-listing-saga';
import getDocumentSaga from './sagas/get-document-saga';
import saveDocumentSaga from './sagas/save-document-saga';
import getCollectionSaga from './sagas/get-collection-saga';
import invokeCloudFunction from './sagas/invoke-cloud-function';

import setPlanInfoFromLocation from './sagas/set-plan-info-from-location';

import fullTextSearch from './sagas/full-text-search-saga';

import setUserListingAddressSaga from './sagas/set-user-listing-address-saga';

export default (sagaMiddleware) => {
  sagaMiddleware.run(getUserListingsSaga);
  sagaMiddleware.run(setUserListingAddressSaga);
  sagaMiddleware.run(saveUserListingSaga);  
  sagaMiddleware.run(getDocumentSaga);
  sagaMiddleware.run(saveDocumentSaga);
  sagaMiddleware.run(getCollectionSaga);
  sagaMiddleware.run(invokeCloudFunction);
  sagaMiddleware.run(fullTextSearch);
  sagaMiddleware.run(setPlanInfoFromLocation);
};

