import React from 'react';
import TripGalaxyMap from './trip-galaxy-map';
import PropTypes from 'prop-types';

const TripGalaxyMapContainer = ({width, height, lat, lng, children, defaultZoom=14}) =>

  <TripGalaxyMap				
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCG0eLmU9Da0dsGMfryLs8lLjDb-JE5LD0&v=3.exp&libraries=geometry,drawing,places`}
    lat={lat}
    lng={lng}
    defaultZoom={defaultZoom}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height, width }} />}
    mapElement={<div style={{ height: `100%` }} />}
  >
    {children}
  </TripGalaxyMap>;

  TripGalaxyMapContainer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default TripGalaxyMapContainer;