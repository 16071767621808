import { validateRequired, validatePhoneNumber, validatePostalCode, validateURL, validateLatLng } from '../../forms';
export default (values) => {
  var errors = {};

  if (!validateRequired(values.name)) {
    errors.name = 'Name required';
  }

  if (!values.categories || values.categories.length === 0) {
    errors.categories = "Category required";
  }
  /*
  if (!validateRequired(values.locationCategoryId)) {
    errors.locationCategoryId = 'Category required';
  }
  */
  
  if (values._geoloc && !validateLatLng(values._geoloc.lng)) {
    if (!errors._geoloc) {
      errors._geoloc = {};
    }
    errors._geoloc.lng = "Invalid Lng";
  }

  if (values._geoloc && !validateLatLng(values._geoloc.lat)) {
    if (!errors._geoloc) {
      errors._geoloc = {};
    }
    errors._geoloc.lat = "Invalid Lat";
  }

  if (!validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = "Ten numbers only 1234567890";
  }

  if (!validateRequired(values.postalCode) ||  !validatePostalCode(values.postalCode)) {
    errors.postalCode = "Required, five numbers only ex. 12345";
  }

  if (!validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = "Ten numbers only ex. 1234567890";
  }

  if (!validateRequired(values.city)) {
    errors.city = 'City required';
  }

  if (!validateURL(values.url)) {
    errors.url = 'Bad URL format';
  }

// description
// street
// state

// url
// description, state, categories, file

  return errors;
}


/*
const validate = values => {
  const errors = {}

  if (!values.city || values.city.length === 0) {
    errors.city = 'City Required'
  }

  return errors;
}

*/

