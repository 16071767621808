import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { isNumber } from 'lodash';
import PlaceMap from '../../places/components/place-map';

function PlacesCustomForm({ isSubmitting, savePlace, deletePlace, place, canDelete, stateOptions, states }) {
    const [lngLat, setLngLat] = useState({ lat: 0, lng: 0});

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = (data) => {
        console.log("Submitted");
        console.log(data);
        savePlace(data);
    };

    const isNumber = (value) => {
        return !isNaN(value);
    }

    useEffect(() => {
        if ( place.new ) {
            setLngLat({ lat: 38.578730, lng: -121.495512 });
            console.log("form set lnglat new");
        }else {
          setLngLat({ lat: place.lat, lng: place.lng });
          console.log("form set lnglat edit");
        }
    }, [place]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className=" col p-0">
                    <div className="card">
                        <div className="card-body">
                            <div className="col mb-2">
                                <div className="card-title">
                                    <div className="col mb-2 p-0">
                                        <input 
                                        type="text" className="form-control" defaultValue={place.name} name="name" placeholder="Place Name" 
                                        {...register( 'name', { 
                                        required: {
                                            value: true,
                                            message: "name is required"
                                        }})}/>
                                    
                                        { errors.name &&
                                        <span className="badge badge-warning">
                                            {errors.name.message}
                                        </span>
                                        }
                                    </div>
                                    
                                    <div className="col mb-2 p-0">
                                        <input 
                                        type="text" className="form-control" defaultValue={place.radius} name="radius" placeholder="Place Radius" 
                                        {...register( 'radius', { 
                                            validate: isNumber
                                        })}/>

                                        { errors.radius && errors.radius.type === "validate" &&
                                        <span className="badge badge-warning">
                                            Radius needs to be a number
                                        </span>
                                        }
                                    </div>
                                </div>
                                
                            </div>

                            <div className="col mb-2">
                                <select type="text" className="form-control" name="state" multiple 
                                {...register( 'state', { 
                                    required: {
                                        value: true,
                                        message: "state is required"
                                    }
                                })}>
                                    {stateOptions.map( state => {
                                        if(states && states.includes(state)){
                                            return <option value={state} selected>{state}</option>
                                        }else{
                                            return <option value={state}>{state}</option>
                                        }
                                    })}
                                </select>

                                { errors.state &&
                                <span className="badge badge-warning">
                                    {errors.state.message}
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

            <div className="row">  
                <div className="col p-0">         
                    <div className="card">
                        <div className="card-body">
                            
                            <div className="p-4" align="center">
                                <p>Right Click to place a marker.</p>
                                <PlaceMap 
                                lngLat={lngLat}
                                setLngLat={setLngLat}
                                />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6 p-0">       
                    <div className="card">   
                        <div className="card-body">
                            <p className="text-center">Latitude</p>

                            <input 
                            type="text" className="form-control" value={lngLat.lat} name="_geoloc.lat" placeholder="Latitude" disabled
                            {...register( '_geoloc.lat', { 
                                required: {
                                    value: true,
                                    message: "latitude is required"
                                }, 
                                pattern: {
                                    value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                    message: " invalid Latitude " 
                                }
                                    })}/>

                            { errors._geoloc && errors._geoloc.lat &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lat.message}
                            </span>
                            }
                        </div> 
                    </div> 
                </div> 

                <div className="col-6 p-0">               
                    <div className="card">   
                        <div className="card-body">
                            <p className="text-center">Longitude</p>

                            <input 
                            type="text" className="form-control" value={lngLat.lng} name="_geoloc.lng" placeholder="Longitude" disabled
                            {...register( '_geoloc.lng', { 
                            required: {
                                value: true,
                                message: "longitude is required"
                            }, 
                            pattern: {
                                value: /^\-{0,1}[0-9]+(\.[0-9]+){0,1}$/, 
                                message: " invalid Longitude " 
                            }
                                })}/>

                            { errors._geoloc && errors._geoloc.lng &&
                            <span className="badge badge-warning">
                                {errors._geoloc.lng.message}
                            </span>
                            }
                        </div>
                    </div>
                </div>   
            </div>   
             

            <div className="form-row">
                <div className="col mb-2 p-4">
                    {
                        canDelete && !isSubmitting &&
                        <button type="button" className="btn btn-danger pull-left" onClick={() => { deletePlace() }}>
                            <i className="fa fa-trash" />
                        </button>
                    }
                    <button type="submit" className="btn btn-primary pull-right" disabled={isSubmitting}>
                        { !isSubmitting && <span>Save</span>}
                        { isSubmitting && <i className="fa fa-spinner fa-spin" /> }
                    </button>
                    { !isSubmitting && <Link to="/places-custom" className="btn btn-light pull-right mr-2">Cancel</Link> }

                </div>
            </div>
        </form>

    );
}

export default PlacesCustomForm;