// import { API } from 'aws-amplify';

import * as Constants from '../actions/constants';

import {
  SET_PLANNING_TAB,
  PLANNING_SELECT_SOURCE,
  PLANNING_INCREASE_SEARCH_WIDTH,
  PLANNING_DECREASE_SEARCH_WIDTH,
  PLANNING_SET_DESTINATION_ID,
  PLANNING_SET_THINGS_TO_DO_ID,
  PLANNING_SET_INFO,
  SET_PLANNING_VIEW,
  PLANNING_TOGGLE_LOCATION_FAVORITE,
} from './constants';

export const setDestinationId = (id) => ({
  type: PLANNING_SET_DESTINATION_ID,
  id,
});

export const setThingsToDoId = (id) => ({
  type: PLANNING_SET_THINGS_TO_DO_ID,
  id,
});

export const setPlanningTab = (tab) => ({
  type: SET_PLANNING_TAB,
  tab,
});

export const selectSource = (source, sourceType) => ({
  type: PLANNING_SELECT_SOURCE,
  source,
  sourceType,
});

export const setInfo = (info) => ({
  type: PLANNING_SET_INFO,
  info,
});

export const increasePlanningSearchWidth = (searchWidth=1) => ({
  type: PLANNING_INCREASE_SEARCH_WIDTH,
  searchWidth,
});

export const decreasePlanningSearchWidth = (searchWidth=1) => ({
  type: PLANNING_DECREASE_SEARCH_WIDTH,
  searchWidth,
});

export const setPlanInfoFromLocation = () => ({
  type: Constants.SET_PLAN_INFO_FROM_LOCATION,
});

export const getPlanLocationCategories = () => (dispatch) => {
  // API.get('plan-location-categories', '/plan-location-categories').then(results => {
  //   dispatch({
  //     type: PLANNING_LOCATION_CATEGORIES_SUCCESS,
  //     planLocationCategories: JSON.parse(results.body), 
  //   })
  // }).catch((error) => {
  //   console.log("error", error);
  //   dispatch({
  //     type: PLANNING_LOCATION_CATEGORIES_ERROR,
  //     error, 
  //   })
  // });
}

export const setPlanningView = (planningView) => ({
  type: SET_PLANNING_VIEW,
  planningView,
});

export const toggleLocationFavorite = (planId, locationId) => ({
  type: PLANNING_TOGGLE_LOCATION_FAVORITE,
  planId,
  locationId,
});