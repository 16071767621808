import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class BookingIdentity extends Component {
  render() {
    return (
      <div className="container mt-2">
        <form>
          <div className="row">          
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 class="card-title text-left">Confirm Your Identity with HomeAway</h5>
                    <p class="card-text text-left">Your request is more likely to be accepted when you confirm your identity.</p>

                    
                    <div className="pull-left">
                      <div className="text-left">
                      Dan Munk
                      </div>
                      <div className="text-left">
                      danmunk@gmail.com
                      </div>

                      <div className="text-left">
                      (916) 509-3564
                      </div>

                      <div className="text-left">
                      9307 Rock Canyon Way, Orangevale, CA 95662
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-right text-muted">
                    To finish your confirmation, we'll send a 4-digit verification code to <strong>(916) 508-3564</strong>
                  </div>
                </div>

                <Link className="btn btn-primary pull-right mt-3 ml-1" to="/booking/confirmation">Send Code</Link>
                <Link className="btn btn-secondary pull-right mt-3" to="/booking/confirmation">Maybe Later</Link>
              </div>              
          </div>              
        </form>
      </div>
    );
  }
}

export default BookingIdentity;