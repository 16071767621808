import React from 'react';

import Image, { SITE } from '../components/image';
import Card from "./card";

export default () =>
<div className="container mt-4">
<div className="roa">
  <div className="point-of-interest carousel slide w-100" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <Image className="d-block w-100 img-fluid" imageType={SITE} imageFileName="iStock-670755526.jpg" /> 
        <div className="carousel-caption text-left" style={{ position: "absolute", top: 5, left: 15 }}>
          <h1 style={{ fontWeight: 900 }}>NAPA VALLEY</h1>
          <p style={{ fontWeight: 'bold' }} className="d-none d-md-block">
          The Napa Valley is filled with beautiful scenery, extraordinary food created by top-rated chefs and unforgettable wine, as well as an abundance of things to do.  There truly is something for everyone. 
          </p>  
        </div>
      </div>
    </div>
  </div>    
</div>  

<ul className="list-group text-left">
  <Card title="Calistoga" img="iStock-824897218.jpg">
  Calistoga is the place for relaxation, known for its hot springs and mud baths, it also boasts a lovely downtown, and is home to a few of our favorite wineries. 
  </Card>

  <Card title="St. Helena" img="iStock-627285142.jpg">
  St. Helena is home to some of our favorite shops, the charming downtown is full of specialty shops, boutiques, galleries, bakeries, tasting rooms, restaurants and more to explore. 
  </Card>

  <Card title="Napa" img="iStock-174763425.jpg">
  Napa, located on the river, is a beautiful town featuring famous restaurants, shops and galleries. The Oxbow Public Market is not to be missed, if you love food and drinks as much as we do. Napa is also host to a vibrant nightlife.  
  </Card>

  <Card title="Yountville" img="iStock-616251384.jpg">
  Yountville is a foodie paradise, boasting more Michelin stars per capita than anywhere in North America. The home of the famous French Laundry, Chef Thomas Keller's three Michelin-starred restaurant, and our favorite, Bouchon Bakery. Yountville is also known for its art scene, including museums and a performing arts theater.
  </Card>

  <Card title="Oakville/Rutherford" img="iStock-507759766.jpg">
  Oakville and Rutherford are the towns for you if you love Cabernet Sauvignon. We also love the Oakville Market, stop in for gifts, snacks, or picnic supplies. 

  </Card>

  <Card title="American Canyon" img="iStock-937810282.jpg">
  American Canyon, located in the southern end of the Napa Valley, is the gateway to this amazing area featuring world-class wineries and restaurants. The town boasts golf courses, as well as wetlands and a wilderness preserve with hiking and biking trails and areas for bird watching.
  </Card>  
</ul>
</div>;