import { LISTINGS_SUCCESS, LISTING_SAVED } from './constants';

const steps = [
  {
    name: "Address",
    path: '/listing/address',
  },
  {    
    name: "Verify Location",
    path: '/listing/location',
  },
  {
    name: "Describe Your Property",
    path: '/listing/details',
  },
  {
    name: "Accomodations",
    path: '/listing/accomodations',
  },
  {
    name: "Amenities",
    path: '/listing/amenities',
  },
  {
    name: "Highlights",    
    path: '/listing/highlights',    
  },
  {
    name: "Photos of Your Property",
    path: '/listing/photos',
  },
  {
    name: "Listing",
    path: '/listing/info',
  },
  {
    name: "Booking Options",
    path: '/listing/options',
  },

  {
    name: "Calendar",
    path: '/listing/calendar',
  },
];

export default (state={ Count: 0, Items: [], Item: {}, steps }, action) => {
  switch (action.type) {
    case LISTINGS_SUCCESS:
      console.log('reducer', action);
      return { ...state, Items: action.Items, Count: action.Count };
    case LISTING_SAVED:
      console.log('listings reducer', action);
      return { ...state, ...action.results };
    default:
      return state;
  }
};