import React from 'react';
import { Route, Switch } from 'react-router';

import LocationSubCategories from './containers/location-sub-categories';
import LocationSubCategory from './containers/location-sub-category';

export default () =>
  <Switch>
    <Route path="/location-sub-categories/:id">
      <LocationSubCategories />
    </Route>

    <Route path="/location-sub-category/:locationCategoryId/:id">
      <LocationSubCategory />
    </Route>
  </Switch>;