import React from 'react';
import { Route, Switch } from 'react-router';

import { BookingRoutes } from '../booking';
import Home from '../home';

// import{ SCRoutes } from '../sc';

import LocationSearch from '../locations/containers/location-search';
import LocationCategoryRename from '../location-categories/containers/location-category-rename';
import { ListingRoutes } from '../listings';
import { LocationCategories, LocationCategory } from '../location-categories';
import { ReadsFilters, ReadsFilter, ReadsCities, ReadsCity } from '../reads-filters';
import HomePage from '../home/containers/homepage';
import TermsAndConditions from '../home/containers/termAndConditions';
import PrivacyPolicy from '../home/containers/privacyPolicy';

import LocationSubCategoriesRoutes from '../location-sub-categories/location-sub-categories-routes';
import { Location } from '../locations';
import { PlanningRoutes } from '../planning';
import { PlacesRoutes } from '../places';
import { PlacesCustomRoutes } from '../places-custom';
import ProfileRoutes from '../profile/routes';
import { PointsOfInterestRoutes } from '../points-of-interest';
import Login from "../session/login";
import { VacationRentalRoutes } from '../vacation-rentals';
import './body.css';
import { Activities } from '../activities';
import { Reports } from '../location-reports';
import FeaturedPointsOfInterestRoutes from '../featured_points_of_interest/routes';

export default ({ match, ...rest }) =>
  <div className="clearfix"> 
    <ListingRoutes />
    <VacationRentalRoutes />
    <PointsOfInterestRoutes />
    <BookingRoutes />
    <PlanningRoutes />
    <ProfileRoutes />
    <LocationSubCategoriesRoutes />
    <PlacesRoutes />
    <PlacesCustomRoutes />
    <FeaturedPointsOfInterestRoutes />

    <Route exact path="/home">
        <HomePage/>
    </Route>

    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>

      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>

      <Route exact path="/login">
      <Login />
      </Route>
      
      <Route path="/location/:id">
        <Location />
      </Route>

      <Route path="/location-categories">
        <LocationCategories />
      </Route>

      <Route exact path="/locations/:id?">
        <LocationSearch />
      </Route>


      <Route path="/location-category/:id">
        <LocationCategory />
      </Route>

      <Route path="/location-category-rename/:id">
        <LocationCategoryRename />
      </Route>

      <Route path="/activities">
        <Activities />
      </Route>

      <Route path="/location-reports">
        <Reports />
      </Route>

      <Route path="/reads-filters">
        <ReadsFilters />
      </Route>

      <Route path="/reads-filter/:id">
        <ReadsFilter />
      </Route>

      <Route path="/reads-cities/:id">
      <ReadsCities />
    </Route>

    <Route path="/reads-city/:readsRegionId/:id">
      <ReadsCity />
    </Route>

    </Switch>
  </div>;