import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Place } from './place';
import PlacesCard from '../components/places-card';
import PlaceSearchBox from '../components/place-search-box';

import searchClient from '../../search';

import {
    InstantSearch,
    Hits,
    SearchBox,  
    Pagination,
    Stats,
    Configure
  } from 'react-instantsearch-dom';

function PlacesSearch() {
   
    return (
        <div className="container mt-4">
            <InstantSearch searchClient={searchClient} indexName="places_metadata">
                <div className="card mt-4">
                    <div className="card-header">
                        <h3 className="pull-left" style={{ marginBottom: 0 }}>
                            Places Metadata
                            <Stats />

                        </h3>
                        {
                            <Link className="btn btn-sm btn-outline-secondary pull-right" to='/place/new'>
                              <i className="fa fa-plus"/>
                              <span className="d-none d-md-inline"  style={{ marginLeft: 4 }}>Place</span>
                            </Link>
                        }
                    </div>
                    <div className="card-body">              
                        <div className="container">
                            <div className="row form-group">
                            <div className="col d-flex justify-content-center">
                                <Pagination />
                            </div>
                            </div>
                            <div className="row form-group">                  
                                <div className="col d-flex justify-content-center">
                                    <PlaceSearchBox />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col d-flex justify-content-center">
                                    <PlacesCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </InstantSearch>  
        </div>  
        

    );
}

export default PlacesSearch;