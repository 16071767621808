import { createSelector } from 'reselect';
import get from 'lodash/get';

const getSession = (state) => state.session;
const getDocuments  = (state) => state.session.documents;

export const getUser = createSelector(
  [getSession], (session) => { return session.user }
);

export const getUID = createSelector(
  [getSession], (session) => get(session, 'user.uid')
);

export const selectSessionDocument = (state, id) => getDocuments(state)[id];
