import React, { Component } from 'react';

import { createBrowserHistory } from 'history';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, combineReducers, applyMiddleware /*, compose */ } from "redux";
import { reducer as form } from "redux-form";
import thunk from "redux-thunk";

import createSagaMiddleware from 'redux-saga'
import configureSagas from '../actions/configure-sagas';

import { createLogger } from "redux-logger";

import search from '../search/reducer';
import listings from '../listings/reducer';
import session from '../session/reducer';

import collections from '../data/collections-reducer';

import { planning } from '../planning';

import { Provider } from "react-redux";

import SessionLoader from '../session/containers/session-loader';
import LocationLoader from '../session/containers/location-loader';

import Footer from './footer';

import './app.css';

import Navbar from './nav-bar';
import Body from './body';

import $ from "jquery";

import 'popper.js';
import "bootstrap";
import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";

import { ToastContainer /*, toast */ } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

window.$ = window.jQuery = $; // eslint-disable-line no-multi-assign

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

const reducers = combineReducers({
  listings,
  session,
  planning,
  collections,
  form,
  search,
  router: connectRouter(history),
});

const logger = createLogger({
  collapsed: true,
  predicate: (getState, action) => !action || !action.type || !action.type.includes("redux-form"),
});

//const sagaMiddleware = createSagaMiddleware();


// export const makeStore = () => {
// eslint-disable-next-line no-underscore-dangle
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, {}, composeEnhancers(middleware));
const store = createStore(connectRouter(history)(reducers), {}, applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk, logger));// , {}, thunk, logger());
  
// sagaMiddleware.run(saveLocationSaga);
configureSagas(sagaMiddleware);


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAdmin: true,
      isAdmin: false,
    };
  }

  render() {
    return (
      <Provider store={store}>        
        <ConnectedRouter history={history}>          
          <div className="App" {...console.log(this.props)}>
            <ToastContainer />
            <SessionLoader />
            <Navbar />
            <Body />
            <Footer />
            <LocationLoader />   
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
