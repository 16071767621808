import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactMapGL, { NavigationControl, Popup } from 'react-map-gl';

import * as Constants from '../constants';
import PlanGrid from './plan-grid';
import CategoryGrid from './category-grid';
import { SizeMe } from 'react-sizeme'

import PlanMarker from './plan-marker';

const mapStatToProps = (state, ownProps) => {
  return {
    tab: state.planning.tab,
    mapView: Constants.PLANNING_VIEW_MAP === state.planning.planningView,
  };
};

class RootPlanTabContent extends Component {
  constructor(props) {
    super(props);

    this.state = {};    

    this.renderPopup = this.renderPopup.bind(this);
    this.setSelectedLocation = this.setSelectedLocation.bind(this);
  }

  setSelectedLocation(selectedLocation) {
    // console.log("set selected location", selectedLocation);
    this.setState({ ...this.state, selectedLocation });
  }

  renderPopup() {
    const { selectedLocation } = this.state;

    return (
      selectedLocation && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={selectedLocation._geoloc.lng}
          latitude={selectedLocation._geoloc.lat}
          closeOnClick={false}
          onClose={() => this.setState({ ...this.state, selectedLocation: null })}
        >
          {selectedLocation.name}
        </Popup>
      )
    );
  }

  render() {
    const { mapView, destinationLocations, onViewportChange, viewport, locationCategories, 
    showCategories, selectedCategory, searchResults, getawayHatchSearch, googleSearch, showDetailsCard,
    googleNextPage, loadMoreResults, yelpSearch, yelpNextPage, selectedLocations, addToSelectedLocations, 
    removeFromSelectedLocations, place, isPlanOwner, selectingForMessage, addToMessageLocation, localityID, ...rest /*, setSelectedLocation */ } = this.props;

    // console.log("root plan content", viewport);

    return (
      <div>
        <div style={{ padding: 0, display: (mapView ? 'none' : 'block' ) }} className="container">
          
            { !showCategories &&
              <PlanGrid {...rest} locations={searchResults} getawayHatchSearch={getawayHatchSearch} 
              googleSearch={googleSearch} showDetailsCard={showDetailsCard} googleNextPage={googleNextPage} 
              loadMoreResults={loadMoreResults} yelpSearch={yelpSearch} yelpNextPage={yelpNextPage} 
              selectedLocations={selectedLocations} addToSelectedLocations={addToSelectedLocations} 
              removeFromSelectedLocations={removeFromSelectedLocations} place={place} isPlanOwner={isPlanOwner}
              selectingForMessage={selectingForMessage} addToMessageLocation={addToMessageLocation} localityID={localityID}/>  
            }

            {
              showCategories && 
              <CategoryGrid locationCategories={locationCategories} selectedCategory={selectedCategory}/>
            }   
          
          <div className="row">
          <SizeMe>
                {({ size }) => <div style={{width: '100%', backgroundColor: '#eee', color: 'white', fontSize: '0.9em' }}>{size.width}px</div>}
              </SizeMe>
          </div>
        </div>

        

        <div style={{ display: (mapView ? 'block' : 'none' ) }}>          
          <ReactMapGL
            {...viewport}
            mapboxApiAccessToken='pk.eyJ1IjoiZGFubXVua3RnIiwiYSI6ImNqdHo4b3ZjMDMyMWE0M20yN2NkOHFidG4ifQ.RsFGVqqQBEYWKGnXE4HDow'
            onViewportChange={onViewportChange}
            width='100%'
            height={700}
          >
            

            { destinationLocations.map((location) =>
                <PlanMarker
                  key={location.objectID}
                  location={location}
                  locationCategories={locationCategories}
                  setSelectedLocation={this.setSelectedLocation}
                />
            )}

            { this.renderPopup() }

            <div style={{position: 'absolute', right: 10, top: 10}}>
              <NavigationControl onViewportChange={onViewportChange} />
            </div>
          </ReactMapGL>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStatToProps,
)(RootPlanTabContent);