import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router";

import LocationSubCategoryForm from '../components/location-sub-category-form';

import { getDocument, insertDocument, updateDocument, deleteDocument } from '../../firebase';

export default () => {
  var { locationCategoryId, id } = useParams();
  var history = useHistory();
  const [locationCategory, setLocationCategory] = useState(null);
  const [locationSubCategory, setLocationSubCategory] = useState(null);

  useEffect(() => {
    getDocument('locationCategories', locationCategoryId).then(locationCategory => {      
      if (id !== 'new') {
        getDocument('locationCategories', id).then(locationSubCategory => {
          setLocationSubCategory(locationSubCategory);
          setLocationCategory(locationCategory);
        });
      } else {
        setLocationCategory(locationCategory);
      }
    });
  }, [locationCategoryId]);

  const saveLocationSubCategory = (data) => {
    console.log(data);

    if (id === 'new') {
      data.parentID = locationCategoryId;

      insertDocument('locationCategories', data).then((item) => {
        history.push(`/location-sub-categories/${locationCategoryId}`);
      });
    } else {
      updateDocument('locationCategories', id, data).then((item) => {
        history.push(`/location-sub-categories/${locationCategoryId}`);
      });
    }
  
  }

  const deleteLocationSubCategory = () => {
    console.log('delete');
    deleteDocument('locationCategories', id).then((item) => {
      history.push(`/location-sub-categories/${locationCategoryId}`);
    });
  }

  console.log(locationCategoryId, id);

  if (!locationCategory) {
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <h5 className="text-left">Loading...</h5>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          {!locationCategory && <h5 className="mb-0 pull-left">Loading...</h5>}
          { locationCategory && <h5 className="mb-0 pull-left"> {locationCategory.name} </h5> }
        </div>
        <div className="card-body">
          <LocationSubCategoryForm
            locationCategory={locationCategory}
            locationSubCategory={locationSubCategory}
            deleteLocationSubCategory={deleteLocationSubCategory}
            saveLocationSubCategory={saveLocationSubCategory} />
        </div>
      </div>
    </div>
  );
};



