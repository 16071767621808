import React from 'react';
import TripGalaxyMapContainer from '../map/trip-galaxy-map-container';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
 
import Image, { SITE } from '../components/image';

export default ({ title, children, lat, lng, img, zoom=9 }) =>
  <div className="card w-100 mt-3">
    <div className="card-header text-left">
      <h3>{title}</h3>
    </div>
    <div className="card-body">
      <div className="fluid-container">
        <div className="row">
          { (img || (lat && lng)) && 
            <div className="col-md-3">
              { img && <Image imageFileName={img} imageType={SITE} className="img-fluid" alt=""  />}
              { lat && lng && <TripGalaxyMapContainer width={200} height={200} lat={lat} lng={lng} defaultZoom={zoom} /> }    
            </div>
          }
          <div
            className={ classNames("text-left", {
                'col-md-9': (img || (lat && lng)),
                'col-md-12': (!img && !lat && !lng),
              })
            }
          >
            {children} <Link to="/points-of-interest/map/listings">Details</Link>
          </div>
        </div>
      </div>
    </div>
  </div>