import * as Constants from './constants';

export const setSearchRefinement = (searchIndex, searchRefinement) => ({
  type: Constants.SET_SEARCH_REFINEMENT,
  searchIndex,
  searchRefinement,
})

export const setSearchPage = (page) => ({
  type: Constants.SET_SEARCH_PAGE,
  page,
});