import React from 'react';

export const firebaseImageURL = (imageFileName) => 
  `https://firebasestorage.googleapis.com/v0/b/trip-galaxy.appspot.com/o/${imageFileName}?alt=media`;


export default ({ imageFileName, style, rest }) => {
  if (imageFileName) {
    // style={{ maxHeight: '8rem', maxWidth: '16rem' } }
    return (
      <img src={firebaseImageURL(imageFileName)} style={style} {...rest}  alt="" />
    )
  }

  return (
    <span display="none"></span>
  )
}
