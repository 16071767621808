import React from 'react';
import { Route, Switch } from 'react-router';

import Profile from './containers/profile';

export default () =>
  <Switch>
    <Route exact path="/profile">
      <Profile />
    </Route>

  </Switch>;