import React, { Component, setState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router";

import { getUID } from '../../session/selectors';
import { selectCollection, selectDocument } from '../../actions/selectors';
import { selectThingsToDo, selectServices, selectPlanLocations } from '../selectors';
import { getIDFromParams, getLastTokenFromPath } from '../../actions/selectors';
import { savePlanData, deletePlanData, getUsersFromPlan, getUsersFromIDs, 
  inviteUser, getUserFromEmail, inviteRemoveUser } from '../../firebase';

import * as Actions from '../actions';
import * as CoreActions from '../../actions/actions';

import PlanLocationSelect from '../components/plan-location-select';
import PlanFinalization from '../components/plan-finalization';
import PlanInvite from '../components/plan-invite';
import findIndex from 'lodash/findIndex';

import find from 'lodash/find';
import {v4} from 'uuid';
import moment from 'moment';
import { push } from 'connected-react-router';

const mapStateToProps = (state, ownProps) => {
  const destinationLocations = selectPlanLocations(state);
  //const tab = state.planning.tab || "All";
  const planId = getIDFromParams(state);
  //const plan = selectDocument(state, 'deprecatedPlans', planId);

  //const category = planId ? null : getLastTokenFromPath(state);

  return {
    //tab,
    info: false /*state.session.info*/,
    planId,
    //category,
    isNew: !Boolean(planId),
    uid: getUID(state),
    ...state.planning,
    //plan,
    planningView: state.planning.planningView,
    thingsToDoLocations: selectThingsToDo(state),
    serviceLocations: selectServices(state),
    destinationLocations,
    locationCategoryTypes: selectCollection(state, 'locationCategoryTypes') || {},
    locationCategories: selectCollection(state, 'locationCategories') || {},
  }
};

const google = window.google;

let service;

class Plan extends Component {
  constructor(props) {
    super(props);
    this.onSelected                   = this.onSelected.bind(this);
    this.onViewportChange             = this.onViewportChange.bind(this);
    this.viewClassName                = this.viewClassName.bind(this);
    this.savePlan                     = this.savePlan.bind(this);
    this.toSharePlan                  = this.toSharePlan.bind(this);
    this.backFromFinalize             = this.backFromFinalize.bind(this);
    this.addToSelectedLocations       = this.addToSelectedLocations.bind(this);
    this.removeFromSelectedLocations  = this.removeFromSelectedLocations.bind(this);
    this.resetSelectedLocations       = this.resetSelectedLocations.bind(this);
    this.addPlaceDataToLocalities     = this.addPlaceDataToLocalities.bind(this);
    this.setPlanName                  = this.setPlanName.bind(this);
    this.setLocalityName              = this.setLocalityName.bind(this);
    this.savePlanForm                 = this.savePlanForm.bind(this);
    this.deletePlanForm               = this.deletePlanForm.bind(this);
    this.getPlanUsers                 = this.getPlanUsers.bind(this);
    this.addEmailToInvite             = this.addEmailToInvite.bind(this);
    this.acceptInvite                 = this.acceptInvite.bind(this);
    this.declineInvite                = this.declineInvite.bind(this);
    this.addToMessageLocation         = this.addToMessageLocation.bind(this);
    this.selectLocationFromDiscussion = this.selectLocationFromDiscussion.bind(this);
    this.removeLocationFromDiscussion = this.removeLocationFromDiscussion.bind(this);
    this.addLocationFromDiscussion    = this.addLocationFromDiscussion.bind(this);
    this.setDetailedLocations         = this.setDetailedLocations.bind(this);
    this.removeLocality               = this.removeLocality.bind(this);
    this.setLocationTime              = this.setLocationTime.bind(this);
    this.setLocalityDateRange         = this.setLocalityDateRange.bind(this);
    this.stopsOnDay                   = this.stopsOnDay.bind(this);
    this.getDatesInRange              = this.getDatesInRange.bind(this);
    this.localitiesOnDragEnd          = this.localitiesOnDragEnd.bind(this);

    this.state = {
      viewport: {
        zoom: 12,
        minZoom: 10,
      },

      // locations
      place: false,
      localities: [], //{},---------------------------------------------------------------------------------------------------changed to array
      selectedLocations: [], // stops
      selectedScheduledStops: [],
      selectedUnscheduledStops: [],
      localityID: false,
      passedLocalityID: false,
      detailedLocations: {},
      localitiesDateRange: [],
      passedLocalityDate: false,



      // bool checks for user status and current page to display
      planSelect: true,
      planInvite: false,
      planFinalize: false,
      isPlanOwner: false,
      isPlanUser: false,
      isPlanInvitee: false,
      newPlan: true,
      fromSelectingLocationMessage: false,
      selectingForMessage: false,

      //plan data
      imageNumber: (Math.floor(Math.random() * 30) + 1),
      planData: false,
      planName: "",
      planUsers: false,
      invites: [],
      email: false,
      messageLocation: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if ((!this.state.place) && this.props.info && this.props.isNew) { //---------------when creating a new localityID, add a new locality to localities, when going to finalization add data to locality
      if (this.state.localityID) {
        console.log("updating place with locality id");
        this.setState({place: this.props.info, isPlanOwner: true, isPlanUser: true, isPlanInvitee: false});
      } else {
        console.log("updating place with NO locality id");
        this.setState({place: this.props.info, isPlanOwner: true, isPlanUser: true, isPlanInvitee: false});
      }
    }

    if ( this.props.info && (this.props.info.name !== prevProps.info.name)) {
      console.log("RESETTING LOCALITY ID TO FALSE ", this.state.passedLocalityID, this.state.localityID);
      if (this.state.passedLocalityID) {
        this.setState({
          place: this.props.info,
          passedLocalityID: false,
          passedLocalityDate: false,
        }, () => this.resetSelectedLocations(this.state.localityID));
      } else {
        this.setState({
          place: this.props.info,
          localityID: false,
          passedLocalityDate: false,
        }, () => this.resetSelectedLocations());
      }
      
    }
    
    if (!this.state.planData && !this.state.place && this.props.location.state && !this.state.isNew) {
      console.log("loading plan");
      let planPlace = false;
      let planSelectedLocations = [];

      let planSelectedScheduledStops = [];
      let planSelectedUnscheduledStops = [];
      let localityID = false;

      let plan = this.props.location.state;

      
      plan.created = new Date(plan.created._seconds * 1000);

      for (let j = 0; j < plan.localities.length; j++) {
        if (plan.localities[j].end !== null && plan.localities[j].start !== null) {
          plan.localities[j].end =  moment(new Date(plan.localities[j].end._seconds * 1000));
          plan.localities[j].start = moment(new Date(plan.localities[j].start._seconds * 1000));
        }

        for (let k = 0; k < plan.localities[j].scheduledStops.length; k++) {
          if (plan.localities[j].scheduledStops[k].day !== null) {
            plan.localities[j].scheduledStops[k].day = new Date(plan.localities[j].scheduledStops[k].day._seconds * 1000);
          }

          if (plan.localities[j].scheduledStops[k].timeOfDay !== null) {
            plan.localities[j].scheduledStops[k].timeOfDay = new Date(plan.localities[j].scheduledStops[k].timeOfDay._seconds * 1000);
          }
        }

        for (let k = 0; k < plan.localities[j].unscheduledStops.length; k++) {
          if (plan.localities[j].unscheduledStops[k].day !== null) {
            plan.localities[j].unscheduledStops[k].day = new Date(plan.localities[j].unscheduledStops[k].day._seconds * 1000);
          }

          if (plan.localities[j].unscheduledStops[k].timeOfDay !== null) {
            plan.localities[j].unscheduledStops[k].timeOfDay = new Date(plan.localities[j].unscheduledStops[k].timeOfDay._seconds * 1000);
          }
        }
      }
      

      // old structure support
      if (typeof plan.localities === 'object' && plan.localities !== null) {
        if (Object.keys(plan.localities).length === 0) {
          planPlace = false;
          planSelectedLocations = [];

        } else {
          planPlace = plan.localities[Object.keys(plan.localities)[0]].place;
          planPlace.id = planPlace.objectID;
          planSelectedLocations = plan.localities[Object.keys(plan.localities)[0]].locations;
        }

      //current structure
      } else if (Array.isArray(plan.localities)) { //---------------------------------------------------------------------load plan structure changes
        if (plan.localities.length === 0) {
          planPlace = false;
          planSelectedLocations = [];

        } else {
          planPlace = plan.localities[0].place; //this.props.location.state.localities[this.props.location.state.localities[0]].place;
          planPlace.id = planPlace.objectID;

          planSelectedScheduledStops = plan.localities[0].scheduledStops;
          planSelectedUnscheduledStops = plan.localities[0].unscheduledStops;

          planSelectedLocations = planSelectedScheduledStops.concat(planSelectedUnscheduledStops); // --- need to load location from stops, 
          //stops.map( stop => stop.location );

          
        }
      }

      this.setState({
        planData: plan,
        localities: plan.localities,
        place: planPlace,
        selectedLocations: planSelectedLocations,
        selectedScheduledStops: planSelectedScheduledStops,
        selectedUnscheduledStops: planSelectedUnscheduledStops,
        planName: plan.name
      }, () => {

        let users = plan.users;
        console.log(users);

        this.getDatesInRange();
        
        this.getPlanUsers(users);

        this.setState({
          planSelect: false,
          planInvite: false,
          planFinalize: true
        });
      })
    }

    if (this.state.isNew && !this.state.isPlanOwner) {
      console.log("new plan");
      this.setState({isPlanOwner: true, isPlanUser: true, isPlanInvitee: false});
    }
  }
  
  onSelected(viewport, item) {
    console.log('on selected', viewport, item);
    this.setState({ ...this.state, viewport,  });
    this.props.fullTextSearch('locations', 'plan_locations', [item.lng, item.lat]);
    this.props.setInfo(item);
    this.setState({ infoFromLocation: false });
  }

  async getPlanUsers(users) {
    //let planUsers = await getUsersFromPlan(this.props.location.state.users);
    //let data = { data: {users: users}};
    let data = {users: users};

    console.log(data);

    let returnedUsers = await getUsersFromIDs(data);
    console.log(returnedUsers);

    this.setState({planUsers: returnedUsers.data}, () => {
      let uid = this.props.uid;
        
      if (this.state.planData.owner && (this.state.planData.owner.localeCompare(uid) == 0)) {
        let userIndex = findIndex(this.state.planUsers, function(user) { return (user.uid.localeCompare(uid) == 0)});
        let email;
        if (userIndex != -1 && this.state.planUsers[userIndex].email) {
          email = this.state.planUsers[userIndex].email;
        } else {
          email = false;
        }

        this.setState({isPlanOwner: true, isPlanUser: true, isPlanInvitee: false, email: email});
        //this.setState({isPlanInvitee: true, isPlanOwner: false})

        console.log("is owner");
      } else {
        console.log("not owner");
        
        if (this.state.planData.invites) {
          //if (this.state.planUsers.includes(uid)) {
          if (this.state.planData.users.includes(uid)) {
            let userIndex = findIndex(this.state.planUsers, function(user) { return (user.uid.localeCompare(uid) == 0)});
            let email;
            if (userIndex != -1 && this.state.planUsers[userIndex].email) {
              email = this.state.planUsers[userIndex].email;
            } else {
              email = false;
            }

            console.log("not invitee");
            this.setState({isPlanInvitee: false, isPlanUser: true, isPlanOwner: false, email: email});

          } else if (findIndex(this.state.planData.invites, function(invite) { if (invite.inviteeUid) return (invite.inviteeUid.localeCompare(uid) == 0)}) != -1) {
            console.log("is invitee", this.state.planData.invites, findIndex(this.state.planData.invites, function(invite) { if (invite.inviteeUid) return (invite.inviteeUid.localeCompare(uid) == 0)}) != -1);
            this.setState({isPlanInvitee: true, isPlanUser: false, isPlanOwner: false});

          } else {
            console.log("Not part of plan and not invited");
            this.props.history.push("/");
            //not in plan and not invited
          }
        }
      }
    });

    /*let response = await fetch('http://localhost:5001/trip-galaxy/us-central1/getUsersFromIDs', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    response.json().then(data => {
      console.log(data);
      this.setState({planUsers: data.data}, () => {
        
        if (this.state.planData.owner && (this.state.planData.owner.localeCompare(this.props.uid) == 0)) {
          this.setState({isPlanOwner: true, isPlanInvitee: false});
          //this.setState({isPlanInvitee: true, isPlanOwner: false})
          console.log("is owner");
        } else {
          console.log("not owner");
          let uid = this.props.uid;

          if (this.state.planData.invites) {
            if (this.state.planUsers.includes(uid)) {
              console.log("not invitee");
              this.setState({isPlanInvitee: false, isPlanOwner: false});
            } else if (findIndex(this.state.planData.invites, function(invite) { return (invite.inviteeUid.localeCompare(uid) == 0)}) != -1) {
              console.log("is invitee", this.state.planData.invites, findIndex(this.state.planData.invites, function(invite) { return (invite.inviteeUid.localeCompare(uid) == 0)}) != -1);
              this.setState({isPlanInvitee: true, isPlanOwner: false});
            } else {
              this.props.history.push("/");
              //not in plan and not invited
            }
          }
        }
      });
    })*/
  }

  savePlan() { //--------------------------------------------------------------------------------------------reset locality id when going to finalization
    
    if (this.state.selectingForMessage) {
      this.setState({
        planSelect: false,
        planInvite: false,
        planFinalize: true,
        localityID: false,
        passedLocalityDate: false,
      });

    } else {
      
      let localities = this.state.localities;
      //let locations = Object.keys(localities);

      //for(let i = 0; i < locations.length; i++) {
        // go through each place and check algolia metadata to see if they have a photo
      //}

      //if (this.state.planSelect) {
        this.setState({
          planSelect: false,
          planInvite: false,
          planFinalize: true,
          localityID: false,
          passedLocalityDate: false,
        });
      //} 
      /*else if (this.state.planInvite) {
        this.setState({
          planSelect: false,
          planInvite: false,
          planFinalize: true
        });
      }*/
        
      }
  }

  toSharePlan() {
    this.setState({
      planSelect: false,
      planInvite: true,
      planFinalize: false
    });
  }

  backFromFinalize(locality = false, place = false, date = false) {
    let noPlace = false;

    if (place === false) {
      noPlace = true;
    }

    this.setState({
      localityID: locality,
      passedLocalityID: locality,
      place: place,
      passedLocalityDate: date,
    }, () => this.setState({
      planSelect: true,
      planInvite: false,
      planFinalize: false,
    }));
  }

  viewClassName(viewName) {
    viewName = (this.props.planningView === viewName ? 'btn-secondary' : 'btn-outline-secondary');
    return `btn ${viewName}`;        
  }

  onViewportChange = (newViewport) => {
    this.setState({ viewport: newViewport });
  }  

  addToSelectedLocations(locationID, service, object) { //----------------------------------------------------if no locality id then make a new one
    console.log("in addToSelectedLocations with date: ", this.state.passedLocalityDate);

    let location = {
      service: service,
      uid: locationID,
    }

    let stop = {    //-----------------------------------------------------------------------------------------------updated selected locations to be stops (use findIndex)
      day: null,
      location: location,
      id: v4(),
      timeOfDay: null,
    }

    if (this.state.passedLocalityDate) {
      console.log("adding date");
      stop.day = this.state.passedLocalityDate;

      let locations = this.state.selectedLocations;
      locations.push(stop);
      let scheduledStops = this.state.selectedScheduledStops;
      scheduledStops.push(stop);
      //locations.push(location);

      this.setState({
        selectedLocations: locations,
        selectedScheduledStops: scheduledStops,
      }, () => this.addPlaceDataToLocalities()); //-------------------------------------------------------------check this function


    } else {

      let locations = this.state.selectedLocations;
      locations.push(stop);
      let unscheduledStops = this.state.selectedUnscheduledStops;
      unscheduledStops.push(stop);
      //locations.push(location);

      this.setState({
        selectedLocations: locations,
        selectedUnscheduledStops: unscheduledStops,
      }, () => this.addPlaceDataToLocalities()); //-------------------------------------------------------------check this function
    }

    
  }

  addToMessageLocation(locationID, service, object) {
    console.log("in addToMessageLocation");

    let place = {
      lat: this.state.place.lat,
      lng: this.state.place.lng,
      name: this.state.place.name,
      objectID: this.state.place.id,
      state: this.state.place.state
    }

    let location = {
      service: service,
      uid: locationID,
      //place: place
    }

    let messageLocation = {
      location: location,
      place: place
    }

    this.setState({
      fromSelectingLocationMessage: true,
      messageLocation: messageLocation,
      selectingForMessage: false,
    }, () => this.setState({
            planSelect: false,
            planInvite: false,
            planFinalize: true
            })
      );
  }

  selectLocationFromDiscussion() {
    console.log("selectLocationFromDiscussion");

    this.setState({
      selectingForMessage: true,
      planSelect: true,
      planInvite: false,
      planFinalize: false
    });
  }

  removeLocationFromDiscussion() {
    console.log("removeLocationFromDiscussion");

    this.setState({
      selectingForMessage: false,
      messageLocation: false
    });
  }

  removeFromSelectedLocations(locationID, place, localityID = false) { //---------------------------can find location once then use stop id to find it for rest?
    console.log("in removeFromSelectedLocations, ", locationID, place);

    let localities = this.state.localities;
    let locations = this.state.selectedLocations;
    let unscheduledStops = this.state.selectedUnscheduledStops;
    let scheduledStops = this.state.selectedScheduledStops;
    
    let locationsIndex = false;
    let localitiesIndex = false;
    let stopID = find(this.state.selectedLocations, function(location) {return (locationID.localeCompare(location.location.uid) === 0)});
    
    
    if (stopID !== false && stopID !== undefined) {
      console.log("in stopID if statement");
      
      if (localityID !== false) {
        console.log("in localityID not false if statement");
        //find index of that locality
        localitiesIndex = findIndex(localities, function(locality) { return localityID.localeCompare(locality.id) === 0 });
        
        if (localitiesIndex === -1) {
          localitiesIndex = false;
        }

      } else if (this.state.localityID !== false) {
        console.log("in state localityID not false if statement");
        //find index of that locality
        let stateLocalityID = this.state.localityID;
        localitiesIndex = findIndex(localities, function(locality) { return stateLocalityID.localeCompare(locality.id) === 0 });
        
        if (localitiesIndex === -1) {
          localitiesIndex = false;
        }
      }

      if (localitiesIndex !== false) {
        console.log("in localitiesIndex not false if statement");
        //find location index in localities, then the index in the current state locations, if location has day timestamp then it is scheduled
        
        locationsIndex = findIndex(locations, function(location) { return locationID.localeCompare(location.location.uid) === 0 })  //check if not -1?

        if (locationsIndex !== -1) {
          let locationStop = locations[locationsIndex];

          if (locationStop.day === null) {
            console.log("in day is null if statement");
            //unscheduled stop
            
            //unscheduled is concat into scheduled stops to make selectedLocations, so the index in uncheduled locations is selectedLocations index
            //minus scheduled locations length -1

            console.log(locationStop, unscheduledStops[locationsIndex - scheduledStops.length /*- 1*/], 'if they dont match there is an index error', localities[localitiesIndex].unscheduledStops[/*locations.length*/locationsIndex - scheduledStops.length /*-1*/]);

            unscheduledStops.splice(/*locations.length*/locationsIndex - scheduledStops.length /*- 1*/, 1);

            localities[localitiesIndex].unscheduledStops.splice(/*locations.length*/locationsIndex - scheduledStops.length /*- 1*/, 1);
            locations.splice(locationsIndex, 1);

          } else {
            //scheduled stop
            console.log("in day is not null if statement");

            console.log(locationStop, scheduledStops[locationsIndex], 'if they dont match there is an index error', localities[localitiesIndex].scheduledStops[locationsIndex]);

            scheduledStops.splice(locationsIndex, 1);

            localities[localitiesIndex].scheduledStops.splice(locationsIndex, 1);
            locations.splice(locationsIndex, 1);
          }
        }
      }
    
      this.setState({
        selectedLocations: locations,
        selectedUnscheduledStops: unscheduledStops,
        selectedScheduledStops: scheduledStops,
        localities: localities
      });

    }
    /*let index;

    for (let i = 0; i < locations.length; i++) {
      if (locationID.localeCompare(locations[i].uid) === 0) {
        index = i;
        break;
      }
    }
    
    let localities = this.state.localities;
    let localitiesLocations = localities[place.id].locations;
    let localitiesIndex;

    for (let i = 0; i < localitiesLocations.length; i++) {
      if (locationID.localeCompare(localitiesLocations[i].uid) === 0) {
        localitiesIndex = i;
        break;
      }
    }
    
    localitiesLocations.splice(index, 1);
    localities[place.id].locations = localitiesLocations;

    if (localities[place.id].locations.length === 0) {
      delete localities[place.id];
    }

    this.setState({
      selectedLocations: locations,
      localities: localities
    });*/
  }

  resetSelectedLocations(localityID = false) {
    if (localityID) {
      
      let planSelectedScheduledStops = [];
      let planSelectedUnscheduledStops = [];
      let planSelectedLocations = [];

      let localitiesIndex = findIndex(this.state.localities, function(locality) {return localityID.localeCompare(locality.id) === 0});

      if (localitiesIndex !== -1) {
        planSelectedScheduledStops = this.state.localities[localitiesIndex].scheduledStops;
        planSelectedUnscheduledStops = this.state.localities[localitiesIndex].unscheduledStops;

        planSelectedLocations = planSelectedScheduledStops.concat(planSelectedUnscheduledStops);
      }
      
      this.setState({
        selectedLocations: planSelectedLocations,
        selectedScheduledStops: planSelectedScheduledStops,
        selectedUnscheduledStops: planSelectedUnscheduledStops
      })

    } else {
      this.setState({
        selectedLocations: [],
        selectedScheduledStops: [],
        selectedUnscheduledStops: []
      })
    }

    

    /*this.setState({
      selectedLocations: []
    }, () => {
      let localities = this.state.localities;
      if (localities[this.state.place.id]) {
        this.setState({ selectedLocations: localities[this.state.place.id].locations });
      }
    })*/
  }

  addPlaceDataToLocalities() {

    let localityID;

    if (this.state.localityID) {
      localityID = this.state.localityID;
    } else {
      localityID = false;
    }

    if (this.state.place && (this.state.selectedLocations.length > 0)) {

      let localities = this.state.localities;

      if (localityID) {

        let index = findIndex(localities, function(locality) {return locality.id.localeCompare(localityID) === 0});

        if (index !== -1) {

          localities[index].place = {
            lat: this.state.place.lat,
            lng: this.state.place.lng,
            name: this.state.place.name,
            objectID: this.state.place.id,
            state: this.state.place.state
          };

          localities[index].scheduledStops = this.state.selectedScheduledStops;
          localities[index].unscheduledStops = this.state.selectedUnscheduledStops;
          
          this.setState({localities: localities});
        }

      } else {
        localityID = v4();

        let newLocality = {
          scheduledStops: this.state.selectedScheduledStops,
          unscheduledStops: this.state.selectedUnscheduledStops,
          place: {
            lat: this.state.place.lat,
            lng: this.state.place.lng,
            name: this.state.place.name,
            objectID: this.state.place.id,
            state: this.state.place.state
          },
          end: null,
          start: null,
          name: this.state.place.name,
          id: localityID,
        }

        localities.push(newLocality);

        this.setState({localityID: localityID}, () => this.setState({localities: localities}));
      }
    }

    /*if (this.state.place && (this.state.selectedLocations.length > 0)) {
      let localities = this.state.localities;

      localities[this.state.place.id] = {
        locations: this.state.selectedLocations,
        place: {
          lat: this.state.place.lat,
          lng: this.state.place.lng,
          name: this.state.place.name,
          objectID: this.state.place.id,
          state: this.state.place.state
        }
      }

      this.setState({localities: localities});
    }*/
  }

  addLocationFromDiscussion(location) {
    /*let localities = this.state.localities;
    
    if (this.state.localities[location.placeID]) {
      let locations = this.state.localities[location.place.objectID].locations;
      if (findIndex(locations, function(savedLocation) { return (savedLocation.uid.localeCompare(location.uid) === 0) }) === -1){
        //not already in the array
        locations.push({service: location.service, uid: location.uid});

        localities[location.place.objectID].locations = locations;
        this.setState({localities: localities});
      }

    } else {

      localities[location.placeID] = {
        locations: [{service: location.service, uid: location.uid}],
        place: location.place
      }

      this.setState({localities: localities});
    }*/
  }

  setPlanName(name) {
    this.setState({planName: name});
  }

  setLocalityName(name, localityID) {
    let localityIndex = findIndex(this.state.localities, function(locality) { return localityID.localeCompare(locality.id) === 0 });
    
    if (localityIndex !== -1) {
      let localities = this.state.localities;
      localities[localityIndex].name = name;

      this.setState({localities: localities});
    }
  }

  async savePlanForm() {
    console.log("plan save plan");
    // moment(startDate).toDate()
    let date = new Date().getTime();

    let localities = this.state.localities;

    for (let i = 0; i < localities.length; i++) {
      if(localities[i].start !== null && localities[i].end !== null) {
        localities[i].start = moment(localities[i].start).toDate();
        localities[i].end = moment(localities[i].end).toDate();

        localities[i].start = localities[i].start.getTime();
        localities[i].end = localities[i].end.getTime();

      } else {
        localities[i].start = null;
        localities[i].end = null
      }

      for (let j = 0; j < localities[i].scheduledStops.length; j++) {
        if (localities[i].scheduledStops[j].day !== null) {
          
          localities[i].scheduledStops[j].day = localities[i].scheduledStops[j].day.getTime();
        }

        if (localities[i].scheduledStops[j].timeOfDay !== null) {
          
          localities[i].scheduledStops[j].timeOfDay = localities[i].scheduledStops[j].timeOfDay.getTime();
        }
      }

      for (let j = 0; j < localities[i].unscheduledStops.length; j++) {
        if (localities[i].unscheduledStops[j].day !== null) {
          
          localities[i].unscheduledStops[j].day = localities[i].unscheduledStops[j].day.getTime();
        }

        if (localities[i].unscheduledStops[j].timeOfDay !== null) {
          
          localities[i].unscheduledStops[j].timeOfDay = localities[i].unscheduledStops[j].timeOfDay.getTime();
        }
      }
    }

    let plan = {
      localities: this.state.localities,
      name: this.state.planName,
      owner: this.props.uid,
      users: [this.props.uid],
      start: null,
      end: null,
    }

    if ( this.props.isNew ) {
      plan.created = date //----------------------------------------------------------------------------------------use timeStamp.fromDate(date)
      plan.featuredImg = `https://firebasestorage.googleapis.com/v0/b/trip-galaxy-places/o/generic-${this.state.imageNumber}.jpg?alt=media`;
      plan.invites = this.state.invites
    } else {
      plan.created = this.state.planData.created;
      plan.featuredImg = this.state.planData.featuredImg;
      plan.objectID = this.state.planData.objectID;
    }

    //let planReturn = await savePlanData(plan);
    let data = {data: plan};
    console.log(data);

    /*let planReturn = await fetch('http://localhost:5001/trip-galaxy/us-central1/savePlan', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(response => {
      let returnData = response.json();
      console.log(returnData);
      return returnData;
    }).catch( error => {
      console.log("save plan error: ", error);
    });*/

    let planReturn = await savePlanData(plan);
    
    console.log(planReturn);
    if (planReturn && planReturn.data) {
      
      if (this.props.isNew && (this.state.invites.length > 0) && (typeof planReturn.data === 'string' || planReturn.data instanceof String)) {
        //send invites to users
        console.log("Is new plan and inviting users to plan: ", planReturn.data);
        //let invites = {data: {users: this.state.invites, plan: planReturn.data}};
        let planInvites = this.state.invites;

        for (let i = 0; i < planInvites; i++) {
          planInvites[i].plan = planReturn.data;
          planInvites[i].sender = this.props.uid;
        }

        let invites = {invites: planInvites, plan: planReturn.data, sender: this.props.uid};
        /*let invites = {data: {invites: planInvites, plan: planReturn.data, sender: this.props.uid}};

        let inviteReturn = await fetch('http://localhost:5001/trip-galaxy/us-central1/inviteUser', {
          method: 'POST',
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(invites)
        }).then(response => {
          let returnData = response.json();
          console.log(returnData);
          return returnData;
        }).catch( error => {
          console.log("save plan error: ", error);
        });
        */
        let inviteReturn = await inviteUser(invites);

        console.log(inviteReturn);

        if (inviteReturn && inviteReturn.data) {
          this.props.history.push("/");
        }
      } else {
        console.log("not new plan and returning to homepage after saving");
        this.props.history.push("/");
      }
    }
  }

  async deletePlanForm(objectID) {
    console.log("in delete plan form: ", objectID);
    let data = {objectID: objectID};
    //let data = {data: {objectID: objectID}};
    let planReturn = await deletePlanData(data);

    /*let planReturn = await fetch('http://localhost:5001/trip-galaxy/us-central1/deletePlan', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(response => {
      let returnData = response.json();
      console.log(returnData);
      return returnData;
    }).catch( error => {
      console.log("delete plan error: ", error);
    });
    */
    console.log(planReturn);
    if(planReturn && planReturn.data) {
      //let history = useHistory();
      this.props.history.push("/");
    }
  }

  async addEmailToInvite(email) {
    console.log("Adding email to invite list: ", email);
    let combinedInvites = this.state.invites;
    //load user to get uid
    //put email and uid in object and add to invites array

    //let data = { data: {email: email}};
    let data = {email: email};

    console.log(data);

    /*let userReturned = await fetch('http://localhost:5001/trip-galaxy/us-central1/getUserFromEmail', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })

    let result = await userReturned.json();
    */
    let result = await getUserFromEmail(data);
    
    console.log(result);

    if (result.data && Array.isArray(result.data) &&  !this.state.invites.includes(email)) {
      let invite = {}
      console.log("Valid email");
    
      if(result.data.length > 0) {
        
        let user = result.data;
        invite.inviteeEmail = email;
        invite.inviteeUid = user[0].uid;
        
      } else {
        invite.inviteeEmail = email;
        invite.inviteeUid = null;
        console.log("No user found with that email");
      }

      if (this.props.isNew) {
        combinedInvites.push(invite);
        this.setState({invites: combinedInvites}, () => console.log(this.state.invites));
      } else if (!this.props.isNew && this.state.planData.objectID && !(this.state.planData.users.includes(invite.inviteeUid))) {
        //call function to send invite to user
        console.log("sending invite");
        invite.plan = this.state.planData.objectID;
        invite.sender = this.props.uid;

        combinedInvites.push(invite);
        this.setState({invites: combinedInvites}, () => console.log(this.state.invites));

        let plans = await inviteUser({invites: [invite], plan: this.state.planData.objectID, sender: this.props.uid});
  
        /*let getPlansResponse = await fetch('http://localhost:5001/trip-galaxy/us-central1/inviteUser', {
          method: 'POST',
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({data: {invites: [invite], plan: this.state.planData.objectID, sender: this.props.uid}})
        });
  
        let plans = await getPlansResponse.json();
        */
      }
      
    }
    
  }

  async acceptInvite() {
    this.setState({isPlanInvitee: false, isPlanUser: true, isPlanOwner: false});
    console.log(this.state.planData.invites);
    let uid = this.props.uid;

    let index = findIndex(this.state.planData.invites, function(invite) { 
      console.log("invite: ", invite.inviteeUid.localeCompare(uid) == 0);
      return (invite.inviteeUid.localeCompare(uid) == 0);
    });

    console.log(index);
    
    if (index != -1) {
      let invitee = this.state.planData.invites[index];
      console.log(invitee);

      //let data = {data: {plan: this.state.planData.objectID, user: invitee, accepted: true}};
      let data = {plan: this.state.planData.objectID, user: invitee, accepted: true};

      let response = await inviteRemoveUser(data);

      /*let acceptInviteResponse = await fetch('http://localhost:5001/trip-galaxy/us-central1/inviteRemoveUser', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      let response = await acceptInviteResponse.json();
      */

    } else {
      console.log("couldnt find user in invites");
      this.props.history.push("/");
    }

  }

  async declineInvite() {
    this.setState({isPlanInvitee: false, isPlanUser: false, isPlanOwner: false});

    let uid = this.props.uid;

    let index = findIndex(this.state.planData.invites, function(invite) { 
      console.log("invite: ", invite.inviteeUid.localeCompare(uid) == 0);
      return (invite.inviteeUid.localeCompare(uid) == 0);
    });

    console.log(index);

    if (index != -1) {
      let invitee = this.state.planData.invites[index];
      console.log(invitee);

      //let data = {data: {plan: this.state.planData.objectID, user: invitee, accepted: false}};
      let data = {plan: this.state.planData.objectID, user: invitee, accepted: false};

      let functionReturn = await inviteRemoveUser(data);

      /*let acceptInviteResponse = await fetch('http://localhost:5001/trip-galaxy/us-central1/inviteRemoveUser', {
          method: 'POST',
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
      });

      let functionReturn = await acceptInviteResponse.json();
      */

      if(functionReturn && functionReturn.data) {
        //let history = useHistory();
        this.props.history.push("/");
      }
    } else {
      console.log("couldnt find user in invites");
      this.props.history.push("/");
    }

  }

  setDetailedLocations(details) {
    this.setState({detailedLocations: details});
  }

  removeLocality(localityID) {
    console.log("remove locality ", localityID);

    let localities = this.state.localities;
    let localitiesIndex;

    localitiesIndex = findIndex(localities, function(locality) { return localityID.localeCompare(locality.id) === 0 });

    if (localitiesIndex !== -1) {
      localities.splice(localitiesIndex, 1);
    }

    this.setState({localities: localities});
  }

  setLocationTime(time, scheduled, localityID, locationID, localityIndex = false, locationIndex = false) {
    console.log("IN SET LOCATION TIME");
    console.log(time, scheduled, localityID, locationID, localityIndex, locationIndex);

    let indexOfLocality = false;
    let indexOfLocation = false;

    let localities = this.state.localities;

    if (localityIndex === false) {
      //use find index to get the spot and add the time
      indexOfLocality = findIndex(localities, function(locality) { return localityID.localeCompare(locality.id) === 0 });

      if (indexOfLocality !== -1) {
        if (scheduled) {
          indexOfLocation = findIndex(localities[indexOfLocality].scheduledStops, function(stop) { return locationID.localeCompare(stop.id) === 0 });
        } else {
          indexOfLocation = findIndex(localities[indexOfLocality].unscheduledStops, function(stop) { return locationID.localeCompare(stop.id) === 0 });
        }

        if (indexOfLocation === -1) {
          indexOfLocation = false;
        }
        
      } else {
        indexOfLocality = false;
      }

    } else {
      //access the location using the indexes
      indexOfLocality = localityIndex;

      if (scheduled) {
        indexOfLocation = findIndex(localities[indexOfLocality].scheduledStops, function(stop) { return locationID.localeCompare(stop.id) === 0 });
      } else {
        indexOfLocation = findIndex(localities[indexOfLocality].unscheduledStops, function(stop) { return locationID.localeCompare(stop.id) === 0 });
      }

      if (indexOfLocation === -1) {
        indexOfLocation = false;
      }
    }

    if (indexOfLocality !== false && indexOfLocation !== false) {
      //let date = new Date(`January 1, 1970 ${time}:00`);
      let timeOfDay;

      if (time === null) {
        timeOfDay = null;
      } else {
        //let splitTime = time.split(": ");
        timeOfDay = new Date(`January 1, 1970 ${time}:00`);//new Date(date.getFullYear(), date.getMonth(), date.getDate(), splitTime[0], splitTime[1], 0);
      }
      
      console.log(timeOfDay);
      if (scheduled) {
        console.log(localities[indexOfLocality].scheduledStops);
        console.log(localities[indexOfLocality].scheduledStops[indexOfLocation]);
        localities[indexOfLocality].scheduledStops[indexOfLocation].timeOfDay = timeOfDay;
      } else {
        localities[indexOfLocality].unscheduledStops[indexOfLocation].timeOfDay = timeOfDay;
      }

      this.setState({localities: localities},
        () => console.log(this.state.localities));
    }

  }

  setLocalityDateRange(startDate, endDate, localityIndex = false, localityID = false) {
    console.log(startDate, endDate, localityIndex, localityID);
    let start;
    let end;
    let index;
    let localities = this.state.localities;

    //console.log("type of ", moment(startDate).toDate(), moment(startDate).toDate() instanceof Date);

    if (localityIndex !== false) {
      index = localityIndex

    } if (localityIndex === false && localityID !== false) {
      index = findIndex(localities, function(locality) {return localityID.localeCompare(locality.id) === 0});

      if (index === -1) {
        index = false
      }
    }

    if (index !== false) {

      if (startDate === null && endDate !== null) {
        localities[index].end = endDate;

      } else if (startDate !== null && endDate === null) {
        localities[index].start = startDate;

      } else if (startDate !== null && endDate !== null) {
        localities[index].start = startDate;
        localities[index].end = endDate;
      }

      this.getDatesInRange();
  
      this.setState({localities: localities});

    }
  }

  stopsOnDay(day, localityID, localityIndex = false) {
    console.log("get stops by day");
    let stops = [];
    let index = false;

    let localities = this.state.localities;

    if (localityIndex !== false) {
      index = localityIndex;
    } else {
      index = findIndex(localities, function(locality) {return localityID.localeCompare(locality.id) === 0});

      if (index === -1) {
        index = false;
      }
    }

    console.log("locality index: ", index);

    if (index !== false || index !== undefined) {
      for (let i = 0; i < localities[index].scheduledStops.length; i++) {
        //console.log("going through scheduled stops: ", localities[index].scheduledStops[i]);
        //console.log("day instanceof Date && localities[index].scheduledStops[i].day instanceof Date: ", day instanceof Date && localities[index].scheduledStops[i].day instanceof Date);
        if (day instanceof Date && localities[index].scheduledStops[i].day instanceof Date) {
          //console.log("day.getFullYear() === localities[index].scheduledStops[i].day.getFullYear(): ", day.getFullYear() === localities[index].scheduledStops[i].day.getFullYear());
          //console.log("day.getMonth() === localities[index].scheduledStops[i].day.getMonth(): ", day.getMonth() === localities[index].scheduledStops[i].day.getMonth());
          //console.log("day.getDay() === localities[index].scheduledStops[i].day.getDay(): ", day.getDay() === localities[index].scheduledStops[i].day.getDay());

          if ((day.getFullYear() === localities[index].scheduledStops[i].day.getFullYear()) && 
          (day.getMonth() === localities[index].scheduledStops[i].day.getMonth()) &&
          (day.getDay() === localities[index].scheduledStops[i].day.getDay())) {

            stops.push(localities[index].scheduledStops[i]);

          }
        }
      }

      return stops;

    } else { 
      return [];
    }
  }

  getDatesInRange() {
    let dates = [];
    let localities = this.state.localities;
		// if localitiesDates has correct length at each locality index then do nothing

		//in loop find num of days between start and end and compare to localities dates at that same index
		//			if not same, then recreate the array at that index in localitiesDates
    console.log("in get dates in range");
		for (let i = 0; i < localities.length; i++) {
      console.log("in localities #", i);
			if (localities[i].start !== null && localities[i].end !== null) {
				let range = [];
        console.log("localities #", i, " has non null range");
        let test = 0;
				if (moment.isMoment(localities[i].start) && moment.isMoment(localities[i].end)) {
					let currentDate = localities[i].start;
					currentDate = currentDate.toDate();
					console.log(currentDate instanceof Date);
					while(moment(currentDate).isBefore(localities[i].end)) {
            console.log("Current Date: ", currentDate);
						range.push(new Date(currentDate));
						//currentDate = currentDate.addDays(1);
            test = test + 1;
            currentDate.setDate(currentDate.getDate() + 1);
					}
          
          range.push(new Date(currentDate));
				}
				dates.push(range);

			} else {
				dates.push([]);
			}
		}

    console.log(dates);

    this.setState({localitiesDateRange: dates});

		//--check if date range is the same as first and last in dates array
		//if they are different, go through for loop for each date
		//make a date an object with a string with the date, and an index where the scheduled locations start having this date--
		
		//in localities structure on app, there is a stopsOnDay funtion -- put that on plan file
  }

  localitiesOnDragEnd(source, destination, draggableId) { // need locality index also

    console.log("in localities on drag end");
    if (!destination) {
      console.log("no destination");
      return;
    }

    let splitSourceID = source.droppableId.split(",");
    let splitDestinationID = destination.droppableId.split(",");
    const localityID =  splitSourceID[0];
    const sInd = splitSourceID[1];
    const dInd = splitDestinationID[1];

    if (splitSourceID[0] !== splitDestinationID[0]) {
      console.log("dropped into seperate locality");
      return;
    }

    let localities = this.state.localities;
    console.log("localities before: ", localities);

		if (sInd.localeCompare(dInd) === 0) {
      console.log("matching start and ending IDs");
			if (sInd.localeCompare('unscheduled') === 0) {
        console.log("start and ended in unscheduled");
				let unscheduled = localities[localityID].unscheduledStops;
        console.log("unscheduled before: ", unscheduled);
				let [stop] = unscheduled.splice(source.index, 1);
				unscheduled.splice(destination.index, 0, stop);
        console.log("unscheduled after: ", unscheduled);

        localities[localityID].unscheduledStops = unscheduled;

        console.log("localities: ", localities);
        this.setState({localities: localities});
			} else {

        let date = new Date(dInd);

        let scheduled = localities[localityID].scheduledStops;

        let index = findIndex(scheduled, function(scheduledStop) {
          
          return draggableId.localeCompare(scheduledStop.id) === 0
        });

        let [stop] = scheduled.splice(index, 1);

        let laststop;

        console.log(stop);
        stop.day = date;

        index = findIndex(scheduled, function(scheduledStop) {
          if (stop.day < scheduledStop.day) {
            laststop = scheduledStop;
          }
          console.log(stop.day, " -- ", scheduledStop.day)
          return stop.day.getTime() === scheduledStop.day.getTime()
        });
        console.log("lastStop: ", laststop, " - index: ", index);

        if (index === -1) {
          index = findIndex(localities[localityID].scheduledStops, function(scheduledStop) {
            return laststop.id.localeCompare(scheduledStop.id) === 0
          });

          if (index === -1) {
            index = localities[localityID].scheduledStops.length;
          }
        }
        console.log("index: ", index);

        index = index + destination.index;

        scheduled.splice(index, 0, stop);
        scheduled[index].day = date;

        localities[localityID].scheduledStops = scheduled;

        console.log("localities: ", localities);
        this.setState({localities: localities});

      }
		} else {
      //source and destination are different 
      console.log("start and ending IDs are different");
      if (sInd.localeCompare('unscheduled') === 0) {
        //add to scheduled stops
        if (localities[localityID].scheduledStops.length > 0) {
          let date = new Date(dInd);

          let unscheduled = localities[localityID].unscheduledStops;
          console.log("unscheduled before: ", unscheduled);
          let [stop] = unscheduled.splice(source.index, 1);
          let laststop = false;

          console.log(stop);
          stop.day = date;

          let index = findIndex(localities[localityID].scheduledStops, function(scheduledStop) {
            if (stop.day < scheduledStop.day) {
              laststop = scheduledStop;
            }
            console.log(stop.day, " -- ", scheduledStop.day)
            return stop.day.getTime() === scheduledStop.day.getTime()
          });
          console.log("lastStop: ", laststop, " - index: ", index);

          if (index === -1 && laststop !== false) {
            index = findIndex(localities[localityID].scheduledStops, function(scheduledStop) {
              return laststop.id.localeCompare(scheduledStop.id) === 0
            });

            if (index === -1) {
              index = localities[localityID].scheduledStops.length;
            }
          } else if (index === -1 && laststop === false) {
            index = localities[localityID].scheduledStops.length;
          }

          console.log("index: ", index);

          index = index + destination.index;

          let scheduled = localities[localityID].scheduledStops;
          scheduled.splice(index, 0, stop);
          console.log("unscheduled after: ", unscheduled);
          scheduled[index].day = date;

          localities[localityID].unscheduledStops = unscheduled;
          localities[localityID].scheduledStops = scheduled;

          console.log("localities: ", localities);
          this.setState({localities: localities});

        } else {
          let date = new Date(dInd);
          console.log("adding to scheduled stops with date: ", date);
          
          let unscheduled = localities[localityID].unscheduledStops;
          console.log("unscheduled before: ", unscheduled);
          let [stop] = unscheduled.splice(source.index, 1);
          let scheduled = localities[localityID].scheduledStops;
          scheduled.splice(destination.index, 0, stop);
          console.log("unscheduled after: ", unscheduled);
          scheduled[destination.index].day = date;

          localities[localityID].unscheduledStops = unscheduled;
          localities[localityID].scheduledStops = scheduled;

          console.log("localities: ", localities);
          this.setState({localities: localities});
        }
        

      } else {
        //add from a scheduled stops

        if (dInd.localeCompare('unscheduled') === 0) {
          console.log("start in scheduled and ended in unscheduled");
          let scheduled = localities[localityID].scheduledStops;

          //use findIndex to find the index and remove the spot, then put into destination index for unscheduled

          let index = findIndex(scheduled, function(scheduledStop) {
            
            return draggableId.localeCompare(scheduledStop.id) === 0
          });

          let [stop] = scheduled.splice(index, 1);

          

          let unscheduled = localities[localityID].unscheduledStops;
          
          unscheduled.splice(destination.index, 0, stop);
          console.log("unscheduled after: ", unscheduled);

          localities[localityID].unscheduledStops = unscheduled;
          localities[localityID].scheduledStops = scheduled;

          console.log("localities: ", localities);
          this.setState({localities: localities});

        } else {
          //scheduled to different time in scheduled

          let date = new Date(dInd);

          let scheduled = localities[localityID].scheduledStops;

          let index = findIndex(scheduled, function(scheduledStop) {
            
            return draggableId.localeCompare(scheduledStop.id) === 0
          });

          let [stop] = scheduled.splice(index, 1);

          let laststop = false;

          console.log(stop);
          stop.day = date;

          index = findIndex(scheduled, function(scheduledStop) {
            if (stop.day < scheduledStop.day) {
              laststop = scheduledStop;
            }
            console.log(stop.day, " -- ", scheduledStop.day)
            return stop.day.getTime() === scheduledStop.day.getTime()
          });
          console.log("lastStop: ", laststop, " - index: ", index);

          if (index === -1 && laststop !== false) {
            index = findIndex(localities[localityID].scheduledStops, function(scheduledStop) {
              return laststop.id.localeCompare(scheduledStop.id) === 0
            });

            if (index === -1) {
              index = localities[localityID].scheduledStops.length;
            }
          } else if (index === -1 && laststop === false) {
            index = localities[localityID].scheduledStops.length
          }
          console.log("index: ", index);

          index = index + destination.index;

          scheduled.splice(index, 0, stop);
          scheduled[index].day = date;

          localities[localityID].scheduledStops = scheduled;

          console.log("localities: ", localities);
          this.setState({localities: localities});
        }

      }
    }
  }


  render() {
    // const { tab, listings, destinationLocations, read, setPlanningView, isNew, info, uid } = this.props;
    const { tab, setPlanningView, info, locationCategories } = this.props;

    return (
      <div {...console.log(this.props.info, this.state.planData, this.state.localities, this.state.localityID, this.state.selectedLocations, this.state.selectedScheduledStops, this.state.selectedUnscheduledStops)}>
        { this.state.isPlanInvitee && !this.state.isPlanOwner &&
          <nav className="navbar navbar-light bg-light fixed-bottom">
            <button className="btn btn-outline-success px-5 btn-block" onClick={() => this.acceptInvite()} >
                Accept
              </button>
              <button className="btn btn-outline-danger px-5 btn-block" onClick={() => this.declineInvite()} >
                Decline
              </button>
          </nav>
					
				}
        {
          this.state.planSelect && !this.props.isNew &&
          <PlanLocationSelect {...this.props} selectedLocations={this.state.selectedLocations} 
          addToSelectedLocations={this.addToSelectedLocations} removeFromSelectedLocations={this.removeFromSelectedLocations}
          savePlan={this.savePlan} addPlaceDataToLocalities={this.addPlaceDataToLocalities} planId={this.props.planId} 
          plan={this.state.planData} place={this.state.place} isPlanOwner={this.state.isPlanOwner}
          selectingForMessage={this.state.selectingForMessage} addToMessageLocation={this.addToMessageLocation} localityID={this.state.localityID}/>
        }
        {
          this.state.planSelect && this.props.isNew &&
          <PlanLocationSelect {...this.props} selectedLocations={this.state.selectedLocations} 
          addToSelectedLocations={this.addToSelectedLocations} removeFromSelectedLocations={this.removeFromSelectedLocations}
          savePlan={this.savePlan} addPlaceDataToLocalities={this.addPlaceDataToLocalities} 
          planId={this.props.planId} plan={this.state.planData} place={this.state.place} isPlanOwner={this.state.isPlanOwner}
          selectingForMessage={this.state.selectingForMessage} addToMessageLocation={this.addToMessageLocation} localityID={this.state.localityID}/>
        }
        {
          this.state.planFinalize &&
          <PlanFinalization planId={this.props.planId} selectedLocations={this.state.selectedLocations} 
          selectedScheduledStops={this.state.selectedScheduledStops} selectedUnscheduledStops={this.state.selectedUnscheduledStops} uid={this.props.uid}
          removeFromSelectedLocations={this.removeFromSelectedLocations} place={this.state.place} isNew={this.props.isNew}
          backFromFinalize={this.backFromFinalize} imageNumber={this.state.imageNumber} localities={this.state.localities}
          savePlanForm={this.savePlanForm} deletePlanForm={this.deletePlanForm} planData={this.state.planData} 
          planName={this.state.planName} setPlanName={this.setPlanName} setLocalityName={this.setLocalityName} toSharePlan={this.toSharePlan} 
          isPlanOwner={this.state.isPlanOwner} planUsers={this.state.planUsers} isPlanInvitee={this.state.isPlanInvitee}
          invites={this.state.invites} isPlanUser={this.state.isPlanUser} email={this.state.email}
          fromSelectingLocationMessage={this.state.fromSelectingLocationMessage} messageLocation={this.state.messageLocation}
          selectLocationFromDiscussion={this.selectLocationFromDiscussion} removeLocationFromDiscussion={this.removeLocationFromDiscussion}
          setDetailedLocations={this.setDetailedLocations} detailedLocations={this.state.detailedLocations} removeLocality={this.removeLocality}
          setLocalityDateRange={this.setLocalityDateRange} setLocationTime={this.setLocationTime} stopsOnDay={this.stopsOnDay}
          localitiesDateRange={this.state.localitiesDateRange} localitiesOnDragEnd={this.localitiesOnDragEnd}/>
        }
        {
          this.state.planInvite &&
          <PlanInvite isNew={this.props.isNew} planData={this.state.planData} uid={this.props.uid} owner={"You"} 
          savePlan={this.savePlan} backFromFinalize={this.backFromFinalize} isPlanOwner={this.state.isPlanOwner} 
          planUsers={this.state.planUsers} addEmailToInvite={this.addEmailToInvite} isPlanInvitee={this.state.isPlanInvitee}
          invites={this.state.invites}/>
        }
        
      </div>
    );
  }
}
export default withRouter(connect(
  mapStateToProps,
  { ...Actions, ...CoreActions },
)(Plan));