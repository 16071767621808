
import geocoding from '@mapbox/mapbox-sdk/services/geocoding';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGFubXVua3RnIiwiYSI6ImNqdHo4b3ZjMDMyMWE0M20yN2NkOHFidG4ifQ.RsFGVqqQBEYWKGnXE4HDow';
const geocodingService = geocoding({ accessToken: MAPBOX_TOKEN });

export const forwardGeocode = async (query) => {
  try {
    const results = await geocodingService.forwardGeocode({ 
      query,
      countries: ['us'],
      //types: ['place']
      types: ['postcode', 'district', 'place', 'locality', 'neighborhood'],

      // config.types Array<("country" | "region" | "postcode" | "district" | "place" | "locality" | "neighborhood" | "address" | "poi" | "poi.landmark")
      // limit: 2
    }).send();

    // const body = results.body;


    console.log('results', results);
    return results.body
    /*
    if (body.features && body.features.length > 0) {
      const center = body.features[0].center;
      return { lng: center[0], lat: center[1] };
    }
    */
  } catch (error) {
    // suppress and fall-through
    return { error };
  }
}