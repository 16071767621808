// import omit from 'lodash/omit';

import * as Constants from './constants';

// import { SET_SESSION_DOCUMENT } from '../actions/constants';
const DEFAULT_STATE = { info: {}, documents: {}, scratchpad: {} };

export default (state=DEFAULT_STATE, action) => {
  var updated = Date.now();

  switch (action.type) {
    case Constants.AUTHENTICATED:    
      return { ...state, user: action.user, error: null, updated, };
    case Constants.SIGNED_OUT:
      return { ...DEFAULT_STATE, updated, user: null };
    case Constants.LOCATION_LOOKUP_SUCCESS:
      return { ...state, info: action.info };
    case Constants.LOCATION_LOOKUP_ERROR:
      return { ...state, info: { error: action.error } };

    case Constants.SET_SCRATCHPAD_VALUE:
    case Constants.CLEAR_SCRATCHPAD_VALUE:
      return { ...state, scratchpad: { ...state.scratchpad, [action.key]: action.value } };

    default:  
      return state;
  }
};