import React, {useState, useEffect} from 'react';
import IsEmpty from 'lodash/isEmpty';
import { sendMessageReactionPlan } from '../../firebase'



const DiscussionReaction = ({ uid, message, right, planID, isPlanOwner, location }) => {

  const [sending, setSending] = useState(false);
  const [hasLocation, setHasLocation] = useState(false);

  useEffect(() => {
    
    if (location && location.uid && location.place) {
      console.log("location reaction: ",location);
      setHasLocation(true);
    }
		
  }, [location]);

  const sendReaction = async (emoji) => {
    setSending(true);

    //if statement for if reaction.uid exists, if it does then delete it, else add it

    /*let data = {
      data: {
        emoji: emoji,
        uid: uid,
        messageID: message.objectID,
        planID: planID
      }
    }

    console.log(data);

    await fetch('http://localhost:5001/trip-galaxy/us-central1/reactionToMessagePlan', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(async response => {
      let result = await response.json();
      console.log(result);

      setSending(false);
    }).catch((error) => {
      console.error('Error:', error);
    });*/

    let data = {
      emoji: emoji,
      uid: uid,
      messageID: message.objectID,
      planID: planID
    }

    await sendMessageReactionPlan(data);

    setSending(false);
  }

  return (
    <>
      {
        right && 
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 align-self-end text-right">
            <div className="row">
              <div className="col pull-right text-right pr-0 mr-0">
                { !IsEmpty(message.reactions) && 
                  <div className="row pull-right text-right">
                    { Object.keys(message.reactions).map((reaction) => 
                      <div className="col-md-auto mx-0 px-0 pull-right text-right" key={reaction}>
                        {(reaction.localeCompare('👍') === 0) && Object.keys(message.reactions['👍']).length > 0 &&
                          <span className="badge badge-light">👍{Object.keys(message.reactions['👍']).length}</span>
                        }
                        {(reaction.localeCompare('👎') === 0) && Object.keys(message.reactions['👎']).length > 0 &&
                          <span className="badge badge-light">👎{Object.keys(message.reactions['👎']).length}</span>
                        }
                        {(reaction.localeCompare('😄') === 0) && Object.keys(message.reactions['😄']).length > 0 &&
                          <span className="badge badge-light">😄{Object.keys(message.reactions['😄']).length}</span>
                        }
                        {(reaction.localeCompare('💗') === 0) && Object.keys(message.reactions['💗']).length > 0 &&
                          <span className="badge badge-light">💗{Object.keys(message.reactions['💗']).length}</span>
                        }
                      </div>
                    )
                    }
                  </div>
                }
              </div>
              <div className="col-md-auto align-self-end text-right dropdown">
                <button href="#" className="badge badge-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-plus-circle" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('👍')}}}>👍</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('👎')}}}>👎</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('😄')}}}>😄</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('💗')}}}>💗</button>
                  { false && isPlanOwner && hasLocation && 
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {console.log("Adding to plan")}}}>
                    <i className="fa fa-plus-circle" />Add location to plan
                  </button>
                  }
                </div>
                { //!isEmpty(message.reactions) && 
                true &&
                  <div>
                    <i className="fa thumbs-up" />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      {
        !right && 
        <div className="row">
          <div className="col-md-6 align-self-start text-left">
            <div className="row">
              <div className="col-md-auto align-self-start text-left dropdown">
                <button href="#" className="badge badge-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-plus-circle" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('👍')}}}>👍</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('👎')}}}>👎</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('😄')}}}>😄</button>
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {sendReaction('💗')}}}>💗</button>
                  { false && isPlanOwner && hasLocation &&
                  <button className="dropdown-item" type="button" onClick={() => {if (!sending) {console.log("Adding to plan")}}}>
                    <i className="fa fa-plus-circle" />Add location to plan
                  </button>
                  }
                </div>
                { //!isEmpty(message.reactions) && 
                true &&
                  <div>
                    <i className="fa thumbs-up" />
                  </div>
                }
              </div>
              <div className="col pull-left text-left pl-0 ml-0">
                { !IsEmpty(message.reactions) && 
                  <div className="row pull-left text-left">
                    { Object.keys(message.reactions).map((reaction) => 
                      <div className="col-md-auto pull-left text-left mx-0 px-0" key={reaction}>
                        {(reaction.localeCompare('👍') === 0) &&
                          <span className="badge badge-light">👍{Object.keys(message.reactions['👍']).length}</span>
                        }
                        {(reaction.localeCompare('👎') === 0) &&
                          <span className="badge badge-light">👎{Object.keys(message.reactions['👎']).length}</span>
                        }
                        {(reaction.localeCompare('😄') === 0) &&
                          <span className="badge badge-light">😄{Object.keys(message.reactions['😄']).length}</span>
                        }
                        {(reaction.localeCompare('💗') === 0) &&
                          <span className="badge badge-light">💗{Object.keys(message.reactions['💗']).length}</span>
                        }
                      </div>
                    )
                    
                    }
                  </div>
                    }
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      } 
  </>)
}

export default DiscussionReaction;