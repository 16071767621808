import * as Constants from './constants';
import { push } from 'connected-react-router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export const signOut = () => (dispatch) => {
  firebase.auth().signOut().then(() => {
    dispatch(signedOut());
    dispatch(push('/'));
  });
}

export const authenticated = user => ({
  type: Constants.AUTHENTICATED,
  user,
});

export const signedOut = () => dispatch =>({
  type: Constants.SIGNED_OUT,
});

export const locationLookup = () => dispatch => {
  var getLocation = firebase.functions().httpsCallable('getLocation');

  getLocation().then(function(results) {
    dispatch(locationLookupSuccess(JSON.parse(results.data)));
  }).catch(error => {
    dispatch(locationLookupError(error));
  });
}

export const locationLookupSuccess = info => ({
  type: Constants.LOCATION_LOOKUP_SUCCESS,
  info,
});

export const setScratchPadValue = (key, value) => ({
  type: Constants.SET_SCRATCHPAD_VALUE,
  key,
  value,
});

export const clearScratchPadValue = (key) => ({
  type: Constants.CLEAR_SCRATCHPAD_VALUE,
  key,
});



export const locationLookupError = error => ({
  type: Constants.LOCATION_LOOKUP_ERROR,
  error,
});