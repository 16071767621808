import React from 'react';
import { Link } from 'react-router-dom';

export default ({ locationType, children, id, registerViewLocationActivity }) => {
  var path = `/location/${id}`;

  return (
    <Link to={path} onClick={registerViewLocationActivity}>
      { children }
    </Link>
  )

};
  