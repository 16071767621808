import { createSelector } from 'reselect';
import { compareByName } from '../helpers';
import omit from 'lodash/omit';

const getLocations = (state) => omit(state.locations, ['result', 'search', 'lastIdentity']);

export const filterLocations = (items, name, category, file) => {
  if (!items) { return items; }

  if (!name && !category && !file) { return items };  
  
  return items.filter(item => {
    var sName = item._source.name; 
    var sCategory = item._source.categories;
    var sFile = item._source.file;

    if (name && sName && !sName.includes(name)) {
      return false;
    }

    if (category && sCategory && !sCategory.includes(category)) {
      return false;
    }

    if (file && sFile && !sFile.includes(file)) {
      return false;
    }

    return true;

  });
}

export const selectLocationsByName = createSelector(
  [ getLocations ], (locations) => 
    Object.values(locations).sort(compareByName)  
);