import React from 'react';
import { TGField } from '../../forms';

import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import Icons from './icons';
// import LocationSubCategories from './location-sub-categories';

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.item,
  };
};

const required = value => value ? undefined : 'Required'

const LocationSubCategoryRenameForm = ({ item, pristine, handleSubmit, subCategories, save, submitting, ...rest }) =>
  <form onSubmit={handleSubmit(save)} noValidate autoComplete="off">
    <div className="container mt-4">
        <div className="row mb-2">
            <div className="col-md-12 text-left">
                <h5 className="mb-0">{item.name}</h5>
                Rename Sub-Category
            </div>
        </div>
    
      <div className="form-row">
        <div className="col-md-3 mb-2">          
          <Field name="old" component="select" className="form-control" validate={[required]}>
            <option></option>
            { 
              Object.values(subCategories).map(subCategory => 
                <option value={subCategory} key={subCategory}>{subCategory}</option>
              )
            }
          </Field>  
          </div> 

          <div className="col-md-3 mb-2">
            <Field component={TGField} className="form-control" name="new" placeholder="New Sub-Category Name" validate={[required]}/>
          </div>
        
      </div>
      

      <div className="form-row">
        <div className="col mt-2">

          <button type="submit" className="btn btn-primary pull-right" disabled={pristine || submitting}>
            Save
          </button>

          <Link to="/location-categories" className="btn btn-light pull-right mr-2">Cancel</Link>
        </div>
      </div>


    </div>
  </form>;
const Wrapped = reduxForm({
  form: "location-subcategory-rename-form",
  enableReinitialize: true, 
})(LocationSubCategoryRenameForm);

export default connect(
  mapStateToProps,
)(Wrapped);