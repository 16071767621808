import React from 'react';

import { Link } from 'react-router-dom';

export default ({ step, buttonClassName="btn-primary", buttonText="Next" }) => {
  if (step) {
    return <Link className={`btn ${buttonClassName} mr-2`} to={step.path}>{buttonText}</Link>;
  }

  return <Link className="btn btn-primary mr-2" to="/">Done</Link>;
  
}