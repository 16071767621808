const IDS = [
  '7tnnSo6p33VkMBpEAJySdHVMIV13',
  '8jRjZZb9JNUOGjE19HDzkOLV8m12',
  '9wrP6e2fmAUpoIUBQh1WZ9gl7q52',
  'Aiww6ItBP3VttUmc1wGkNB9Sk3i2',
  'FdWIGqyvvLa6tZKlOOl90fZ7Iwc2',
  'S6br6qtLdPRy4BAZPuUYp2W5ZfG3',
  'TV9YsbE5rScslNJpoAIwTByiQL63',
  'cPTQr9I9eyb5T4HXAG1ABUO1qXp2',
  'dfFwzassLNfSAUPVj1PU8MEtDYv1',
  'g49ujCixL1XgMj9NL9zIGrRUL2q2',
  'jgJgg2zzTqRjdsjacEVbPRGygVh1',
  'oA62zsxZwoUDLTf7s1lg7AL02xz2',
  'r4sIwq1Il8bfFdtIWkV5OyRQ6Pz1',
  '4QS7S0pGPZgW2ZBWWSF2plPvlRj2',
  //'z9bAWeKcDZQimhYD9uHqdRENIP62',
  'L0VVen6fAKbSFPRkc3IROx34Yng1',
  'ncoM2gJcCTXsVadrOvJPPjax7Np1',
  //'7AaeZuDZFwhO97ScqMzK7OsFK0p1',
  'mXII8WmIC7ZujAfr3gMJ28sEJUB2',
  'WwI4mAR2jYaqnWikNoQrZxdct032',
]

const IDCheck = ( id ) => {
  return IDS.includes(id);
}
  

  

export default IDCheck;