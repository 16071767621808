import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Image from '../../components/image';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { Link } from 'react-router-dom';

import { getImageAspect } from '../../helpers';

const PlanListItem = ({ plan, uid }) => {

  return (
    <div className="card border-dark pb-0 mb-0" style={{ height: '100%', marginBottom: '10px' }} {...console.log(plan)}>
      
      <Link className="btn pb-0 mb-0" to={{
      pathname: `/planning/${plan.objectID}`,
      state: plan
      }}>
        
        {
          plan.featuredImg &&
          <div>
            <div className="row overflow-hidden">
              <div className="col w-100">
                <img src={plan.featuredImg} alt={plan.name + " Image"} className="card-img-top center-block" 
                style={{ maxHeight: "150px", width: 'auto', height: 'auto'}} />
              </div>
            </div>
          </div>
        }

        {
          !plan.featuredImg &&
          <div className="py-5"></div>
        }

        <div className="card-body px-1" style={{ paddingTop: '-4px', marginTop: '0' }}>
            <h3 className="card-title text-reset">{plan.name}</h3>
            
        </div>
        { plan.owner && (typeof plan.owner === 'string' || plan.owner instanceof String) && plan.owner.localeCompare(uid) === 0 &&
        <div className="card-footer text-muted px-0 py-0">
          <p>My Plan</p>
        </div>
        }
      </Link>
    </div>
  );
}

export default (PlanListItem);