export * from "./location-helpers";

export const itemToPath = (item, multiple) => item.toLowerCase().replace(' ', '-');

export const itemsToPath = item => itemToPath(pluralize(item));

export const pluralize = (item, count=0) => {
  if (count === 1) { return item; }

  switch (item) {
    case 'Winery':          return 'Wineries';
    case 'Distillery':      return 'Distilleries';
    case 'Brewery':         return 'Breweries';
    default:                return `${item}s`
  }
}

export const isBlank = (str) => !str || str.length === 0;

export * from './reselect-helpers';

export const LANDSCAPE_IMAGE = "landscape-image";
export const SQUARE_IMAGE = "square-image";
export const PORTRAIT_IMAGE = "portrait-image";
export const UNKNOWN_ASPECT = "unknown-aspect";

export const getImageAspect = (imageSize={}) => {
  const { height, width } = imageSize;

  if (!width || width === 0 || !height || height === 0) {
    return UNKNOWN_ASPECT;
  }

  const aspect = height / width;
 
  if (aspect < 0.8) {
    return LANDSCAPE_IMAGE;
  }

  if (aspect < 1.2) {
    return SQUARE_IMAGE;
  }
  
  return PORTRAIT_IMAGE;
}