import React from 'react';
import Card from './card';

import Image, { SITE } from '../components/image';

const TempTwo = () =>  
  <div className="container mt-4 mb-1 ">
    <div className="roa">
      <div className="point-of-interest carousel slide w-100" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <Image className="d-block w-100 img-fluid" imageType={SITE} imageFileName="iStock-917834102.jpg" /> 
            <div className="carousel-caption text-left" style={{ position: "absolute", top: 5, left: 15 }}>
              <h1 style={{ fontWeight: 900 }}>SAN DIEGO</h1>
              <p style={{ fontWeight: 'bold' }} className="d-none d-md-block">
              San Diego is a city known for its beaches, parks and warm climate.  It is considered one of America's best cities for foodies. 

              </p>  
            </div>
          </div>
        </div>
      </div>    
    </div> 

    <p className="text-left">
    Offering both California cuisine, distinguished for its devotion to fresh local ingredients and farm-to-table focus, and Mexico's Baja Med cuisine, a food revolution that combines traditional Mexican ingredients with those of the Mediterranean.
San Diego's also boasts the title of “One of the World's Top Beer Cities”, according to Beer Connoisseur. The craft brew scene has garnered respect from around the globe, with more than 140 breweries and a collection of distinctive distilleries throughout the county.
    </p>
    <p className="text-left">
    There is something for wine drinkers, too, with more than 100 wineries located in all corners of the county and ample opportunities to sip local varietals at restaurants, wine bars and urban tasting rooms. In San Diego's wine region there are not busloads of tourists or over-the-top estates, but small owner-driven boutique wineries that offer friendly tours and tastings in intimate settings that reflect the laid-back San Diego lifestyle.

    </p>

    <ul className="list-group text-left">

      <Card title="La Jolla" img="iStock-490826207.jpg">
      La Jolla with its dramatic coastline boasting stunning views, it is one of the most popular beach destinations in California. Surrounded on three sides by the sea and backed by the steep slopes of Mt. Soledad, the beauty, abundance of activities and upscale village lifestyle it lives up to its nickname, "the jewel" of San Diego. La Jolla is also home to the famous Torrey Pines Golf Course.

      </Card>
      <Card title="Mission Bay" img="iStock-615906266.jpg">
      Mission Bay, located just minutes from downtown San Diego, is a 4,600 acre aquatic playground. Surrounded by 27 miles of meandering shoreline, and bordered by several beach communities, this bustling bay is action packed from sunup to sundown. 

      </Card>
      <Card title="Mission Beach and Pacific Beach" img="iStock-459230091.jpg">
      Mission Beach and Pacific Beach, with an ambiance of an eternal spring break, are the gathering place of San Diego's young adult crowd. Along the 3 mile oceanfront boardwalk there are funky surf shops, beach bars, cafes and nightclubs. Also home to Mission Beach's iconic Belmont Park amusement park and entertainment center and the WaveHouse.   

      </Card>
      <Card title="Ocean Beach" img="iStock-919788834.jpg">
      Ocean Beach has a funky vibe all its own. This area features a quirky collection of antique and vintage stores, bars, restaurants, artist studios and eccentric local haunts, most of which can be found on Newport Avenue, the main road through town. There is also a pet friendly dog beach with dog wash establishments within walking distance.

      </Card>
      <Card title="Sunset Cliffs" img="iStock-493058838.jpg">
      Sunset Cliffs is between Ocean Beach and Point Loma. Sunset Cliffs Natural Park, a 68 acre recreational area that extends 1 1/2 miles along the Point Loma peninsula western shoreline and features parklands, dramatic cliff formations and coastal sea caves. Access to the water here is limited but the setting provides some of the most picturesque views of the Pacific Ocean. 

      </Card>
      <Card title="Point Loma Peninsula">
      Point Loma Peninsula, west of downtown San Diego, includes Point Loma, Harbor Island and Shelter Island. All of these offer great restaurants, activities and attractions, as well as incredible views of the San Diego skyline.

      </Card>
      <Card title="Point Loma" img="iStock-157677283.jpg">
      Point Loma is home to the Cabrillo National Monument commemorating the landing of Juan Rodriguez Cabrillo, who sailed his ship the San Salvador in to San Diego Bay in 1542, to become the first European to set foot in California. Boasting sweeping views of the bay and downtown San Diego skyline. Some of the best tide pooling in San Diego can be found at the Old Point Loma Lighthouse. 

      </Card>
      <Card title="Harbor Island" img="iStock-515437260.jpg">
      Harbor Island features marinas, restaurants with incredible views, and a shoreline path complete with a steady ocean breeze and panoramic views of the San Diego skyline, Coronado Island and the Big Bay. Several grassy areas provide perfect picnic spots. Harbor Island is also an ideal place to enjoy the city lights by night, firework shows on the 4th of July, the annual boat Parade of Lights in December and the various sailing races that taking place in the bay.

      </Card>
      <Card title="Shelter Island" img="iStock-812192914.jpg">
      Shelter Island is one of San Diego's principal boating centers, many short and long-range sportfishing trips depart from here. This area features marinas, restaurants and bayside parks. Located at the end of Shelter Island, the Yokohama Friendship Bell  was a gift from the city of Yokohama, Japan in 1958 to commemorate the sister city relationship between San Diego and Yokohama.

      </Card>
      <Card title="North County" img="iStock-679235808.jpg">
      North County is anchored by historic Highway 101, this stretch of California coastline has some of the best surfing anywhere in the world, along with hiking, horseback riding, golf, and water sports; plus a laid-back, SoCal vibe all its own. North County offers everything from five-star restaurants to tacos to-go, along with craft brew tasting rooms, urban wineries, and seaside watering holes. 


      </Card>
      <Card title="East County" img="iStock-1062666250.jpg">
      East County boasts rolling hills, majestic mountains, sparkling lakes, deep canyons, regional and state parks that make it one of the most diverse areas in San Diego County to explore. The area offers hiking, mountain biking, rock climbing and fishing in a vast and beautiful wilderness. There is also plenty of entertainment including Las Vegas-style Native American casinos, challenging golf courses, historic sites and highways, as well as annual festivals and rodeos.

      </Card>


        <Card title="Downtown" img="iStock-857609462.jpg">
        Downtown and the Gaslamp Quarter make up the urban center of San Diego. In the historic Gaslamp Quarter, where modern architecture and Victorian buildings stand side-by-side, there are art galleries, boutiques, nightclubs, music venues, rooftop bars, gastropubs, craft beer haunts and restaurants lining the streets. Downtown features museums, Petco Park, home of the San Diego Padres, along with an abundance of restaurants, shops and nightlife. 

      </Card>
      <Card title="Hillcrest" img="iStock-493794200.jpg">
      Hillcrest located only a few miles north of San Diego's bustling downtown, the uptown neighborhood is San Diego's LGBTQ central hub, offering a array of bars, restaurants, coffee shops, boutiques and unique shops. 

      </Card>
      
      <Card title="Little Italy" img="iStock-493577796.jpg">
      Little Italy, located on the North end of Downtown, is a lively neighborhood filled with patio cafés, authentic restaurants, craft brew pubs, urban wineries, art galleries, shops, and the beautiful Amici Park. 

      </Card>
      <Card title="North Park and South Park" img="iStock-898306938.jpg">
      North Park and South Park, southeast of downtown and bordering Balboa Park, cater to a local crowd. The streets here are lined with coffee shops, boutiques, art galleries, restaurants and craft beer pubs.
  
      </Card>
    </ul>
  </div>;

export default TempTwo;