import React from 'react';

import { connectSearchBox } from 'react-instantsearch-dom';

const LocationSearchBox = ({ currentRefinement, isSearchStalled, setSearchRefinement, refine, getSearchQuery }) => (
  <form noValidate action="" role="search" {...console.log(currentRefinement)}>
    <input
      type="search"
      className="form-control"
      value={currentRefinement}
      onChange={event => {
        setSearchRefinement('locations', event.target.value);
        getSearchQuery(event);
      }}
      placeholder="Search..."
    />

    {isSearchStalled ? 'Search is stalled' : ''}
  </form>
);

export default connectSearchBox(LocationSearchBox);