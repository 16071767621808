import React from 'react';

const PlaceSearchItem = ({ place }) => {
  return (
    <span>
      { place.name + ", " + place.state }
    </span>
  );
};

export default PlaceSearchItem;