export const mapFilterInfo = (key, setPlanningTab) => filterInfo[key].map((name) => {
  return { name, id: filterInfo[name] };
});

const mapFilterIds = (key, setPlanningTab) => filterInfo[key].map((name) => {
  return filterInfo[tokenizeCategory(name)];
});

// export const mapFilterInfo;

export const tokenizeCategory = (category) =>
  decodeURI(category).toLowerCase().replace(/[ &\/]+/g, '-'); // eslint-disable-line no-useless-escape

export const getFilterIds = (item) => {
  var ids = [];
  const name = tokenizeCategory(item.name);

  console.log("-------- get filter ids", name);

  if (name === "all-eats-drinks") {
    ids = mapFilterIds('destinations');
  } else if (name === "all-things-to-do") {
    ids = mapFilterIds('thingsToDo');
  } else if (name === "all-services") {
    ids = mapFilterIds('services');
  } else {
    ids.push(filterInfo[name]);
  }

  console.log("ids", name, ids);

  return ids;
};


const filterInfo = {
  destinations: [
    "Bars",
    "Breweries",
    "Coffee Shops",
    "Distilleries",
    "Restaurants",
    "Taprooms & Wine Bars",
    "Wineries",
  ],
  thingsToDo: [
    "Arts & Entertainment",
    "Artisan Shops & Markets",
    "Gardens",
    "Historic Sites & Landmarks",
    "Indoor Sports & Games",
    "Kids & Family",
    "Museums",
    "Outdoor Adventures",
    "Parks & Recreation",
    "Resorts",
    "Shopping",
    "Spas",
    "Tourist Attractions",
    "Tours",
    "Zoos & Wildlife Preserves"
  ],
  services: [
    "Limo Services",
    "Groceries",
    "Catering",
    "Personal Chefs",
    "Private Charters",
  ],

  "groceries":                                "AVUoaNFFirVjrIt6PAce",
  "golf-courses":                             "BrJJzJ9nrB5VDtv6q2yU",
  "recommended-read-restaurants":             "EvXxm35rnOPSP03w1YSB",
  "shopping":                                 "FWkX5xaTab2ct5SOv5c4",
  "recommended-read-personal-chefs-catering": "FoWyAN2zeq5GfOxQGZSF",
  "grocery-delivery":                         "Ic4MpJR2WaXtJ0xjAbeU",
  "tours":                                    "K2o0uGVOZapPqgVBCIPE",
  "distilleries":                             "LhAFWtxIIM2p9m8pPS6K",
  "tourist-attractions":                      "MW91pjDSpUz8e2qAPeYp",
  "kids-family":                              "Pru0Ibzx3zlRPUSNaZT4",
  "spas":                                     "RpfJntaAGVN5gTDNvubG",
  "limo-service":                             "Rvbl8Ef3pCHldzB1eE3x",
  "restaurants":                              "TKDJvE3gme3Wl4sqiYyc",
  "bars":                                     "UiAd4ulERCE3FT2o24sj",
  "parks-recreation":                         "Z7MkqIkjNLixkwILe7VU",
  "driving-service":                          "ZRvvxCX29ev39jlElVO6",
  "resorts":                                  "cACnBhPw7XtICQFuKwmY",
  "catering":                                 "caxU7V4F2U8wuFQF6Bo7",
  "recommended-read-breweries":               "cjvRpEHgq6ah3ETzx2E6",
  "wineries":                                 "fUBeCwfepSAiy8bDtUsq",
  "coffee-shops":                             "iG9ybWGpKvVX1KTZ0nSl",
  "catering-personal-chefs":                  "ikDr9v8dgA4oqETMo19o",
  "recommended-read-things-to-do":            "inHOy2VMfE80U5k2deak",
  "private-charters":                         "pFYFtlBswJkZvSDB5RQd",
  "arts-entertainment":                       "sUKEPhGlUv91QfP1TWQC",
  "museums":                                  "sp4QLXFRxYUIsyXr5Pec",
  "personal-chef":                            "uKIEmMiaPiR9w5VTxbgJ",
  "indoor-sports-games":                      "uNF4FaIKMF2TAPYmQkbF",
  "gardens":                                  "uuJw0B8AIzTknuYEH3KZ",
  "zoos-wildlife-preserves":                  "vcKWGdE6BhS1e1d9HZFP",
  "historic-sites-landmarks":                 "xQZdY3jqrF2yNhUfouhS",
  "breweries":                                "zPIm8nhnBUNXFC1SLPRx",
  "artisan-shops-markets":                    "AM5IxucwUEVUALuzbIAa",
  "outdoor-adventure":                        "6RsVB5eU4R95aMYlrCNt",
  "taprooms-wine-bars":                       "uR7G6UtuL5z9SArmbt0i",
  /*
	wineries:                   "fUBeCwfepSAiy8bDtUsq", 
	breweries:                  "zPIm8nhnBUNXFC1SLPRx",
	distilleries:               "LhAFWtxIIM2p9m8pPS6K",
	"golf-courses":             "BrJJzJ9nrB5VDtv6q2yU",
	restaurants:                "TKDJvE3gme3Wl4sqiYyc",
	"coffee-shops":             "iG9ybWGpKvVX1KTZ0nSl",
	"spas":                     "RpfJntaAGVN5gTDNvubG",
	"other-things-to-do":       "9dytav9N506ofOWDjM6h",
	"limo-driving-services":    "ZRvvxCX29ev39jlElVO6", // "Rvbl8Ef3pCHldzB1eE3x",
	groceries:                  "AVUoaNFFirVjrIt6PAce",
	catering:                   "caxU7V4F2U8wuFQF6Bo7",
  "personal-chefs":           "ikDr9v8dgA4oqETMo19o",
  */
  // Recommended Read / RestaurantsEvXxm35rnOPSP03w1YSB

  // Shopping FWkX5xaTab2ct5SOv5c4
};

export default filterInfo;