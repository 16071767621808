import React, {useState, useEffect} from 'react';
import Image from '../../components/image';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { analytics } from '../../firebase/index'
//import TimePicker from 'react-time-picker';
import { Draggable } from "react-beautiful-dnd";

import './plan-grid-item.css';


const PlanFinalizationItem = ({ source, removePlanLocations, planPlace, showDetailsCard, uid, isPlanOwner, localityID, timeOfDay, setLocationTime,
	scheduled, locationID, localityIndex, locationIndex, isSubmitting, stop, stopIndex }) => {

	const [selected, setSelected] = useState(false);
	const [getawayHatchSearch, setGetawayHatchSearch] = useState(false);
	const [googleSearch, setGoogleSearch] = useState(false);
	const [yelpSearch, setYelpSearch] = useState(false);
	const [canDelete, setCanDelete] = useState(true);
	const [showSetTime, setShowSetTime] = useState(false);
	const [time, setTime] = useState('');


  useEffect(() => {
		if (source.service && (source.service.localeCompare("getaway-hatch") === 0)) {
			setGetawayHatchSearch(true);
			setGoogleSearch(false);
			setYelpSearch(false);

		} else if (source.service && (source.service.localeCompare("google-places") === 0)) {
			setGetawayHatchSearch(false);
			setGoogleSearch(true);
			setYelpSearch(false);

		} else if (source.service && (source.service.localeCompare("yelp-fusion") === 0)) {
			setGetawayHatchSearch(false);
			setGoogleSearch(false);
			setYelpSearch(true);
		}
		
  }, [source]);

	useEffect(() => {
		if (timeOfDay !== null) {
			setTime(timeOfDay);
		} else {
			setTime('');
		}
		
  }, [timeOfDay]);

  const removeButtonHandler = (event) => {
		event.preventDefault();
		if (canDelete) {
			setCanDelete(false);
			removePlanLocations(source.objectID, planPlace, localityID);
		}
	
	}
	
	const handleClick = () => {
		// analytics.logEvent('location_viewed', {
		// 	userID: uid,
		// 	locationName: source.name,
		// 	locationID: source.objectID
		// });

		showDetailsCard(source);
	}
	
	const setTimeButtonHandler = (event) => {
		event.preventDefault();

		setShowSetTime(true);
	
	}

	const setTimeOnChange = (time) => {
		console.log("set time: ", time);

		setLocationTime(time, scheduled, localityID, locationID, localityIndex, locationIndex);
	}

	//https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
	const formatAMPM = (date) => {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0'+minutes : minutes;
		let strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

  return (
		<div className="h-100">
{
		isPlanOwner && 
		<Draggable draggableId={stop.id} index={stopIndex}>
			{provided => (
			<div className="card plan-grid-item h-100" style={{ marginBottom: '10px' }}
			{...provided.draggableProps}
			
			ref={provided.innerRef}
			>
			<div className="card-body d-flex flex-column">
				<div className="clearfix card-controls">

					<div className="clearfix card-controls">
						
						<div className="btn btn-light pull-left" {...provided.dragHandleProps}>
							<i className="fa fa-bars" />
						</div>
						
						{
							!selected && 
							<button className="btn btn-sm btn-link pull-right right-button" onClick={removeButtonHandler}>
								<i className="fa fa-times-circle" />
							</button>
						}
						
					</div>
					
				</div>

				<a className="btn" onClick={() =>  handleClick()}>
					<div className="row">
						<div className="col">
							{
								getawayHatchSearch && source.imageFileName &&
								<Image imageFileName={source.imageFileName} className="card-img-top"/>
							}

							{
								googleSearch && source.imageURL &&
								<div>
									<img src={source.imageURL} alt={source.name + " Image"} className="card-img-top" style={{ maxHeight: "150px", overflow: "hidden", objectFit: 'cover'}} /> 
									<img className="float-left" style={{ maxWidth: "100px", width: 'auto', height: 'auto', objectFit: 'cover' }} src='/powered_by_google.png'></img>
								</div>
							}

							{
								yelpSearch && source.imageURL && 
								<div>
									<img src={source.imageURL} alt={source.name + " Image"} className="card-img-top" style={{ maxHeight: "150px", overflow: "hidden", objectFit: 'cover'}} /> 
									<img className="float-left" style={{ maxWidth: "40px", width: 'auto', height: 'auto', objectFit: 'cover' }} src='/yelp-title-logo.png'></img>
								</div>
							}

							{
								!source.imageURL && !getawayHatchSearch &&
								<div className="py-5"></div>
							}
						</div>
					</div>

					{ timeOfDay !== null && timeOfDay instanceof Date && !isSubmitting &&
					<div className="row">
						<div className="col">
							<div className="card-header px-1">
								<h5 className="card-title text-center">{formatAMPM(timeOfDay)}</h5>
							</div>
						</div>
					</div>
					}

					<div className="row">
						<div className="col">
							<div className="card-header px-1">
								<h5 className="card-title text-center">{source.name}</h5>
							</div>
						</div>
					</div>
				</a>
				

				{ !showSetTime && 
					<button className="btn btn-primary btn-sm pull-left left-button mt-auto" onClick={setTimeButtonHandler}>
						set time
						<i className="fa fa-edit" />
					</button>
				}
				{
					// showSetTime && !isSubmitting &&
					// <TimePicker className="mt-auto"
					// 	onChange={setTimeOnChange}
					// 	value={time}
					// />
				}
				</div>
				
			</div>
			)}
		</Draggable>
		}
		{
		!isPlanOwner && 
		<div className="card plan-grid-item h-100" style={{ height: '100%', marginBottom: '10px' }}>
				

				<a className="btn" onClick={() =>  handleClick()}>
					<div className="row">
						<div className="col">
							{
								getawayHatchSearch && source.imageFileName &&
								<Image imageFileName={source.imageFileName} className="card-img-top"/>
							}

							{
								googleSearch && source.imageURL &&
								<div>
									<img src={source.imageURL} alt={source.name + " Image"} className="card-img-top" style={{ maxHeight: "150px", overflow: "hidden", objectFit: 'cover'}} /> 
									<img className="float-left" style={{ maxWidth: "100px", width: 'auto', height: 'auto', objectFit: 'cover' }} src='/powered_by_google.png'></img>
								</div>
							}

							{
								yelpSearch && source.imageURL && 
								<div>
									<img src={source.imageURL} alt={source.name + " Image"} className="card-img-top" style={{ maxHeight: "150px", overflow: "hidden", objectFit: 'cover'}} /> 
									<img className="float-left" style={{ maxWidth: "40px", width: 'auto', height: 'auto', objectFit: 'cover' }} src='/yelp-title-logo.png'></img>
								</div>
							}

							{
								!source.imageURL && !getawayHatchSearch &&
								<div className="py-5"></div>
							}
						</div>
					</div>

					{ timeOfDay !== null && timeOfDay instanceof Date && !isSubmitting &&
					<div className="row">
						<div className="col">
							<div className="card-header px-1">
								<h5 className="card-title text-center">{formatAMPM(timeOfDay)}</h5>
							</div>
						</div>
					</div>
					}

					<div className="row">
						<div className="col">
							<div className="card-header px-1">
								<h5 className="card-title text-center">{source.name}</h5>
							</div>
						</div>
					</div>
				</a>
			</div>
			}
			</div>
  );
}

export default (PlanFinalizationItem);