import React from 'react';

import { connect } from 'react-redux';
import { Field } from 'redux-form';

const Checkbox = ({ label, name }) =>
<div className="form-check pull-left">
  <small>
    <Field component="input" type="checkbox" name={name} />  
    <label className="form-check-label ml-2">{label}</label>
  </small>
</div>;

export default connect(

)(Checkbox);


// <input className="form-check-input" type="checkbox" value="" />