import findIndex from 'lodash/findIndex';

import {
  PLANNING_SELECT_SOURCE,
  PLANNING_LOCATION_CATEGORIES_SUCCESS,
  PLANNING_LOCATION_CATEGORIES_ERROR,
  PLANNING_SET_DESTINATION_ID,
  PLANNING_SET_THINGS_TO_DO_ID,
  PLANNING_SET_INFO,
  SET_PLANNING_TAB,
  SET_PLANNING_VIEW,
  PLANNING_VIEW_GRID,
} from './constants';

const defaultState = {
  locations: {},
  planningView: PLANNING_VIEW_GRID,
};

export default (state=defaultState, action) => {
  if (action.entities && action.entities.planningLocations) {
    return { ...state, locations: action.entities.planningLocations };
  }

  switch (action.type) {
    case SET_PLANNING_TAB:
      return { ...state, tab: action.tab };
    case PLANNING_SELECT_SOURCE:
      let baseKey = (action.sourceType === 'listings' ? 'listings' : 'locations');
      let selectedKey = `${baseKey}_selected`;
      let index = findIndex(state[action.sourceType], { id: action.source.id });
      let newArray = state[action.sourceType].slice()      
      newArray.splice(index, 1)

      return {
        ...state,
        [action.sourceType]: newArray,
        [selectedKey]: [...state[selectedKey], action.source],
      };
    case PLANNING_SET_INFO:
      return { ...state, info: action.info };
    case PLANNING_LOCATION_CATEGORIES_SUCCESS:
      return { ...state, ...splitPlanLocationCategories(action.planLocationCategories) };
    case PLANNING_LOCATION_CATEGORIES_ERROR:
      return { ...state, error: action.error };
    case PLANNING_SET_DESTINATION_ID:
      return { ...state, destinationId: action.id };
    case PLANNING_SET_THINGS_TO_DO_ID:
      return { ...state, thingsToDoId: action.id };
    case SET_PLANNING_VIEW:
      return { ...state, planningView: action.planningView };
    default:
      return state;
  }
};

const categoryKey = (item) => {
  const more = item.more ? 'more_' : '';
  switch (item.location_category_type_id) {
    case 1:   return `${more}destinations`;
    case 2:   return `${more}thingsToDo`;
    default:  return `${more}services`;
  }  
}

const locationCategoryId = (locationCategories) => {
  if (locationCategories && locationCategories.length > 0) {
    return locationCategories[0].id;
  }

  return null;
}

const splitPlanLocationCategories = (planLocationCategories) => {
  var categorized = {
    destinations: [],
    more_destinations: [],
    thingsToDo: [],
    more_thingsToDo: [],
    services: [],
    more_services: [],
  };

  planLocationCategories.forEach(item => {
    categorized[categoryKey(item)].push(item);      
  });

  categorized.destinationId = locationCategoryId(categorized.destinations);
  categorized.thingsToDoId = locationCategoryId(categorized.thingsToDo);

  return categorized;
}