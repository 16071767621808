import React, {useState} from 'react';
import { connectHits } from 'react-instantsearch-dom';

function ActivityCards ({ hits }) {
    
   return (
    <div>
        <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Activity</th>
            <th scope="col">Location Name / Search Term</th>
            <th scope="col">Time</th>
          </tr>
        </thead>
          <tbody>
            {hits.map( (hit) => (
            <tr>
              <td>{hit.email}</td>
              {
                hit.activity.localeCompare("viewedLocation") === 0 &&
                <td>{"View"}</td>
              }
              {
                hit.activity.localeCompare("locationEdit") === 0 &&
                <td>{"Edit"}</td>
              }
              {
                hit.activity.localeCompare("locationAdd") === 0 &&
                <td>{"Add"}</td>
              }
              {
                hit.activity.localeCompare("locationDelete") === 0 &&
                <td>{"Delete"}</td>
              }
              {
                hit.activity.localeCompare("locationSearch") === 0 &&
                <td>{"Search"}</td>
              }
              
              {
                hit.locationName &&
                <td>{hit.locationName}</td>
              }
              {
                hit.searchTerm &&
                <td>{hit.searchTerm}</td>
              }
              {
                hit.dateTime &&
                <td>{new Date(hit.dateTime * 1000).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })}</td>
              }
            </tr>
            
            ))}
          </tbody>
        </table>
    </div>
);
    
}

export default connectHits(ActivityCards);

{/* <li className="list-inline-item">
                <div className="card border-dark mb-3" style={{width: "22rem", height: "14rem"}}>
                  <p className="text-left my-0 py-0">{(index + 1)}</p>
                    <div className="card-header bg-transparent">
                        <h5 className="card-title">{"email: " + hit.email}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text font-weight-bold h5">{"Activity: " + hit.activity}</p>
                        {
                          hit.searchTerm &&
                          <p className="card-text">{"Search Term: " + hit.searchTerm}</p>
                        }
                        {
                          hit.locationName &&
                          <p className="card-text">{"Location Name: " + hit.locationName}</p>
                        }
                    </div>
                    <div className="card-footer my-0 py-0">
                      <small className="text-muted">{"id: " + hit.objectID + " "}</small>
                      {
                        hit.locationID &&
                        <small className="text-muted">{"LocationID: " + hit.locationID}</small>
                      }
                    </div>
                </div>
            </li> */}