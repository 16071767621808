import { createSelector } from 'reselect';

export const getSteps = (state) => Object.values(state.listings.steps);
export const getStep = (state, props) => props.step;
export const getPath = (state, props) => props.match.path;

export const getStepInfo = createSelector(
  [getSteps, getPath], (steps, path) => {
    for (var i = 0; i < steps.length; i++) {
      if (path.startsWith(steps[i].path)) {
        var prevIndex = i - 1;
        var nextIndex = i + 1;
        var previous = prevIndex >= 0 ? steps[prevIndex] : null;
        var next = nextIndex < steps.length ? steps[nextIndex] : null;        
        var results =  { previous, next, current: steps[i] };

        return results;
      }
    }
  }
);

export const getStepIndex = createSelector(
  [getSteps, getStep], (steps, step) => {
    for (var i = 0; i < steps.length; i++) {
      if (step.path === steps[i].path) {
        return i;
      }
    }
  }
);

/*
export const selectLocationCategoriesByName = createSelector(
  [ getLocationCategories ], (locationCategories) => 
    Object.values(locationCategories).sort(compareByName)
  
);
*/