import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as actions from '../actions';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const mapStateToProps = (state, ownProps) => ({})

class SessionLoader extends Component {

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.props.authenticated(user);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return <div style={{ display: 'none' }}></div>;
  }
};

export default connect(
  mapStateToProps,
  { ...actions },
)(SessionLoader);