import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { getSteps, getStepIndex } from '../selectors';
import { getIDFromParams } from '../../actions/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    steps: getSteps(state),
    index: getStepIndex(state, ownProps),
    id: getIDFromParams(state),
  }
};

const ListingProgressBar = ({ index, steps, id }) =>
    <div className="card">
      <ul className="list-group list-group-flush">
        { steps.map((s, i) =>
          <li className="list-group-item" key={`${i}-${s.name}`}>
            <span className="pull-left">
              <i className={ classNames('fa mr-2', { "fa-check-circle-o": i < index, "fa-circle-o": i > index, "fa-circle": i === index })} />

              { i < index && <Link to={`${s.path}/${id}`}>{s.name}</Link> }
              { i >= index && <span>{s.name}</span> }
            </span>
            
          </li>  
        )}        
      </ul>
    </div>;


export default connect(
  mapStateToProps,
)(ListingProgressBar);