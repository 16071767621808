import React from 'react';

export default ({ hit }) => {

  return (
    <div className="card-footer bg-transparent">
      {
        hit.categories && hit.categories.map(category => 
          category.id && <span className="badge badge-secondary" key={category.id} style={{ fontSize: 'smaller', marginRight: 4 }}>{category.name}</span>
        )
      }

      {
        hit.tags && (typeof hit.tags == 'string') && hit.tags.split(', ').map(tag => 
          <span className="badge badge-light" key={tag} style={{ fontSize: 'smaller', marginRight: 4 }}>{tag}</span>
        )
      }

      {
        hit.tags && (Array.isArray(hit.tags)) && hit.tags.map(tag => 
          <span className="badge badge-light" key={tag} style={{ fontSize: 'smaller', marginRight: 4 }}>{tag}</span>
        )
      }
      <span className="font-weight-light muted">
        {hit.file}
      </span>
    </div>
  )
};