import { app } from '../../firebase';
import { push } from 'connected-react-router';
import * as Constants from '../constants';
import { takeEvery, call, put } from 'redux-saga/effects'
import isArray from 'lodash/isArray';
import get from 'lodash/get';

function cloudFunction(name, message={}) {
  console.log('cloudFunction', name, message);
  var addMessage = app.functions().httpsCallable(name);
  return addMessage(message)
}

function* invokeCloudFunction(action) {  
  console.log("calling", action.name, action.message);
  const saved = yield call(cloudFunction, action.name, action.message);
  console.log("clould function saved", saved);
  console.log("cloud function complete", action.next)
  // yield call(sfb.functions.call, action.name, action.message);

  if (action.next) {
    if (isArray(action.next) && action.next.length > 1 && saved) {      
      const path = action.next[0] + "/" + get(saved, action.next[1]);
      yield put(push(path));
    } else {
      yield put(push(action.next));
    }
  }
}

export default function* () {
  yield takeEvery(Constants.INVOKE_CLOUD_FUNCTION, invokeCloudFunction);
}