import React from 'react';

import { connect } from 'react-redux';
import { Marker } from '@react-google-maps/api';

const mapStateToProps = (state) => {
  return {};
};

const LocationMarker = ({ item, label }) => {
  
  var location = {};
  var coordinates = item.location.split(",");
  if (coordinates.length >= 2) {
    location.lat = parseFloat(coordinates[0]);
    location.lng = parseFloat(coordinates[1]);
  }

  // console.log("------ ", item, location);
  return (
    <Marker position={location} label={label} />
  )

  // return (
  //   <div style={{ color: 'white' }}>{item.location}</div>
  // )
};

export default connect(
  mapStateToProps,
)(LocationMarker);