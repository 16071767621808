import React, { Component, setState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router";

import { getUID, getUser } from '../../session/selectors';
import { getPlansByID, getInvitedPlansByID } from '../../firebase';
import moment from 'moment';

import * as Actions from '../actions';
import * as CoreActions from '../../actions/actions';

import PlanListItem from '../components/plan-list-item';

const mapStateToProps = (state, ownProps) => {
  
  return {
    uid: getUID(state),
    user: getUser(state)
    
  }
};

class PlanList extends Component {
  constructor(props) {
    super(props);
    this.getPlans = this.getPlans.bind(this);

    this._isMounted = false;
    

    this.state = {
      plans: [],
      invitedPlans: [],
      loadedPlans: false,
      loadedInvites: false,
      loadingPlans: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!this.state.loadedPlans && !this.state.loadingPlans) && this.props.uid) {
      //let plans = getPlanIDs(uid);
      console.log("has account");
      this.setState({loadingPlans: true}, async () => {
        console.log("getting plans in update");
        this.getPlans(this.props.uid);
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if ((!this.state.loadedPlans && !this.state.loadingPlans) && this.props.uid && this._isMounted) {
      //let plans = getPlanIDs(uid);
      this.setState({loadingPlans: true}, () => {
        console.log("getting plans in mount");
        this.getPlans(this.props.uid);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
 }

  async getPlans( uid ) {
    this.setState({loadingPlans: true} , async () => {
      //let plans = await getPlanIDs({uid: uid});

      /*let getPlansResponse = await fetch('http://localhost:5001/trip-galaxy/us-central1/getAllPlansByUser', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({data: {uid: uid}})
      });

      let plans = await getPlansResponse.json();
      */
      let plans = await getPlansByID({uid: uid});
      console.log(plans);
      if(plans && plans.data && this._isMounted) {
        
        /*for (let i = 0; i < plans.data.length; i++) {
          plans.data[i].created = new Date(plans.data[i].created._seconds * 1000);

          for (let j = 0; j < plans.data[i].localities.length; j++) {
            if (plans.data[i].localities[j].end !== null && plans.data[i].localities[j].start !== null) {
              plans.data[i].localities[j].end = new Date(plans.data[i].localities[j].end._seconds * 1000); //moment(new Date(plans.data[i].localities[j].end._seconds * 1000));
              plans.data[i].localities[j].start = new Date(plans.data[i].localities[j].start._seconds * 1000);//moment(new Date(plans.data[i].localities[j].start._seconds * 1000));
            }

            for (let k = 0; k < plans.data[i].localities[j].scheduledStops.length; k++) {
              if (plans.data[i].localities[j].scheduledStops[k].day !== null) {
                plans.data[i].localities[j].scheduledStops[k].day = new Date(plans.data[i].localities[j].scheduledStops[k].day._seconds * 1000);
              }

              if (plans.data[i].localities[j].scheduledStops[k].timeOfDay !== null) {
                plans.data[i].localities[j].scheduledStops[k].timeOfDay = new Date(plans.data[i].localities[j].scheduledStops[k].timeOfDay._seconds * 1000);
              }
            }

            for (let k = 0; k < plans.data[i].localities[j].unscheduledStops.length; k++) {
              if (plans.data[i].localities[j].unscheduledStops[k].day !== null) {
                plans.data[i].localities[j].unscheduledStops[k].day = new Date(plans.data[i].localities[j].unscheduledStops[k].day._seconds * 1000);
              }

              if (plans.data[i].localities[j].unscheduledStops[k].timeOfDay !== null) {
                plans.data[i].localities[j].unscheduledStops[k].timeOfDay = new Date(plans.data[i].localities[j].unscheduledStops[k].timeOfDay._seconds * 1000);
              }
            }
          }
        }*/

        this.setState({plans: plans.data, loadedPlans: true});
      }

      /*let getPlanInvitesResponse = await fetch('http://localhost:5001/trip-galaxy/us-central1/getInvitedPlansByUser', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({data: {uid: uid}})
      });

      let invites = await getPlanInvitesResponse.json();
      */
      let invites = await getInvitedPlansByID({uid: uid});
      console.log(invites);

      if(invites && invites.data && this._isMounted) {

        /*for (let i = 0; i < invites.data.length; i++) {
          //invites.data[i].created = new Date(invites.data[i].created._seconds * 1000);

          for (let j = 0; j < invites.data[i].localities.length; j++) {
            if (invites.data[i].localities[j].end !== null && invites.data[i].localities[j].start !== null) {
              invites.data[i].localities[j].end = moment(new Date(invites.data[i].localities[j].end._seconds * 1000));
              invites.data[i].localities[j].start = moment(new Date(invites.data[i].localities[j].start._seconds * 1000));
            }

            for (let k = 0; k < invites.data[i].localities[j].scheduledStops.length; k++) {
              if (invites.data[i].localities[j].scheduledStops[k].day !== null) {
                invites.data[i].localities[j].scheduledStops[k].day = new Date(invites.data[i].localities[j].scheduledStops[k].day._seconds * 1000);
              }

              if (invites.data[i].localities[j].scheduledStops[k].timeOfDay !== null) {
                invites.data[i].localities[j].scheduledStops[k].timeOfDay = new Date(invites.data[i].localities[j].scheduledStops[k].timeOfDay._seconds * 1000);
              }
            }

            for (let k = 0; k < invites.data[i].localities[j].unscheduledStops.length; k++) {
              if (invites.data[i].localities[j].unscheduledStops[k].day !== null) {
                invites.data[i].localities[j].unscheduledStops[k].day = new Date(invites.data[i].localities[j].unscheduledStops[k].day._seconds * 1000);
              }

              if (invites.data[i].localities[j].unscheduledStops[k].timeOfDay !== null) {
                invites.data[i].localities[j].unscheduledStops[k].timeOfDay = new Date(invites.data[i].localities[j].unscheduledStops[k].timeOfDay._seconds * 1000);
              }
            }
          }
        }*/

        this.setState({invitedPlans: invites.data, loadedInvites: true, loadingPlans: false});
      }
    })
    
  }

  render() {
    // const { tab, listings, destinationLocations, read, setPlanningView, isNew, info, uid } = this.props;
    const { uid } = this.props;

    return (
      <div {...console.log(this.props)}>
        { uid &&
        <div className="card">
          <h1 className="text-dark"><u>Plans</u></h1>
          
          <div className="row">
            {
              this.state.loadedPlans && !this.state.loadingPlans && this.state.plans.length > 0 &&
              this.state.plans.filter(plan => plan.objectID).map((plan) => 
              
                <div key={plan.objectID} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>
                  <PlanListItem plan={plan} uid={uid}></PlanListItem>
                </div>
              )
            }
            {
              !this.state.loadedPlans && this.state.loadingPlans && ((this.state.plans.length === 0) || (Object.keys(this.state.plans).length === 0)) &&
              
              <div className="col" >
                <p className="text-center">Loading...</p>
                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
              </div>
            }
            {
              this.state.loadedPlans && !this.state.loadingPlans && ((this.state.plans.length === 0) || (Object.keys(this.state.plans).length === 0)) &&
                <div className="col">
                  <p>No current plans. You can use the search bar above to start creating one.</p>
                </div>
            }
          </div>

          <div className="row">
                
            <div className="col">
              <p className="h3 text-dark text-left"><u>Plan Invites:</u></p>
            </div>
                
          </div>
          { !this.state.loadedInvites && this.state.loadingPlans && this.state.invitedPlans.length === 0 &&
            <div className="row" >
              <div className="col" >
                <p className="text-center">Loading...</p>
                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
              </div>
            </div>
          }

          { this.state.loadedInvites && !this.state.loadingPlans && this.state.invitedPlans.length === 0 &&
            <div className="row">
              
              <div className="col">
                <p>No current plan invites.</p>
              </div>
              
            </div>
          }
          <div className="row">
          {
            this.state.loadedInvites && !this.state.loadingPlans && this.state.invitedPlans.length > 0 &&
            this.state.invitedPlans.map((plan) => 
                <div key={plan.objectID} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{ marginBottom: '14px', marginTop: '14px' }}>
                  <PlanListItem plan={plan} uid={uid}></PlanListItem>
                </div>
              )
          }
          </div>
        </div>
      }
      </div>
      
    );
  }
}
export default withRouter(connect(
  mapStateToProps,
  { ...Actions, ...CoreActions },
)(PlanList));