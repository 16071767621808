import React from 'react';
import { Route, Switch } from 'react-router';

import Plan from './containers/plan';

export default () =>
  <Switch>
    <Route exact path="/planning/:planId?">
      <Plan />
    </Route>

    <Route exact path="/planning/category/:category?">
      <Plan />
    </Route>
  </Switch>;