import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class BookingDates extends Component {

  render() {
    return (
      <div className="container mt-2">
        <form>
          <div className="row">          
              <div className="col-md-6">
                <div className="card">
                <h5 class="card-header text-left">Your Info</h5>
                  <div className="card-body">
                    <input className="form-control mb-1" type="text" placeholder="First Name" />
                    <input className="form-control mt-1 mb-1" type="text" placeholder="Last Name" />
                    <input className="form-control mt-1 mb-1" type="text" placeholder="Email" />
                    <input className="form-control mt-1" type="text" placeholder="Phone" />
                  </div>
                </div>

                <div className="card mt-3">
                  <div className="card-body">
                    <h6 class="card-title text-left">Include message to property manager</h6>
                    <textarea className="form-control"></textarea>
                  </div>

                  <div class="card-footer text-muted text-left" style={{ fontSize: 'small'}}>
                    By clicking 'Agree & Continue' you are agreeing to our Terms and Conditions,
                    Privacy Polic and to receive booking-related texts.  Standard message rates
                    may apply.
                  </div>
                </div>

                <Link className="btn btn-primary pull-right mt-3" to="/booking/payment">Agree & Continue</Link>
              </div>

              <div className="col-md-6">   
                <div className="card">
                  <div className="card-body">
                    <h6 class="card-title text-left">Room to Roam in Santa Barbara</h6>
                    <p class="card-text text-left">Santa Barbara, CA</p>

                    <div className="form-row">
                      <div className="col-md-4">
                        <input className="form-control mb-1" type="text" placeholder="Arrive" />
                      </div>

                      <div className="col-md-4">
                        <input className="form-control mb-1" type="text" placeholder="Depart" />
                      </div>

                      <div className="col-md-4">
                        <input className="form-control mb-1" type="text" placeholder="Nights" />
                      </div>
                    </div>
                  </div>

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="pull-left">
                        $240 x 4 nights
                      </span>

                      <span className="pull-right">
                        $960.00
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span className="pull-left">
                        Cleaning Fee
                      </span>

                      <span className="pull-right">
                        $55.00
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span className="pull-left">
                        Service Fee
                      </span>

                      <span className="pull-right">
                        $106.00
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span className="pull-left" style={{ fontWeight: 'bold'}}>
                        Total
                      </span>

                      <span className="pull-left" style={{ fontWeight: 'bold'}}>
                        $1,121.00
                      </span>
                    </li>

                    <li className="list-group-item">
                      <span className="pull-left">
                        Refundable Deposit
                      </span>

                      <span className="pull-right">
                        $500.00
                      </span>
                    </li>

                    <li className="list-group-item">
                      <span className="pull-left">
                        Total + Deposit
                      </span>

                      <span className="pull-right">
                        $1,621.00
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
        </form>
      </div>
    );
  }
}

export default BookingDates;