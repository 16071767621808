import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';

import ListingProgressBar from '../components/listing-progress-bar';
import FullCalendar from 'fullcalendar-reactwrapper';
import { getStepInfo } from '../selectors';
import NextStep from '../components/next-step';

const mapStateToProps = (state, ownProps) => ({
  ...getStepInfo(state, ownProps),
});

const Calendar = ({ current, next}) =>
  <div className="container mt-4">
    <div className="row">
      <div className="col-md-3">
        <ListingProgressBar step={current} />
      </div>

      <div className="col-md-9">
        <div className="card">
          <h5 className="card-header text-left">Update your calendar</h5>        
          <div className="card-body">
          <FullCalendar
            id = "your-custom-ID"
            header = {{
              left: 'prev, next',
              center: 'title',
              right: ''
            }}
            defaultDate={Date.now()}
            navLinks= {true} // can click day/week names to navigate views
            editable= {true}
            eventLimit= {true} // allow "more" link when too many events        
          />

          </div>          
        </div>

        <div className="pull-right mt-2">
          <NextStep step={next} />
        </div>
      </div>
    </div>
  </div>

export default withRouter(connect(
  mapStateToProps,
)(Calendar));