import React, { useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
//import { Document } from 'react-pdf';
import terms from '../files/Getaway_Hatch_Terms_and_Conditions.pdf';

function TermsAndConditions () {
  const [numPages, setNumPages] = useState(null);
  const [pageArray, setPageArray] = useState([]);
 
  function onDocumentLoadSuccess({ numPages }) {
    console.log(numPages);
    let array = new Array(numPages);

    
    for (let i = 0; i < array.length; i++) {
      array[i] = i + 1;
    }

    setPageArray(array);

    setNumPages(numPages);
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-6'>
        <Document
          file={terms}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          {
          pageArray.map(page => (
            <Page key={page} pageNumber={page} />
          ))
          }
        </Document>
      </div>
    </div>
  )
}

export default TermsAndConditions;