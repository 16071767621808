import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import FirebasePlacesImage from '../../firebase/firebase-places-image';
import PlaceLink from './place-link';

function PlacesCard ({ hits }) {
    
   return (
    <div>
        <ul>
            {hits.map( hit => (
            <li className="list-inline-item" key={hit.objectID}>
                <div className="card border-dark mb-3" style={{width: "14rem", height: "22rem"}}>
                    <h5>
                        {
                        <PlaceLink id={hit.objectID} placeData={{hit}}>
                            <i className="fa fa-pencil mr-2 pull-left" />                
                        </PlaceLink>
                        }
                    </h5> 
                    <div>
                        {hit.imageFileName !== null &&
                            <FirebasePlacesImage imageFileName={hit.imageFileName} style={{ width: "100%" }} />
                        }
                        {hit.imageFileName == null && 
                            <i className="fa fa-picture-o fa-5x"></i>
                        }
                    </div> 
                    <div className="card-header bg-transparent">
                        <h5 className="card-title">{hit.name}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text">{"Latitude: " + hit.lat + ", Longitude: " + hit.lng}</p>
                    </div>
                </div>
            </li>
            ))}
        </ul>
    </div>
);
    
}

export default connectHits(PlacesCard);