import React, {useState} from 'react';
import PointsOfInterestLink from './points-of-interest-link';

function PointsOfInterestCard ({ hits }) {

    function stringOfPointsOfInterestNames(pointsOfInterest) {
        let pointsOfInterestNames = "";

        if (pointsOfInterest.length > 5) {

            for(let i = 0; i < 5; i++) {
                pointsOfInterestNames = pointsOfInterestNames.concat(pointsOfInterest[i].interestName, ", ");
            }
            

        } else {
            for(let i = 0; i < pointsOfInterest.length; i++) {
                pointsOfInterestNames = pointsOfInterestNames.concat(pointsOfInterest[i].interestName, ", ");
            }
        }

        return pointsOfInterestNames;
    }
    
   return (
    <div>
        <ul>
            {hits.map( (hit, i) => (
            <li className="list-inline-item" key={"interest" + i}>
                <div className="card border-dark mb-3" style={{width: "22rem", height: "14rem"}}>
                    <h5>
                    {
                            <PointsOfInterestLink id={hit.objectID} pointsOfInterestData={{hit}}>
                                <i className="fa fa-pencil mr-2 pull-left" />                
                            </PointsOfInterestLink>
                            }
                    </h5> 
                    {(hit.pointsOfInterest.length > 0 ) && 
                    <div className="card-header bg-transparent">
                        <h5 className="card-title">{stringOfPointsOfInterestNames(hit.pointsOfInterest)}</h5>
                    </div>
                    }
                    <div className="card-body">
                        
                        <p className="card-text">{"Number of Points Of Interest: " + hit.pointsOfInterest.length}</p>
                        <p className="card-text">{"Featured: " + hit.featured}</p>
                        
                    </div>
                </div>
            </li>
            ))}
        </ul>
    </div>
);
    
}

export default PointsOfInterestCard;