import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BookingDates from "./containers/booking-dates";
import BookingPayment from "./containers/booking-payment";
import BookingProtection from "./containers/booking-protection";
import BookingIdentity from "./containers/booking-identity";
import BookingConfirmation from "./containers/booking-confirmation";
import BookingSummary from "./containers/booking-summary";

export default () => 
  <Switch>
    <Route path="/booking/dates">
      <BookingDates />
    </Route>

    <Route path="/booking/payment">
      <BookingPayment />
    </Route>

    <Route path="/booking/protection">
      <BookingProtection />
    </Route>

    <Route path="/booking/identity">
      <BookingIdentity />
    </Route>

    <Route path="/booking/confirmation">
      <BookingConfirmation />
    </Route>

    <Route path="/booking/summary">
      <BookingSummary />
    </Route>
  </Switch>;