import React from 'react';
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import uniqBy from 'lodash/uniqBy';

export default ({ input }) => {
  const onChange = (tags) => {    
    if (tags.length === 0) {
      input.onChange(null);
      return;
    }
  
    var value = uniqBy(tags, (val) => val.toUpperCase()).sort().join(', ');
    input.onChange(value);
  }

  console.log(Array.isArray([input.value]));
  let value;

  /*if (typeof input.value == 'string') {
    value = input.value ? input.value.split(', ') : [];
  } else if (Array.isArray(input.value)) {
    input.value = input.value.join(", ");
    value = input.value ? input.value.split(', ') : [];
  }*/

  value = input.value ? input.value.split(', ') : [];
  
  
  return <TagsInput
    value={value} onChange={onChange}
    inputProps={{ placeholder: "Add a Tag", className: "tags-input" }}
  />
};