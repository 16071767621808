import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer () {

  return (
    <div>
      <div className="row justify-content-center pb-0 mb-0" style={{color: '#777', backgroundColor:'#DCDCDC'}}>
        <div className="col-3">
            <ul className="list-unstyled">
                <li className="pt-0 mt-0">
                    <p className="text-muted">Contact Us At:</p>
                </li>
                <li className="pt-0 mt-0">
                    <p className="text-muted">Email: Corp@getawayhatch.com</p>
                </li>
            </ul>
        </div>

        <div className="col-3">
            <ul className="list-unstyled">
                <li className="pt-0 mt-0">
                    <Link to="/terms-and-conditions">
                        <p className="text-muted">Terms and Conditions</p>
                    </Link>
                </li>
                <li className="pt-0 mt-0">
                    <Link to="/privacy-policy">
                        <p className="text-muted">Privacy Policy</p> 
                    </Link>
                </li>
            </ul>
        </div>

      </div>

      <div className="row justify-content-md-center py-0 my-0" style={{color: '#777', backgroundColor:'#DCDCDC'}}>
          <hr className="py-0 my-0" style={{ width: "90%", backgroundColor: "#FFFFFF", height: "1px", border: "none"}}/>
          <ul className="list-unstyled py-0 my-0">
              <li className="font-weight-light py-0 my-0">
                  <p> © 2020 Getaway Hatch LLC All Rights Reserved</p>
              </li>
          </ul>
          
      </div>
    </div>
  )
}

export default Footer;