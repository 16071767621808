import React, { Component } from 'react';

class BookingSummary extends Component {
  render() {
    return (
      <div>
        summary
      </div>
    );
  }
}

export default BookingSummary;