import React, {useState, useEffect} from 'react';

const PlanPeople = ({ user, uid, planOwner, isUser }) => {

  return (
    <div className="card border-dark" style={{ height: '100%' }}>
      {
        isUser && user.uid && (uid.localeCompare(user.uid) == 0) &&
        <div className="row">
          <div className="col-md-auto">
            <i className="fa fa-user-circle align-middle text-right"></i>
          </div>
          <div className="col-md-auto">
            <p className="text-left px-2">You</p>
          </div>
          { (uid.localeCompare(planOwner) == 0) &&
            <div className="col-md-auto">
              <p className="text-right px-2">Owner</p>
            </div>
          }
          { (uid.localeCompare(planOwner) != 0) &&
            <div className="col-md-auto">
              <p className="text-right px-2"></p>
            </div>
          }
        </div>
      }
      {
        user.uid && (uid.localeCompare(user.uid) != 0) &&
        <div className="row">
          <div className="col-md-auto">
            <i className="fa fa-user-circle align-middle text-right"></i>
          </div>
          {
            user.email && 
            <div className="col-md-auto">
              <p className="text-left px-2">{user.email}</p>
            </div>
          }
          {
            !user.email && 
            <div className="col-md-auto">
              <p className="text-left px-2">Unknown User</p>
            </div>
          }
          
          { isUser && user.uid && (user.uid.localeCompare(planOwner) == 0) &&
            <div className="col-md-auto">
              <p className="text-right px-2">Owner</p>
            </div>
          }
          { isUser && user.uid && (user.uid.localeCompare(planOwner) != 0) &&
            <div className="col-md-auto">
              <p className="text-right px-2"></p>
            </div>
          }
          { !isUser &&
            <div className="col-md-auto">
              <p className="text-right px-2">Invited</p>
            </div>
          }
        </div>
      }
      
    </div>
  );
}

export default (PlanPeople);