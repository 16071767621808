import * as Constants from './constants';

export const getDocument = (collection, id) => ({
  type: Constants.GET_DOCUMENT,
  collection,
  id,
});

export const invokeCloudFunction = (name, message, next) => ({
  type: Constants.INVOKE_CLOUD_FUNCTION,
  name,
  message,
  next,
});

export const saveDocument = (collection, item, next) => ({
  type: Constants.SAVE_DOCUMENT,
  collection,
  item,
  next,
});

export const getCollection = (collection, filters=[]) => ({
  type: Constants.GET_COLLECTION,
  collection,
  filters,
});

export const fullTextSearch = (indice, key, query) => ({
  type: Constants.FULL_TEXT_SEARCH,
  indice,
  key,
  query,
});