import React from 'react';
import { Link } from 'react-router-dom';

export default ({ children, id, pointsOfInterestData }) => {
  let path = `/featured-points-of-interest/${id}`;

  return (
    <Link to={{
      pathname: path,
      state: pointsOfInterestData
      }}>
      { children }
    </Link>
  )

};
  