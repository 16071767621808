import React from 'react';
import { connect } from 'react-redux';
import Image from '../../components/image';
import isEmpty from 'lodash/isEmpty';

import './plan-grid-item.css';
import classNames from 'classnames';
import { getImageAspect } from '../../helpers';

import PhoneNumber from '../../components/phone-number';

const PlanGridItemDetails = ({ location, /*planId, toggleLocationFavorite, favorite,*/ getawayHatchSearch,
                            googleSearch, yelpSearch, hideDetailsCard }) => {

    return(
        <div>
            <div className="row">
                <button className="btn btn-primary btn-lg pull-left left-button" onClick={() => hideDetailsCard()} >
                    <div className="fa fa-arrow-left"/>
                    Back
                    </button>
            </div>
            
            <div className="row">
                <div className="col align-self-center">
                    <div className="card border-dark text-center mx-auto" style={{ maxWidth: "500px" }}>
                        
                        <div className="card-header border-dark">
                            <h1 className="display-1 text-center text-reset font-weight-bold" style={{fontSize: '1.5rem'}}>{location.name}</h1>
                            {
                                getawayHatchSearch && location.imageFileName &&
                                <Image imageFileName={location.imageFileName} className="card-img-top" style={{ maxHeight: "400px", overflow: "hidden"}}/>
                            }

                            {
                                googleSearch && location.imageURL &&
                                <div>
                                    <img src={location.imageURL} alt={location.name + " Image"} className="card-img-top" style={{ maxHeight: "400px", overflow: "hidden"}} /> 
                                    <img className="float-left" style={{ maxWidth: "150px" }} src='/powered_by_google.png'></img>
                                </div>
                            }

                            {
                            yelpSearch && location.imageURL && 
                            <div>
                                <img src={location.imageURL} alt={location.name + " Image"} className="card-img-top" style={{ maxHeight: "400px", overflow: "hidden"}} /> 
                                <img className="float-left" style={{ maxWidth: "100px" }} src='/yelp-title-logo.png'></img>
                            </div>
                            }

                            {
                                !location.imageURL &&
                                <div className="py-5"></div>
                            }

                            {
                                location.description &&
                                <p className="text-left">{location.description}</p>
                            }
                        </div>

                        <div className="card-header border-dark pt-4" style={{backgroundColor: "#FFE4B5"}}>
                            <p className="text-left">Details</p>
                        </div>
                    
                        <div className="card-body border-dark px-1" style={{ paddingTop: '-14px', marginTop: '0' }}>
                            <ul className="list-group-flush card-text text-secondary">
                                {
                                    !isEmpty(location.phoneNumber) &&
                                    
                                    <li className="list-group-item text-left">
                                        <p className="h4 text-left text-reset">Phone</p>
                                        <p className="text-left mb-0">
                                            <PhoneNumber className="card-text text-secondary text-lighter" number={location.phoneNumber} />
                                        </p>
                                    </li>
                                }

                                <li className="list-group-item text-left">
                                    <p className="h4 text-left text-reset">Address</p>
                                    <ul className="list-group-flush card-text text-secondary text-lighter">
                                        {
                                            location.street &&
                                            <li className="list-group-item text-left">{location.street}</li>
                                        }
                                        {
                                            location.city && location.region && location.postalCode &&
                                            <li className="list-group-item text-left">{location.city}, {location.region} {location.postalCode}</li>
                                        }
                                    </ul>
                                </li>

                                {
                                    location.url && getawayHatchSearch &&
                                    <li className="list-group-item text-left">
                                        <p className="text-left mb-0">
                                            <a href={location.url} target="_blank" className="text-left" rel="noopener noreferrer">
                                            Website 
                                            <i className="fa fa-external-link ml-2" />
                                            </a>
                                        </p>  
                                    </li>
                                }

                                {
                                    location.url && googleSearch &&
                                    <li className="list-group-item text-left">
                                        <div className="row">
                                            <div className="col-xs-3">
                                                <img style={{ maxWidth: "25px" }} src='/google-icon.png'></img>
                                            </div>
                                            <div className="col-xs-3">
                                                <p className="text-left mb-0">
                                                    <a href={location.url} target="_blank" className="text-left" rel="noopener noreferrer">
                                                    Website 
                                                    <i className="fa fa-external-link ml-2" />
                                                    </a>
                                                </p> 
                                            </div>
                                        </div>
                                    </li>
                                }

                                {
                                    location.url && yelpSearch &&
                                    <li className="list-group-item text-left">
                                        <div className="row">
                                            <div className="col-xs-3">
                                                <img style={{ maxWidth: "25px" }} src='/yelp_icon.png'></img>
                                            </div>
                                            <div className="col-xs-3">
                                                <p className="text-left mb-0">
                                                    <a href={location.url} target="_blank" className="text-left" rel="noopener noreferrer">
                                                    Website 
                                                    <i className="fa fa-external-link ml-2" />
                                                    </a>
                                                </p> 
                                            </div>
                                        </div>
                                        
                                    </li>
                                }

                                <div className="card-footer border-dark mt-2 px-0">
                                {
                                    location.tags && (typeof location.tags === 'string' || location.tags instanceof String) && location.tags.split(', ').map(tag => 
                                    <span key={tag} className="badge badge-light">{tag}</span>
                                    )     
                                }

                                {
                                    location.tags && Array.isArray(location.tags) && location.tags.map(tag => 
                                    <span key={tag} className="badge badge-light">{tag}</span>
                                    )     
                                }
                                </div>
                            </ul>
                        </div>
                        {
                            location.attributions && (location.attributions.length !== 0) &&
                            <div className="card-footer mt-2 px-0">
                                <p>Google Attributions</p>
                                <div dangerouslySetInnerHTML={{__html: location.attributions[0]}}/>
                            </div>
                            
                        }
                        {
                            location.imageAttributions && (location.imageAttributions.length !== 0) &&
                            <div className="card-footer mt-2 px-0">
                                <p className="px-0 py-0 mx-0 my-0">Image Attributions</p>
                                <div className="px-0 py-0 mx-0 my-0" dangerouslySetInnerHTML={{__html: location.imageAttributions[0]}}/>
                            </div>
                        }
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default PlanGridItemDetails;