import React from 'react';
import { connect } from 'react-redux';

import TripGalaxyMapContainer from './trip-galaxy-map-container';

const mapStateToProps = state => {
  return {
    latitude: state.session.info.latitude,
    longitude: state.session.info.longitude,
  };
};

const LocationsMap = ({ latitude, longitude, children }) => {
  if (!latitude || !longitude) {
    return (<div>Loading location...</div>);
  }

  return (
    <TripGalaxyMapContainer width={800} height={600} lat={latitude} lng={longitude}>
      {children}
    </TripGalaxyMapContainer>
  );
};

export default connect (
  mapStateToProps
)(LocationsMap);