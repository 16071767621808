export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNED_OUT = "SIGNED_OUT";

export const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException";
export const PASSWORD_RESET_REQUIRED_EXCEPTION = "PasswordResetRequiredException";

export const LOCATION_LOOKUP_SUCCESS = "/session/LOCATION_LOOKUP_SUCCESS";
export const LOCATION_LOOKUP_ERROR = "/session/LOCATION_LOOKUP_ERROR";

export const SET_SCRATCHPAD_VALUE = '/session/SET_SCRATCHPAD_VALUE';
export const CLEAR_SCRATCHPAD_VALUE = '/session/CLEAR_SCRATCHPAD_VALUE';