import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import browserImageSize from 'browser-image-size';
import * as actions from '../actions';
import LocationForm from '../components/location-form';
import { copy, trimLocationValues } from '../shared-functions';
import * as Actions from '../../actions/actions';
import { selectDocument, selectCollection } from '../../actions/selectors';

import { setScratchPadValue, clearScratchPadValue } from '../../session/actions';
import { getUID, getUser } from '../../session/selectors';

import { invoke, analytics, addActivity, getReadsFilters } from '../../firebase';

import findIndex from 'lodash/findIndex';
// import remove from 'lodash/remove';
// import { func } from 'prop-types';

const mapStateToProps = (state, ownProps) => { 
  var id = ownProps.match.params.id;
  var item;
  
  if (id === 'new' && state.session.scratchpad.location) {
    item = state.session.scratchpad.location;
  } else {
    item = selectDocument(state, 'locations', id) || { region: 'CA' };
  }
  
  return {
    id,
    item,
    uid: getUID(state),
    user: getUser(state)
    // locationCategories: selectCollection(state, 'locationCategories'),
  };
};

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      sizes: [],
      isSubmitting: false,
      locationCategories: [],
      // readsRegions: [],
      // readsCities: [],
      // readsGroups: [],
      // readsRegionsLoading: false,
      // readsCitiesLoading: false,
      // readsSelectedRegion: '',
      // readsSelectedCity: ''
    };

    this.setImages = this.setImages.bind(this);
    this.saveLocationAndUploadImages = this.saveLocationAndUploadImages.bind(this);
    this.copyLocation = this.copyLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    // this.getReads = this.getReads.bind(this);
    // this.getCities = this.getCities.bind(this);
    // this.setRegion = this.setRegion.bind(this);
    // this.setCity = this.setCity.bind(this);
  }

  setImages(images) {
    console.log("set images", images);
    var sizes = [];
    if (images.length > 0) {
      browserImageSize(images[0]).then((size) => {
        console.log("size", size);
        sizes.push(size);
        this.setState({ ...this.state, images, sizes });
      })
    } else {
      this.setState({ ...this.state, images, sizes });
    }
  }

  copyLocation(){
    copy(this.props);
  }

  async saveLocationAndUploadImages(values) {
    console.log('saving location', values);

    values = trimLocationValues(values);
    
    this.setState({ ...this.state, isSubmitting: true });

    //go through all category ids in values and check to see if they are in the locationCategories -------------------------------put this in location card form also!!!!!!!!!!
    //if they are not, remove them
    let categoriesToRemove = [];
    // for (let i = 0; i < values.categories.length; i++) {
    //   let index = findIndex(this.state.locationCategories, function(category) { return category.objectID === values.categories[i]});
    //   if (index === -1) {
    //     console.log(values.categories[i], ' category doesnt exist in categories anymore');
    //     categoriesToRemove.push(values.categories[i]);
    //   } else {
    //     console.log(values.categories[i], ' category exists');
    //   }
    // }

    // for (let i = 0; i < categoriesToRemove.length; i++) {
    //   remove(values.categories, function(category) {return category === categoriesToRemove[i]});
    // }

    for (let i = 0; i < values.categories.length; i++) {
      let index = findIndex(this.state.locationCategories, function(category) { return category.objectID === values.categories[i]});
      if (index === -1) {
        console.log(values.categories[i], ' category doesnt exist in categories anymore');
        values.categories.splice(i, 1);
        i--;
      } else {
        console.log(values.categories[i], ' category exists');
      }
    }
    
    console.log(values.categories);

    /*if (values.tags) {
      if (typeof values.tags == 'string') {
        values.tags = values.tags ? values.tags.split(', ') : [];
      }
      
    } else {
      values.tags = [];
    }*/

    if (this.props.id.localeCompare('new') == 0 ) {
      console.log("analytics: new location created");
      analytics.logEvent('location_created', {
        userID: this.props.uid,
        locationName: values.name,
      });

      let activity = {
        email: this.props.user.email,
        locationName: values.name,
        activity: 'locationAdd'
      }

      await addActivity(activity);

    } else {
      console.log("analytics: location updated");
      analytics.logEvent('location_edited', {
        userID: this.props.uid,
        locationName: values.name,
        locationID: this.props.id
      });

      let activity = {
        email: this.props.user.email,
        locationName: values.name,
        locationID: this.props.id,
        activity: 'locationEdit'
      }

      await addActivity(activity);

    }

   
    if (values._geoloc && (typeof values._geoloc.lat === 'string' || values._geoloc.lat instanceof String)) {
      let lat = parseFloat(values._geoloc.lat);
      let lng = parseFloat(values._geoloc.lng);
      values._geoloc.lat = lat;
      values._geoloc.lng = lng;
    }

    this.props.saveLocation(values, this.state.images, this.state.sizes);
  }

  async deleteLocation(id) {
    analytics.logEvent('location_deleted', {
      userID: this.props.uid,
      locationName: this.props.item.name,
      locationID: id
    });

    let activity = {
      email: this.props.user.email,
      locationName: this.props.item.name,
      locationID: id,
      activity: 'locationDelete'
    }

    await addActivity(activity);

    this.props.archiveLocation(id);
  }

  async componentDidMount() {
    const { getCollection, id, lastIdentity } = this.props;

    getCollection('locationCategories');
    // getCollection('locationCategories');

    if (id !== 'new') {
      this.props.getDocument('locations', id);

      lastIdentity(id);
    } else {
      this.props.clearScratchPadValue('location');
    }


    var response = await invoke('locationCategories');
    console.log("locationCategories", response);
    var locationCategories = [];

    if (response && response.data) {

      response.data.forEach(locationCategory => {
        locationCategories.push(locationCategory);

        if (locationCategory.children) {
          locationCategory.children.forEach(child => {
            locationCategories.push({ ...child, name: `${locationCategory.name} > ${child.name}` });
          });
        }
      });
    }
    
    this.setState({
      ...this.state,
      locationCategories,
    });
  }

  // async getReads() {
  //   console.log("called get reads");
  //   this.setState({ ...this.state, readsRegionsLoading: true });
    

  //   let complete = getReadsFilters({});

  //   complete.then((results) => {
  //     console.log(results);
  //     this.setState({ ...this.state, readsRegionsLoading: false, readsRegions: results.data });
  //   });
  // }

  // async getCities() {
  //   console.log('called get cities');
  //   let index = findIndex(this.state.readsRegions, (region) => { 
      
  //     return region.region == this.state.readsSelectedRegion; 
  //   });

  //   if (index !== -1) {
  //   //use readsSelectedRegion
  //   //console.log(this.state.readsSelectedRegion);
  //   this.setState({ ...this.state, readsCitiesLoading: true });

  //   let complete = getReadsFilters({regionID: this.state.readsRegions[index].objectID});

  //   complete.then((results) => {
  //     console.log(results);
  //     this.setState({ ...this.state, readsCitiesLoading: false, readsCities: results.data });
  //   });
  //   } else {
  //     console.log('couldnt find matching region ID');
  //   }
    
  // }

  // setRegion(region) {
  //   console.log("in set region");
  //   console.log(region);
  //   this.setState({
  //     ...this.state,
  //     readsSelectedRegion: region,
  //   });
  // }

  // setCity(city) {
  //   console.log("in set city");
  //   console.log(city);
  //   this.setState({
  //     ...this.state,
  //     readsSelectedcity: city,
  //   });
  // }

  render() {
    return (
      <div className="container mt-4">        
        <h2 className="text-left">{this.props.locationType}</h2>
        <LocationForm
          {...this.props}
          locationCategories={this.state.locationCategories}
          // readsRegions={this.state.readsRegions}
          // readsCities={this.state.readsCities}
          setImages={this.setImages}
          isSubmitting={this.state.isSubmitting}
          // readsRegionsLoading={this.state.readsRegionsLoading}
          // readsCitiesLoading={this.state.readsCitiesLoading}
          saveLocationAndUploadImages={this.saveLocationAndUploadImages}
          images={this.state.images}
          copy={this.copyLocation}
          deleteLocation={this.deleteLocation}
          // getReads={this.getReads}
          // getCities={this.getCities}
          // setRegion={this.setRegion}
          // readsSelectedRegion={this.readsSelectedRegion}
          // readsSelectedCity={this.readsSelectedCity}
          // readsGroups={this.readsGroups}
          // setCity={this.setCity}
        />
      </div>
    );
  }
};

export default withRouter(connect(
  mapStateToProps,
  { ...actions, ...Actions, setScratchPadValue, clearScratchPadValue },
)(Location));